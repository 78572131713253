import React, { useEffect } from 'react';
import Appearance from 'styles/Appearance.js';

const PasswordValidator = ({ onValidationChange, style, value }) => {

    const onTextChange = () => {

        // determine if any of the requirements have not been met
        let invalid = getKeys().find(key => validateKey(key) ? false : true);

        // notify subscribers that validation has changed
        if(typeof(onValidationChange) === 'function') {
            onValidationChange(invalid ? false : true);
        }
    }

    const getComponents = () => {
        return getKeys().map((key, index) => {
            let validated = validateKey(key);
            return (
                <div 
                key={index}
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    flexGrow: 1,
                    padding: 2,
                    width: '50%'
                }}>
                    <img 
                    src={`images/password-validation-checkmark-${validated ? 'green' : 'grey'}.png`}
                    style={{
                        height: 15,
                        objectFit: 'contain',
                        width: 15
                    }} />
                    <span style={{
                        ...Appearance.textStyles.key(),
                        flexGrow: 1,
                        marginLeft: 6,
                        width: '100%'
                    }}>{keyToText(key)}</span>
                </div>
            )
        })
    }

    const getKeys = () => {
        return ['length', 'lowercase_character', 'number', 'uppercase_character'];
    }

    const keyToText = key => {
        switch(key) {
            case 'length':
            return '10 Characters Minimum';

            case 'lowercase_character':
            return 'Lowercase Characters';

            case 'number':
            return 'Numbers';

            case 'uppercase_character':
            return 'Uppercase Characters';

            default:
            return false;
        }
    }

    const validateKey = key => {
        switch(key) {
            case 'length':
            return value?.length >= 10;

            case 'lowercase_character':
            return value?.length > 0 && /[a-z]/.test(value);

            case 'number':
            return value?.length > 0 && /[0-9]/.test(value);

            case 'uppercase_character':
            return value?.length > 0 && /[A-Z]/.test(value);

            default:
            return false;
        }
    }

    useEffect(() => {
        onTextChange();
    }, [value]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            minWidth: 0,
            width: '100%',
            ...style
        }}>
            {getComponents()}
        </div>
    )
}

export default PasswordValidator;