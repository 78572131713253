import React, { useRef, useState, useEffect, Component } from 'react';

import { convertToRaw } from 'draft-js';
import { hasGrantedAllScopesGoogle, useGoogleLogin } from '@react-oauth/google';
import moment from 'moment-timezone';
import update from 'immutability-helper';

import Abstract from 'classes/Abstract.js';
import { AddPaymentMethod, NewSubscriptionRequest, SubscriptionDetails } from 'managers/Payments.js';
import AltFieldMapper, { AltFieldItem, validateRequiredFields } from 'views/AltFieldMapper.js';
import Appearance from 'styles/Appearance.js';
import Content from 'managers/Content.js';
import Cookies from 'js-cookie';
import Dealership from 'classes/Dealership.js';
import DealershipLookupField from 'views/DealershipLookupField.js';
import FieldMapper from 'views/FieldMapper.js';
import Layer, { CollapseArrow, LayerItem } from 'structure/Layer.js';
import LottieView from 'views/Lottie.js';
import { Map } from 'views/MapElements.js';
import { OmniShieldWhiteLabelChangeRequestDetails } from 'managers/OmniShield.js';
import OmniShieldWhiteLabel from 'classes/OmniShieldWhiteLabel.js';
import PageControl from 'views/PageControl.js';
import Panel from 'structure/Panel.js';
import Payment from 'classes/Payment.js';
import ProgressBar from 'views/ProgressBar.js';
import Request from 'files/Request.js';
import RichTextEditor from 'views/RichTextEditor.js';
import Sector from 'classes/Sector.js';
import { SectorDetails } from 'managers/Sectors.js';
import SystemEventsList from 'views/SystemEventsList.js';
import { TableListHeader, TableListRow } from 'views/TableList.js';
import TextField from 'views/TextField.js';
import TextView from 'views/TextView.js';
import User from 'classes/User.js';
import { UserDetails } from 'managers/Users.js';
import Utils from 'files/Utils.js';
import Views, { AltBadge } from 'views/Main.js';

export const AddEditDealership = ({ isNewTarget, onAddDealership, requireDealer = true, showNewTargetConfirmation = true }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? `new_dealership` : `edit_dealership_${abstract.getID()}`;
    
    const [dealership, setDealership] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [shouldRequestGoogleAccess, setShouldRequestGoogleAccess] = useState(false);

    const onEditGoogleBusinessProfileClick = () => {

        // prompt user to give access to google business profile if not already setup
        let { google_business_profile = {} } = dealership.preferences || {};
        if(google_business_profile.configured !== true) {
            utils.alert.show({
                title: 'Google Business Profile',
                message: `You'll need to sign-in with your Google business account before we can link your profile to your dealership.`,
                buttons: [{
                    key: 'continue',
                    title: 'Sign-In',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Cancel',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(key === 'continue') {
                        setShouldRequestGoogleAccess(moment().unix());
                        return;
                    }
                }
            });
            return;
        }

        // open editing layer for google business profile preferences
        onShowGoogleBusinessProfileEditingLayer();
    }

    const onGoogleAuthSuccess = result => {

        // remove flag for google access component
        setShouldRequestGoogleAccess(false);

        // update abstract target with new profile object and update local edits
        abstract.object.preferences.google_business_profile = result;
        onUpdateTarget({ preferences: abstract.object.preferences });

        // notify subscribers of data change
        utils.content.update(abstract);
    }

    const onShowGoogleBusinessProfileEditingLayer = () => {
        utils.layer.open({
            abstract: abstract,
            Component: EditGoogleBusinessProfilePreferences.bind(this, {
                onChange: result => {

                    // update abstract target with new profile object
                    abstract.object.preferences.google_business_profile = result;

                    // update locate state with new edits
                    let edits = abstract.object.set({ preferences: abstract.object.preferences });
                    setDealership(edits);

                    // sbumit changes to server
                    onSubmit('google_business_profile');
                },
                onRemove: () => {

                    // remove google business profile from preferences
                    abstract.object.preferences.google_business_profile = { configured: false };

                    // update edits for target and local state
                    let edits = abstract.object.set({ preferences: abstract.object.preferences });
                    setDealership(edits);

                    // submit changes to server
                    onSubmit('google_business_profile');
                }
            }),
            id: `edit_google_busines_profile_preferences_${abstract.getID()}`
        });
    }

    const onSubmit = async sender => {
        try {

            // set loading flag and validate required fields
            setLoading('done');
            await validateRequiredFields(getFields);

            // submit request to server
            let { change_request_id } = await abstract.object.apply(utils, isNewTarget);
            setLoading(false);

            // no additional logic is required if the sender is google_business_profile
            if(sender === 'google_business_profile'){
                return;
            }

            // notify subscribers of new dealership creation if applicable
            if(isNewTarget && typeof(onAddDealership) === 'function') {
                onAddDealership(abstract.object);
            }

            // prevent showing confirmation alert if applicable
            if(showNewTargetConfirmation === false) {
                setLayerState('close');
                return;
            }

            // determine if a change request was generated from the changes
            if(change_request_id) {
                utils.alert.show({
                    title: 'All Done!',
                    message: `The "${dealership.name}" dealership has been updated. Some of your changes require approval before they become available. We'll notify you once we have made a decision regarding your change request.`,
                    onClick: setLayerState.bind(this, 'close')
                });
                return;
            }

            // show default confirmation alert
            utils.alert.show({
                title: 'All Done!',
                message: `The "${dealership.name}" dealership has been ${isNewTarget ? 'created' : 'updated'}`,
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this dealership. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = async props => {
        try {
            let edits = await abstract.object.set(props);
            setDealership(edits);
        } catch(e) {
            console.log(e.message);
        }
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'done',
            loading: loading === 'done',
            onClick: onSubmit,
            text: isNewTarget ? 'Done' : 'Save Changes'
        }];
    }

    const getFields = () => {

        // prevent moving forward if no dealership edits have posted
        if(!dealership) {
            return [];
        }

        // declare google business profile and protection invoice objects
        let { google_business_profile = {}, protection_invoice = {} } = dealership.preferences || {};

        // prepare default list of items
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'dealer',
                required: requireDealer,
                visible: requireDealer,
                title: 'Dealer',
                description: 'Who is the dealer for this dealership? This user may receive emails, text messages, and push notifications meant for this dealership.',
                value: dealership.dealer,
                component: 'user_lookup',
                onChange: user => onUpdateTarget({ dealer: user }),
                props: {
                    restrictToDealership: false,
                    levels: [
                        User.levels.get().admin,
                        User.levels.get().region_director,
                        User.levels.get().division_director,
                        User.levels.get().area_director,
                        User.levels.get().dealer
                    ]
                }
            },{
                key: 'name',
                title: 'Name',
                description: 'What do you want to call this dealership? The dealership name will be visible across AFT, Global Data, and OmniShield.',
                value: dealership.name,
                component: 'textfield',
                onChange: text => onUpdateTarget({ name: text })
            },{
                key: 'phone_number',
                title: 'Phone Number',
                description: 'How should we contact this dealership? The dealership phone number will be visible across AFT, Global Data, and OmniShield.',
                value: dealership.phone_number,
                component: 'textfield',
                props: { format: 'phone_number' },
                onChange: text => onUpdateTarget({ phone_number: text })
            }]
        },{
            key: 'location',
            title: 'Location',
            items: [{
                key: 'address',
                title: 'Physical Address',
                description: 'What is the primary address for this dealership?',
                component: 'address_lookup',
                onChange: props => onUpdateTarget(props),
                value: dealership.address && {
                    address: dealership.address,
                    location: dealership.location
                }
            },{
                key: 'timezone',
                title: 'Timezone',
                description: 'We use the timezone to format dates for the timezone where this dealership is located',
                component: 'timezone_picker',
                value: dealership.timezone,
                onChange: zone => onUpdateTarget({ timezone: zone })
            }]
        },{
            key: 'marketing',
            title: 'Marketing',
            items: [{
                key: 'alias',
                title: 'Alias',
                description: 'This alias is used for the dealership website, trainee signup links, and any other scenarios where a public dealership alias is required.',
                component: 'textfield',
                value: dealership.alias,
                onChange: text => onUpdateTarget({ alias: text })
            },{
                required: protection_invoice.reviews?.type === 'google' ? true : false,
                key: 'preferences.google_business_profile',
                title: 'Google Business Profile',
                description: 'Adding your Google business listing allows us to offer up features geared towards improving your Google reputation.',
                component: 'textfield',
                onClick: onEditGoogleBusinessProfileClick,
                props: {
                    placeholder: 'Click to configure'
                },
                value: google_business_profile.name
            }]
        },{
            key: 'protection_invoice',
            title: 'Customer Protection Invoice',
            items: getProtectionInvoiceItems()
        }];
    }

    const getProtectionInvoiceItems = () => {

        // declare default protection invoice object
        let { protection_invoice = {} } = dealership.preferences || {};

        // prepare default items for section
        let items = [{
            component: 'list',
            description: `The customer protection invoice includes a link to leave a review for your dealership. This link can be customized to direct the customer to the review site of your choosing.`,
            items: getProtectionReviewItems(),
            key: 'preferences.protection_invoice.reviews',
            onChange: item => {
                onUpdateTarget({
                    preferences: update(dealership.preferences, {
                        protection_invoice: {
                            $set: {
                                ...dealership.preferences.protection_invoice,
                                reviews: {
                                    ...dealership.preferences.protection_invoice?.reviews,
                                    type: item && item.id,
                                    url: null
                                }
                            }
                        }
                    })
                });
            },
            required: false,
            title: 'Reviews',
            value: getProtectionReviewItems().find(item => item.id === protection_invoice.reviews?.type)?.title
        }];

        // determine which additional items are required using the reviews type
        if(protection_invoice.reviews?.type && ['google', 'replicated_website'].includes(protection_invoice.reviews?.type) === false) {
            items.push({
                component: 'textfield',
                description: `Please provide the url to direct customers to your review website.`,
                key: 'preferences.protection_invoice.reviews.url',
                onChange: text => {
                    onUpdateTarget({
                        preferences: update(dealership.preferences, {
                            protection_invoice: {
                                $set: {
                                    ...dealership.preferences.protection_invoice,
                                    reviews: {
                                        ...dealership.preferences.protection_invoice?.reviews,
                                        url: text
                                    }
                                }
                            }
                        })
                    });
                },
                title: protection_invoice.reviews.type === 'custom' ? 'Custom URL' : `${getProtectionReviewItems().find(item => item.id === protection_invoice.reviews.type)?.title} URL`,
                value: protection_invoice.reviews?.url
            });
        }

        return items;
    }

    const getProtectionReviewItems = () => {
        return [{
            id: 'bbb',
            title: 'Better Business Bureau'
        },{
            id: 'custom',
            title: 'Custom URL'
        },{
            id: 'facebook',
            title: 'Facebook'
        },{
            id: 'google',
            title: 'Google Reviews'
        },{
            id: 'instagram',
            title: 'Instagram'
        },{
            id: 'linked_in',
            title: 'Linked-In'
        },{
            id: 'trustpilot',
            title: 'Trust Pilot'
        },{
            id: 'yelp',
            title: 'Yelp'
        }];
    }

    const setupTarget = async () => {
        try {
            let edits = await abstract.object.open();
            setDealership(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up this dealership. ${e.message || 'An unknown error occurred'}`,
                onClick: setLayerState.bind(this, 'close')
            });
        }
    }

    useEffect(() => {
        setupTarget();
        utils.content.subscribe(layerID, ['dealership', 'omnishield_white_label_change_request'], {
            onUpdate: next => {
                switch(next.type) {
                    case 'dealership':
                    if(next.getID() === abstract.getID()) {
                        abstract.object = next.object;
                        setupTarget();
                    }
                    break;

                    case 'omnishield_white_label_change_request':
                    let { change_request } = abstract.object.omnishield || {};
                    if(change_request && change_request.id === next.getID()) {
                        abstract.object.omnishield.change_request.status = next.object.status;
                        setDealership(abstract.object);
                    } 
                    break;
                }
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={isNewTarget ? `New Dealership` : `Editing ${abstract.getTitle()}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            fields={getFields()} 
            utils={utils} />
            {shouldRequestGoogleAccess && (
                <GoogleBusinessProfileAccessManager 
                dealership={abstract.object} 
                nonce={shouldRequestGoogleAccess}
                onAuthorized={onGoogleAuthSuccess}
                onError={setShouldRequestGoogleAccess.bind(this, false)}
                onLoadingChange={setLoading}
                utils={utils} />
            )}
        </Layer>
    )
}

export const Dealerships = ({ index, options, utils }) => {

    const panelID = 'dealerships';
    const limit = 15;
    const offset = useRef(0);
    const showInactive = useRef(false);
    const sorting = useRef({
        sort_key: 'name',
        sort_type: Content.sorting.type.ascending
    });

    const [dealerships, setDealerships] = useState([]);
    const [loading, setLoading] = useState(null);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onChangeActiveStatus = async (dealership, evt) => {
        try {
            evt.stopPropagation();
            let next_status = !dealership.active;
            await Request.post(utils, '/dealerships/', {
                type: 'set_active_status',
                id: dealership.id,
                active: next_status
            });

            dealership.active = next_status;
            utils.content.update({
                type: 'dealership',
                object: dealership
            });

            setDealerships(dealerships => {
                return dealerships.map(prevDealership => {
                    if(prevDealership.id === dealership.id) {
                        prevDealership.active = next_status;
                    }
                    return prevDealership;
                })
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue changing the status of this Dealership. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onDealershipClick = async id => {
        try {

            // fetch details for dealership from server
            setLoading(true);
            let dealership = await Dealership.get(utils, id);

            // end loading and show details layer
            setLoading(false);
            utils.layer.open({
                id: `dealership_details_${dealership.id}`,
                abstract: Abstract.create({
                    type: 'dealership',
                    object: dealership
                }),
                Component: DealershipDetails
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this dealership. ${e.message || 'An unknown error occurred'}`
            });

        }
    }

    const onGDLActiveStatusChange = ({ detail }) => {
        setDealerships(dealerships => {
            return dealerships.map(dealership => {
                if(dealership.id === detail.id) {

                    // update active status for gdl_active flag
                    dealership.gdl_active = detail.active;

                    // determine if an update subscription object was provided
                    if(detail.subscription) {
                        dealership.subscriptions.map(subscription => {
                            return subscription.id === detail.subscription.id ? detail.subscription : subscription;
                        });
                    }
                }
                return dealership;
            });
        });
    }

    const onNewDealership = () => {
        utils.layer.open({
            id: 'new_dealership',
            abstract: Abstract.create({
                type: 'dealership',
                object: Dealership.new()
            }),
            Component: AddEditDealership.bind(this, {
                isNewTarget: true
            })
        });
    }

    const onOmniShieldWhiteLabelActiveStatusChange = ({ detail }) => {
        setDealerships(dealerships => {
            return dealerships.map(dealership => {
                if(dealership.id === detail.id) {

                    // update active status for enabled flag
                    dealership.omnishield.enabled = detail.active;

                    // determine if an update subscription object was provided
                    if(detail.subscription) {
                        dealership.subscriptions.map(subscription => {
                            return subscription.id === detail.subscription.id ? detail.subscription : subscription;
                        });
                    }
                }
                return dealership;
            });
        });
    }

    const onPrintDealerships = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let { dealerships } = await Request.get(utils, '/dealerships/', {
                    type: 'all',
                    search_text: searchText,
                    ...props
                });

                setLoading(false);
                resolve(dealerships.map(dealership => Dealership.create(dealership)));

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        });
    }

    const onSubscriptionAdded = ({ detail }) => {
        setDealerships(dealerships => {
            return dealerships.map(dealership => {
                if(dealership.id === detail.id) {

                    // add subscription if subscription does not already exist with target
                    let match = dealership.subscriptions.find(subscription => subscription.id === detail.subscription.id);
                    if(!match) {
                        dealership.subscriptions.push(detail.subscription);
                    }
                }
                return dealership;
            });
        });
    }


    const onSubscriptionRemoved = ({ detail }) => {
        setDealerships(dealerships => {
            return dealerships.map(dealership => {
                dealership.subscriptions = dealership.subscriptions.filter(subscription => subscription.id !== detail.id);
                return dealership;
            });
        });
    }

    const getActiveStatus = dealership => {
        if(!dealership) {
            return;
        }
        let color = dealership.active ? Appearance.colors.primary() : Appearance.colors.grey();
        return (
            <div
            className={'text-button'}
            onClick={onChangeActiveStatus.bind(this, dealership)}
            style={{
                alignItems: 'center',
                background: Appearance.colors.softGradient(color),
                border: `1px solid ${color}`,
                borderRadius: 5,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center',
                maxWidth: 75,
                overflow: 'hidden',
                textAlign: 'center',
                width: 100
            }}>
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    color: 'white',
                    fontWeight: '600',
                    width: '100%'
                }}>{dealership.active ? 'Active' : 'Not Active'}</span>
            </div>
        )
    }

    const getFields = (dealership, index) => {

        let target = dealership || {};
        let fields = [{
            key: 'name',
            title: 'Name',
            value: target.name
        },{
            key: 'locality',
            title: 'City',
            value: target.address ? target.address.locality : null
        },{
            key: 'administrative_area_level_1',
            title: 'State',
            value: target.address ? target.address.administrative_area_level_1 : null
        },{
            key: 'dealer',
            title: 'Dealer',
            value: target.dealer && target.dealer.full_name || target.dealer_name
        },{
            key: 'phone_number',
            sortable: false,
            title: 'Phone Number',
            value: target.phone_number && Utils.formatPhoneNumber(target.phone_number)
        },{
            color: target.has_white_label_app ? Appearance.colors.green : Appearance.colors.grey(),
            key: 'has_white_label_app',
            title: 'White Label App',
            value: target.has_white_label_app ? 'Enabled' : 'Disabled'
        },{
            color: target.gdl_active ? Appearance.colors.green : Appearance.colors.grey(),
            key: 'gdl_active',
            title: 'Global Data Access',
            value: target.gdl_active ? 'Enabled' : 'Disabled'
        },{
            key: 'active',
            sortable: false,
            title: 'Status',
            value: getActiveStatus(target)
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!dealership) {
            return (
                <TableListHeader
                fields={fields}
                onChange={props => {
                    sorting.current = props;
                    fetchDealerships();
                }}
                value={sorting.current} />
            )
        }

        // loop through result rows
        return (
            <TableListRow
            key={index}
            values={fields}
            lastItem={index === dealerships.length - 1}
            onClick={onDealershipClick.bind(this, dealership.id)} />
        )
    }

    const getPrintProps = () => {
        return {
            onFetch: onPrintDealerships,
            onRenderItem: item => ({
                name: item.name,
                locality: item.address ? item.address.locality : null,
                administrative_area_level_1: item.address ? item.address.administrative_area_level_1 : null,
                dealer: item.dealer ? item.dealer.full_name : null,
                phone_number: item.dealer ? item.dealer.phone_number : null,
                gdl_active: item.gdl_active ? 'Enabled' : 'Disabled',
                active: item.active ? 'Yes' : 'No'
            }),
            headers: [{
                key: 'name',
                title: 'Name'
            },{
                key: 'locality',
                title: 'City'
            },{
                key: 'administrative_area_level_1',
                title: 'State'
            },{
                key: 'dealer',
                title: 'Dealer'
            },{
                key: 'phone_number',
                title: 'Phone Number'
            },{
                key: 'gdl_active',
                title: 'Global Data Access',
                visible: utils.user.get().level <= User.levels.get().admin
            },{
                key: 'active',
                title: 'Status'
            }].filter(field => {
                return field.visible !== false;
            })
        }
    }

    const fetchDealerships = async () => {
        try {
            setLoading(true);
            let { dealerships, paging } = await Request.get(utils, '/dealerships/', {
                limit: limit,
                offset: offset.current,
                search_text: searchText,
                show_inactive: showInactive.current,
                type: 'all',
                ...sorting.current
            });

            setLoading(false);
            setPaging(paging);
            setDealerships(dealerships.map(dealership => Dealership.create(dealership)))

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the dealerships list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getButtons = () => {
        let buttons = [];
        if(utils.user.get().level <= User.levels.get().admin) {
            buttons.push({
                key: 'new',
                title: 'New Dealership',
                style: 'default',
                onClick: onNewDealership
            });
        }
        if(utils.user.get().level < User.levels.get().dealer) {
            buttons.push({
                key: 'active',
                title: `${showInactive.current ? 'Hide' : 'Show'} Inactive`,
                style: showInactive.current ? 'default' : 'grey',
                onClick: () => {
                    offset.current = 0;
                    showInactive.current = showInactive.current ? false : true;
                    fetchDealerships();
                }
            })
        }
        return buttons.length > 0 ? buttons : null;
    }

    const getContent = () => {
        if(dealerships.length === 0) {
            return (
                Views.entry({
                    title: 'No Dealerships Found',
                    subTitle: 'No dealerships were found in the system',
                    bottomBorder: false,
                    hideIcon: true
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {dealerships.map((dealership, index) => {
                        return getFields(dealership, index)
                    })}
                </tbody>
            </table>
        )
    }

    useEffect(() => {
        fetchDealerships();
    }, [searchText]);

    useEffect(() => {

        utils.events.on(panelID, 'dealership_change', fetchDealerships);
        utils.events.on(panelID, 'dealership_gdl_active_status_change', onGDLActiveStatusChange);
        utils.events.on(panelID, 'dealership_omnishield_white_label_active_status_change', onOmniShieldWhiteLabelActiveStatusChange);
        utils.events.on(panelID, 'subscription_added', onSubscriptionAdded);
        utils.events.on(panelID, 'subscription_removed', onSubscriptionRemoved);

        utils.content.subscribe(panelID, [ 'dealership' ], {
            onFetch: fetchDealerships,
            onUpdate: abstract => {
                setDealerships(dealerships => {
                    return dealerships.map(dealership => {
                        return dealership.id === abstract.getID() ? abstract.object : dealership
                    })
                })
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchDealerships);
            utils.events.off(panelID, 'dealership_gdl_active_status_change', onGDLActiveStatusChange);
            utils.events.off(panelID, 'dealership_omnishield_white_label_active_status_change', onOmniShieldWhiteLabelActiveStatusChange);
            utils.events.off(panelID, 'subscription_added', onSubscriptionAdded);
            utils.events.off(panelID, 'subscription_removed', onSubscriptionRemoved);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'Dealerships'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            search: {
                placeholder: 'Search by name, dealer, city, state, or zipcode...',
                onChange: text => {
                    offset.current = 0;
                    setSearchText(text);
                }
            },
            print: getPrintProps(),
            buttons: getButtons(),
            paging: {
                data: paging,
                limit: limit,
                offset: offset.current,
                onClick: next => {
                    offset.current = next;
                    fetchDealerships();
                }
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const DealershipDetails = ({ abstract, index, options, utils }) => {

    const layerID = `dealership_details_${abstract.getID()}`;
    const [dealership, setDealership] = useState(abstract.object);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [whiteLabelCollapse, setWhiteLabelCollapse] = useState({ android: true, ios: true });

    const copyGoogleBusinessProfileUrl = url => {
        try {

            // copy text to clipboard
            Utils.copyText(url);

            // show notification that copy request was successful
            utils.notification.show({
                title: 'Google Review Link Copied',
                message: `A link to leave a Google review for ${dealership.preferences.google_business_profile.name} has been copied to your clipboard.`
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue copying your review link. ${(typeof(e) === 'string' ? e : e.message) || 'An unknown error occurred'}`
            });
        }
    }

    const onAppReviewStatusClick = (status, evt) => {
        evt.stopPropagation();
        utils.alert.show({
            title: status.text,
            message: status.description
        });
    }

    const onChangeRequestClick = async id => {
        try {

            // set loading flag and fetch request details from server
            setLoading(id);
            let request = await OmniShieldWhiteLabel.ChangeRequest.get(utils, id);

            // end loading and show request details layer
            setLoading(false);
            utils.layer.open({
                id: `omnishield_white_label_change_request_details_${request.id}`,
                abstract: Abstract.create({
                    type: 'omnishield_white_label_change_request',
                    object: request
                }),
                Component: OmniShieldWhiteLabelChangeRequestDetails
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this omnishield white label change request. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onCustomDomainNameClick = (item, evt) => {

        // no additional options are available for users who are not at least dealers
        if(utils.user.get().level > User.levels.get().dealer) {
            window.open(abstract.object.preferences.replicated_website.custom_domain.name);
            return;
        }

        // show editing options for dealers, directors, and administrators
        utils.sheet.show({
            items: [{
                key: 'dns',
                title: 'Edit DNS Records',
                style: 'default'
            },{
                key: 'open',
                title: 'Open in New Window',
                style: 'default'
            },{
                key: 'nameservers',
                title: 'View Nameservers',
                style: 'default'
            }],
            position: 'bottom',
            target: evt.target
        }, key => {
            switch(key) {
                case 'dns':
                onEditCustomDomainDNS();
                break;

                case 'open':
                window.open(`https://${abstract.object.preferences.replicated_website.custom_domain.name}`);
                break;

                case 'nameservers':
                onNameServersClick();
                break;
            }
        });
    }
    
    const onDealerClick = async () => {
        try {

            setLoading(true);
            let user = await User.get(utils, abstract.object.dealer.user_id);

            setLoading(false);
            utils.layer.open({
                id: `user_details_${user.user_id}`,
                abstract: Abstract.create({
                    type: 'user',
                    object: user
                }),
                Component: UserDetails
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onEditClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'dealership',
                title: 'Dealership',
                style: 'default'
            },{
                key: 'omnishield',
                title: 'OmniShield White Label App',
                style: 'default'
            },{
                key: 'replicated_website',
                title: 'Website',
                style: 'default'
            }],
            target: evt.target
        }, key => {
            if(key === 'dealership') {
                utils.layer.open({
                    id: `edit_dealership_${abstract.getID()}`,
                    abstract: abstract,
                    Component: AddEditDealership.bind(this, {
                        isNewTarget: false,
                    })
                });
                return;
            }
            if(key === 'omnishield') {
                onEditOmniShieldWhiteLabelApp();
                return;
            }
            if(key === 'replicated_website') {
                onEditReplicatedWebsite();
                return;
            }
        });
    }

    const onEditCustomDomainDNS = () => {
        utils.layer.open({
            abstract: abstract,
            Component: EditCustomDomainDNS.bind(this, { domain: abstract.object.preferences.replicated_website.custom_domain.name }),
            id: `edit_custom_domain_dns_records_${abstract.getID()}`
        });
    }

    const onEditOmniShieldWhiteLabelApp = () => {

        // show subscription layer to enable omnishield white label app if services are not enabled
        let { change_request, enabled } = dealership.omnishield || {};
        if(enabled === false) {
            utils.alert.show({
                title: 'OmniShield White Label App',
                message: 'It looks white labeling services are not setup for this dealership. Would you like to start the setup process now?',
                buttons: [{
                    key: 'confirm',
                    title: 'Yes',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Cancel',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(key === 'confirm') {
                        onNewOmniShieldWhiteLabelSubscriptionRequest();
                        return;
                    }
                }
            });
            return;
        }

        // prevent editing if a change request is in progress
        if(change_request && change_request.status.code === OmniShieldWhiteLabel.ChangeRequest.status.get().pending) {
            utils.alert.show({
                title: 'Just a Second',
                message: `Changes to your OmniShield white label app can't be made while you have a change request in-review. Please wait until we complete your review or remove your current changes from review.`,
                buttons: [{
                    key: 'details',
                    title: 'View Change Request',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Cancel',
                    style: 'cancel'
                }] ,
                onClick: key => {
                    if(key === 'details') {
                        onShowChangeRequestDetails();
                        return;
                    }
                } 
            });
            return;
        }

        // show layer to make changes to omnishield preferences
        onStartOmniShieldWhiteLabelEditing();
    }

    const onEditReplicatedWebsite = () => {
        utils.layer.open({
            abstract: abstract,
            Component: EditReplicatedWebsitePreferences,
            id: `replicated_website_preferences_${abstract.getID()}`
        });
    }

    const onGDLActiveStatusChange = ({ detail }) => {
        if(detail.id === abstract.getID()) {

            // update active status for gdl_active flag
            abstract.object.gdl_active = detail.active;

            // determine if an update subscription object was provided
            if(detail.subscription) {
                abstract.object.subscriptions.map(subscription => {
                    return subscription.id === detail.subscription.id ? detail.subscription : subscription;
                });
            }

            // update local state
            setDealership(abstract.object);
        }
    }

    const onGoogleBusinessProfileClick = () => {
        utils.sheet.show({
            items: [{
                key: 'review_url',
                title: 'Copy Review URL',
                style: 'default',
                visible: dealership.preferences.google_business_profile.locations.length > 0
            },{
                key: 'google_maps_url',
                title: 'Open in Google Maps',
                style: 'default',
                visible: dealership.preferences.google_business_profile.locations.length > 0
            }]
        }, key => {
            if(key === 'google_maps_url') {
                window.open(dealership.preferences.google_business_profile.locations[0].maps_url);
                return;
            }
            if(key === 'review_url') {
                copyGoogleBusinessProfileUrl(dealership.preferences.google_business_profile.locations[0].new_review_url);
                return;
            }
        });
    }

    const onManageTraineeSignup = () => {
        utils.layer.open({
            abstract: abstract,
            Component: ManageTraineeSignup,
            id: `manage_trainee_signup_${abstract.getID()}`
        });
    }

    const onNameServersClick = () => {

        // no additional logic is needed if nameservers are not available
        let { name, nameservers } = abstract.object.preferences.replicated_website.custom_domain || {};
        if(!nameservers) {
            return;
        } 

        // show alert with nameserver values
        utils.alert.show({
            title: 'Custom Domain Name',
            message: `Your dealership website is using the ${name} custom domain name. Below are the nameservers that you need to update with your domain registrar.`,
            content: (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 12,
                    textAlign: 'center',
                    width: '100%'
                }}>
                    {nameservers.map((ns, index) => {
                        return (
                            <span 
                            key={index}
                            style={{
                                ...Appearance.textStyles.subTitle(),
                                color: Appearance.colors.text(),
                                marginBottom: 8
                            }}>{ns}</span>
                        )
                    })}
                </div>
            )
        });
    }

    const onNewOmniShieldWhiteLabelSubscriptionRequest = () => {
        let category = Payment.Subscription.categories.get().gold;
        utils.layer.open({
            abstract: abstract,
            Component: NewSubscriptionRequest.bind(this, {
                category: category,
                sourceCategory: Payment.Method.source_categories.get().dealership,
                onComplete: onSubscriptionCreated.bind(this, category)
            }),
            id: 'new_subscription_request'
        });
    }

    const onNewSubscription = async evt => {
        try {
            setLoading('options');
            let { options } = await Request.get(utils, '/dealerships/', {
                type: 'subscription_options'
            });

            setLoading(false);
            utils.sheet.show({
                items: options.map(option => ({
                    key: option.category,
                    title: option.title,
                    style: 'default'
                })),
                target: evt.target,
                title: 'New Subscription',
                message: `Which subscripton would you like to setup for ${abstract.object.name}?`
            }, key => {
                if(key !== 'cancel') {
                    utils.layer.open({
                        abstract: abstract,
                        Component: NewSubscriptionRequest.bind(this, {
                            category: key,
                            onComplete: onSubscriptionCreated.bind(this, key),
                            sourceCategory: Payment.Method.source_categories.get().dealership
                        }),
                        id: 'new_subscription_request'
                    });
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the available dealership subscriptions. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onOmniShieldWhiteLabelActiveStatusChange = ({ detail }) => {
        if(detail.id === abstract.getID()) {

            // update active status for enabled flag
            abstract.object.omnishield.enabled = detail.active;

            // determine if an update subscription object was provided
            if(detail.subscription) {
                abstract.object.subscriptions.map(subscription => {
                    return subscription.id === detail.subscription.id ? detail.subscription : subscription;
                });
            }

            // update local state
            setDealership(abstract.object);
        }
    }

    const onOptionsClick = evt => {

        // determine if current user is an administrator
        if(utils.user.get().level <= User.levels.get().admin ) {
            utils.sheet.show({
                items: [{
                    key: 'gdl_active',
                    title: `${dealership.gdl_active ? 'Disable' : 'Enable'} Global Data Access`,
                    style: dealership.gdl_active ? 'destructive' : 'default',
                    visible: utils.user.get().level <= User.levels.get().admin
                },{
                    key: 'active',
                    title: dealership.active ? 'Deactivate' : 'Activate',
                    style: dealership.active ? 'destructive' : 'default'
                },{
                    key: 'trainee_signup',
                    title: 'Manage Trainee Signup',
                    style: 'default'
                },{
                    key: 'new_subscription',
                    title: 'New Subscription',
                    style: 'default'
                },{
                    key: 'replicated_website_links',
                    title: 'Dealership Website Links',
                    style: 'default',
                    visible: dealership.alias ? true : false
                },{
                    key: 'transfer',
                    title: 'Transfer Protections',
                    style: 'default'
                }],
                target: evt.target
            }, key => {
                
                if(key === 'active') {
                    onSetActiveStatus();
                    return;
                }
                if(key === 'gdl_active') {
                    onSetGDLActiveStatus();
                    return;
                }
                if(key === 'new_subscription') {
                    onNewSubscription(evt);
                    return;
                }
                if(key === 'replicated_website_links') {
                    setTimeout(onShowReplicatedWebsiteLinks.bind(this, evt), 500);
                    return;
                }
                if(key === 'trainee_signup') {
                    onManageTraineeSignup();
                    return;
                }
                if(key === 'transfer') {
                    onTransferProtections();
                    return;
                }
            });
            return;
        }
        
        // fallback to showing director and dealer options
        utils.sheet.show({
            items: [{
                key: 'trainee_signup',
                title: 'Manage Trainee Signup',
                style: 'default'
            },{
                key: 'replicated_website_links',
                title: 'Dealership Website Links',
                style: 'default',
                visible: dealership.alias ? true : false
            }],
            target: evt.target
        }, key => {
            if(key === 'replicated_website_links') {
                setTimeout(onShowReplicatedWebsiteLinks.bind(this, evt), 500);
                return;
            }
            if(key === 'trainee_signup') {
                onManageTraineeSignup();
                return;
            }
            if(key === 'transfer') {
                onTransferProtections();
                return;
            }
        });
    }

    const onPhoneNumberClick = () => {
        window.open(`tel:${dealership.phone_number}`);
    }

    const onRefreshSSLValidationClick = async () => {
        try {
            setLoading('ssl_validation');
            let { status } = await Request.get(utils, '/dealerships/', {
                dealership_id: abstract.getID(),
                type: 'replicated_website_ssl_validation_status'
            });

            // update abstract target with new validation status and update local state
            setLoading(false);
            abstract.object.preferences.replicated_website.custom_domain.ssl_certificate_validation_status = status;
            setDealership(abstract.object);
            
            // notify subscribers of data change
            utils.content.update(abstract);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue refreshing the certificate validation status. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onReplicatedWebsiteUrlClick = () => {
        try {

            // copy text to clipboard
            Utils.copyText(dealership.preferences.replicated_website.url);

            // show notification that copy request was successful
            utils.notification.show({
                title: 'Website Link Copied',
                message: 'Your dealership website link has been copied to your clipboard.'
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue copying your website link. ${(typeof(e) === 'string' ? e : e.message) || 'An unknown error occurred'}`
            });
        }
    }

    const onSectorClick = async (target, type) => {
        try {

            setLoading(true);
            let sector = await Sector.get(utils, target.id, type);

            setLoading(false);
            utils.layer.open({
                id: `sector_details_${target.id}`,
                abstract: Abstract.create({
                    type: 'sector',
                    object: sector
                }),
                Component: SectorDetails
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this sector. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSetActiveStatus = () => {
        utils.alert.show({
            title: `${dealership.active ? 'Deactivate' : 'Activate'} Dealership`,
            message: `Are you sure that you want to ${dealership.active ? 'deactivate' : 'activate'} this dealership?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: dealership.active ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: `Do Not ${dealership.active ? 'Deactivate' : 'Activate'}`,
                style: dealership.active ? 'default' : 'destructive'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetActiveStatusConfirm();
                    return;
                }
            }
        });
    }

    const onSetActiveStatusConfirm = async () => {
        try {

            // set loading flag and prepare next active status value
            setLoading('options');
            let next_status = !dealership.active;

            // submit request to server
            await Request.post(utils, '/dealerships/', {
                type: 'set_active_status',
                id: dealership.id,
                active: next_status
            });

            // update target active status and notify subscribers of data change
            abstract.object.active = next_status;
            utils.content.update(abstract);

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The ${dealership.name} dealership has been ${next_status ? 'activated' : 'deactivated'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this dealership. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSetGDLActiveStatus = () => {

        // determine if gdl access is being disabled
        if(dealership.gdl_active) {
            utils.alert.show({
                title: 'Disable Global Data Access',
                message: 'Global Data is part of the subscription for this dealership. Please cancel their subscription or choose a new subscription without Global Data access if they no longer wish to use Global Data.'
            });
            return;
        }

        // verify that a payment profile has been setup for this dealership before enabling global data access
        if(abstract.object.payments_account === false) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'A payment account needs to be setup for this dealership before they can be given access to Global Data. Please add a credit or debit card to the dealership to continue.',
                buttons: [{
                    key: 'setup',
                    title: 'Add Credit or Debit Card',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Cancel',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(key === 'setup') {
                        onSetupPaymentsAccount();
                        return;
                    }
                }
            });
            return;
        }

        // open layer to request details for subscription
        let category = Payment.Subscription.categories.get().silver;
        utils.layer.open({
            abstract: abstract,
            Component: NewSubscriptionRequest.bind(this, {
                category: category,
                onComplete: onSubscriptionCreated.bind(this, category),
                sourceCategory: Payment.Method.source_categories.get().dealership
            }),
            id: 'new_subscription_request'
        });
    }

    const onSetupPaymentsAccount = () => {
        utils.layer.open({
            id: 'add_payment_method',
            Component: AddPaymentMethod.bind(this, {
                dealership: abstract.object,
                onChange: () => {
                    abstract.object.payments_account = true;
                    setDealership(abstract.object);
                },
                sourceCategory: Payment.Method.source_categories.get().dealership
            })
        });
    }

    const onSetTraineeSignupLinkStatus = () => {

        let status = abstract.object.preferences.trainee_signup_link.enabled;
        utils.alert.show({
            title: `${status ? 'Disable' : 'Enable'} Trainee Signup Link`,
            message: `Are you sure that you want to ${status ? 'disable' : 'enable'} the trainee signup link?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: status ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: `Do Not ${status ? 'Disable' : 'Enable'}`,
                style: status ? 'default' : 'destructive'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetTraineeSignupLinkStatusConfirm(status ? false : true);
                    return;
                }
            }
        });
    }

    const onSetTraineeSignupLinkStatusConfirm = async val => {
        try {

            // set loading flag and submit request to server
            setLoading('options');
            await Request.post(utils, '/dealerships/', {
                id: dealership.id,
                status: val,
                type: 'set_trainee_signup_link_status'
            });

            // update target active status and notify subscribers of data change
            abstract.object.preferences.trainee_signup_link.enabled = val;
            utils.content.update(abstract);

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The trainee signup link for this dealership has been ${val ? 'enabled' : 'disabled'}.`,
                buttons: [{
                    key: 'copy',
                    title: 'Copy Link',
                    style: 'default',
                    visible: val === true
                },{
                    key: 'cancel',
                    title: val ? 'Close' : 'Okay',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(key === 'copy') {
                        onTraineeSignupLinkClick();
                        return;
                    }
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the trainee signup link for this dealership. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onShowChangeRequestDetails = async () => {
        try {

            // set loading flag and fetch request details from server
            setLoading(true);
            let request = await OmniShieldWhiteLabel.ChangeRequest.get(utils, dealership.omnishield.change_request.id);

            // end loading and show request details layer
            setLoading(false);
            utils.layer.open({
                id: `omnishield_white_label_change_request_details_${request.id}`,
                abstract: Abstract.create({
                    type: 'omnishield_white_label_change_request',
                    object: request
                }),
                Component: OmniShieldWhiteLabelChangeRequestDetails
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for your change request. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onShowReplicatedWebsiteLinks = evt => {

        let { lead_generation_survey, google_reviews, recruiting_survey } = abstract.object.preferences.replicated_website || {};
        utils.sheet.show({
            title: 'Dealership Website Links',
            message: 'These links are direct links to specific content on your website. Using a direct link can help a customer find the content they need as quick as possible.',
            items: [{
                key: 'careers',
                title: 'Careers',
                style: 'default',
                visible: recruiting_survey ? true : false
            },{
                key: 'contact',
                title: 'Contact Us',
                style: 'default'
            },{
                key: 'home',
                title: 'Homepage',
                style: 'default'
            },{
                key: 'download',
                title: 'Mobile App Download',
                style: 'default'
            },{
                key: 'promotions',
                title: 'Promotions',
                style: 'default',
                visible: lead_generation_survey ? true : false
            },{
                key: 'testimonials',
                title: 'Testimonials',
                style: 'default',
                visible: google_reviews && google_reviews.enabled === true ? true : false
            }],
            target: evt.target
        }, (key, item) => {

            // show alert asking which type of url presentation is needed
            if(key !== 'cancel') {
                utils.alert.show({
                    title: `${item.title} Link`,
                    message: 'Would you like your link prepared as a plain text link or a qr-code that a customer can scan?',
                    buttons: [{
                        key: 'plain_text',
                        title: 'Plain Text',
                        style: 'default'
                    },{
                        key: 'qr_code',
                        title: 'QR Code',
                        style: 'default'
                    },{
                        key: 'cancel',
                        title: 'Cancel',
                        style: 'cancel'
                    }],
                    onClick: key => {

                        if(key === 'cancel'){
                            return;
                        }

                        // prepare url and determine how to present it
                        let url = item.key === 'home' ? dealership.preferences.replicated_website.url : `${dealership.preferences.replicated_website.url}/${item.key}`;
                        if(key === 'plain_text') {
                            
                            // copy url to clipboard
                            Utils.copyText(url);
                    
                            // show notification that copy request was successful
                            utils.notification.show({
                                title: 'Link Copied',
                                message: `Your ${item.title.toLowerCase()} link has been copied to your clipboard.`
                            });
                            return;
                        }
                        if(key === 'qr_code') {
                            createQRCodeFromUrl(item, url);
                            return;
                        }
                    }
                });
            }
        });
    }
    
    const onStartOmniShieldWhiteLabelEditing = () => {
        utils.layer.open({
            abstract: abstract,
            Component: EditWhiteLabelOmniShieldPreferences.bind(this, {
                onChange: onUpdateOmnishieldWhiteLabelApp,
                preferences: abstract.object.omnishield
            }),
            id: `omnishield_preferences_${abstract.getID()}`
        });
    }

    const onSubscriptionClick = subscription => {
        utils.layer.open({
            abstract: Abstract.create({
                object: subscription,
                type: 'subscription'
            }),
            Component: SubscriptionDetails,
            id: `subscription_details_${subscription.id}`
        });
    }

    const onSubscriptionAdded = ({ detail }) => {
        if(detail.id === abstract.getID()) {
            
            // add subscription if subscription does not already exist with target
            let match = abstract.object.subscriptions.find(subscription => subscription.id === detail.subscription.id);
            if(!match) {
                abstract.object.subscriptions.push(detail.subscription);
                setDealership(abstract.object);
            }
        }
    }

    const onSubscriptionCreated = async (category, subscription) => {
        try {

            // add new subscription to list of dealership subscriptions
            abstract.object.omnishield.enabled = true;

            // determine if category represents a global data subscription
            if(category === Payment.Subscription.categories.get().global_data) {

                // set gdl_active flag and update local state
                abstract.object.gdl_active = true;
                setDealership(abstract.object);
                
                // notify event listeners that a status change has been made
                utils.events.emit('dealership_gdl_active_status_change', {
                    active: true,
                    id: abstract.getID(),
                    subscription: subscription
                });
            }
            
            // determine if category represents a global data subscription
            if(category === Payment.Subscription.categories.get().omnishield_white_label) {

                // set gdl_active flag and update local state
                abstract.object.omnishield.enabled = true;
                setDealership(abstract.object);
                
                // notify event listeners that a status change has been made
                utils.events.emit('dealership_omnishield_white_label_active_status_change', {
                    active: true,
                    id: abstract.getID(),
                    subscription: subscription
                });

                // open editing layer for white label services
                onStartOmniShieldWhiteLabelEditing();
            }

        } catch(e) {
            console.error(e.message);
        }
    }

    const onSubscriptionRemoved = ({ detail }) => {
        abstract.object.subscriptions = abstract.object.subscriptions.filter(subscription => subscription.id !== detail.id);
        setDealership(abstract.object);
    }

    const onTraineeSignupLinkClick = () => {
        try {

            // copy text to clipboard
            Utils.copyText(abstract.object.preferences.trainee_signup_link.url);

            // show notification that copy request was successful
            utils.notification.show({
                title: 'Link Copied',
                message: 'Your trainee signup link has been copied to your clipboard.'
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue copying your link. ${(typeof(e) === 'string' ? e : e.message) || 'An unknown error occurred'}`
            });
        }
    }

    const onTransferProtections = () => {
        let dealership = null;
        utils.alert.show({
            title: 'Transfer Protections',
            message: 'Admnistrators are able to transfer all protections from a dealership into another dealership. Please search for a dealership below to continue.',
            content: (
                <div style={{
                    width: '100%',
                    padding: 12
                }}>
                    <DealershipLookupField
                    utils={utils}
                    inline={true}
                    onChange={result => dealership = result}/>
                </div>
            ),
            buttons: [{
                key: 'confirm',
                title: 'Transfer',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Do Not Transfer',
                style: 'cancel'
            }],
            onClick: key => {
                if(dealership && key === 'confirm') {
                    onTransferProtectionsConfirm(dealership);
                    return;
                }
            }
        });
    }

    const onTransferProtectionsConfirm = async dealership => {
        try {
            setLoading(true);
            let { count } = await Request.post(utils, '/dealerships/', {
                type: 'transfer_protections',
                origin_id: abstract.getID(),
                destination_id: dealership.id
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `${count} ${count === 1 ? 'protection has' : 'protections have'} been transfered from the "${abstract.object.name}" dealership to the "${dealership.name}" dealership. These protections will not be shown in sales reports for the "${dealership.name}" dealership.`,
                onClick: utils.content.fetch.bind(this, 'cards')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue transfering these protections to the "${dealership.name}" dealership. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateOmnishieldWhiteLabelApp = props => {
        return new Promise(async (resolve, reject) => {
            try {

                // open editing and apply changes
                abstract.object.open();
                abstract.object.set({ omnishield: props });
    
                // set loading flag and send request to server
                setLoading('edit');
                let { change_request_id } = await abstract.object.update(utils);

                // y root that request was successful
                resolve();
    
                // determine if a change request was generated from the changes
                setLoading(false);
                if(change_request_id) {
                    utils.alert.show({
                        title: 'All Done!',
                        message: `Some of your changes require approval before they become available. We'll notify you once we have made a decision regarding your change request.`
                    });
                    return;
                }
    
                // show default confirmation alert
                utils.alert.show({
                    title: 'All Done!',
                    message: 'The OmniShield white label app changes have been saved.'
                });
    
            } catch(e) {
                setLoading(false);
                reject(e);
            }
        });
    }

    const onWhiteLabelCollapseChange = (key, val, evt) => {
        if(evt) {
            evt.stopPropagation();
        }
        setWhiteLabelCollapse(props => {
            return update(props, {
                [key]: {
                    $set: val
                }
            });
        });
    }

    const getAppVersion = platform => {

        // prepare target and app review status codes
        let target = dealership.omnishield[platform];
        let codes = OmniShieldWhiteLabel.app_review_status.get();

        // return current version if app has been approved or released
        if([codes.approved, codes.in_review, codes.ready_for_distribution, codes.rejected].includes(target.status?.code) === true) {
            return `Pending Version: ${target.version && target.version.current || 'Unpublished'}`;
        }
        return `Version: ${target.version && target.version.current || 'Unpublished'}`;
    }

    const getButtons = () => {

        // prepare default list of buttons
        let buttons = [];

        // add options to list of buttons if user is an administrator
        if(utils.user.get().level <= User.levels.get().dealer) {
            buttons.push({
                color: 'secondary',
                key: 'options',
                loading: loading === 'options',
                onClick: onOptionsClick,
                text: 'Options'
            });
        }

        // allow user to edit the dealership if they are the dealership dealer of an administrator
        if(utils.user.get().level <= User.levels.get().admin || utils.user.get().dealership_id === abstract.getID()) {
            buttons.push({
                color: 'primary',
                key: 'edit',
                loading: loading === 'edit',
                onClick: onEditClick,
                text: 'Edit'
            });
        }

        return buttons.length > 0 ? buttons : null;
    }

    const getFields = () => {

        let dealership = abstract.object;
        let { genealogy } = dealership.dealer || {};
        let { google_business_profile = {} } = dealership.preferences || {};

        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'alias',
                title: 'Alias',
                value: dealership.alias || 'Not Setup'
            },{
                key: 'dealer',
                title: 'Dealer',
                value: dealership.dealer && dealership.dealer.full_name,
                onClick: dealership.dealer ? onDealerClick : null
            },{
                color: dealership.gdl_active ? Appearance.colors.green : Appearance.colors.red,
                key: 'gdl_active',
                title: 'Global Data Access',
                value: dealership.gdl_active ? 'Enabled' : 'Disabled'
            },{
                key: 'id',
                title: 'ID',
                value: dealership.id
            },{
                key: 'name',
                title: 'Name',
                value: dealership.name
            },{
                key: 'phone_number',
                onClick: dealership.phone_number ? onPhoneNumberClick : null,
                title: 'Phone Number',
                value: dealership.phone_number && Utils.formatPhoneNumber(dealership.phone_number)
            },{
                color: dealership.active ? Appearance.colors.green : Appearance.colors.red,
                key: 'active',
                title: 'Status',
                value: dealership.active ? 'Active' : 'Inactive'
            }]
        },{
            key: 'location',
            title: 'Location',
            items: [{
                key: 'location',
                title: 'Location',
                component: 'map',
                visible:  dealership.address && dealership.location ? true : false,
                value: dealership.location
            },{
                key: 'address',
                title: 'Physical Address',
                value: dealership.address ? Utils.formatAddress(dealership.address) : null
            },{
                key: 'timezone',
                title: 'Timezone',
                value: dealership.timezone
            }]
        },{
            key: 'genealogy',
            title: 'Genealogy',
            items: [{
                key: 'division',
                title: 'Division',
                ...genealogy && genealogy.division && {
                    value: genealogy.division.name,
                    onClick: utils.user.get().level <= User.levels.get().admin ? onSectorClick.bind(this, genealogy.division, Sector.types.division) : null
                }
            },{
                key: 'region',
                title: 'Region',
                ...genealogy && genealogy.region && {
                    value: genealogy.region.name,
                    onClick: utils.user.get().level <= User.levels.get().admin ? onSectorClick.bind(this, genealogy.region, Sector.types.region) : null
                }
            }]
        },{
            key: 'marketing',
            lastItem: false,
            title: 'Marketing',
            items: [{
                key: 'preferences.google_business_profile',
                onClick: google_business_profile.configured ? onGoogleBusinessProfileClick : null,
                title: 'Google Business Profile',
                value: google_business_profile.name
            }]
        },{
            id: 'trainee_signup',
            lastItem: false,
            title: 'Trainee Signup',
            items: [{
                key: 'trainee_signup_link.enabled',
                title: 'Open to Public',
                value: dealership.preferences.trainee_signup_link.enabled ? 'Yes' : 'No'
            },{
                key: 'trainee_signup_link.setup_testcom_account',
                title: 'Request Certification Test Fee',
                value: dealership.preferences.trainee_signup_link.setup_testcom_account ? 'Yes' : 'No',
                visible: dealership.preferences.trainee_signup_link.enabled === true
            },{
                key: 'trainee_signup_link.url',
                onClick: onTraineeSignupLinkClick,
                title: 'Signup Link',
                value: 'Click to Copy',
                visible: dealership.preferences.trainee_signup_link.enabled === true
            }]
        }];
    }

    const getOmniShieldWhiteLabel = () => {

        // no additional logic is needed if no omnishield white label preferences were found
        if(!dealership.omnishield) {
            return null;
        }

        // no additional logic is required if white label services are not enabled
        let { android, change_request, enabled, ios } = dealership.omnishield;
        if(enabled === false) {

            let fields = [{
                color: enabled ? Appearance.colors.green : Appearance.colors.red,
                key: 'android_enabled',
                title: 'Android App',
                value: enabled ? 'Enabled' : 'Disabled'
            },{
                color: enabled ? Appearance.colors.green : Appearance.colors.red,
                key: 'ios_enabled',
                title: 'iOS App',
                value: enabled ? 'Enabled' : 'Disabled'
            }];

            return (
                <LayerItem
                collapsed={false}
                title={'OmniShield White Label App'}>
                    <div style={{
                        ...Appearance.styles.unstyledPanel(),
                        marginBottom: 8
                    }}>
                        {fields.map((field, index) => {
                            return (
                                Views.row({
                                    bottomBorder: index !== fields.length - 1,
                                    color: field.color,
                                    key: index,
                                    label: field.title,
                                    value: field.value
                                })
                            )
                        })}
                    </div>
                </LayerItem>
            )
        }

        // prepare android app fields
        let androidFields = [{
            key: 'android.app_icon',
            title: 'App Icon',
            value: android.app_icon && (
                <img 
                src={android.app_icon.url}
                style={{
                    borderRadius: 8,
                    height: 25,
                    objectFit: 'cover',
                    width: 25
                }} />
            ) || 'Not Added'
        },{
            key: 'android.app_name',
            title: 'App Name',
            value: android.app_name || 'Not Added'
        },{
            key: 'android.bundle_id',
            title: 'Bundle ID',
            value: android.bundle_id
        },{
            key: 'android.play_store_url',
            onClick: android.play_store_url ? window.open.bind(this, android.play_store_url) : null,
            title: 'Store Listing',
            value: android.play_store_url ? 'Click to open' : 'Unavailable'
        }];

        // prepare ios app fields
        let iosFields = [{
            key: 'ios.app_icon',
            title: 'App Icon',
            value: ios.app_icon && (
                <img 
                src={ios.app_icon.url}
                style={{
                    borderRadius: 8,
                    height: 25,
                    objectFit: 'cover',
                    width: 25
                }} />
            ) || 'Not Added'
        },{
            key: 'ios.app_name',
            title: 'App Name',
            value: ios.app_name || 'Not Added'
        },{
            key: 'ios.bundle_id',
            title: 'Bundle ID',
            value: ios.bundle_id
        },{
            key: 'ios.app_store_url',
            onClick: ios.app_store_url ? window.open.bind(this, ios.app_store_url) : null,
            title: 'Store Listing',
            value: ios.app_store_url ? 'Click to open' : 'Unavailable'
        }];

        return (
            <LayerItem
            collapsed={false}
            title={'OmniShield White Label App'}>
                {change_request && (
                    <div style={{
                        ...Appearance.styles.unstyledPanel(),
                        marginBottom: 8,
                        width: '100%'
                    }}>
                        {Views.entry({
                            bottomBorder: false,
                            hideOnClickArrow: true,
                            icon: { 
                                path: 'images/change-request-icon-clear.png',
                                imageStyle: {
                                    backgroundColor: change_request.status.color
                                } 
                            },
                            loading: loading === change_request.id,
                            onClick: onChangeRequestClick.bind(this, change_request.id),
                            subTitle: `Last Updated: ${Utils.formatDate(change_request.date)}`,
                            title: 'Change Request',
                            rightContent: (
                                <AltBadge 
                                content={change_request.status} 
                                style={{
                                    minWidth: 0
                                }}/>
                            )
                        })}
                    </div>
                )}
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginBottom: 8
                }}>
                    {Views.entry({
                        bottomBorder: whiteLabelCollapse.android === false,
                        hideOnClickArrow: true,
                        icon: { 
                            path: 'images/android-icon-clear.png',
                            imageStyle: {
                                backgroundColor: android.status && android.status.color || Appearance.colors.grey()
                            } 
                        },
                        onClick: onWhiteLabelCollapseChange.bind(this, 'android', !whiteLabelCollapse.android),
                        title: 'Android App',
                        subTitle: `Version: ${android.version && android.version.current || 'Unpublished'}`,
                        rightContent: (
                            <div style={{
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'row'
                            }}>
                                <AltBadge 
                                content={android.status} 
                                onClick={android.status ? onAppReviewStatusClick.bind(this, android.status) : null}/>
                                <CollapseArrow 
                                collapsed={whiteLabelCollapse.android} 
                                onClick={onWhiteLabelCollapseChange.bind(this, 'android')} />
                            </div>
                        )
                    })}
                    {whiteLabelCollapse.android === false && androidFields.map((field, index, fields) => {
                        return (
                            Views.row({
                                bottomBorder: index !== fields.length - 1,
                                color: field.color,
                                key: index,
                                label: field.title,
                                onClick: field.onClick,
                                value: field.value
                            })
                        )
                    })}
                </div>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginBottom: 8
                }}>
                    {Views.entry({
                        bottomBorder: whiteLabelCollapse.ios === false,
                        hideOnClickArrow: true,
                        icon: { 
                            path: 'images/ios-icon-clear.png',
                            imageStyle: {
                                backgroundColor: ios.status && ios.status.color || Appearance.colors.grey()
                            } 
                        },
                        onClick: onWhiteLabelCollapseChange.bind(this, 'ios', !whiteLabelCollapse.ios),
                        textProps: {
                            subTitle: {
                                color: enabled ? Appearance.colors.green : Appearance.colors.red
                            }
                        },
                        title: 'iOS App',
                        subTitle: getAppVersion('ios'),
                        rightContent: (
                            <div style={{
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'row'
                            }}>
                                <AltBadge 
                                content={ios.status} 
                                onClick={ios.status ? onAppReviewStatusClick.bind(this, ios.status) : null}/>
                                <CollapseArrow 
                                collapsed={whiteLabelCollapse.ios} 
                                onClick={onWhiteLabelCollapseChange.bind(this, 'ios')} />
                            </div>
                        )
                    })}
                    {whiteLabelCollapse.ios === false && iosFields.map((field, index, fields) => {
                        return (
                            Views.row({
                                bottomBorder: index !== fields.length - 1,
                                color: field.color,
                                key: index,
                                label: field.title,
                                onClick: field.onClick,
                                value: field.value
                            })
                        )
                    })}
                </div>
            </LayerItem>
        )
    }

    const getReplicatedSSLCertificateValidationStatus = () => {

        // prevent moving forward if a custom domain is not enabled
        if(!abstract.object.preferences.replicated_website.custom_domain) {
            return null;
        }

        // determine if a timestamp was provided for the validation date
        if(abstract.object.preferences.replicated_website.custom_domain.ssl_certificate_validation_status) {
            return {
                color: Appearance.colors.green,
                value: 'Success'
            }
        }

        // fallback to showing a pending label with an option to manually refresh the status
        return { 
            value: (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    flexGrow: 1,
                    justifyContent: 'flex-end'
                }}>
                    <span>{'Pending'}</span>
                    <img 
                    className={`text-button ${loading === 'ssl_validation' ? 'rotate-animation' : ''}`}
                    onClick={onRefreshSSLValidationClick}
                    src={'images/refresh-button-grey-small.png'}
                    style={{
                        height: 20,
                        marginLeft: 8,
                        minWidth: 20,
                        width: 20
                    }} />
                </div>
            ) 
        }
    }

    const getReplicatedWebsite = () => {

        // no additional logic is required if replicated website details are not available
        if(!abstract.object.preferences.replicated_website) {
            return null;
        }

        // prepare fields for mapper
        let fields = [{
            collapsed: false,
            key: 'replicated_website',
            lastItem: false,
            title: 'Dealership Website',
            items: [{
                key: 'address',
                style: {
                    key: {
                        width: '50%'
                    },
                    value: {
                        maxWidth: '50%'
                    }
                },
                title: 'Address',
                value: Utils.formatAddress({
                    administrative_area_level_1: abstract.object.preferences.replicated_website.administrative_area_level_1,
                    country: abstract.object.preferences.replicated_website.country,
                    locality: abstract.object.preferences.replicated_website.locality,
                    postal_code: abstract.object.preferences.replicated_website.postal_code,
                    street_address_1: abstract.object.preferences.replicated_website.street_address_1,
                    street_address_2: abstract.object.preferences.replicated_website.street_address_2,
                })
            },{
                key: 'custom_domain.name',
                title: 'Custom Domain Name',
                value: abstract.object.preferences.replicated_website.custom_domain && abstract.object.preferences.replicated_website.custom_domain.name,
                onClick: abstract.object.preferences.replicated_website.custom_domain ? onCustomDomainNameClick : null
            },{
                key: 'custom_domain.ssl_certificate_validation_status',
                title: 'Custom Domain Validation',
                visible: abstract.object.preferences.replicated_website.custom_domain ? true : false,
                ...getReplicatedSSLCertificateValidationStatus()
            },{
                key: 'name',
                title: 'Dealership Name',
                value: abstract.object.preferences.replicated_website.name
            },{
                key: 'email_address',
                title: 'Email Address',
                value: abstract.object.preferences.replicated_website.email_address
            },{
                color: abstract.object.preferences.replicated_website.google_reviews && abstract.object.preferences.replicated_website.google_reviews.enabled ? Appearance.colors.green : Appearance.colors.red,
                key: 'google_reviews',
                title: 'Google Reviews',
                value: abstract.object.preferences.replicated_website.google_reviews && abstract.object.preferences.replicated_website.google_reviews.enabled ? 'Enabled' : 'Disabled'
            },{
                key: 'lead_generation_survey',
                title: 'Lead Generation Survey',
                value: abstract.object.preferences.replicated_website.lead_generation_survey && abstract.object.preferences.replicated_website.lead_generation_survey.name
            },{
                key: 'logo',
                onClick: abstract.object.preferences.replicated_website.logo ? window.open.bind(this, abstract.object.preferences.replicated_website.logo.url) : null,
                title: 'Logo',
                value: abstract.object.preferences.replicated_website.logo ? 'Click to view' : null
            },{
                key: 'phone_number',
                title: 'Phone Number',
                value: abstract.object.preferences.replicated_website.phone_number
            },{
                key: 'recruiting_survey',
                title: 'Recruiting Survey',
                value: abstract.object.preferences.replicated_website.recruiting_survey && abstract.object.preferences.replicated_website.recruiting_survey.name
            },{
                key: 'favicon',
                onClick: abstract.object.preferences.replicated_website.favicon ? window.open.bind(this, abstract.object.preferences.replicated_website.favicon.url) : null,
                title: 'Website Icon',
                value: abstract.object.preferences.replicated_website.favicon ? 'Click to view' : null
            },{
                key: 'url',
                onClick: abstract.object.alias ? onReplicatedWebsiteUrlClick : null,
                title: 'Website Link',
                value: abstract.object.alias ? 'Click to copy' : 'Not setup'
            }]
        }];

        return (
            <FieldMapper
            fields={fields}
            utils={utils} />
        )        
    }

    const getSubscriptions = () => {
        return abstract.object.subscriptions.length > 0 && (
            <LayerItem 
            collapsed={false}
            title={'Subscriptions'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    width: '100%'
                }}>
                    {abstract.object.subscriptions.map((subscription, index, subscriptions) => {
                        return (
                            Views.entry({
                                bottomBorder: index !== subscriptions.length - 1,
                                hideIcon: true,
                                key: index,
                                onClick: onSubscriptionClick.bind(this, subscription),
                                rightContent: (
                                    <AltBadge content={{
                                        color: subscription.active ? Appearance.colors.green : Appearance.colors.grey(),
                                        text: subscription.active ? `${Utils.toCurrency(subscription.amount.value)} ${subscription.schedule.descriptor}` : 'Inactive'
                                    }} 
                                    style={{
                                        marginRight: 0 
                                    }}/>
                                ),
                                subTitle: subscription.renewal_date ? `Renews ${Utils.formatDate(subscription.renewal_date, true)}` : 'No upcoming payments scheduled',
                                title: `${subscription.category.text} Subscription`
                            })
                        )
                    })}
                </div>
            </LayerItem>
        )
    }

    const createQRCodeFromUrl = async (item, target) => {
        try {
            setLoading('options');
            let { url } = await Request.post(utils, '/dealerships/', {
                dealership_id: abstract.getID(),
                type: 'replicated_website_qr_code_from_url',
                url: target
            });

            setLoading(false);
            utils.alert.show({
                title: 'Link Copied',
                message: `Your ${item.title.toLowerCase()} link has been copied to your clipboard.`,
                content: (
                    <img 
                    src={url}
                    style={{
                        height: 225,
                        marginBottom: 24,
                        width: 225
                    }} />
                ),
                buttons: [{
                    key: 'download',
                    title: 'Download',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Close',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(key === 'download') {
                        window.open(url);
                        return;
                    }
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing your qr-code. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {

        utils.events.on(layerID, 'dealership_gdl_active_status_change', onGDLActiveStatusChange);
        utils.events.on(layerID, 'dealership_omnishield_white_label_active_status_change', onOmniShieldWhiteLabelActiveStatusChange);
        utils.events.on(layerID, 'subscription_added', onSubscriptionAdded);
        utils.events.on(layerID, 'subscription_removed', onSubscriptionRemoved);

        utils.content.subscribe(layerID, ['dealership', 'omnishield_white_label_preferences', 'omnishield_white_label_change_request'], {
            onUpdate: next => {
                switch(next.type) {
                    case 'dealership':
                    if(abstract.getID() === next.getID()) {
                        setDealership(next.object);
                    }
                    break;

                    case 'omnishield_white_label_preferences':
                    if(next.object.dealership_id === abstract.getID()) {
                        switch(next.object.category) {
                            case 'app_review_status':
                            abstract.object.omnishield.android.status = next.object.data.android;
                            abstract.object.omnishield.ios.status = next.object.data.ios;
                            break;
                        }
                        setDealership(abstract.object);
                    }
                    break;

                    case 'omnishield_white_label_change_request':
                    let { change_request } = abstract.object.omnishield || {};
                    if(change_request && change_request.id === next.getID()) {
                        abstract.object.omnishield.change_request.status = next.object.status;
                        setDealership(abstract.object);
                    } 
                    if(next.object.dealership_id === abstract.getID()) {
                        switch(next.object.category) {
                            case 'cancel_change_request':
                            abstract.object.omnishield.change_request = null;
                            break;
                        }
                        setDealership(abstract.object);
                    }
                    break;
                }
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
            utils.events.off(layerID, 'dealership_gdl_active_status_change', onGDLActiveStatusChange);
            utils.events.off(layerID, 'dealership_omnishield_white_label_active_status_change', onOmniShieldWhiteLabelActiveStatusChange);
            utils.events.off(layerID, 'subscription_added', onSubscriptionAdded);
            utils.events.off(layerID, 'subscription_removed', onSubscriptionRemoved);
        }
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'Dealership Details'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            <FieldMapper
            fields={getFields()}
            utils={utils} />

            {getOmniShieldWhiteLabel()}
            {getReplicatedWebsite()}
            {getSubscriptions()}
            
            <SystemEventsList
            abstract={abstract}
            utils={utils} />

        </Layer>
    )
}

export const DealershipLocations = ({ index, options, utils }) => {

    const panelID = 'dealership_locations';
    const [loading, setLoading] = useState(false);
    const [locationData, setLocationData] = useState(null);
    const [region, setRegion] = useState(null);
    const [showInactive, setShowInactive] = useState(false);

    const onDealershipClick = async props => {
        try {
            let dealership = await Dealership.get(utils, props.id);
            utils.layer.open({
                id: `dealership_details_${dealership.id}`,
                abstract: Abstract.create({
                    type: 'dealership',
                    object: dealership
                }),
                Component: DealershipDetails
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this dealership. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getButtons = () => {
        if(utils.user.get().level < User.levels.get().dealer) {
            return [{
                key: 'active',
                title: `${showInactive ? 'Hide' : 'Show'} Inactive`,
                style: showInactive ? 'default' : 'grey',
                onClick: () => setShowInactive(status => !status)
            }]
        };
        return null;
    }

    const getFeatures = () => {
        if(!locationData) {
            return null;
        }
        return {
            id: 'dealership_locations',
            data: {
                ...locationData,
                features: locationData.features.filter(feature => {
                    if(showInactive) {
                        return true;
                    }
                    return feature.properties.active === true;
                })
            },
            icons: [{
                key: 'location-icon-grey',
                path: 'images/location-icon-grey.png'
            }],
            layers: [{
                id: 'dealership_locations',
                layout: {
                    'icon-allow-overlap': true,
                    'icon-anchor': 'center',
                    'icon-image': 'location-icon-grey',
                    'icon-size': 0.2
                },
                onClick: onDealershipClick,
                onHover: properties => {
                    return {
                        subTitle: properties.address,
                        title: properties.name
                    }
                },
                paint: {
                    'icon-color': [ 'get', 'color' ]
                },
                source: 'dealership_locations',
                type: 'symbol'
            }]
        }
    }

    const fetchLocations = async () => {
        try {
            setLoading(true);
            let { data, region } = await Request.get(utils, '/dealerships/', {
                type: 'locations',
                show_inactive: showInactive
            });

            setLoading(false);
            setRegion(region);
            setLocationData(data);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the location map data. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchLocations();
    }, [showInactive]);

    useEffect(() => {

        utils.events.on(panelID, 'dealership_change', fetchLocations);
        utils.content.subscribe(panelID, 'dealership', {
            onFetch: fetchLocations,
            onUpdate: abstract => {
                setLocationData(data => {

                    if(!data) {
                        return data;
                    }

                    // attempt to find dealership in feature collection
                    // may not be present if dealership was previously inactive
                    for(var i in data.features) {
                        if(data.features[i].properties.id === abstract.getID()) {

                            // update active status
                            data.features[i].properties.active = abstract.object.active;
                            data.features[i].properties.color = abstract.object.active ? Appearance.colors.primary() : Appearance.colors.lightGrey;
                            return { ...data };
                        }
                    }
                    // add to feature collection if not found above and location data is present
                    if(abstract.object.location) {
                        data.features.push({
                            type: 'Feature',
                            geometry: {
                                type: 'Point',
                                coordinates: [ abstract.object.location.longitude, abstract.object.location.latitude ]
                            },
                            properties: {
                                id: abstract.getID(),
                                name: abstract.object.name,
                                address: Utils.formatAddress(abstract.object.address),
                                active: abstract.object.active,
                                color: abstract.object.active ? Appearance.colors.primary() : Appearance.colors.lightGrey
                            }
                        })
                        return { ...data };
                    }
                    return data;
                })
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchLocations);
        }
    }, []);

    return (
        <Panel
        id={panelID}
        name={'Dealership Locations'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            buttons: getButtons()
        }}>
            <Map
            features={getFeatures()}
            isScrollEnabled={true}
            isZoomEnabled={true}
            isRotationEnabled={true}
            region={region}
            style={{
                width: '100%',
                height: 350
            }}/>
        </Panel>
    )
}

export const DealershipReportSettings = ({ abstract, index, options, utils }) => {

    const layerID = 'dealership_report_settings';

    const [edits, setEdits] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onDoneClick = async () => {
        try {

            // set loading flag and submit changes to server
            setLoading(true);
            await abstract.object.update(utils);

            // end loading and notify subscribers of data change
            setLoading(false);
            utils.content.update(abstract);

            // show confirmation alert
            utils.alert.show({
                title: 'All Done!',
                message: 'The report settings for this dealership have been updated. The changes will be available in the AFT mobile app next time a safety advisor visits the reports page.',
                onClick: setLayerState.bind(this, 'close')
            });
            
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the report settings for this dealership. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = async props => {
        let edits = await abstract.object.set(props);
        setEdits(edits);
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'done',
            loading: loading === 'done',
            onClick: onDoneClick,
            text: 'Save Changes'
        }];
    }

    const getFields = () => {
        if(!edits) {
            return [];
        }
        return [{
            key: 'safety_advisor_visibility',
            title: 'Allow Safety Advisor Visibility',
            items: [{
                component: 'bool_list',
                key: 'new_recruits',
                onChange: val => {
                    onUpdateTarget({ 
                        preferences: update(edits.preferences, {
                            reports: {
                                safety_advisor_visibility: {
                                    new_recruits: {
                                        $set: val
                                    }
                                }
                            }
                        })
                    });
                },
                required: false,
                title: 'New Recruits',
                value: edits.preferences.reports.safety_advisor_visibility.new_recruits
            },{
                component: 'bool_list',
                key: 'safety_advisor_personal_sales',
                onChange: val => {
                    onUpdateTarget({ 
                        preferences: update(edits.preferences, {
                            reports: {
                                safety_advisor_visibility: {
                                    safety_advisor_personal_sales: {
                                        $set: val
                                    }
                                }
                            }
                        })
                    });
                },
                required: false,
                title: 'Safety Advisor Personal Sales',
                value: edits.preferences.reports.safety_advisor_visibility.safety_advisor_personal_sales
            }]
        }];
    }

    const setupEditing = async () => {
        let edits = await abstract.object.open();
        setEdits(edits);
    }

    useEffect(() => {
        setupEditing();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'Dealership Report Settings'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            fields={getFields()}
            utils={utils} />
        </Layer>
    )
}

export const DealershipSelector = ({ index, options, utils }) => {

    const layerID = 'dealership_selector';
    const limit = 5;

    const [dealerships, setDealerships] = useState([]);
    const [defaultDealershipID, setDefaultDealershipID] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState('init');
    const [paging, setPaging] = useState(null);
    const [offset, setOffset] = useState(0);
    const [searchText, setSearchText] = useState(null);

    const onDealershipClick = async dealership => {
        utils.dealership.set(dealership);
        setLayerState('close');
    }

    const getBadge = dealership => {
        let badges = [];
        if(dealership.id === defaultDealershipID) {
            badges.push({
                text: 'Selected',
                color: Appearance.colors.primary()
            });
        }
        if(!dealership.active) {
            badges.push({
                text: 'Not Active',
                color: Appearance.colors.grey()
            });
        }
        return badges.length > 0 ? badges : null;
    }

    const getContent = () => {
        if(loading === 'init') {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: 100,
                    width: '100%'
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 40,
                        width: 40
                    }}/>
                </div>
            )
        }
        if(dealerships.length === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: 'There are no dealerships available to view',
                    title: 'No Dealerships Found'
                })
            )
        }
        return dealerships.map((dealership, index) => {
            return (
                Views.entry({
                    badge: getBadge(dealership),
                    bottomBorder: index !== dealerships.length - 1,
                    hideIcon: true,
                    key: index,
                    onClick: onDealershipClick.bind(this, dealership),
                    subTitle: dealership.address && dealership.address.street_address_1 ? Utils.formatAddress(dealership.address) : 'Address Not Available',
                    title: dealership.name
                })
            )
        });      
    }

    const fetchDealerships = async () => {
        try {
            let { dealerships, paging } = await Request.get(utils, '/dealerships/', {
                include_subscriptions: true,
                limit: limit,
                offset: offset,
                search_text: searchText,
                type: 'all'
            });
            setLoading(false);
            setPaging(paging);
            setDealerships(dealerships.map(dealership => Dealership.create(dealership)));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the dealerships list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const setupTarget = async () => {
        try {
            let dealershipID = Cookies.get('default_dealership_id');
            if(dealershipID) {
                setDefaultDealershipID(parseInt(dealershipID));
                return;
            }
            setDefaultDealershipID(utils.user.get().dealership.id);
        } catch(e) {
            console.log(e.message);
        }
    }

    useEffect(() => {
        fetchDealerships();
    }, [searchText, offset]);

    useEffect(() => {
        setupTarget();
    }, [])

    return (
        <Layer
        id={layerID}
        index={index}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            removePadding: true,
            sizing: 'medium',
            sticky: {
                top: (
                    <div style={{
                        borderBottom: `1px solid ${Appearance.colors.divider()}`,
                        padding: 15
                    }}>
                        <TextField
                        containerStyle={{
                            width: '100%'
                        }}
                        icon={'search'}
                        onChange={text => {
                            setLoading(true);
                            setOffset(0);
                            setSearchText(text);
                        }}
                        placeholder={'Search by name, dealer, city, state, or zipcode...'}
                        useDelay={true}
                        value={searchText}/>
                    </div>
                ),
                bottom: paging && (
                    <PageControl
                    data={paging}
                    limit={limit}
                    loading={loading === 'paging'}
                    offset={offset}
                    onClick={next => {
                        setLoading('paging');
                        setOffset(next);
                    }}/>
                )
            }
        }}>
            <div style={{
                padding: 15
            }}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {getContent()}
                </div>
            </div>
        </Layer>
    )
}

export const EditCustomDomainDNS = ({ domain }, { abstract, index, options, utils }) => {

    const layerID = `edit_custom_domain_dns_records_${abstract.getID()}`;
    const [customDomainName, setCustomDomainName] = useState(null);
    const [loading, setLoading] = useState('init');
    const [records, setRecords] = useState([]);

    const onAddRecord = evt => {
        utils.sheet.show({
            items: [{
                key: 'CNAME',
                title: 'CNAME',
                style: 'default'
            },{
                key: 'MX',
                title: 'MX',
                style: 'default'
            },{
                key: 'TXT',
                title: 'TXT',
                style: 'default'
            }],
            target: evt.target
        }, key => {
            if(key !== 'cancel') {
                setRecords(records => {
                    return update(records, {
                        $push: [{ 
                            Name: key === `${customDomainName}.`,
                            ResourceRecords: [{ Value: null }],
                            Type: key 
                        }]
                    })
                });
                return;
            }
        });
    }

    const onDoneClick = async () => {
        try {

            // verify that all required fields were filled out
            let record = records.find(record => record.Name && record.ResourceRecords && record.ResourceRecords.length > 0 ? false : true);
            if(record) {
                throw new Error(`Please fill out the Name and Value fields for the ${record.Name ? `${record.Name} ` : ''}${record.Type} record`);
            }

            // add beginning and ending double quotes to txt records if applicable
            let targets = records.map(record => {
                if(record.Type === 'TXT') {
                    let value = record.ResourceRecords[0].Value;
                    if(value.substring(0,1) !== '"') {
                        value = `"${value}`;
                    }
                    if(value.charAt(value.length - 1) !== '"') {
                        value = `${value}"`;
                    }
                    record.ResourceRecords[0].Value = value;
                }
                return record;
            });

            // validate contents of mx records
            targets.forEach(target => {
                if(target.Type === 'MX') {
                    target.ResourceRecords.forEach(entry => {
                        console.log(entry);
                        if(!entry.Value) {
                            throw new Error(`Please fill out the Value field for the ${target.Name} ${target.Type} record.`)
                        }
                        let parts = entry.Value.split(' ');
                        if(/^-?[\d.]+(?:e-?\d+)?$/.test(parts[0]) === false) {
                            throw new Error(`An invalid priority has been provided for the ${target.Name} ${target.Type} record value. The record priority should be one of the following numbers (0, 10, or 25). This value should be available from your email provider.`);
                        }
                        if(parts.length < 2 || typeof(parts[1]) !== 'string') {
                            throw new Error(`An invalid domain name has been provided for the ${target.Name} ${target.Type} record name. The record name should be a domain or sub-domain that you received from your email provider.`)
                        }
                    });
                }
            });

            // submit records to server for update
            setLoading(true);
            await Request.post(utils, '/dealerships/', {
                dealership_id: abstract.getID(),
                records: targets,
                type: 'update_replicated_website_dns_record'
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: 'The dns records for this dealership have been updated. DNS changes can take up to 24 hours to propagate across the internet but normally changes are seen much sooner than that.',
                onClick: () => {
                    setLoading(true);
                    fetchDnsRecords();
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue submitting your records. ${e.message || 'An unknown error occurred'}`
            });
        }
    }
    
    const onRemoveRecordClick = index => {

        // automatically remove record if no id has been set
        if(!records[index].id) {
            setRecords(records => {
                return update(records, {
                    $splice: [[index, 1]]
                });
            });
            return;
        }

        // request confirmation to remove record from server
        utils.alert.show({
            title: `Remove ${records[index].Type} Record`,
            message: 'Are you sure that you want to remove this record? Some records are needed to help your website function properly. Make sure that you understand what you are doing before removing records.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onRemoveRecordConfirm(index);
                    return;
                }
            }
        });
    }

    const onRemoveRecordConfirm = async index => {
        try {
            setLoading(true);
            await Request.post(utils, '/dealerships/', {
                dealership_id: abstract.getID(),
                record_id: records[index].id,
                type: 'remove_replicated_website_dns_record'
            });

            setLoading(false);
            setRecords(records => {
                return update(records, {
                    $splice: [[index, 1]]
                });
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue removing this record. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = (index, props) => {
        setRecords(records => {
            return update(records, {
                [index]: {
                    $set: {
                        ...records[index],
                        ...props
                    }
                }
            });
        });
    }

    const getButtons = () => {
        return [{
            color: 'secondary',
            key: 'add_record',
            onClick: onAddRecord,
            text: 'New DNS Record'
        },{
            color: 'primary',
            key: 'done',
            loading: loading === 'done',
            onClick: onDoneClick,
            text: 'Save Changes'
        }];
    }

    const getContent = () => {
        if(loading === 'init') {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: 100,
                    width: '100%'
                }}>
                    <LottieView
                    loop={true}
                    autoPlay={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 40,
                        width: 40
                    }}/>
                </div>
            )
        }
        
        return records.map((record, index) => {
            return (
                <AltFieldMapper
                fields={[{
                    key: 'details',
                    lastItem: false,
                    rightContent: (
                        <img
                        className={'text-button'}
                        src={'images/trash-icon-red.png'}
                        onClick={onRemoveRecordClick.bind(this, index)}
                        style={{
                            height: 20,
                            marginRight: 8,
                            objectFit: 'contain',
                            width: 20
                        }} />
                    ),
                    items: [{
                        component: 'textfield',
                        key: 'name',
                        onChange: text => onUpdateTarget(index, { Name: text }),
                        props: {
                             disabled: record.id ? true : false
                        },
                        title: 'Name',
                        value: record.Name
                    },{
                        component: record.Type === 'TXT' ? 'textview' : 'textfield',
                        key: 'value',
                        onChange: text => onUpdateTarget(index, { ResourceRecords: [{ Value: text }] }),
                        props: {
                            placeholder: record.Type === 'MX' ? `10 ${domain}` : null
                        },
                        title: 'Value',
                        value: getResourceValues(record.ResourceRecords)
                    }],
                    title: record.Type
                }]} 
                key={index}
                utils={utils} />
            )
        });
    }

    const getResourceValues = values => {
        if(values.length === 1) {
            return values[0].Value;
        }
        return values.map(entry => entry.Value).join("\n");
    }

    const fetchDnsRecords = async () => {
        try {
            let { custom_domain_name, records } = await Request.get(utils, '/dealerships/', {
                dealership_id: abstract.getID(),
                type: 'replicated_website_dns_records'
            });

            setLoading(false);
            setCustomDomainName(custom_domain_name);
            setRecords(records);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the dns records for this domain name. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchDnsRecords();
    }, []);

    return (
        <Layer 
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Editing DNS Records for ${abstract.object.name}`}
        utils={utils}
        options={{
            ...options,
            loading: loading === true,
            sizing: 'medium'
        }}>
            {getContent()}
        </Layer>
    )
}

export const EditGoogleBusinessProfilePreferences = ({ onChange, onRemove }, { abstract, index, options, utils }) => {

    const layerID = `edit_google_busines_profile_preferences_${abstract.getID()}`;

    const [layerState, setLayerState] = useState(null);
    const [edits, setEdits] = useState(abstract.object.preferences.google_business_profile || {});

    const onDoneClick = () => {
        setLayerState('close');
        if(typeof(onChange) === 'function') {
            onChange(edits);
        }
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'remove',
                title: 'Remove from Dealership',
                style: 'destructive'
            }],
            target: evt.target
        }, key => {
            if(key === 'remove') {
                onRemoveFromDealership();
                return;
            }
        });
    }

    const onRemoveFromDealership = () => {
        utils.alert.show({
            title: 'Remove from Dealership',
            message: 'Are you sure that you want to remove this Google business profile from the dealership? This will deactivate any GRACI features that require a Google business profile.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    setLayerState('close');
                    if(typeof(onRemove) === 'function') {
                        onRemove();
                        return;
                    }
                }
            }
        });
    }

    const onUpdateTarget = props => {
        setEdits(edits => ({ ...edits, ...props }));
    }

    const getButtons = () => {
        return [{
            color: 'secondary',
            key: 'options',
            onClick: onOptionsClick,
            text: 'Options'
        },{
            color: 'primary',
            key: 'done',
            onClick: onDoneClick,
            text: 'Done'
        }];
    }

    const getFields = () => {

        let reviews = edits.google_reviews || {};
        let { notifications = {} } = reviews || {};

        return [{
            key: 'google_reviews',
            title: 'Google Reviews',
            items: [{
                component: 'bool_list',
                key: 'google_reviews.notifications.new_reviews',
                onChange: val => {
                    onUpdateTarget({ 
                        google_reviews: update(edits.google_reviews || {}, {
                            notifications: {
                                $set: {
                                    ...notifications,
                                    new_reviews: val
                                }   
                            }
                        }) 
                    });
                },
                required: false,
                title: 'Notifications for New Reviews',
                value: notifications.new_reviews || false
            },{
                component: 'bool_list',
                key: 'google_reviews.notifications.new_reviews',
                onChange: val => {
                    onUpdateTarget({ 
                        google_reviews: update(edits.google_reviews || {}, {
                            notifications: {
                                $set: {
                                    ...notifications,
                                    updated_reviews: val
                                }
                            }
                        }) 
                    });
                },
                required: false,
                title: 'Notifications for Updated Reviews',
                value: notifications.updated_reviews || false
            },{
                component: 'bool_list',
                key: 'google_reviews.omnishield_app_link',
                onChange: val => {
                    onUpdateTarget({ 
                        google_reviews: update(edits.google_reviews || {}, {
                            omnishield_app_link: {
                                $set: val
                            }
                        }) 
                    });
                },
                required: false,
                title: 'Show Link in OmniShield White Label App',
                value: reviews.omnishield_app_link || false
            }]
        }];
    }

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Editing Google Business Profile for ${abstract.getTitle()}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            fields={getFields()} 
            utils={utils} />
        </Layer>
    )
}

export const EditPrivacyPolicy = ({ data, onChange }, { abstract, index, options, utils }) => {

    const layerID = `edit_privacy_policy_${abstract.getID()}`;
    const [content, setContent] = useState(data);
    const [layerState, setLayerState] = useState(null);

    const onDoneClick = () => {

        // close layer state and notify subscribers of data change
        setLayerState('close');
        if(typeof(onChange) === 'function') {
            let raw = convertToRaw(content.getCurrentContent());
            onChange(raw);
        }
    }

    const getButtons = () => {
        return [{
            color: 'grey',
            key: 'cancel',
            onClick: setLayerState.bind(this, 'close'),
            text: 'Cancel'
        },{
            color: 'primary',
            key: 'done',
            onClick: onDoneClick,
            text: 'Done'
        }];
    }

    return (
        <Layer
        buttons={getButtons()} 
        id={layerID}
        index={index}
        options={{
            ...options,
            layerState: layerState
        }}
        utils={utils}>
            <RichTextEditor
            content={content}
            onChange={setContent}
            placeholder={'Type or paste your privacy policy here...'}
            utils={utils}/>
        </Layer>
    )
}

export const EditReplicatedWebsitePreferences = ({ abstract, index, options, utils }) => {

    const layerID = `replicated_website_preferences_${abstract.getID()}`;
    const [edits, setEdits] = useState(abstract.object.preferences.replicated_website || {});
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [shouldRequestGoogleAccess, setShouldRequestGoogleAccess] = useState(false);

    const onDoneClick = async () => {
        try {

            // verify that all required fields were filled out
            await validateRequiredFields(getFields);

            // determine if google reviews are enabled and if a google business profile has not been setup
            if(edits.google_reviews && edits.google_reviews.enabled === true && abstract.object.preferences.google_business_profile.configured !== true) {
                utils.alert.show({
                    title: 'Google Business Profile',
                    message: `You'll need to sign-in with your Google business account before we can include Google reviews on your dealership website.`,
                    buttons: [{
                        key: 'continue',
                        title: 'Sign-In',
                        style: 'default'
                    },{
                        key: 'cancel',
                        title: 'Cancel',
                        style: 'cancel'
                    }],
                    onClick: key => {
                        if(key === 'continue') {
                            setShouldRequestGoogleAccess(moment().unix());
                            return;
                        }
                    }
                });
                return;
            }

            // determine if custom domain name has been changed
            let domain = abstract.object.preferences.replicated_website.custom_domain && abstract.object.preferences.replicated_website.custom_domain.name;
            if(edits.custom_domain && domain !== edits.custom_domain.name) {
                await requestCustomDomainNameConfirmation(edits.custom_domain.name);
                await Utils.sleep(0.25);
            }

            // open editing for target and apply replicated preference edits
            abstract.object.open();
            abstract.object.set({
                preferences: update(abstract.object.preferences, {
                    replicated_website: {
                        $set: edits
                    }
                })
            });

            // submit changes to server
            setLoading('done');
            let { nameservers } = await abstract.object.update(utils);

            // determine if custom nameservers were returned from the server
            setLoading(false);
            if(nameservers) {
                utils.alert.show({
                    title: 'All Done!',
                    message: `The changes to the replicated website preferences for ${abstract.object.name} have been saved. Below are the nameservers that you need to update with your domain registrar within the next 72 hours. You can access these nameservers anytime by viewing your dealership details, scrolling down to the "Dealership "Website section, and clicking on "Custom Domain Name".`,
                    content: (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginBottom: 12,
                            textAlign: 'center',
                            width: '100%'
                        }}>
                            {nameservers.map((ns, index) => {
                                return (
                                    <span 
                                    key={index}
                                    style={{
                                        ...Appearance.textStyles.subTitle(),
                                        color: Appearance.colors.text(),
                                        marginBottom: 8
                                    }}>{ns}</span>
                                )
                            })}
                        </div>
                    ),
                    buttons: [{
                        key: 'okay',
                        title: 'Okay',
                        style: 'default'
                    }],
                    onClick: setLayerState.bind(this, 'close')
                });
                return;
            }

            // show default confirmation alert
            utils.alert.show({
                title: 'All Done!',
                message: `The changes to the replicated website preferences for ${abstract.object.name} have been saved. These changes will be immediately available on the replicated website.`,
                buttons: [{
                    key: 'okay',
                    title: 'Okay',
                    style: 'default'
                }],
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            if(e.message === 'user_cancelled') {
                return;
            }
            if(e.message.includes('Missing required parameters') === true) {
                utils.alert.show({
                    title: 'Just a Second',
                    message: `It looks like there is some information missing for this dealership. Please update the dealership details and verify that all fields are completed before making changes to the dealership website.`
                });
                return;
            }
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your changes. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onEditPrivacyPolicyClick = () => {
        utils.layer.open({
            abstract: abstract,
            Component: EditPrivacyPolicy.bind(this, { 
                data: edits.privacy_policy,
                onChange: content => onUpdateTarget({ privacy_policy: content }) 
            }),
            id: `edit_privacy_policy_${abstract.getID()}`
        });
    }
    console.log(edits.privacy_policy)

    const onGoogleAuthSuccess = result => {
        abstract.object.preferences.google_business_profile = result;
        setShouldRequestGoogleAccess(false);
        onDoneClick();
    }

    const onUpdateTarget = props => {
        setEdits(prev => ({ ...prev, ...props }));
    }

    const getAddressComonents = () => {
        return {
            address: {
                administrative_area_level_1: edits.administrative_area_level_1,
                country: edits.country,
                locality: edits.locality,
                postal_code: edits.postal_code,
                street_address_1: edits.street_address_1,
                street_address_2: edits.street_address_2
            }
        }
    }

    const getButtons = () => {
        return [{
            key: 'graci_support_content',
            items: [{
                id: 'aft.replicated_websites.custom_themes',
                title: 'App Theme Colors'
            },{
                id: 'aft.replicated_website.bbb_seal',
                title: 'Better Business Bureau Seal'
            },{
                id: 'aft.replicated_websites.custom_domains',
                title: 'Custom Domain Names'
            },{
                id: 'aft.replicated_websites.google_places_review',
                title: 'Google Reviews'
            },{
                id: 'aft.replicated_websites.global_data_lead_generation_surveys',
                title: 'Lead Generation Surveys'
            },{
                id: 'aft.replicated_websites.global_data_recruiting_surveys',
                title: 'Recruiting Surveys'
            },{
                id: 'aft.replicated_websites.open_graph',
                title: 'Social Media Presense'
            }]
        },{
            color: 'primary',
            key: 'done',
            loading: loading === 'done',
            onClick: onDoneClick,
            text: 'Save Changes'
        }];
    }

    const getFields = () => {
        
        if(!edits) {
            return [];
        }

        // prepare default collection of editable items
        let items = [{
            key: 'general',
            title: 'Details',
            items: [{
                component: 'textfield',
                key: 'domain_name',
                onChange: text => {
                    onUpdateTarget({ 
                        custom_domain: update(edits.custom_domain || {}, {
                            name: {
                                $set: text
                            }
                        }) 
                    });
                },
                required: false,
                title: 'Custom Domain Name',
                value: edits.custom_domain && edits.custom_domain.name
            },{
                component: 'global_data_survey_lookup_field',
                key: 'lead_generation_survey',
                onChange: survey => onUpdateTarget({ lead_generation_survey: survey }),
                props: {
                    category: 'lead_generation',
                    dealershipID: abstract.getID()
                }, 
                required: false,
                title: 'Lead Generation Survey',
                value: edits.lead_generation_survey
            },{
                component: 'image_picker',
                key: 'logo',
                onChange: result => onUpdateTarget({ logo: result }),
                props: {
                    fileTypes: ['jpg','png'],
                    previewStyle: {
                        border: 'none',
                        borderRadius: 8,
                        height: 35,
                        width: 35
                    },
                    requirements: {
                        dimensions: {
                            maxHeight: 1024,
                            maxWidth: 1024,
                            minHeight: 512,
                            minWidth: 512
                        }
                    }
                },
                required: false,
                title: 'Logo',
                value: edits.logo
            },{
                component: 'textfield',
                key: 'name',
                onChange: text => onUpdateTarget({ name: text }),
                title: 'Name',
                value: edits.name
            },{
                component: 'global_data_survey_lookup_field',
                key: 'recruiting_survey',
                onChange: survey => onUpdateTarget({ recruiting_survey: survey }),
                props: {
                    category: 'recruiting',
                    dealershipID: abstract.getID()
                }, 
                required: false,
                title: 'Recruiting Survey',
                value: edits.recruiting_survey
            },{
                component: 'image_picker',
                key: 'favicon',
                onChange: result => onUpdateTarget({ favicon: result }),
                props: {
                    fileTypes: ['jpg','png'],
                    previewStyle: {
                        border: 'none',
                        borderRadius: 8,
                        height: 35,
                        width: 35
                    },
                    requirements: {
                        dimensions: {
                            maxHeight: 1024,
                            maxWidth: 1024,
                            minHeight: 512,
                            minWidth: 512
                        }
                    }
                },
                required: false,
                title: 'Website Icon',
                value: edits.favicon
            }]
        },{
            key: 'contact',
            title: 'Contact Information',
            items: [{
                component: 'textfield',
                key: 'email_address',
                onChange: text => onUpdateTarget({ email_address: text }),
                title: 'Email Address',
                value: edits.email_address
            },{
                component: 'address_lookup',
                key: 'address',
                onChange: result => {
                    onUpdateTarget({
                        administrative_area_level_1: result.address && result.address.administrative_area_level_1,
                        country: result.address && result.address.country,
                        locality: result.address && result.address.locality,
                        postal_code: result.address && result.address.postal_code,
                        street_address_1: result.address && result.address.street_address_1,
                        street_address_2: result.address && result.address.street_address_2
                    });
                },
                title: 'Mailing Address',
                value: getAddressComonents() 
            },{
                component: 'textfield',
                key: 'phone_number',
                onChange: text => onUpdateTarget({ phone_number: text }),
                props: {
                    format: 'phone_number',
                },
                title: 'Phone Number',
                value: edits.phone_number
            }]
        },{
            key: 'content',
            title: 'Content',
            items: [{
                component: 'textview',
                key: 'bio',
                onChange: text => onUpdateTarget({ bio: text }),
                required: false,
                title: 'About Us',
                value: edits.bio
            },{
                component: 'textfield',
                key: 'bbb_url',
                onChange: text => onUpdateTarget({ bbb_url: text }),
                required: false,
                title: 'Better Business Bureau Link',
                value: edits.bbb_url
            },{
                component: 'textview',
                key: 'contact_form_text',
                onChange: text => onUpdateTarget({ contact_form_text: text }),
                required: false,
                title: 'Contact Form',
                value: edits.contact_form_text
            },{
                component: 'textview',
                key: 'disclaimer_text',
                onChange: text => onUpdateTarget({ disclaimer_text: text }),
                required: false,
                title: 'Disclaimer',
                value: edits.disclaimer_text
            },{
                component: 'textfield',
                key: 'privacy_policy',
                onClick: onEditPrivacyPolicyClick,
                props: {
                    placeholder: edits.privacy_policy ? 'Click to make chanages...' : 'Click to configure...'
                },
                required: false,
                title: 'Privacy Policy',
                value: edits.privacy_policy ? 'Click to make chanages...' : null
            },{
                component: 'bool_list',
                key: 'show_affiliation_disclaimer',
                onChange: val => onUpdateTarget({ show_affiliation_disclaimer: val }),
                required: false,
                title: 'Show Affiliation Disclaimer',
                value: edits.show_affiliation_disclaimer || false,
                visible: utils.user.get().level <= User.levels.get().admin
            }]
        },{
            key: 'google_reviews',
            title: 'Google Reviews',
            visible: canUseGoogleReviews(),
            items: [{
                component: 'bool_list',
                key: 'google_reviews',
                onChange: val => {
                    onUpdateTarget({ 
                        google_reviews: update(edits.google_reviews || {}, {
                            $set: {
                                ...edits.google_reviews,
                                enabled: val
                            }
                        }) 
                    });
                },
                required: false,
                title: 'Enabled',
                value: edits.google_reviews && edits.google_reviews.enabled || false
            },{
                component: 'list',
                items: getGoogleReviewRatingFilterItems(),
                key: 'google_reviews.rating_filter',
                onChange: item => {
                    onUpdateTarget({ 
                        google_reviews: update(edits.google_reviews || {}, {
                            $set: {
                                ...edits.google_reviews,
                                rating_filter: item && item.id
                            }
                        }) 
                    });
                },
                required: false,
                title: 'Filter Reviews by Rating',
                value: getGoogleReviewRatingFilterValue(),
                visible: edits.google_reviews && edits.google_reviews.enabled ? true : false
            }]
        },{
            key: 'social_media',
            title: 'Social Media',
            items: [{
                component: 'textview',
                key: 'open_graph_description',
                onChange: text => onUpdateTarget({ open_graph_description: text }),
                title: 'Share Description',
                value: edits.open_graph_description
            },{
                component: 'image_picker',
                key: 'open_graph_image',
                onChange: result => onUpdateTarget({ open_graph_image: result }),
                props: {
                    fileTypes: ['jpg','png'],
                    previewStyle: {
                        border: 'none',
                        borderRadius: 8,
                        height: 35,
                        width: 35
                    },
                    requirements: {
                        dimensions: {
                            minHeight: 630,
                            minWidth: 1200
                        }
                    }
                },
                required: false,
                title: 'Share Image',
                value: edits.open_graph_image
            },{
                component: 'textfield',
                key: 'open_graph_title',
                onChange: text => onUpdateTarget({ open_graph_title: text }),
                title: 'Share Title',
                value: edits.open_graph_title
            }]
        },{
            key: 'colors',
            title: 'Theme Colors',
            items: [{
                component: 'color_picker',
                key: 'primary',
                onChange: val => {
                    onUpdateTarget({ 
                        colors: update(edits.colors, {
                            primary: {
                                $set: val
                            }
                        }) 
                    });
                },
                title: 'Primary',
                value: edits.colors.primary
            },{
                component: 'color_picker',
                key: 'secondary',
                onChange: val => {
                    onUpdateTarget({ 
                        colors: update(edits.colors, {
                            secondary: {
                                $set: val
                            }
                        }) 
                    });
                },
                title: 'Secondary',
                value: edits.colors.secondary
            },{
                component: 'color_picker',
                key: 'tertiary',
                onChange: val => {
                    onUpdateTarget({ 
                        colors: update(edits.colors, {
                            tertiary: {
                                $set: val
                            }
                        }) 
                    });
                },
                title: 'Tertiary',
                value: edits.colors.tertiary
            }]
        }];
        return items;
        
    }

    const getGoogleReviewRatingFilterItems = () => {
        return [{
            id: 5,
            title: '5 Stars Only'
        },{
            id: 4,
            title: '4 Stars and Higher'
        },{
            id: 3,
            title: '3 Stars and Higher'
        },{
            id: 2,
            title: '2 Stars and Higher'
        },{
            id: 1,
            title: '1 Star and Higher'
        }];
    }

    const getGoogleReviewRatingFilterValue = () => {
        let items = getGoogleReviewRatingFilterItems();
        let result = edits.google_reviews && items.find(item => item.id === edits.google_reviews.rating_filter);
        return result && result.title;
    }

    const canUseGoogleReviews = () => {
        return utils.subscriptions.capabilities.get('aft.replicated_websites.google_places_review', { dealership: abstract.object });
    }

    const requestCustomDomainNameConfirmation = target => {
        return new Promise((resolve, reject) => {

            // request confirmation to remove custom domain name resources if no target was provided
            if(!target) {
                utils.alert.show({
                    title: 'Custom Domain Name',
                    message: `Removing your custom domain name will prevent your website from using that domain name. Do you want to proceed with your custom domain name change?`,
                    buttons: [{
                        key: 'confirm',
                        title: 'Yes',
                        style: 'destructive'
                    },{
                        key: 'cancel',
                        title: 'No',
                        style: 'default'
                    }],
                    onClick: key => {
                        if(key !== 'confirm') {
                            let error = new Error('user_cancelled');
                            reject(error);
                            return;
                        }
                        resolve();
                    }
                });
                return;
            }

            // request confirmation to create custom doamin name resources 
            utils.alert.show({
                title: 'Custom Domain Name',
                message: `In order for us to setup your custom domain name, you must have access to ${edits.custom_domain.name} and have the ability to change the dns nameservers. Do you want to proceed with your custom domain name change?`,
                buttons: [{
                    key: 'confirm',
                    title: 'Yes',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'No',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(key !== 'confirm') {
                        let error = new Error('user_cancelled');
                        reject(error);
                        return;
                    }
                    resolve();
                }
            });
        });
    }

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Dealership Website Preferences: ${abstract.getTitle()}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            fields={getFields()} 
            utils={utils} />
            {shouldRequestGoogleAccess && (
                <GoogleBusinessProfileAccessManager 
                dealership={abstract.object} 
                nonce={shouldRequestGoogleAccess}
                onAuthorized={onGoogleAuthSuccess}
                onError={setShouldRequestGoogleAccess.bind(this, false)}
                onLoadingChange={setLoading}
                utils={utils} />
            )}
        </Layer>
    )
}

export const EditWhiteLabelOmniShieldPreferences = ({ onChange, preferences }, { abstract, index, options, utils }) => {

    const layerID = `omnishield_preferences_${abstract.getID()}`;
    const [colors, setColors] = useState(null);
    const [edits, setEdits] = useState(null);
    const [images, setImages] = useState({})
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onDoneClick = async () => {
        try {

            // verify that all required fields were filled out
            setLoading('submit');
            await validateRequiredFields(getBottomFields);

            // verify that the name 'omnishield' wasn't chosen as an app name
            if(edits.omnishield && edits.omnishield.enabled === true) {
                if(edits.omnishield.android.app_name.toLowerCase() === 'omnishield' || edits.omnishield.ios.app_name.toLowerCase() === 'omnishield') {
                    throw new Error('Your app name can not be OmniShield since there is already an app with that name. Please choose something unique.');
                }
            } 

            // submit request and wait for successful response before closing layer
            await onChange(edits);
            setLayerState('close');

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your changes. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        setEdits(prev => ({ ...prev, ...props }));
        if(props.colors) {
            setColors(props.colors);
        }
    }

    const getButtons = () => {
        return [{
            key: 'graci_support_content',
            items: [{
                id: 'aft.omnishield.white_label.configure',
                title: 'Configuring White Label Apps'
            },{
                id: 'aft.omnishield.white_label.customizations_overview',
                title: 'White Label Customizations Overview'
            }]
        },{
            color: 'primary',
            key: 'submit',
            loading: loading === 'submit',
            onClick: onDoneClick,
            text: 'Save Changes'
        }];
    }

    const getBottomFields = () => {

        if(!edits) {
            return [];
        }

        return [{
            collapsed: true,
            key: 'activation_and_self_tests',
            title: 'Activations and Self Tests',
            items: [{
                component: 'textfield',
                key: 'email_alert_label',
                onChange: text => onUpdateTarget({ email_alert_label: text }),
                props: {
                    placeholder: `${abstract.object.name} Alert`
                },
                title: 'Email Alert Label',
                value: edits.email_alert_label
            },{
                component: 'textfield',
                key: 'sms_alert_label',
                onChange: text => onUpdateTarget({ sms_alert_label: text }),
                props: {
                    placeholder: `${abstract.object.name} Alert`
                },
                title: 'Text Message Alert Label',
                value: edits.sms_alert_label
            }]
        },{
            collapsed: true,
            key: 'android',
            title: 'Android Mobile App',
            items: [{
                component: 'image_picker',
                key: 'app_icon',
                onChange: result => {
                    onUpdateTarget({ 
                        android: update(edits.android, {
                            app_icon: {
                                $set: result
                            }
                        }) 
                    });
                },
                props: {
                    fileTypes: ['jpg'],
                    previewStyle: {
                        border: 'none',
                        borderRadius: 8,
                        height: 35,
                        width: 35
                    },
                    requirements: {
                        dimensions: {
                            height: 1024,
                            width: 1024
                        }
                    }
                },
                subTitle: 'Required dimensions are 1024x1024',
                title: 'App Icon',
                value: edits.android.app_icon
            },{
                component: 'textfield',
                key: 'app_name',
                onChange: text => {
                    onUpdateTarget({ 
                        android: update(edits.android, {
                            app_name: {
                                $set: text
                            }
                        }) 
                    });
                },
                props: {
                    maxCharacters: 30
                },
                subTitle: 'Maximum of 30 characters long',
                title: 'Name',
                value: edits.android.app_name
            },{
                component: 'textfield',
                key: 'app_subtitle',
                onChange: text => {
                    onUpdateTarget({ 
                        android: update(edits.android, {
                            app_subtitle: {
                                $set: text
                            }
                        }) 
                    });
                },
                props: {
                    maxCharacters: 30
                },
                subTitle: 'Maximum of 30 characters long',
                title: 'Subtitle',
                value: edits.android.app_subtitle
            }]
        },{
            collapsed: true,
            key: 'emergency_contacts',
            title: 'Emergency Contacts',
            items: [{
                component: 'bool_list',
                key: 'emergency_contacts.onboarding.enabled',
                onChange: val => {
                    onUpdateTarget({ 
                        emergency_contacts: update(edits.emergency_contacts, {
                            onboarding: {
                                enabled: {
                                    $set: val
                                }
                            }
                        })
                    });
                },
                title: 'Send Onboarding Text Message',
                value: edits.emergency_contacts.onboarding.enabled
            },{
                component: 'textview',
                key: 'emergency_contacts.onboarding.message',
                onChange: text => {
                    onUpdateTarget({ 
                        emergency_contacts: update(edits.emergency_contacts, {
                            onboarding: {
                                message: {
                                    $set: text
                                }
                            }
                        })
                    });
                },
                required: edits.emergency_contacts.onboarding.enabled,
                title: 'Onboarding Text Message Content',
                value: edits.emergency_contacts.onboarding.message,
                visible: edits.emergency_contacts.onboarding.enabled
            },{
                component: 'global_data_survey_lookup_field',
                key: 'emergency_contacts.onboarding.survey',
                onChange: survey => {
                    onUpdateTarget({ 
                        emergency_contacts: update(edits.emergency_contacts, {
                            onboarding: {
                                survey: {
                                    $set: survey
                                }
                            }
                        })
                    });
                },
                props: {
                    category: 'lead_generation',
                    dealershipID: abstract.getID()
                }, 
                required: false,
                title: 'Onboarding Global Data Survey',
                value: edits.emergency_contacts.onboarding.survey,
                visible: edits.emergency_contacts.onboarding.enabled
            }]
        },{
            collapsed: true,
            key: 'ios',
            title: 'iOS Mobile App',
            items: [{
                component: 'image_picker',
                key: 'app_icon',
                onChange: result => {
                    onUpdateTarget({ 
                        ios: update(edits.ios, {
                            app_icon: {
                                $set: result
                            }
                        }) 
                    });
                },
                props: {
                    fileTypes: ['jpg'],
                    previewStyle: {
                        border: 'none',
                        borderRadius: 8,
                        height: 35,
                        width: 35
                    },
                    requirements: {
                        dimensions: {
                            height: 1024,
                            width: 1024
                        }
                    }
                },
                subTitle: 'Required dimensions are 1024x1024',
                title: 'App Icon',
                value: edits.ios.app_icon
            },{
                component: 'textfield',
                key: 'app_name',
                onChange: text => {
                    onUpdateTarget({ 
                        ios: update(edits.ios, {
                            app_name: {
                                $set: text
                            }
                        }) 
                    });
                },
                props: {
                    maxCharacters: 30
                },
                subTitle: 'Maximum of 30 characters long',
                title: 'Name',
                value: edits.ios.app_name
            },{
                component: 'textfield',
                key: 'app_subtitle',
                onChange: text => {
                    onUpdateTarget({ 
                        ios: update(edits.ios, {
                            app_subtitle: {
                                $set: text
                            }
                        }) 
                    });
                },
                props: {
                    maxCharacters: 30
                },
                subTitle: 'Maximum of 30 characters long',
                title: 'Subtitle',
                value: edits.ios.app_subtitle
            }]
        },{
            collapsed: true,
            key: 'preferences',
            title: 'Preferences',
            items: [{
                component: 'bool_list',
                key: 'show_splash_app_name',
                onChange: val => onUpdateTarget({ show_splash_app_name: val }),
                title: 'Show App Name on Splash Screen',
                value: edits.show_splash_app_name
            },{
                component: 'bool_list',
                key: 'show_splash_app_subtitle',
                onChange: val => onUpdateTarget({ show_splash_app_subtitle: val }),
                title: 'Show App Subtitle on Splash Screen',
                value: edits.show_splash_app_subtitle
            },{
                component: 'image_picker',
                key: 'splash_image',
                onChange: result => {
                    onUpdateTarget({ 
                        assets: update(edits.assets, {
                            splash_image: {
                                $set: result
                            }
                        })
                    });
                },
                props: {
                    fileTypes: ['png'],

                    previewStyle: {
                        borderRadius: 0,
                        height: 35,
                        width: 35
                    },
                    requirements: {
                        dimensions: {
                            height: 512,
                            width: 512
                        }
                    }
                },
                subTitle: 'Required dimensions are 512x512',
                title: 'Splash Image',
                value: edits.assets.splash_image
            },{
                component: 'list',
                items: [{
                    id: 'C',
                    title: 'Celsius'
                },{
                    id: 'F',
                    title: 'Fahrenheit'
                }],
                key: 'temperature_units',
                onChange: item => onUpdateTarget({ temperature_units: item && item.id }),
                title: 'Temperature Units',
                value: edits.temperature_units && (edits.temperature_units === 'C' ? 'Celsius' : 'Fahrenheit')
            },{
                component: 'textfield',
                key: 'website',
                onChange: text => onUpdateTarget({ website: text }),
                title: 'Website',
                value: edits.website
            }]
        },{
            collapsed: true,
            key: 'social_media',
            title: 'Social Media',
            items: [{
                component: 'textfield',
                key: 'facebook',
                onChange: text => {
                    onUpdateTarget({
                        social_media: update(edits.social_media, {
                            facebook: {
                                $set: text
                            }
                        })
                    });
                },
                required: false,
                title: 'Facebook',
                value: edits.social_media.facebook
            },{
                component: 'textfield',
                key: 'instagram',
                onChange: text => {
                    onUpdateTarget({
                        social_media: update(edits.social_media, {
                            instagram: {
                                $set: text
                            }
                        })
                    });
                },
                required: false,
                title: 'Instagram',
                value: edits.social_media.instagram
            },{
                component: 'textfield',
                key: 'tiktok',
                onChange: text => {
                    onUpdateTarget({
                        social_media: update(edits.social_media, {
                            tiktok: {
                                $set: text
                            }
                        })
                    });
                },
                required: false,
                title: 'TikTok',
                value: edits.social_media.tiktok
            },{
                component: 'textfield',
                key: 'x',
                onChange: text => {
                    onUpdateTarget({
                        social_media: update(edits.social_media, {
                            x: {
                                $set: text
                            }
                        })
                    });
                },
                required: false,
                title: 'X (formerly Twitter)',
                value: edits.social_media.x
            }]
        }];
    }

    const getThemeColors = () => {

        if(!edits) {
            return null;
        }

        // determine if mockups should be shown
        let shouldShowMockups = images.splash && images.sensors && images.sensor_details ? true : false;

        // prepare list of field items
        let fields = [{
            component: 'color_picker',
            key: 'primary',
            onChange: val => {
                onUpdateTarget({ 
                    colors: update(edits.colors, {
                        primary: {
                            $set: val
                        }
                    }) 
                });
            },
            title: 'Primary',
            value: edits.colors.primary
        },{
            component: 'color_picker',
            key: 'secondary',
            onChange: val => {
                onUpdateTarget({ 
                    colors: update(edits.colors, {
                        secondary: {
                            $set: val
                        }
                    }) 
                });
            },
            title: 'Secondary',
            value: edits.colors.secondary
        }/*,{
            component: 'color_picker',
            key: 'tertiary',
            onChange: val => {
                onUpdateTarget({ 
                    colors: update(edits.colors, {
                        tertiary: {
                            $set: val
                        }
                    }) 
                });
            },
            title: 'Tertiary',
            value: edits.colors.tertiary
        }*/];

        return (
            <LayerItem 
            collapsed={false}
            title={'App Theme Colors'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    width: '100%'
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        minHeight: 125,
                        padding: 12,
                        position: 'relative',
                        width: '100%'
                    }}>
                        <img 
                        src={images.splash}
                        style={{
                            height: 'auto',
                            objectFit: 'contain',
                            opacity: shouldShowMockups ? 1: 0,
                            padding: 6,
                            width: '33%'
                        }} />
                        <img 
                        src={images.sensors}
                        style={{
                            height: 'auto',
                            objectFit: 'contain',
                            opacity: shouldShowMockups ? 1: 0,
                            padding: 6,
                            width: '33%'
                        }} />
                        <img 
                        src={images.sensor_details}
                        style={{
                            height: 'auto',
                            objectFit: 'contain',
                            opacity: shouldShowMockups ? 1: 0,
                            padding: 6,
                            width: '33%'
                        }} />
                        {loading === 'theme_colors' && (
                            <div style={{ 
                                alignItems: 'center',
                                backgroundColor: window.theme === 'dark' ? 'rgba(60,60,60,0.5)' : 'rgba(252,252,252,0.5)',
                                bottom: 0,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                height: '100%',
                                position: 'absolute',
                                top: 0,
                                width: '100%'
                            }}>
                                <LottieView
                                loop={true}
                                autoPlay={true}
                                source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                                style={{
                                    height: 40,
                                    width: 40
                                }}/>
                            </div>
                        )}
                    </div>
                    <div style={{
                        borderTop: `1px solid ${Appearance.colors.divider()}`,
                        padding: 12,
                        width: '100%'
                    }}>
                        {fields.map((field, index) => {
                            return (
                                <AltFieldItem 
                                item={field}
                                key={index}
                                lastItem={index === fields.length - 1}
                                utils={utils} />
                            )
                        })}
                    </div>
                </div>
            </LayerItem>
        )
    }

    const fetchCustomMockups = async () => {
        try {
            setLoading('theme_colors');
            let { images } = await Request.get(utils, '/omnishield/', {
                dealership_id: abstract.getID(),
                primary_color: colors.primary,
                secondary_color: colors.secondary,
                type: 'white_label_custom_mockup_theme_colors'
            });

            setLoading(false);
            setImages(images);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing the previews for your app theme colors. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const setupTarget = () => {

        // prepare app theme colors
        let colors = {
            primary: preferences.colors && preferences.colors.primary || Appearance.colors.omnishieldPrimary,
            secondary: preferences.colors && preferences.colors.secondary || Appearance.colors.omnishieldSecondary,
            tertiary: preferences.colors && preferences.colors.tertiary || Appearance.colors.omnishieldTertiary
        }

        // update local state with color selection
        setColors(colors);

        // setup editing target for preferences object
        setEdits({
            android: {},
            assets: {},
            colors: colors,
            ios: {},
            social_media: {},
            temperature_units: preferences.temperature_units || 'F',
            ...preferences
        });
    }

    useEffect(() => {
        if(colors) {
            fetchCustomMockups();
        }
    }, [colors]);

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`OmniShield White Label Preferences: ${abstract.getTitle()}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            sizing: 'medium'
        }}>
            
            {getThemeColors()}
            <AltFieldMapper
            fields={getBottomFields()} 
            utils={utils} />
        </Layer>
    )
}

const GoogleBusinessProfileAccessManager = ({ dealership, nonce, onAuthorized, onError, onLoadingChange, utils }) => {

    const onGoogleAuthError = error => {
        utils.alert.show({
            title: 'Oops!',
            message: `Unable to complete authorization flow. ${error.message || 'An unknown error occurred'}`
        });
    }

    const onGoogleAuthSuccess = async result => {
        try {

            // verify that business profile management scrope has been granted
            const granted = hasGrantedAllScopesGoogle(
                result,
                'https://www.googleapis.com/auth/business.manage'
            );

            // prevent moving forward if scope was not granted
            if(granted === false) {
                throw new Error('Permission to manage your Google profile is required to link your profile with your dealership.');
            }

            // submit request to sever to configure google business profile
            requestLoading(true);
            let { google_business_profile } = await Request.post(utils, '/dealerships/', {
                code: result.code,
                dealership_id: dealership.id,
                type: 'configure_google_business_profile'
            });

            requestLoading(false);
            if(typeof(onAuthorized) === 'function') {
                onAuthorized(google_business_profile);
            }

        } catch(e) {

            // end loading and notify subscribers of failure
            requestLoading(false);
            if(typeof(onError) === 'function') {
                onError(e);
            }

            // show failure alert
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue authorizing your Google account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const requestLoading = val => {
        if(typeof(onLoadingChange) === 'function') {
            onLoadingChange(val);
        }
    }

    const runGoogleSignIn = useGoogleLogin({
        flow: 'auth-code',
        onError: onGoogleAuthError,
        onSuccess: onGoogleAuthSuccess,
        scope: 'https://www.googleapis.com/auth/business.manage'
    });

    useEffect(() => {
        runGoogleSignIn();
    }, [nonce]);

    return null;
}

export const GoogleReviews = ({ index, options, utils }) => {

    const panelID = 'google_reviews';
    const limit = 10;
    const offset = useRef(0);

    const [loading, setLoading] = useState('init');
    const [pageTokens, setPageTokens] = useState([]);
    const [reviews, setReviews] = useState([]);

    const onEditDealership = async () => {
        try {

            // fetch details for dealership from server
            let dealership = await Dealership.get(utils, utils.dealership.get().id);

            // open replicated website preferences layer
            utils.layer.open({
                abstract: Abstract.create({
                    object: dealership,
                    type: 'dealership'
                }),
                Component: DealershipDetails,
                id: `dealership_details_${dealership.id}`
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving your dealership details. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onPageClick = val => {
        offset.current = val;
        setLoading('paging');
        fetchReviews();
    }

    const onReviewClick = review => {
        utils.layer.open({
            Component: GoogleReviewReply.bind(this, {
                dealershipID: utils.dealership.get().id,
                id: review.id,
                review: review,
            }),
            id: 'google_review_reply'
        });
    }

    const onRequestGoogleReviewAccess = () => {
        utils.alert.show({
            title: 'Just a Second',
            message: 'Google Reviews have not been enabled for this dealership. You can link your Google business profile by editing your dealership details.',
            buttons: [{
                key: 'edit',
                title: 'Edit',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'edit') {
                    onEditDealership();
                    return;
                }
            }
        });
    }

    const getPagingComponent = () => {

        // declare token for next page
        let next = pageTokens[offset.current + 1];

        return reviews.length > 0 && (
            <div style={{
                borderTop: `1px solid ${Appearance.colors.divider()}`,
                padding: 10,
                position: 'relative'
            }}>
                {loading === 'paging' && (
                    <div style={{
                        borderRadius: 2,
                        left: 0,
                        overflow: 'hidden',
                        position: 'absolute',
                        height: 2,
                        right: 0,
                        top: 0,
                        width: '100%'
                    }}>
                        <ProgressBar/>
                    </div>
                )}
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    margin: 'auto',
                }}>
                    <img
                    title={'Previous Page'}
                    src={'images/back-arrow-light-grey-small.png'}
                    className={offset.current > 0 ? 'image-button' : ''}
                    onClick={offset.current > 0 ? onPageClick.bind(this, offset.current - 1) : null}
                    style={{
                        height: 12,
                        marginRight: 8,
                        objectFit: 'contain',
                        opacity: offset.current === 0 ? 0.25 : 1,
                        width: 12
                    }}/>
                    <img
                    title={'Next Page'}
                    src={'images/next-arrow-light-grey-small.png'}
                    className={next ? 'image-button' : ''}
                    onClick={next ? onPageClick.bind(this, offset.current + 1) : null}
                    style={{
                        height: 12,
                        marginLeft: 8,
                        objectFit: 'contain',
                        opacity: 1,
                        width: 12
                    }}/>
                </div>
            </div>
        )
    }

    const getReviews = () => {
        if(loading === 'init') {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: 12,
                    width: '100%'
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 40,
                        width: 40
                    }}/>
                </div>
            )
        }
        if(reviews.length === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: 'No reviews were found for your dealership',
                    title: 'No Reviews Found'
                })
            )
        }
        return reviews.map((review, index) => {
            return (
                Views.entry({
                    badge: review.reply && {
                        color: Appearance.colors.green,
                        text: 'Replied'
                    },
                    bottomBorder: index !== reviews.length - 1,
                    icon: { 
                        path: review.avatar
                    },
                    key: index,
                    onClick: onReviewClick.bind(this, review),
                    rightContent: (
                        <div style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            marginRight: 8
                        }}>
                            {[1,2,3,4,5].map(val => (
                                <img 
                                key={val}
                                src={val <= review.rating ? 'images/google-review-star-yellow.png' : 'images/google-review-star-grey.png'}
                                style={{
                                    height: 20,
                                    marginLeft: 4,
                                    width: 20
                                }} />
                            ))}
                        </div>
                    ),
                    subTitle: Utils.formatDate(review.date),
                    title: review.full_name
                })
            )
        });
    }

    const fetchReviews = async () => {
        try {

            // prevent moving forward if google reviews are not enabled
            let { enabled = false } = utils.dealership.get().preferences.replicated_website.google_reviews || {};
            if(enabled === false) {
                setLoading(false);
                setReviews([]);
                onRequestGoogleReviewAccess();
                return;
            }

            // prepare next page token for request
            let token = pageTokens[offset.current];

            // fetch reviews from server
            let { next_page_token, reviews } = await Request.get(utils, '/dealerships/', {
                next_page_token: token, 
                offset: offset.current,
                page_size: limit,
                type: 'google_reviews'
            });

            // end loading and update local state
            setLoading(false);
            setReviews(reviews.map(review => ({
                ...review,
                date: moment.utc(review.date).local()
            })));

            // update page tokens list
            setPageTokens(tokens => {
                return update(tokens, {
                    [offset.current + 1]: {
                        $set: next_page_token
                    }
                });
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the reviews list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchReviews();
        utils.events.on(panelID, 'dealership_change', fetchReviews);
        return () => {
            utils.events.off(panelID, 'dealership_change', fetchReviews);
        }
    }, []);

    return (
        <Panel
        index={index}
        name={'Google Reviews'}
        panelID={panelID}
        utils={utils}
        options={{
            ...options,
            loading: loading === true
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                width: '100%'
            }}>
                {getReviews()}
                {getPagingComponent()}
            </div>
        </Panel>
    )
}

export const GoogleReviewReply = ({ dealershipID, id, review }, { index, options, utils }) => {

    const layerID = 'google_review_reply';
    const [layerState, setLayerState] = useState(true);
    const [loading, setLoading] = useState(false);
    const [target, setTarget] = useState(review);
    const [text, setText] = useState(null);

    const onSubmitPress = async () => {
        try {

            // prevent moving forward if no text was provided
            if(!text) {
                throw new Error('Please provide a reply before submitting your response');
            }

            // submit reply request to server
            setLoading('submit');
            await Request.post(utils, '/dealerships/', {
                dealership_id: dealershipID,
                review_id: id,
                text: text,
                type: 'new_google_review_reply'
            });

            setLoading(false);
            utils.alert.show({
                icon: { 
                    path: 'images/google-review-icon.png'
                },
                title: 'All Done!',
                message: `We have submitted your reply to ${target.full_name} and it should be immediately visible on your business profile.`,
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue submitting your reply. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {
        return [{
            color: 'grey',
            key: 'cancel',
            onClick: setLayerState.bind(this, 'close'),
            text: 'Close'
        },{
            color: 'primary',
            key: 'submit',
            loading: loading === 'submit',
            onClick: onSubmitPress,
            text: 'Submit'
        }];
    }

    const getContent = () => {
        if(!target || loading === 'init') {
            return (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: 12,
                    width: '100%'
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 40,
                        width: 40
                    }}/>
                </div>
            )
        }

        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
            }}>
                <div style={{
                    borderBottom: `1px solid ${Appearance.colors.divider()}`,
                    padding: 16,
                    width: '100%'
                }}>
                    <div style={{
                        ...Appearance.styles.unstyledPanel(),
                    }}>
                        {Views.entry({
                            icon: { 
                                path: target.avatar
                            },
                            rightContent: (
                                <div style={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    marginRight: 8
                                }}>
                                    {[1,2,3,4,5].map(val => (
                                        <img 
                                        key={val}
                                        src={val <= target.rating ? 'images/google-review-star-yellow.png' : 'images/google-review-star-grey.png'}
                                        style={{
                                            height: 20,
                                            marginLeft: 4,
                                            width: 20
                                        }} />
                                    ))}
                                </div>
                            ),
                            subTitle: Utils.formatDate(target.date),
                            title: target.full_name
                        })}
                        {target.text && target.text.length > 0 && (
                            <div style={{
                                padding: '8px 12px 8px 12px',
                                width: '100%'
                            }}>
                                <span style={{
                                    ...Appearance.textStyles.key(),
                                    whiteSpace: 'normal'
                                }}>{target.text}</span>
                            </div>
                        )}
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 16,
                    width: '100%'
                }}>
                    <span style={{
                        color: Appearance.colors.text(),
                        fontSize: 16,
                        fontWeight: 700,
                        marginBottom: 4
                    }}>{'Reply to Review'}</span>
                    <span style={{
                        color: Appearance.colors.subText(),
                        fontSize: 13,
                        fontWeight: 500,
                        marginBottom: 12
                    }}>{`Please type in a reply below and click the Submit button when you are finished. Your reply will be shown alongside this review on your Google business profile.`}</span>
                    <TextView 
                    onChange={setText}
                    placeholder={'What would you like to say to this customer?'}
                    value={text}
                    style={{
                        height: 125,
                        width: '100%'
                    }} />
                </div>
            </div>
        )
    }

    const fetchReview = async () => {
        try {

            // determine if a review was already provided
            if(review) {
                setText(review.reply && review.reply.text);
                return;
            }

            // fetch review details from server if applicable
            setLoading('init');
            let response = await Request.get(utils, '/dealerships/', {
                dealership_id: dealershipID || utils.dealership.get().id,
                review_id: id,
                type: 'google_review_details'
            });

            // end loading and update local state
            setLoading(false);
            setText(response.review.reply && response.review.reply.text);
            setTarget({
                ...response.review,
                date: moment.utc(response.review.date).local()
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this review. ${e.message || 'An unknown error occurred'}`,
                onClick: setLayerState.bind(this, 'close')
            });
        }
    }
    useEffect(() => {
        fetchReview();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'Google Review Reply'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            removePadding: true,
            sizing: 'medium'
        }}>
            {getContent()}
        </Layer>
    )
}

export const ManageTraineeSignup = ({ abstract, index, options, utils }) => {

    const layerID = `manage_trainee_signup_${abstract.getID()}`;
    const [edits, setEdits] = useState(abstract.object.preferences?.trainee_signup_link || {});
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onSubmitClick = async () => {
        try {
            setLoading('submit');
            let { preferences } = await Request.post(utils, '/dealerships/', {
                config: edits,
                dealership_id: abstract.getID(),
                type: 'update_trainee_signup'
            });

            // update preferences for abstract target and notify subscribers
            abstract.object.preferences = preferences;
            utils.content.update.bind(this, abstract)

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: 'Your changes to the trainee signup process have been saved and will take effect immediately.',
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue submitting your trainee signup changes. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        setEdits(prev => ({ ...prev, ...props }));
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'submit',
            loading: loading === 'submit',
            onClick: onSubmitClick,
            text: 'Submit'
        }];
    }

    const getFields = () => {
        return [{
            id: 'details',
            title: 'Configuration',
            items: [{
                component: 'bool_list',
                key: 'enabled',
                onChange: val => onUpdateTarget({ enabled: val }),
                title: 'Open to Public',
                value: edits.enabled
            },{
                component: 'bool_list',
                key: 'setup_testcom_account',
                onChange: val => onUpdateTarget({ setup_testcom_account: val }),
                required: false,
                title: 'Request Certification Test Fee',
                value: edits.setup_testcom_account || false,
                visible: edits.enabled === true
            }]
        }];
    }

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'Manage Trainee Signup'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            fields={getFields()}
            utils={utils} />
        </Layer>
    )
}


