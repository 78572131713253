import React, { useEffect, useRef, useState } from 'react';

import moment from 'moment';
import update from 'immutability-helper';
import { withAnchorPoint } from 'files/withAnchorPoint.js';

import Abstract from 'classes/Abstract.js';
import AltFieldMapper, { validateRequiredFields } from 'views/AltFieldMapper.js';
import Appearance from 'styles/Appearance.js';
import { Bar, Line, Pie } from 'react-chartjs-2';
import Card from 'classes/Card.js';
import { CardDetails } from 'managers/Cards.js';
import CommLink from 'classes/CommLink.js';
import Content from 'managers/Content.js';
import CountableLabel from 'views/CountableLabel.js';
import DualDatePickerField from 'views/DualDatePickerField.js';
import DatePickerField from 'views/DatePickerField.js';
import Dealership from 'classes/Dealership.js';
import { DealershipDetails } from 'managers/Dealerships.js';
import FieldMapper from 'views/FieldMapper.js';
import Layer, { CollapseArrow, LayerItem } from 'structure/Layer.js';
import ListField from 'views/ListField';
import LottieView from 'views/Lottie.js';
import { Map } from 'views/MapElements.js';
import OmniShieldWhiteLabel from 'classes/OmniShieldWhiteLabel.js';
import PageControl from 'views/PageControl.js';
import Panel from 'structure/Panel.js';
import Request from 'files/Request.js';
import { TableListHeader, TableListRow } from 'views/TableList.js';
import TextField from 'views/TextField.js';
import TextView from 'views/TextView.js';
import User from 'classes/User.js';
import { UserDetails } from 'managers/Users.js';
import Utils from 'files/Utils.js';
import Views, { AltBadge } from 'views/Main.js';

export const AddEditCommLink = ({ isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new_comm_link' : `edit_comm_link_${abstract.getID()}`;
    const [commLink, setCommLink] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onDoneClick = async () => {
        try {

            setLoading('done');
            await validateRequiredFields(getFields);

            // create target
            if(isNewTarget) {
                await abstract.object.submit(utils);
                setLoading(false);
                utils.alert.show({
                    title: 'All Done!',
                    message: `The Comm Link for ${commLink.name} has been created`,
                    onClick: () => setLayerState('close')
                });
                return;
            }

            // update target
            await abstract.object.update(utils);
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The comm link for ${commLink.name} has been updated`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this comm link. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUpdateTarget = async props => {
        try {
            let edits = await abstract.object.set(props);
            setCommLink(edits);
        } catch(e) {
            console.log(e.message);
        }
    }

    const getFields = () => {

        if(!commLink) {
            return [];
        }
        return [{
            key: 'about',
            title: 'About this Comm Link',
            items: [{
                key: 'name',
                title: 'Name',
                description: `What would you like to call this Comm Link?`,
                value: commLink.name,
                component: 'textfield',
                onChange: text => onUpdateTarget({ name: text })
            },{
                key: 'address',
                title: 'Installation Address',
                description: 'What is the physical location for this Comm Link?',
                component: 'address_lookup',
                onChange: response => onUpdateTarget(response),
                value: commLink.address && {
                    address: commLink.address,
                    location: commLink.location
                }
            }]
        },{
            key: 'dealer_and_seller',
            title: 'Dealer and Seller',
            items: [{
                key: 'dealership',
                title: 'Authorized Dealer',
                description: 'What dealership does this protection belong to?',
                visible: utils.user.get().level < User.levels.get().dealer,
                value: commLink.dealership,
                component: 'dealership_lookup',
                onChange: dealership => {
                    onUpdateTarget({ 
                        dealership: dealership,
                        dealership_phone_number: commLink.dealership_phone_number || (dealership && Utils.formatPhoneNumber(dealership.phone_number))
                    });
                }
            },{
                key: 'dealership_phone_number',
                title: 'Dealer Phone Number',
                description: 'This is the phone number for the dealership that sold the home safe network.',
                props: { format: 'phone_number' },
                visible: utils.user.get().level < User.levels.get().dealer,
                value: commLink.dealership_phone_number,
                component: 'textfield',
                onChange: text => onUpdateTarget({ dealership_phone_number: text })
            },{
                key: 'sold_by_user',
                required: false,
                title: 'Safety Advisor',
                description: 'This is the user who will receive credit, in addition to the dealership, for this protection',
                value: commLink.sold_by_user,
                component: 'user_lookup',
                onChange: user => onUpdateTarget({ sold_by_user: user }),
                props: {
                    dealership: commLink.dealership,
                    restrictToDealership: true
                }
            }]
        }];
    }

    const setupTarget = async () => {
        try {
            let edits = await abstract.object.open();
            setCommLink(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up this comm link. ${e.message || 'An unknown error occurred'}`,
                onClick: () => setLayerState('close')
            })
        }
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        title={isNewTarget ? 'New Comm Link' : `Editing "${abstract.getTitle()}"`}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading === true,
            sizing: 'medium',
            layerState: layerState
        }}
        buttons={[{
            key: 'done',
            text: isNewTarget ? 'Done' : 'Save Changes',
            color: 'primary',
            loading: loading === 'done',
            onClick: onDoneClick
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const AddEditCommLinkContact = ({ commLink, isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new_comm_link_contact' : `edit_comm_link_contact_${abstract.getID()}`;
    const [avatar, setAvatar] = useState(abstract.object.avatar);
    const [contact, setContact] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onDoneClick = async () => {
        try {

            setLoading('done');
            await Utils.sleep(1);
            await validateRequiredFields(getFields);

            // create target
            if(isNewTarget) {
                await abstract.object.submit(utils, { comm_link_guid: commLink.guid });
                setLoading(false);
                utils.alert.show({
                    title: 'All Done!',
                    message: `${abstract.object.full_name} has been added as an emergency contact for ${commLink.name || 'Unnamed Comm Link'}`,
                    onClick: () => setLayerState('close')
                })
                return;
            }

            // update target
            await abstract.object.update(utils, { comm_link_guid: commLink.guid });
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The emergency contact for ${abstract.object.full_name} has been updated`,
                onClick: () => setLayerState('close')
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} your contact. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onRequestIncludeTravelUpdate = async () => {
        return new Promise(resolve => {
            utils.alert.show({
                title: 'Lives with Home Safe Network',
                message: `We would recommend removing ${contact.full_name || 'this contact'} from your Emergency Travel Time Map since they live at the same address as your Home Safe Network. The Emergency Travel Time Map is helpful for contacts who do not live with the Home Safe Network.`,
                buttons: [{
                    key: 'confirm',
                    title: 'Sounds Good',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Do Not Remove',
                    style: 'destructive'
                }],
                onClick: key => {
                    resolve(key === 'confirm' ? false : true);
                }
            })
        })
    }

    const onUpdateTarget = async props => {
        try {

            // check if update includes an address and set resident to true if locations are within 25 feet of each other
            if(props.location && commLink.location) {
                props.resident = Utils.linearDistance(props.location, commLink.location).feet < 25 ? true : false;
            }

            // check if resident flag has been set and request removal from map if applicable
            if(props.resident && contact.include_with_travel === true) {
                props.include_with_travel = await onRequestIncludeTravelUpdate();
            }

            // apply edits to target
            let edits = await abstract.object.set(props);
            setContact(edits);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this contact. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getButtons = () => {
        return [{
            key: 'done',
            text: 'Done',
            color: 'primary',
            loading: loading === 'done',
            onClick: onDoneClick
        }];
    }

    const getFields = () => {
        if(!contact) {
            return [];
        }
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'first_name',
                title: 'First Name',
                icon: 'title',
                value: contact.first_name,
                component: 'textfield',
                onChange: text => onUpdateTarget({ first_name: text })
            },{
                key: 'last_name',
                title: 'Last Name',
                icon: 'title',
                value: contact.last_name,
                component: 'textfield',
                onChange: text => onUpdateTarget({ last_name: text })
            },{
                key: 'email_address',
                required: false,
                title: 'Email Address',
                icon: 'email',
                value: contact.email_address,
                component: 'textfield',
                onChange: text => onUpdateTarget({ email_address: text }),
                props: {
                    autoCorrect: false,
                    autoCapitalize: false
                }
            },{
                key: 'phone_number',
                title: 'Phone Number',
                icon: 'phone',
                value: contact.phone_number,
                component: 'textfield',
                onChange: text => onUpdateTarget({ phone_number: text }),
                props: {
                    format: 'phone_number'
                }
            },{
                key: 'address',
                required: false,
                title: 'Address',
                value: contact.address && {
                    address: contact.address,
                    location: contact.location
                },
                component: 'address_lookup',
                onChange: result => onUpdateTarget(result)
            },{
                key: 'avatar',
                required: false,
                title: 'Contact Photo',
                value: avatar,
                component: 'image_picker',
                onChange: result => {
                    setAvatar({
                        ...result,
                        local: true,
                        date: moment().unix()
                    });
                }
            }]
        },{
            key: 'preferences',
            title: 'Preferences',
            items: [{
                key: 'owner',
                required: false,
                title: 'Owner of Home Safe Network',
                value: contact.owner,
                component: 'bool_list',
                onChange: enabled => onUpdateTarget({ owner: enabled }),
                props: {
                    color: contact.owner ? Appearance.colors.primary() : Appearance.colors.grey()
                }
            },{
                key: 'resident',
                required: false,
                title: 'Lives with Home Safe Network',
                value: contact.resident,
                component: 'bool_list',
                onChange: enabled => onUpdateTarget({ resident: enabled }),
                props: {
                    color: contact.resident ? Appearance.colors.primary() : Appearance.colors.grey()
                }
            },{
                key: 'include_with_travel',
                required: false,
                title: 'Include on Travel Time Map',
                value: contact.include_with_travel,
                component: 'bool_list',
                onChange: enabled => onUpdateTarget({ include_with_travel: enabled }),
                props: {
                    color: contact.include_with_travel ? Appearance.colors.primary() : Appearance.colors.grey()
                }
            }]
        },{
            key: 'notifications',
            title: 'Notifications',
            items: [{
                key: 'emergency_alerts',
                required: false,
                title: 'Emergencies (Always On)',
                value: contact.getPreference('emergency_alerts'),
                component: 'bool_list',
                props: {
                    editable: false,
                    color: contact.getPreference('emergency_alerts') ? Appearance.colors.primary() : Appearance.colors.grey()
                }
            },{
                key: 'maintenance',
                required: false,
                title: 'Maintenance',
                value: contact.getPreference('maintenance'),
                component: 'bool_list',
                props: {
                    color: contact.getPreference('maintenance') ? Appearance.colors.primary() : Appearance.colors.grey()
                },
                onChange: enabled => {
                    onUpdateTarget({ 
                        preferences: { 
                            maintenance: enabled 
                        } 
                    });
                }
            },{
                key: 'news_and_events',
                required: false,
                title: 'OmniShield News and Events',
                value: contact.getPreference('news_and_events'),
                component: 'bool_list',
                props: {
                    color: contact.getPreference('news_and_events') ? Appearance.colors.primary() : Appearance.colors.grey()
                },
                onChange: enabled => {
                    onUpdateTarget({ 
                        preferences: { 
                            news_and_events: enabled 
                        } 
                    });
                }
            },{
                key: 'self_test',
                required: false,
                title: 'Self Tests',
                value: contact.getPreference('self_test'),
                component: 'bool_list',
                props: {
                    color: contact.getPreference('self_test') ? Appearance.colors.primary() : Appearance.colors.grey()
                },
                onChange: enabled => {
                    onUpdateTarget({ 
                        preferences: { 
                            self_test: enabled 
                        } 
                    });
                }
            }]
        }]
    }

    const setupTarget = () => {
        let edits = abstract.object.open();
        setContact(edits);
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        utils={utils}
        title={isNewTarget ? 'New Contact' : abstract.getTitle()}
        options={{
            ...options,
            loading: loading,
            layerState: layerState,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const AdvancedCommLinksSearch = ({ index, options, utils }) => {

    const layerID = 'advanced_comm_links_search';
    const limit = 10;
    const offset = useRef(0);
    const searchResultsRef = useRef();

    const [edits, setEdits] = useState({});
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [paging, setPaging] = useState(null);
    const [results, setResults] = useState([]);

    const onClearClick = () => {
        utils.alert.show({
            title: 'Clear Fields',
            message: 'Are you sure that you want to clear and reset your search fields?',
            buttons: [{
                key: 'confirm',
                title: 'Confirm',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    setEdits({});
                    return;
                }
            }
        });
    }

    const onCommLinkClick = async target => {
        utils.layer.open({
            id: `comm_link_details_${target.id}`,
            abstract: Abstract.create({
                type: 'comm_link',
                object: target
            }),
            Component: CommLinkDetails
        });
    }

    const onFetchSearchResults = async () => {
        try {

            // prevent moving forward unless at least one filter was provided
            if(Object.keys(edits).length === 0) {
                throw new Error('Please fill out at least one field before submitting your search');
            }

            // prepare payload for query
            let payload = { ...edits };

            // determine if date, dealership, or sold_by_user variables need to be prepared for the query
            if(edits.date || edits.dealership || edits.sold_by_user) {

                // format variables for query
                if(edits.date) {
                    payload.date = moment(edits.date).utc().unix();
                }
                if(edits.dealership) {
                    payload.comm_link_dealership_id = edits.dealership.id;
                    delete payload.dealership;
                }
                if(edits.sold_by_user) {
                    payload.sold_by_user_id = edits.sold_by_user.user_id;
                    delete payload.sold_by_user;
                }
            }

            // submit request to server
            let { results, paging } = await Request.post(utils, '/omnishield/', {
                ...payload,
                limit: limit,
                offset: offset.current,
                type: 'comm_links_advanced_search'
            });

            // prevent moving forward if no resutls are found
            setLoading(false);
            setPaging(paging);
            setResults(results);

            // show alert if no results were found
            if(results.length === 0) {
                utils.alert.show({
                    title: 'No Results Found',
                    message: 'We were unable to find any comm links matching your search criteria'
                });
                return;
            }

            // scroll to the bottom of the view to reveal the search results
            searchResultsRef.current.scrollIntoView({ behavior: 'smooth' });
            
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue performing your search. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        setEdits(prev => ({
            ...prev,
            ...props
        }));
    }

    const getButtons = () => {
        return [{
            color: 'dark',
            key: 'clear',
            onClick: onClearClick,
            text: 'Clear'
        },{
            color: 'primary',
            key: 'submit',
            loading: loading === 'submit',
            onClick: () => {
                offset.current = 0;
                setLoading('submit');
                onFetchSearchResults();
            },
            text: 'Submit'
        }]
    }

    const getCountries = () => {
        let keys = ['Australia', 'Canada', 'The Philippines', 'United States'];
        return keys.map(key => ({
            id: key,
            title: key
        }));
    }

    const getFields = () => {
        let { administrative_area_level_1, locality } = getLocaleAwareAddressLabels();
        return [{
            key: 'customer',
            title: 'AFT Protection',
            items: [{
                component: 'textfield',
                description: 'This field represents the first name provided when the protection for the comm link was submitted to AFT.',
                key: 'first_name',
                onChange: text => onUpdateTarget({ first_name: text }),
                required: false,
                title: 'First Name',
                value: edits.first_name
            },{
                component: 'textfield',
                description: 'This field represents the last name provided when the protection for the comm link was submitted to AFT.',
                key: 'last_name',
                onChange: text => onUpdateTarget({ last_name: text }),
                required: false,
                title: 'Last Name',
                value: edits.last_name
            },{
                component: 'textfield',
                description: 'This field represents the email address provided when the protection for the comm link was submitted to AFT.',
                key: 'email_address',
                onChange: text => onUpdateTarget({ email_address: text }),
                required: false,
                title: 'Email Address',
                value: edits.email_address
            },{
                component: 'textfield',
                description: 'This field represents the phone number provided when the protection for the comm link was submitted to AFT.',
                key: 'phone_number',
                onChange: text => onUpdateTarget({ phone_number: text }),
                props: { format: 'phone_number' },
                required: false,
                title: 'Phone Number',
                value: edits.phone_number
            }]
        },{
            key: 'credentials',
            title: 'Credentials',
            items: [{
                component: 'textfield',
                description: 'This field represents the globally unique identifier, or guid, that is used to identify the comm link.',
                key: 'guid',
                onChange: text => onUpdateTarget({ guid: text }),
                required: false,
                title: 'GUID',
                value: edits.guid
            },{
                component: 'textfield',
                description: 'This field represents the record id that is used to identify the comm link.',
                key: 'comm_link_id',
                onChange: text => onUpdateTarget({ comm_link_id: text }),
                props: { format: 'number' },
                required: false,
                title: 'ID',
                value: edits.comm_link_id
            },{
                component: 'textfield',
                description: 'This field represents the serial number used to register the comm link.',
                key: 'serial_number',
                onChange: text => onUpdateTarget({ serial_number: text }),
                required: false,
                title: 'Serial Number',
                value: edits.serial_number
            },{
                component: 'textfield',
                description: 'This field represents the security key used to register the comm link.',
                key: 'security_key',
                onChange: text => onUpdateTarget({ security_key: text && text.toUpperCase() }),
                required: false,
                title: 'Security Key',
                value: edits.security_key
            }]
        },{
            key: 'contact',
            title: 'Emergency Contact',
            items: [{
                component: 'textfield',
                description: 'This field represents the email address provided when the emergency contact for the comm link was created.',
                key: 'contact_email_address',
                onChange: text => onUpdateTarget({ contact_email_address: text }),
                required: false,
                title: 'Email Address',
                value: edits.contact_email_address
            },{
                component: 'textfield',
                description: 'This field represents the phone number provided when the emergency contact for the comm link was created.',
                key: 'contact_phone_number',
                onChange: text => onUpdateTarget({ contact_phone_number: text }),
                props: { format: 'phone_number' },
                required: false,
                title: 'Phone Number',
                value: edits.contact_phone_number
            }]
        },{
            key: 'location',
            title: 'Location',
            items: [{
                component: 'textfield',
                description: `This field represents the ${locality.toLowerCase()} where the comm link was installed.`,
                key: 'locality',
                onChange: text => onUpdateTarget({ locality: text }),
                required: false,
                title: locality,
                value: edits.locality
            },{
                component: 'list',
                description: 'This field represents the country where the comm link was installed.',
                items: getCountries(),
                key: 'country',
                onChange: item => onUpdateTarget({ country: item && item.id }),
                required: false,
                title: 'Country',
                value: edits.country
            },{
                component: 'textfield',
                description: 'This field represents the postal code where the comm link was installed.',
                key: 'postal_code',
                onChange: text => onUpdateTarget({ postal_code: text }),
                required: false,
                title: 'Postal Code',
                value: edits.postal_code
            },{
                component: 'textfield',
                description: `This field represents the ${administrative_area_level_1.toLowerCase()} where the comm link was installed.`,
                key: 'administrative_area_level_1',
                onChange: text => onUpdateTarget({ administrative_area_level_1: text }),
                required: false,
                title: administrative_area_level_1,
                value: edits.administrative_area_level_1
            },{
                component: 'textfield',
                description: 'This field represents the primary street address where the comm link was installed.',
                key: 'street_address_1',
                onChange: text => onUpdateTarget({ street_address_1: text }),
                required: false,
                title: 'Street Address Line 1',
                value: edits.street_address_1
            },{
                component: 'textfield',
                description: 'This field represents the apartment number, suite number, or any other secondary street address identifier where the comm link was installed. This only applies to comm links registered or updated with the OmniShield V3 app or later.',
                key: 'street_address_2',
                onChange: text => onUpdateTarget({ street_address_2: text }),
                required: false,
                title: 'Street Address Line 2',
                value: edits.street_address_2
            }].sort((a,b) => {
                return a.title.localeCompare(b.title);
            })
        },{
            key: 'details',
            title: 'Details',
            items: [{
                component: 'bool_list',
                description: 'This field represents whether the comm link is active or inactive.',
                key: 'active',
                onChange: val => onUpdateTarget({ active: val }),
                required: false,
                title: 'Active',
                value: typeof(edits.active) === 'boolean' ? edits.active : null
            },{
                component: 'list',
                description: 'This field represents the connection type that the comm link currently has in place.',
                items: [{
                    id: 1,
                    title: 'Ethernet'
                },{
                    id: 2,
                    title: 'Wifi'
                }],
                key: 'connection_status',
                onChange: item => onUpdateTarget({ connection_status: item && item.id }),
                required: false,
                title: 'Connection',
                value: edits.connection_status ? (edits.connection_status === 1 ? 'Ethernet' : 'Wifi') : null
            },{
                component: 'dealership_lookup',
                description: 'This field represents the authorized dealership that was provided during registration. This only applies to comm links registered or updated with the OmniShield V3 app or later.',
                key: 'dealership',
                onChange: dealership => onUpdateTarget({ dealership: dealership }),
                required: false,
                title: 'Dealership',
                value: edits.dealership,
                visible: utils.user.get().level <= User.levels.get().admin
            },{
                component: 'textfield',
                description: 'This field represents the dealership phone number that was provided during registration.',
                key: 'dealership_phone_number',
                onChange: text => onUpdateTarget({ dealership_phone_number: text }),
                props: { format: 'phone_number' },
                required: false,
                title: 'Dealership Phone Number',
                value: edits.dealership_phone_number
            },{
                component: 'multiple_list',
                description: 'This field represents the version of the firmware running on the Comm Link.',
                items: getFirmwareVersions(),
                key: 'firmware_version',
                onChange: items => onUpdateTarget({ firmware_version: items && items.map(item => item.id) }),
                required: false,
                title: 'Firmware Version',
                value: edits.firmware_version && getFirmwareVersions().filter(item => edits.firmware_version.includes(item.id))
            },{
                component: 'date_picker',
                description: 'This field represents the date the comm link was installed and registered.',
                key: 'date',
                onChange: date => onUpdateTarget({ date: date }),
                required: false,
                title: 'Installation Date',
                value: edits.date
            },{
                component: 'user_lookup',
                description: 'This field represents the safety advisor who was provided during registration. This only applies to comm links registered or updated with the OmniShield V3 app or later.',
                key: 'sold_by_user',
                onChange: user => onUpdateTarget({ sold_by_user: user }),
                props: {
                    dealership: edits.dealership,
                    restrictToDealership: true
                },
                required: false,
                title: 'Safety Advisor',
                value: edits.sold_by_user
            },{
                component: 'textfield',
                description: 'This field represents the current name for the comm link.',
                key: 'system_name',
                onChange: text => onUpdateTarget({ system_name: text }),
                required: false,
                title: 'System Name',
                value: edits.system_name
            }]
        }];
    }

    const getFirmwareVersions = () => {
        let versions = ['V0.1','V0.4','V1.0','V1.1','V1.2','V1.4','V1.5','V1.6','V1.7','V1.8','V1.9','V2.0','V2.1','V2.2'];
        return versions.map(version => ({
            id: version,
            title: version
        }));
    }

    const getLocaleAwareAddressLabels = () => {
        switch(edits.country) {

            case 'Canada':
            return {
                administrative_area_level_1: 'Province',
                locality: 'Municipality'
            }

            default:
            return {
                administrative_area_level_1: 'State',
                locality: 'City'
            }
        }
    }

    const getResults = () => {
        let total = paging && paging.total || 0;
        return results.length > 0 && (
            <LayerItem title={`${Utils.softNumberFormat(total)} Search ${total === 1 ? 'Result' : 'Results'}`}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {results.map((target, index) => {
                        return (
                            Views.entry({
                                badge: {
                                    text: target.comm_link && `SN ${target.comm_link.serial_number}`,
                                    color: Appearance.colors.grey()
                                },
                                bottomBorder: index !== results.length - 1,
                                hideIcon: true,
                                key: index,
                                onClick: onCommLinkClick.bind(this, target.comm_link),
                                subTitle: target.card ? `Purchased: ${Utils.formatDate(target.card.date)}` : 'Not Registered with AFT',
                                title: target.comm_link.name || 'Unnamed Network'
                            })
                        )
                    })}
                    {paging && (
                        <PageControl
                        data={paging}
                        limit={limit}
                        loading={loading === 'paging'}
                        offset={offset.current}
                        onClick={next => {
                            offset.current = next;
                            setLoading('paging');
                            onFetchSearchResults();
                        }} />
                    )}
                </div>
            </LayerItem>
        )
    }

    return (
        <Layer
        buttons={getButtons()}
        index={index}
        id={layerID}
        title={'Comm Links Advanced Search'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            removePadding: true,
            sizing: 'medium'
        }}>
            <div style={{
                padding: 12
            }}>
                <AltFieldMapper
                fields={getFields()}
                utils={utils} />
            </div>
            <div 
            ref={searchResultsRef}
            style={{
                borderTop: `1px solid ${Appearance.colors.divider()}`,
                padding: `12px 24px 12px 12px`
            }}>
                {getResults()}
            </div>
        </Layer>
    )
}

export const ComcastIssueNotifications = ({ index, options, utils }) => {

    const panelID = 'comcast_issue_notifications';
    
    const limit = 10;
    const offset = useRef(0);
    const sorting = useRef(null);

    const [loading, setLoading] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [paging, setPaging] = useState(null);
    const [product, setProduct] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onNotificationClick = async notification => {
        try {

            setLoading(notification.id);
            let commLink = await CommLink.get(utils, null, { serial_number: notification.comm_link_serial_number });

            setLoading(false);
            utils.layer.open({
                id: `comm_link_details_${commLink.id}`,
                abstract: Abstract.create({
                    type: 'comm_link',
                    object: commLink
                }),
                Component: CommLinkDetails
            });
            
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this comm link. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSortingChange = val => {
        sorting.current = val;
        setLoading(true);
        fetchNotifications();
    }

    const getContent = () => {
        if(notifications.length === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: 'No notifications were found for your dealership',
                    title: 'Nothing to see here'
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {notifications.map(getFields)}
                </tbody>
            </table>
        )
    }

    const getFields = (notification, index) => {
    
        // create mobile entries for mobile devices if applicable
        let target = notification || {};
        if(Utils.isMobile() === true) {
            return (
                Views.entry({
                    bottomBorder: index !== notifications.length - 1,
                    hideIcon: true,
                    key: index,
                    loading: loading === target.id,
                    onClick: onNotificationClick.bind(this, target),
                    subTitle: `Notified ${Utils.formatDate(target.date)}`,
                    title: `Comm Link ${target.comm_link_serial_number}`,
                    ...product && product.icon && {
                        hideIcon: false,
                        icon: { path: product.icon.url }
                    }
                })
            )
        }

        // create desktop and tablet entries if applicable
        let fields = [{
            key: '_id',
            title: 'Notification ID',
            value: target.id
        },{
            key: 'comm_link_serial_number',
            title: 'Serial Number',
            value: target.comm_link_serial_number
        },{
            key: 'date',
            title: 'Date Notified',
            value: Utils.formatDate(target.date)
        },{
            key: 'duration',
            title: 'Offline Duration',
            value: Utils.parseDuration(target.duration)
        },{
            key: 'isp',
            title: 'Internet Service Provider',
            value: target.isp
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!notification) {
            let sorts = {
                [Content.sorting.type.alphabetically]: 'comm_link_serial_number',
                [Content.sorting.type.ascending]: 'date',
                [Content.sorting.type.descending]: 'date'
            };
            return (
                <TableListHeader
                fields={fields}
                onChange={onSortingChange}
                {...sorting.current && sorting.current.general === true && {
                    value: {
                        direction: sorting.current.sort_type,
                        key: sorts[sorting.current.sort_type]
                    }
                }} />
            )
        }

        return (
            <TableListRow
            key={index}
            values={fields}
            lastItem={index === notifications.length - 1}
            onClick={onNotificationClick.bind(this, target)} />
        )
    }

    const fetchNotifications = async () => {
        try {
            setLoading(true);
            let { notifications, paging, product } = await Request.get(utils, '/omnishield/', {
                limit: limit,
                offset: offset.current,
                restrict_to_dealership: utils.user.get().level > User.levels.get().exigent_admin,
                search_text: searchText,
                type: 'comcast_issue_notifications',
                ...sorting.current
            });

            setLoading(false);
            setPaging(paging);
            setProduct(product);

            setNotifications(notifications.map(entry => {
                return {
                    ...entry,
                    date: moment.utc(entry.date).local()
                }
            }));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the offline comm link notifications list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchNotifications();
    }, [searchText]);

    useEffect(() => {

        utils.events.on(panelID, 'dealership_change', fetchNotifications);
        utils.content.subscribe(panelID, ['comm_link'], {
            onFetch: fetchNotifications,
            onUpdate: fetchNotifications
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchNotifications);
        }
    }, [])

    return (
        <Panel
        index={index}
        name={'Automated Offline Comm Link Notifications'}
        panelID={panelID}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            paging: {
                data: paging,
                limit: limit,
                offset: offset.current,
                onClick: next => {
                    setLoading(true);
                    offset.current = next;
                    fetchNotifications();
                }
            },
            search: {
                placeholder: 'Search by comm link serial number...',
                onChange: text => {
                    offset.current = 0;
                    setSearchText(text);
                }
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                width: '100%'
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const CommLinks = ({ index, options, utils }) => {

    const panelID = 'comm_links';
    
    const limit = 10;
    const offset = useRef(0);
    const sorting = useRef(null);

    const [commLinks, setCommLinks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [paging, setPaging] = useState(null);
    const [product, setProduct] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onAdvancedCommLinkSearch = () => {
        utils.layer.open({
            id: 'advanced_comm_links_search',
            Component: AdvancedCommLinksSearch
        })
    }

    const onCommLinkClick = async commLink => {
        try {
            utils.layer.open({
                id: `comm_link_details_${commLink.id}`,
                abstract: Abstract.create({
                    type: 'comm_link',
                    object: commLink
                }),
                Component: CommLinkDetails
            });
            
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this comm link. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onPrintCommLinks = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let { comm_links } = await Request.get(utils, '/omnishield/', {
                    type: 'comm_links_admin',
                    search_text: searchText,
                    ...sorting.current,
                    ...props
                });

                setLoading(false);
                resolve(comm_links.map(comm_link => CommLink.create(comm_link)));

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        })
    }

    const onRegisterCommLink = () => {
        utils.layer.open({
            id: 'register_comm_link',
            Component: RegisterCommLink
        });
    }

    const onSortingChange = val => {
        sorting.current = val;
        setLoading(true);
        fetchCommLinks();
    }

    const getButtons = () => {
        return [{
            key: 'search',
            onClick: onAdvancedCommLinkSearch,
            title: 'Search',
            style: 'secondary'
        },{
            key: 'register',
            onClick: onRegisterCommLink,
            title: 'Register',
            style: 'default'
        }]
    }

    const getContent = () => {
        if(commLinks.length === 0) {
            return (
                Views.entry({
                    title: 'Nothing to see here',
                    subTitle: 'No comm links were found for your dealership',
                    bottomBorder: false,
                    hideIcon: true
                })
            )
        }
        if(Utils.isMobile()) {
            return commLinks.map(getFields);
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {commLinks.map(getFields)}
                </tbody>
            </table>
        )
    }

    const getFields = (commLink, index) => {
    
        // create mobile entries for mobile devices if applicable
        let target = commLink || {};
        if(Utils.isMobile() === true) {
            return (
                Views.entry({
                    badge: {
                        text: target.comm_link && `SN ${target.comm_link.serial_number}`,
                        color: Appearance.colors.grey()
                    },
                    bottomBorder: index !== commLinks.length - 1,
                    hideIcon: true,
                    key: index,
                    onClick: onCommLinkClick.bind(this, target.comm_link),
                    subTitle: target.card ? target.card.full_name : 'AFT Protection Not Found',
                    title: target.comm_link && target.comm_link.name,
                    ...product && product.icon && {
                        hideIcon: false,
                        icon: {
                            path: product.icon.url,
                            imageStyle: {
                                backgroundColor: target.card ? Appearance.colors.primary() : Appearance.colors.grey()
                            }
                        }
                    }
                })
            )
        }

        // create desktop and tablet entries if applicable
        let fields = [{
            key: 'customer',
            title: 'Customer',
            padding: false,
            value: (
                Views.entry({
                    key: index,
                    title: target.card ? target.card.full_name : 'Customer Not Available',
                    subTitle: target.card && target.card.phone_number || 'AFT Protection Not Found',
                    hideIcon: true,
                    ...product && product.icon && {
                        hideIcon: false,
                        icon: {
                            path: product.icon.url,
                            imageStyle: {
                                backgroundColor: target.card ? Appearance.colors.primary() : Appearance.colors.grey()
                            }
                        }
                    },
                    bottomBorder: false,
                    style: {
                        padding: '8px 12px 8px 12px',
                        textAlign: 'left'
                    }
                })
            )
        },{
            key: 'system_name',
            title: 'System Name',
            value: target.comm_link && target.comm_link.name || 'Unnamed Network',
        },{
            key: 'serial_number',
            title: 'Serial Number',
            value: target.comm_link && target.comm_link.serial_number
        },{
            key: 'locality',
            title: 'City',
            value: target.comm_link && target.comm_link.locality
        },{
            key: 'administrative_area_level_1',
            title: 'State',
            value: target.comm_link && target.comm_link.administrative_area_level_1
        },{
            key: 'install_date',
            title: 'Install Date',
            value: target.comm_link ? Utils.formatDate(target.comm_link.date) : 'Not Applicable'
        },{
            key: 'date',
            title: 'Purchase Date',
            value: target.card ? Utils.formatDate(target.card.date) : 'Not Applicable'
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!commLink) {
            let sorts = {
                [Content.sorting.type.alphabetically]: 'customer',
                [Content.sorting.type.ascending]: 'date',
                [Content.sorting.type.descending]: 'date'
            };
            return (
                <TableListHeader
                fields={fields}
                onChange={onSortingChange}
                {...sorting.current && sorting.current.general === true && {
                    value: {
                        direction: sorting.current.sort_type,
                        key: sorts[sorting.current.sort_type]
                    }
                }} />
            )
        }

        return (
            <TableListRow
            key={index}
            values={fields}
            lastItem={index === commLinks.length - 1}
            onClick={onCommLinkClick.bind(this, target.comm_link)} />
        )
    }

    const getPrintProps = () => {
        return {
            onFetch: onPrintCommLinks,
            onRenderItem: item => ({
                customer: item.card.full_name,
                serial_number: item.comm_link.serial_number,
                locality: item.comm_link.locality,
                administrative_area_level_1: item.comm_link.administrative_area_level_1,
                install_date: Utils.formatDate(item.comm_link.date)
            }),
            headers: [{
                key: 'customer',
                title: 'Customer'
            },{
                key: 'serial_number',
                title: 'Serial Number'
            },{
                key: 'locality',
                title: 'City'
            },{
                key: 'administrative_area_level_1',
                title: 'State'
            },{
                key: 'install_date',
                title: 'Activated'
            }]
        }
    }

    const fetchCommLinks = async () => {
        try {
            setLoading(true);
            let { comm_links, paging, product } = await Request.get(utils, '/omnishield/', {
                limit: limit,
                offset: offset.current,
                restrict_to_dealership: utils.user.get().level !== User.levels.get().exigent_admin,
                search_text: searchText,
                type: 'comm_links_admin',
                ...sorting.current
            });

            setLoading(false);
            setPaging(paging);
            setProduct(product);

            setCommLinks(comm_links.map(entry => {
                return {
                    ...entry,
                    card: entry.card && {
                        ...entry.card,
                        phone_number: entry.card.phone_number ? Utils.formatPhoneNumber(entry.card.phone_number) : null
                    }
                }
            }));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the comm links list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchCommLinks();
    }, [searchText]);

    useEffect(() => {

        utils.events.on(panelID, 'dealership_change', fetchCommLinks);
        utils.content.subscribe(panelID, [ 'card', 'comm_link' ], {
            onFetch: fetchCommLinks,
            onUpdate: fetchCommLinks
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchCommLinks);
        }
    }, [])

    return (
        <Panel
        index={index}
        name={'Comm Links'}
        panelID={panelID}
        utils={utils}
        options={{
            ...options,
            buttons: getButtons(),
            loading: loading,
            paging: {
                data: paging,
                limit: limit,
                offset: offset.current,
                onClick: next => {
                    setLoading(true);
                    offset.current = next;
                    fetchCommLinks();
                }
            },
            search: {
                placeholder: 'Search by card id, comm link serial number, comm link address, or customer name...',
                onChange: text => {
                    offset.current = 0;
                    setSearchText(text);
                }
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                width: '100%'
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const CommLinkCommunicate = ({ index, options, utils }) => {

    const panelID = 'comm_link_communicate';
    const limit = 10;
    const offset = useRef(null);

    const [entries, setEntries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onEntryClick = entry => {
        utils.layer.open({
            id: `comm_link_communication_details_${entry.id}`,
            abstract: Abstract.create({
                object: entry,
                type: 'comm_link_communication'
            }),
            Component: CommLinkCommunicationDetails
        });
    }

    const onNewCommunication = () => {
        utils.layer.open({
            id: 'new_comm_link_communication',
            Component: NewCommLinkCommunication
        });
    }

    const onUserTaskProgressChange = data => {
        try {
            setEntries(entries => {
                return entries.map(entry => {
                    if(entry.task && entry.task.id === data.id) {
                        entry.task = data;
                    }
                    return entry;
                })
            });
        } catch(e) {
            console.error(e.message);
        }
    }

    const getBadges = entry => {
        
        // prepare date badge
        let badges = [{
            color: Appearance.colors.grey(),
            text: Utils.formatDate(entry.date)
        }];

        // determine if a task is present and add status from task if applicable
        if(entry.task) {
            badges.push(entry.task.status);
        }
        return badges;
    }

    const getButtons = () => {
        return [{
            onClick: onNewCommunication,
            style: 'primary',
            title: 'New Communication'
        }];
    }

    const getContent = () => {
        if(entries.length === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: 'No communication entries were found',
                    title: 'Nothing to see here'
                })
            )
        }
        return entries.map((entry, index) => {
            return (
                Views.entry({
                    badge: getBadges(entry),
                    bottomBorder: index !== entries.length - 1,
                    icon: {
                        path: 'images/comm-link-communicate-icon.png',
                        imageStyle: {
                            backgroundColor: Appearance.colors.grey()
                        }
                    },
                    key: index,
                    onClick: onEntryClick.bind(this, entry),
                    subTitle: entry.message,
                    title: entry.title
                })
            )
        });
    }

    const fetchEntries = async () => {
        try {
            setLoading(true);
            let { entries, paging } = await Request.get(utils, '/omnishield/', {
                limit: limit,
                offset: offset.current,
                search_text: searchText,
                type: 'communicate_entries'
            });

            setLoading(false);
            setPaging(paging);
            setEntries(entries.map(entry => ({
                ...entry,
                date: moment.utc(entry.date).local()
            })));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the list of communication entries. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const connectToSockets = async () => {
        try {
            let user = utils.user.get();
            await utils.sockets.on('aft', 'tasks', `on_user_task_progress_change_${user.user_id}`, onUserTaskProgressChange);
        } catch(e) {
            console.error(e.message);
        }
    }

    const disconnectFromSockets = async () => {
        try {
            let user = utils.user.get();
            await utils.sockets.off('aft', 'tasks', `on_user_task_progress_change_${user.user_id}`, onUserTaskProgressChange);
        } catch(e) {
            console.error(e.message);
        }
    }

    useEffect(() => {
        fetchEntries();
    }, [searchText]);

    useEffect(() => {

        connectToSockets();
        utils.content.subscribe(panelID, 'comm_link_communication', {
            onFetch: fetchEntries,
            onUpdate: abstract => {
                setEntries(entries => {
                    return entries.map(entry => {
                        return entry.id === abstract.getID() ? abstract.object : entry
                    });
                });
            }
        });
        return () => {
            disconnectFromSockets();
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'Comm Link Customer Communications'}
        index={index}
        utils={utils}
        options={{
            ...options,
            buttons: getButtons(),
            loading: loading,
            paging: {
                data: paging,
                limit: limit,
                offset: offset,
                onClick: next => {
                    offset.current = next;
                    fetchEntries();
                }
            },
            search: {
                placeholder: 'Search by message or title...',
                onChange: setSearchText
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                width: '100%'
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const CommLinkReachabilityReport = ({ index, options, utils }) => {

    const panelID = 'comm_link_reachability_report';
    const limit = 9;

    const download = useRef(false);
    const preferences = useRef({ 
        date: moment(),
        min_duration: 3600, 
        max_duration: 172800 
    });
    const sorting = useRef(null);

    const [category, setCategory] = useState('isp');
    const [count, setCount] = useState(0);
    const [date, setDate] = useState(null);
    const [durationFilter, setDurationFilter] = useState(null);
    const [geojson, setGeoJson] = useState(null);
    const [ispFilter, setIspFilter] = useState(null);
    const [ispPreferences, setIspPreferences] = useState({ min: 5 });
    const [loading, setLoading] = useState(false);
    const [offset, setOffset] = useState(0);
    const [onlineStatus, setOnlineStatus] = useState(false);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [results, setResults] = useState([]);

    const onCommLinkClick = async sn => {
        try {
            setLoading(sn);
            let commLink = await CommLink.get(utils, null, { serial_number: sn });

            setLoading(false);
            utils.layer.open({
                id: `comm_link_details_${commLink.id}`,
                abstract: Abstract.create({
                    type: 'comm_link',
                    object: commLink
                }),
                Component: CommLinkDetails
            });
            
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this comm link. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onDateArrowClick = val => {
        preferences.current.date = moment(preferences.current.date).add(val, 'days');
        fetchReport();
    }

    const onDownloadResults = () => {
        download.current = true;
        fetchReport();
    }
    
    const getButtons = () => {
        return [{
            key: 'duration',
            onClick: setCategory.bind(this, 'duration'),
            title: 'Duration',
            style: category === 'duration' ? 'secondary' : 'dark',
            visible: onlineStatus === false
        },{
            key: 'locations',
            onClick: setCategory.bind(this, 'locations'),
            title: 'Locations',
            style: category === 'locations' ? 'secondary' : 'dark'
        },{
            key: 'isp',
            lastItem: false,
            onClick: setCategory.bind(this, 'isp'),
            title: 'Internet Service Providers',
            style: category === 'isp' ? 'secondary' : 'dark',
        }];
    }

    const getContent = () => {
        
        // determine if a map is needed for geojson representations
        // a map is shown regardless of results count, otherwise issues with display the correct the region occur
        if(category === 'locations') {
            return (
                <div className={'row m-0'}>
                    <div className={'col-12 p-0'}>
                        {getCustomizationComponents()}
                        <Map
                        features={getLocationFeatures()}
                        isScrollEnabled={true}
                        isZoomEnabled={true}
                        isRotationEnabled={true}
                        region={geojson && geojson.region}
                        style={{
                            height: 500,
                            width: '100%'
                        }}/>
                    </div>
                </div>
            )
        }

        // determine if no results were found
        if(results.length === 0) {
            return (
                <div className={'row m-0'}>
                    <div className={'col-12 p-0'}>
                        {getCustomizationComponents()}
                        {Views.entry({
                            hideIcon: true,
                            bottomBorder: false,
                            subTitle: `No comm links are available to view for ${preferences.current.date.format('MMMM, Do, YYYY')}`,
                            title: 'Nothing to see here'
                        })}
                    </div>
                </div>
            )
        }

        // fallback to rendering the requested report components
        return (
            <div className={'row m-0'}>
                <div className={'col-12 col-lg-8 col-xl-9 p-0'}>
                    {getCustomizationComponents()}
                    <div style={{
                        padding: 12
                    }}>
                        <Bar
                        height={150}
                        data={getData()}
                        options={{
                            legend: { display: false },
                            onClick: (_, element) => {
                                if(element[0] && element[0]._index >= 0) {
                                    switch(category) {
                                        case 'duration':
                                        let hours = parseInt(getData().labels[element[0]._index]);
                                        setDurationFilter(hours);
                                        break;

                                        case 'isp':
                                        setIspFilter(getData().labels[element[0]._index]);
                                        break;
                                    }
                                }
                            },
                            title: { display: false },
                            tooltips: {
                                callbacks: {
                                    title: datasets => {
                                        switch(category) {
                                            case 'duration':
                                            return `${datasets[0].yLabel} ${datasets[0].yLabel === 1 ? 'Comm Link' : 'Comm Links'}`;

                                            case 'isp':
                                            return datasets[0].xLabel;
                                        }
                                    },
                                    label: evt => {
                                        switch(category) {
                                            case 'duration':
                                            return `${Utils.parseDuration(evt.xLabel * 3600)} since last verbose event`;

                                            case 'isp':
                                            return `${evt.yLabel} ${onlineStatus ? 'online' : 'offline'} ${evt.yLabel === 1 ? 'network' : 'networks'}`;
                                        }
                                    }
                                }
                            }
                        }} 
                        width={350}/>
                    </div>
                </div>
                <div className={'col-12 col-lg-4 col-xl-3 p-0'}>
                    <div style={{
                        borderLeft: `1px solid ${Appearance.colors.divider()}`
                    }}>
                        <div style={{
                            alignItems: 'center',
                            borderBottom: `1px solid ${Appearance.colors.divider()}`,
                            display: 'flex',
                            flexDirection: 'row',
                            padding: 12
                        }}>
                            <TextField
                            appendContent={getFilter()} 
                            containerStyle={hasFilter() && {
                                paddingRight: 5
                            }}
                            onChange={setSearchText}
                            placeholder={'Search by comm link serial number...'} />
                            <img
                            className={'text-button'}
                            onClick={onDownloadResults}
                            src={'images/download-icon.png'}
                            style={{
                                height: 30,
                                marginLeft: 8,
                                width: 30
                            }} />
                        </div>
                        {getResults().filter((_, index) => {
                            return paging ? (index >= offset && index < offset + limit) : true;
                        }).map((result, index, results) => {
                            return (
                                Views.entry({
                                    bottomBorder: index !== results.length - 1,
                                    hideIcon: true,
                                    key: index,
                                    loading: loading === result.sn,
                                    onClick: onCommLinkClick.bind(this, result.sn),
                                    subTitle: `Last updated ${Utils.parseDuration(result.d, true)} ago`,
                                    title: `Comm Link SN ${result.sn}`
                                })
                            )
                        })}
                        {paging && (
                            <PageControl
                            data={paging}
                            limit={limit}
                            offset={offset}
                            onClick={setOffset} />
                        )}
                    </div>
                </div>
            </div>
        )
    }

    const getCustomizationComponents = () => {
        return (
            <div style={{
                alignItems: 'center',
                borderBottom: `1px solid ${Appearance.colors.divider()}`,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: '12px 24px 12px 24px'
            }}>
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    <img
                    className={'text-button'}
                    onClick={onDateArrowClick.bind(this, -1)}
                    src={'images/back-arrow-icon-grey.png'}
                    style={{
                        height: 25,
                        marginRight: 8,
                        minWidth: 25,
                        width: 25
                    }} />
                    <DatePickerField
                    hideIcon={true}
                    filterDate={date => {
                        return date.unix() >= moment('2023-09-07 00:00:00').unix() && date.unix() <= moment().endOf('day');
                    }}
                    onDateChange={date => {
                        preferences.current.date = date;
                        fetchReport();
                    }}
                    style={{ 
                        maxWidth: 200 
                    }}
                    utils={utils}
                    selected={preferences.current.date}/>
                    <img
                    className={'text-button'}
                    onClick={onDateArrowClick.bind(this, 1)}
                    src={'images/next-arrow-icon-grey.png'}
                    style={{
                        height: 25,
                        marginLeft: 8,
                        minWidth: 25,
                        width: 25
                    }} />
                </div>
                {getTotalCountOverview()}
                {getModifierFields()}
            </div>
        )
    }
    
    const getData = () => {

        // prepare categories of data based on the duration since the last update
        let data = getDataValues();

        // filter out isp entries if they dont fall within the requested min and max values
        if(category === 'isp') {
            Object.keys(data).forEach(key => {
                if(ispPreferences.min && data[key] < ispPreferences.min) {
                    delete data[key];
                }
                if(ispPreferences.max && data[key] > ispPreferences.max) {
                    delete data[key];
                }
            });
        }

        return { 
            datasets: [{
                backgroundColor: evt => {
                    switch(category) {
                        case 'duration':
                        let hours = Object.keys(data)[evt.dataIndex];
                        return parseInt(hours) === durationFilter ? Appearance.colors.primary() : Appearance.colors.grey();

                        case 'isp':
                        let isp = Object.keys(data)[evt.dataIndex];
                        return isp === ispFilter ? Appearance.colors.primary() : Appearance.colors.grey();
                    }
                },
                data: Object.values(data),
                label: 'Reachability'
            }], 
            labels: Object.keys(data) 
        }
    }

    const getDataValues = () => {
        return results.reduce((object, result) => {
            
            // sort by duration and count if applicable
            if(category === 'duration') {
                let duration = parseInt(Math.floor(result.d / 3600));
                if(!object[duration]) {
                    object[duration] = 0;
                }
                object[duration]++;
            }

            // sort by isp if applicable
            if(category === 'isp' || category === 'locations') {
                if(!result.i) {
                    return object;
                }
                if(!object[result.i]) {
                    object[result.i] = 0;
                }
                object[result.i]++;
            }
            
            return object;
        }, {});
    }

    const getDurationItems = () => {
        return [...new Array(48)].map((_, index) => ({
            id: (index + 1) * 3600,
            title: `${index + 1} ${(index + 1) === 1 ? 'Hour' : 'Hours'}`
        }));
    }

    const getFilter = () => {
        switch(category) {
            case 'duration':
            return durationFilter !== null && (
                <div 
                className={'text-button'}
                onClick={setDurationFilter.bind(this, null)}
                style={{
                    alignItems: 'center',
                    backgroundColor: Appearance.colors.primary(),
                    borderRadius: 15,
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 8,
                    padding: '3px 10px 3px 10px'
                }}>
                    <span style={{
                        color: 'white',
                        fontSize: 11,
                        fontWeight: 600,
                        whiteSpace: 'nowrap'
                    }}>{`${durationFilter} ${durationFilter === 1 ? 'Hour' : 'Hours'}`}</span>
                    <img 
                    src={'images/white-x-icon.png'}
                    style={{
                        height: 12,
                        marginLeft: 8,
                        width: 12
                    }} />
                </div>
            )

            case 'isp':
            return ispFilter !== null && (
                <div 
                className={'text-button'}
                onClick={setIspFilter.bind(this, null)}
                style={{
                    alignItems: 'center',
                    backgroundColor: Appearance.colors.primary(),
                    borderRadius: 15,
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 8,
                    padding: '3px 10px 3px 10px'
                }}>
                    <span style={{
                        color: 'white',
                        fontSize: 11,
                        fontWeight: 600,
                        maxWidth: 110,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }}>{ispFilter}</span>
                    <img 
                    src={'images/white-x-icon.png'}
                    style={{
                        height: 12,
                        marginLeft: 8,
                        width: 12
                    }} />
                </div>
            )
        }
    }

    const getFilterValue = () => {
        return (category === 'duration' && durationFilter) || (category === 'isp' && ispFilter);
    }

    const getHeatMapColors = () => {

        // determine if online colors were requested
        if(onlineStatus) {
            return [
                'interpolate',
                ['linear'],
                ['heatmap-density'],
                0,
                'rgba(177,181,175,0)',
                0.2,
                'rgb(177,181,175)',
                0.4,
                'rgb(219,225,216)',
                0.6,
                'rgb(195,224,181)',
                0.8,
                'rgb(137,197,107)',
                1,
                'rgb(69,170,96)'
            ]
        }

        // fallback to returning offline colors
        return [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0,
            'rgba(184,165,163,0)',
            0.2,
            'rgb(184,165,163)',
            0.4,
            'rgb(196,173,171)',
            0.6,
            'rgb(253,204,199)',
            0.8,
            'rgb(239,138,98)',
            1,
            'rgb(217,66,49)'
        ];
    }

    const getIspItems = () => {

        // prevent moving forward if no geojson is available
        if(!geojson || !geojson.data) {
            return [];
        }

        // prepare object of isp entries
        let entries = geojson.data.features.reduce((object, feature) => {
            let { i } = feature.properties;
            return {
                ...object,
                [i]: {
                    id: i,
                    title: i
                }
            }
        }, {});

        // remove keys and sort by item title
        return Object.values(entries).sort((a,b) => {
            return a.title.localeCompare(b.title);
        });
    }

    const getLocationFeatures = () => {

        // prevent moving forward if no geojson was provided or if loading is in progress
        if(!geojson || loading === true) {
            return null;
        }
        return {
            data: {
                ...geojson.data,
                features: geojson.data.features.filter(feature => {
                    return ispFilter ? feature.properties.i === ispFilter : true;
                })
            },
            id: 'comm_links',
            icons: [{
                key: 'comm-link-reachability-online-icon',
                path: 'images/comm-link-reachability-online-icon.png',
                raster: true
            },{
                key: 'comm-link-reachability-offline-icon',
                path: 'images/comm-link-reachability-offline-icon.png',
                raster: true
            }],
            layers: [{
                id: 'comm_links_heatmap',
                maxzoom: 8,
                source: 'comm_links',
                type: 'heatmap',
                paint: {
                    'heatmap-weight': [
                        'interpolate',
                        ['linear'],
                        ['get', 'dur'],
                        0,
                        0,
                        6,
                        1
                    ],
                    'heatmap-intensity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        0,
                        3,
                        12,
                        5
                    ],
                    'heatmap-color': getHeatMapColors(),
                    'heatmap-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        0,
                        2,
                        9,
                        20
                    ],
                    'heatmap-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        7,
                        1,
                        8,
                        0
                    ]
                }
            },{
                id: 'comm_link_icons',
                minzoom: 7,
                onClick: properties => {
                    onCommLinkClick(properties.s);
                },
                onHover: properties => {
                    return {
                        title: properties.i,
                        subTitle: `Comm Link ${properties.s}`
                    }
                },
                source: 'comm_links',
                type: 'symbol',
                layout: {
                    'icon-size': 0.35,
                    'icon-anchor': 'center',
                    'icon-image': onlineStatus ? 'comm-link-reachability-online-icon' : 'comm-link-reachability-offline-icon',
                    'icon-allow-overlap': true
                }
            }]
        }
    }

    const getModifierFields = () => {
        switch(category) {
            case 'duration':
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    <ListField
                    disablePlaceholder={true}
                    items={getDurationItems()}
                    onChange={item => {
                        preferences.current.min_duration = item ? item.id : preferences.current.min_duration;
                        fetchReport();
                    }}
                    placeholder={'Minimum Hours'}
                    style={{ 
                        marginLeft: 8,
                        maxWidth: 150 
                    }}
                    value={`${preferences.current.min_duration / 3600} ${preferences.current.min_duration / 3600 === 1 ? 'Hour' : 'Hours'}`} />
                    <img 
                    src={'images/next-arrow-grey.png'} 
                    style={{
                        height: 35,
                        minHeight: 35,
                        minWidth: 35,
                        objectFit: 'contain',
                        padding: 10,
                        width: 35
                    }} />
                    <ListField
                    disablePlaceholder={true}
                    items={getDurationItems()}
                    onChange={item => {
                        preferences.current.max_duration = item ? item.id : preferences.current.max_duration;
                        fetchReport();
                    }}
                    placeholder={'Maximum Hours'}
                    style={{ 
                        maxWidth: 150 
                    }}
                    value={`${preferences.current.max_duration / 3600} ${preferences.current.max_duration / 3600 === 1 ? 'Hour' : 'Hours'}`} />
                </div>
            )

            case 'isp':
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    <ListField
                    disablePlaceholder={true}
                    items={getNetworkCountItems()}
                    onChange={item => {
                        setIspPreferences(preferences => ({
                            ...preferences,
                            min: item ? item.id : preferences.min
                        }));
                    }}
                    placeholder={`Minimum ${onlineStatus ? 'Online' : 'Offline'} Networks`}
                    style={{ 
                        marginLeft: 8,
                        maxWidth: 225 
                    }}
                    value={`${ispPreferences.min} ${ispPreferences.min === 1 ? 'Network' : 'Networks'}`} />
                </div>
            )

            case 'locations':
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    <ListField
                    disablePlaceholder={false}
                    items={getIspItems()}
                    onChange={item => setIspFilter(item && item.id)}
                    placeholder={'Internet Service Provider'}
                    style={{ 
                        marginLeft: 8,
                        maxWidth: 225 
                    }}
                    value={ispFilter} />
                    {ispFilter !== null && (
                        <img 
                        className={'text-button'}
                        src={'images/close-button-light-small.png'}
                        onClick={setIspFilter.bind(this, null)} 
                        style={{
                            height: 25,
                            marginLeft: 8,
                            width: 25
                        }}/>
                    )}
                </div>
            )
        }
    }

    const getNetworkCountItems = () => {

        // prepare data values and remove object keys
        let values = getDataValues();
        values = Object.values(values);
        if(values.length === 0) {
            return [];
        }
        
        // find max value in array and prepare list items up to that value
        let max = Math.max(...values);
        return [...new Array(max)].map((_, index) => ({
            id: index + 1,
            title: `${index + 1} ${(index + 1) === 1 ? 'Network' : 'Networks'}`
        }));
    }

    const getResults = () => {
        return results.filter(result => {
            switch(category) {
                case 'duration':
                if(durationFilter) {
                    let duration = parseInt(Math.floor(result.d / 3600));
                    if(duration !== durationFilter) {
                        return false;
                    }
                }
                break;

                case 'isp':
                if(ispFilter && ispFilter !== result.i) {
                    return false;
                }
                break;
            }
            return searchText ? result.sn.toString().includes(searchText.toLowerCase()) : true;
        });
    }

    const getRightContent = () => {
        return (
            <div style={{
                alignItems: 'center',
                borderLeft: `1px solid ${Appearance.colors.softBorder()}`,
                display: 'flex',
                flexDirection: 'row',
                marginLeft: 4,
                paddingLeft: 12
            }}>
                <AltBadge 
                content={{
                    color: onlineStatus === true ? Appearance.colors.green : Appearance.colors.grey(),
                    text: 'Online'
                }} 
                onClick={onlineStatus === false && (() => {
                    setLoading(true);
                    setOnlineStatus(true);
                })}
                style={{
                    padding: '5px 16px 5px 16px'
                }} />
                <AltBadge 
                content={{
                    color: onlineStatus === false ? Appearance.colors.red : Appearance.colors.grey(),
                    text: 'Offline'
                }} 
                onClick={onlineStatus === true && (() => {
                    setLoading(true);
                    setOnlineStatus(false);
                })}
                style={{
                    padding: '5px 16px 5px 16px'
                }} />
            </div>
        )
    }

    const getTotalCountOverview = () => {

        let title = `${Utils.softNumberFormat(count)} Comm ${count === 1 ? 'Link' : 'Links'} ${onlineStatus ? 'Online' : 'Offline'}`;

        // determine if an isp filter has been set for categories other than 'durations'
        if(['isp','locations'].includes(category) && ispFilter) {
            let total = getDataValues()[ispFilter];
            title = `${Utils.softNumberFormat(total || 0)} ${ispFilter} Comm ${total === 1 ? 'Link' : 'Links'} ${onlineStatus ? 'Online' : 'Offline'}`;
        }

        return (
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                opacity: loading === false ? 1 : 0,
                padding: '6px 14px 6px 14px'
            }}>
                <span style={{
                    ...Appearance.textStyles.title(),
                }}>{title}</span>
                {moment().isSame(date, 'day') && (
                    <span style={{
                        ...Appearance.textStyles.subTitle()
                    }}>{`Last updated ${date && Utils.formatDateDuration(date)} ago`}</span>
                )}
            </div>
        )
    }

    const hasFilter = () => {
        return (category === 'duration' && durationFilter) || (category === 'isp' && ispFilter) ? true : false;
    }

    const fetchReport = async () => {
        try {
            setLoading(true);
            let { geojson, results, url } = await Request.get(utils, '/omnishield/', {
                date: preferences.current.date && preferences.current.date.format('YYYY-MM-DD'),
                download: download.current && {
                    category: category,
                    filter: getFilterValue()
                },
                limit: limit,
                online: onlineStatus,
                offset: offset.current,
                search_text: searchText,
                type: 'comm_link_reachability_report',
                ...sorting.current,
                ...onlineStatus === false && {
                    max_duration: preferences.current.max_duration,
                    min_duration: preferences.current.min_duration
                }
            });

            // end loading and determine if a download was requested 
            setLoading(false);
            if(download.current) {
                download.current = false;
                window.open(url);
                return;
            }
    
            // prepare paging object
            setPaging({
                total: results.entries.length,
                current_page: parseInt(offset / limit) + 1,
                number_of_pages: results.entries.length > limit ? Math.ceil(results.entries.length / limit) : 1
            });
            
            // update state for count, date updated, geojson, and report reports
            setCount(results.total);
            setDate(moment.utc(results.date).local());
            setGeoJson(geojson);
            setResults(results.entries);

            // determine if the min networks shown for the graph need to be adjusted for online comm links
            // this helps the graph become more readable with the number of results shown
            if(onlineStatus === true && ispPreferences.min !== 100) {
                setIspPreferences(preferences => ({
                    ...preferences,
                    min: 50
                }));
            }

            // determine if the min networks shown for the graph need to be adjusted for offline comm links
            // this helps the graph become more readable with the number of results shown
            if(onlineStatus === false && ispPreferences.min !== 5) {
                setIspPreferences(preferences => ({
                    ...preferences,
                    min: 5
                }));
            }

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the comm link reachability report list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        let targets = getResults();
        setPaging({
            total: targets.length,
            current_page: parseInt(offset / limit) + 1,
            number_of_pages: targets.length > limit ? Math.ceil(targets.length / limit) : 1
        });
    }, [category, durationFilter, ispFilter, onlineStatus, offset, searchText]);

    useEffect(() => {
        setOffset(0);
    }, [durationFilter, ispFilter, onlineStatus, searchText]);

    useEffect(() => {
        fetchReport();
    }, [onlineStatus]);

    useEffect(() => {

        utils.events.on(panelID, 'dealership_change', fetchReport);
        utils.content.subscribe(panelID, 'comm_link', {
            onFetch: fetchReport,
            onUpdate: fetchReport
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchReport);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'Comm Link Reachability Report'}
        index={index}
        utils={utils}
        options={{
            ...options,
            buttons: getButtons(),
            loading: loading,
            removePadding: true,
            rightContent: getRightContent(),
            subTitle: date ? `Last Updated: ${Utils.formatDate(date)}` : null
        }}>
            {getContent()}
        </Panel>
    )
}

export const OmniShieldCommLinkOfflineNotifications = ({ index, options, utils }) => {

    const panelID = 'omnishield_comm_link_offline_notifications';
    const limit = 10;
    const offset = useRef(0);
    const sorting = useRef({ sort_key: 'date', sort_type: Content.sorting.type.descending })

    const [loading, setLoading] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onNotificationClick = async target => {
        try {

            // fetch details for comm link
            setLoading(true);
            let result = await CommLink.get(utils, null, { serial_number: target.comm_link_serial_number });

            setLoading(false);
            utils.layer.open({
                abstract: Abstract.create({
                    object: result,
                    type: 'comm_link'
                }),
                Component: CommLinkDetails,
                id: `comm_link_details_${result.id}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this comm link. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getContent = () => {
        if(notifications.length === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: 'No comm link offline notifications were found',
                    title: 'No Notifications Found'
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {notifications.map(getFields)}
                </tbody>
            </table>
        )
    }

    const getFields = (target, index) => {
        
        let fields = [{
            key: 'date',
            title: 'Date',
            value: target?.date && Utils.formatDate(target.date)
        },{
            key: 'comm_link_serial_number',
            title: 'Comm Link Serial Number',
            value: target?.comm_link_serial_number
        },{
            color: target?.final_notice ? Appearance.colors.red : null,
            key: 'final_notice',
            title: 'Final Notice',
            value: target?.final_notice ? 'Yes' : 'No'
        },{
            key: 'start_date',
            title: 'Offline Start Date',
            value: target?.start_date && Utils.formatDate(target.start_date)
        },{
            key: 'duration',
            title: 'Offline Duration',
            value: target?.duration && Utils.parseDuration(target.duration)
        },{
            key: 'end_date',
            title: 'Offline End Date',
            value: target?.end_date && Utils.formatDate(target.end_date)
        },{
            key: 'isp',
            title: 'Internet Service Provider',
            value: target?.isp
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!target) {
            return (
                <TableListHeader
                fields={fields}
                onChange={next => {
                    sorting.current = next;
                    setLoading(true);
                    fetchNotifications();
                }} 
                value={sorting.current}/>
            )
        }

        return (
            <TableListRow
            key={index}
            values={fields}
            lastItem={index === notifications.length - 1}
            onClick={onNotificationClick.bind(this, target)} />
        )
    }

    const fetchNotifications = async props => {
        try {

            setLoading(true);
            let { notifications, paging } = await Request.get(utils, '/omnishield/', {
                limit: limit,
                offset: offset.current,
                search_text: searchText,
                type: 'comm_link_offline_notifications',
                ...sorting.current,
                ...props
            });

            setLoading(false);
            setPaging(paging);
            setNotifications(notifications.map(notification => ({
                ...notification,
                date: moment.utc(notification.date).local()
            })));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the comm link offline notifications list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchNotifications(); 
    }, [searchText]);

    return (
        <Panel
        panelID={panelID}
        index={index}
        name={'OmniShield Comm Link Offline Notifications'}
        utils={utils}
        options={{
            ...options,
            loading: loading === true,
            paging: {
                data: paging,
                limit: limit,
                offset: offset.current,
                onClick: next => {
                    offset.current = next;
                    setLoading('paging');
                    fetchNotifications();
                }
            },
            removePadding: true,
            search: {
                placeholder: 'Search by comm link serial number...',
                onChange: setSearchText
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const OmniShieldNetworksDeadSensors = ({ index, options, utils }) => {

    const panelID = 'omnishield_networks_dead_sensors';
    const limit = 10;
    const offset = useRef(0);
    const sorting = useRef({ sort_key: 'sensors', sort_type: Content.sorting.type.descending })

    const [commLinks, setCommLinks] = useState([]);
    const [date, setDate] = useState(moment());
    const [loading, setLoading] = useState(false);
    const [paging, setPaging] = useState(null);

    const onCommLinkClick = async target => {
        try {

            // fetch details for comm link
            setLoading(target.serial_number);
            let commLink = await CommLink.get(utils, null, { serial_number: target.serial_number });

            // fetch all sensor for the target network
            let { sensors } = await Request.get(utils, '/omnishield/', {
                security_key: commLink.security_key,
                serial_number: target.serial_number,
                type: 'sensors_from_network'
            });

            // open layer with sensor details list
            setLoading(false);
            utils.layer.open({
                Component: OmniShieldNetworksDeadSensorsDetails.bind(this, {
                    data: {
                        ...target,
                        sensors: target.sensors.map(sensor => {
                            return {
                                ...sensors.find(s => s.serial_number === sensor.serial_number),
                                ...sensor
                            }
                        })
                    }
                }),
                id: `omnishield_networks_dead_sensors_details_${target.id}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this comm link. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onDownloadClick = () => {
        setLoading(true);
        fetchReport({ download: true });
    }

    const getBadgeColor = count => {
        if(count === 1) {
            return Appearance.colors.yellow;
        }
        if(count === 2) {
            return Appearance.colors.orange;
        }
        return Appearance.colors.red;
    }

    const getButtons = () => {
        return [{
            key: 'download',
            onClick: onDownloadClick,
            style: 'default',
            title: 'Download'
        }];
    }

    const getContent = () => {
        if(commLinks.length === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: 'No networks with dead sensors were found for your dealership',
                    title: 'No Networks Found'
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {commLinks.map(getFields)}
                </tbody>
            </table>
        )
    }

    const getFields = (commLink, index) => {
        
        let fields = [{
            key: 'serial_number',
            title: 'Comm Link Serial Number',
            value: commLink?.serial_number
        },{
            key: 'last_known_date',
            title: 'Last Communication',
            value: commLink?.last_known_date && Utils.formatDate(commLink.last_known_date)
        },{
            color: getBadgeColor(commLink?.sensors.length),
            key: 'sensors',
            title: 'Number of Sensors',
            value: commLink?.sensors.length
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!commLink) {
            return (
                <TableListHeader
                fields={fields}
                onChange={next => {
                    sorting.current = next;
                    setLoading(true);
                    fetchReport();
                }} 
                value={sorting.current}/>
            )
        }

        return (
            <TableListRow
            key={index}
            values={fields}
            lastItem={index === commLinks.length - 1}
            onClick={onCommLinkClick.bind(this, commLink)} />
        )
    }

    const getVisibilityChangeProps = () => {
        if(utils.user.get().level > User.levels.get().admin) {
            return null;
        }
        return {
            onVisibilityChange: val => {
                fetchReport({ restrict_to_dealership: val });
            }
        }
    }

    const fetchReport = async props => {
        try {

            setLoading(true);
            let { comm_links, paging, url } = await Request.get(utils, '/omnishield/', {
                category: 'battery_level',
                date: date.format('YYYYMMDD'),
                limit: limit,
                offset: offset.current,
                type: 'comm_links_overview',
                ...sorting.current,
                ...props
            });

            setLoading(false);
            setPaging(paging);

            // determine if a file download url was provided
            if(url) {
                utils.alert.show({
                    title: 'Ready to Download',
                    message: `Your dead sensors report is ready to download. Click the button below to start the download process.`,
                    buttons: [{
                        key: 'download',
                        title: 'Download',
                        style: 'default'
                    },{
                        key: 'cancel',
                        title: 'Cancel',
                        style: 'cancel'
                    }],
                    onClick: key => {
                        if(key === 'download') {
                            window.open(url);
                            return;
                        }
                    }
                });
                return;
            }

            // update local state with sensor results
            setCommLinks(comm_links.map(comm_link => ({
                ...comm_link,
                last_known_date: moment.utc(comm_link.last_known_date).local()
            })));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the omnishield network dead sensors report. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchReport(); 
    }, [date]);

    useEffect(() => {
        utils.events.on(panelID, 'dealership_change', fetchReport);
        return () => {
            utils.events.off(panelID, 'dealership_change', fetchReport);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        index={index}
        name={'OmniShield Networks with Dead Sensors'}
        utils={utils}
        options={{
            ...options,
            ...getVisibilityChangeProps(),
            buttons: getButtons(),
            loading: loading === true,
            paging: {
                data: paging,
                limit: limit,
                offset: offset.current,
                onClick: next => {
                    offset.current = next;
                    setLoading('paging');
                    fetchReport();
                }
            },
            removePadding: true
        }}>
            <div style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`,
                padding: 12,
                width: '100%'
            }}>
                <DatePickerField
                onDateChange={setDate}
                selected={date}
                utils={utils}/>
            </div>
            {getContent()}
        </Panel>
    )
}

export const OmniShieldNetworksDeadSensorsDetails = ({ data }, { index, options, utils }) => {

    const layerID = `omnishield_networks_dead_sensors_details_${data.id}`;
    const [loading, setLoading] = useState(false);
    const [sensors, setSensors] = useState([]);

    const onSensorClick = async id => {
        try {

            // fetch details for sensor using sensor id
            setLoading(id);
            let sensor = await CommLink.Sensor.get(utils, { id });

            // open sensor details layer
            setLoading(false);
            utils.layer.open({
                abstract: Abstract.create({
                    object: sensor,
                    type: 'comm_link_sensor'
                }),
                Component: CommLinkSensorDetails,
                id: `comm_link_sensor_details_${sensor.id}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this sensor. ${e.message || 'An unknown error occurred;'}`
            });
        }
    }

    const setupTarget = () => {
        setSensors(data.sensors.map(sensor => ({
            ...sensor,
            verbose: {
                ...sensors.verbose,
                date: moment.utc(sensor.verbose.date).local()
            }
        })).sort((a,b) => {
            return a.verbose.date > b.verbose.date ? -1 : 1;
        }));
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        index={index}
        title={`Comm Link ${data.serial_number} Dead Sensors`}
        utils={utils}
        options={{
            ...options,
            sizing: 'medium'
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {sensors.map((sensor, index, sensors) => {
                    return (
                        Views.entry({
                            bottomBorder: index !== sensors.length - 1,
                            icon: { 
                                path: getSensorIcon({
                                    ...sensor,
                                    type: { code: sensor.type }
                                }) 
                            },
                            key: index,
                            loading: loading === sensor.id,
                            onClick: onSensorClick.bind(this, sensor.id),
                            subTitle: `Last Communication: ${Utils.formatDate(sensor.verbose.date)}`,
                            title: sensor.location || 'Unnamed Location'
                        })
                    )
                })}
            </div>
        </Layer>
    )
}

export const OmniShieldInactiveSensorsActivity = ({ index, options, utils }) => {

    const panelID = 'omnishield_inactive_sensors_activity';
    const limit = 10;
    const offset = useRef(0);
    const searchText = useRef(null);
    const sorting = useRef({ sort_key: 'count', sort_type: Content.sorting.type.descending });

    const [date, setDate] = useState(moment());
    const [loading, setLoading] = useState(true);
    const [paging, setPaging] = useState(null);
    const [results, setResults] = useState([]);

    const onDownloadClick = () => {
        console.log('click');
        setLoading(true);
        fetchReport({ download: true });
    }

    const onResultClick = target => {
        utils.layer.open({
            Component: OmniShieldInactiveSensorsActivityDetails.bind(this, {
                commLink: { serial_number: target.comm_link_serial_number }
            }),
            id: `omnishield_inactive_sensors_activity_details_${target.comm_link_serial_number}`
        });
    }

    const getButtons = () => {
        return [{
            key: 'download',
            onClick: onDownloadClick,
            style: 'default',
            title: 'Download'
        }];
    }

    const getContent = () => {
        if(results.length === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: 'No networks with inactive sensor activity were found for your dealership',
                    title: 'No Networks Found'
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {results.map(getFields)}
                </tbody>
            </table>
        )
    }

    const getFields = (result, index) => {
        
        // prepare requests per hour average
        let hours = (result?.duration || 0) / 3600;
        let val = result ? (hours < 1 ? result.count : Math.floor(result.count / hours)) : null;

        let fields = [{
            key: 'comm_link_serial_number',
            title: 'Comm Link Serial Number',
            value: result?.comm_link_serial_number
        },{
            key: 'start_date',
            title: 'Activity Started',
            value: result?.start_date && Utils.formatDate(result.start_date)
        },{
            key: 'end_date',
            title: 'Last Activity',
            value: result?.end_date && Utils.formatDate(result.end_date)
        },{
            color: getValueColor(val),
            key: 'average',
            title: 'Average Events Per Hour',
            value: val
        },{
            key: 'sensors',
            title: 'Number of Sensors',
            value: result?.sensors|| 0
        },{
            key: 'count',
            title: 'Total Events',
            value: result?.count
        },{
            key: 'ip_address',
            title: 'IP Address',
            value: result?.ip_address
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!result) {
            return (
                <TableListHeader
                fields={fields}
                onChange={next => {
                    sorting.current = next;
                    setLoading(true);
                    fetchReport();
                }} 
                value={sorting.current}/>
            )
        }

        return (
            <TableListRow
            key={index}
            values={fields}
            lastItem={index === results.length - 1}
            onClick={onResultClick.bind(this, result)} />
        )
    }

    const getValueColor = count => {
        if(count < 10) {
            return Appearance.colors.yellow;
        }
        if(count < 25) {
            return Appearance.colors.orange;
        }
        return Appearance.colors.red;
    }

    const getVisibilityChangeProps = () => {
        if(utils.user.get().level > User.levels.get().admin) {
            return null;
        }
        return {
            onVisibilityChange: val => {
                fetchReport({ restrict_to_dealership: val });
            }
        }
    }

    const fetchReport = async props => {
        try {

            let { paging, results, url } = await Request.get(utils, '/omnishield/', {
                date: date.format('YYYYMMDD'),
                limit: limit,
                offset: offset.current,
                search_text: searchText.current,
                type: 'inactive_sensors_report',
                ...sorting.current,
                ...props
            });

            setLoading(false);
            setPaging(paging);

            // determine if a file download url was provided
            if(url) {
                utils.alert.show({
                    title: 'Ready to Download',
                    message: `Your inactive sensors activity report is ready to download. Click the button below to start the download process.`,
                    buttons: [{
                        key: 'download',
                        title: 'Download',
                        style: 'default'
                    },{
                        key: 'cancel',
                        title: 'Cancel',
                        style: 'cancel'
                    }],
                    onClick: key => {
                        if(key === 'download') {
                            window.open(url);
                            return;
                        }
                    }
                });
                return;
            }

            // update local state with sensor results
            setResults(results.map(result => ({
                ...result,
                end_date: moment.utc(result.end_date).local(),
                start_date: moment.utc(result.start_date).local()
            })));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the omnishield inactive sensors report. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchReport(); 
    }, [date]);

    useEffect(() => {
        utils.events.on(panelID, 'dealership_change', fetchReport);
        return () => {
            utils.events.off(panelID, 'dealership_change', fetchReport);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        index={index}
        name={'OmniShield Inactive Sensors Activity'}
        utils={utils}
        options={{
            ...options,
            ...getVisibilityChangeProps(),
            buttons: getButtons(),
            loading: loading === true,
            paging: {
                data: paging,
                limit: limit,
                loading: loading === 'paging',
                offset: offset.current,
                onClick: next => {
                    offset.current = next;
                    setLoading('paging');
                    fetchReport();
                }
            },
            removePadding: true,
            search: {
                placeholder: 'Search by comm link serial number...',
                onChange: text => {
                    searchText.current = text;
                    setLoading(true);
                    fetchReport();
                },
                rightContent: (
                    <div style={{
                        marginLeft: 8,
                        minWidth: 250
                    }}>
                        <DatePickerField
                        onDateChange={date => {
                            setLoading(true);
                            setDate(date);
                        }}
                        selected={date}
                        utils={utils}/>
                    </div>
                )
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const OmniShieldInactiveSensorsActivityDetails = ({ commLink }, { index, options, utils }) => {

    const layerID = `omnishield_inactive_sensors_activity_details_${commLink.serial_number}`;
    const sorting = useRef({ sort_key: 'end_date', sort_type: Content.sorting.type.descending });

    const [loading, setLoading] = useState('init');
    const [sensors, setSensors] = useState([]);

    const onCommLinkClick = async () => {
        try {

            // fetch details for comm link
            setLoading('options');
            let result = await CommLink.get(utils, null, { serial_number: commLink.serial_number });

            setLoading(false);
            utils.layer.open({
                abstract: Abstract.create({
                    object: result,
                    type: 'comm_link'
                }),
                Component: CommLinkDetails,
                id: `comm_link_details_${result.id}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this comm link. ${e.message || 'An unknown error occurred'}`
            });
        }
    }
    
    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'comm_link',
                title: 'Comm Link Details',
                style: 'default'
            }],
            target: evt.target
        }, key => {
            if(key === 'comm_link') {
                onCommLinkClick();
                return;
            }
        });
    }

    const getButtons = () => {
        return [{
            color: 'secondary',
            key: 'options',
            loading: loading === 'options',
            onClick: onOptionsClick,
            text: 'Options'
        }];
    }

    const getContent = () => {
        if(loading === 'init') {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: 36,
                    width: '100%'
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 40,
                        width: 40
                    }}/>
                </div>
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {sensors.map(getFields)}
                </tbody>
            </table>
        )
    }

    const getFields = (sensor, index) => {
    
        let fields = [{
            key: 'serial_number',
            title: 'Serial Number',
            value: sensor?.serial_number
        },{
            key: 'sensor_type',
            title: 'Sensor Type',
            value: sensor?.sensor_type && CommLink.Sensor.types.toText(sensor.sensor_type)
        },{
            key: 'end_date',
            title: 'Last Activity',
            value: sensor?.end_date && Utils.formatDate(sensor.end_date)
        },{
            key: 'location',
            sortable: false,
            title: 'Location',
            value: sensor?.location || 'Unnamed Location'
        },{
            key: 'count',
            title: 'Total Events',
            value: sensor?.count
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!sensor) {
            return (
                <TableListHeader
                fields={fields}
                onChange={next => {
                    sorting.current = next;
                    setLoading(true);
                    fetchSensors();
                }} 
                value={sorting.current}/>
            )
        }

        return (
            <TableListRow
            key={index}
            lastItem={index === sensors.length - 1} 
            values={fields}/>
        )
    }

    const fetchSensors = async () => {
        try {
        
            let { sensors } = await Request.get(utils, '/omnishield/', {
                comm_link_serial_number: commLink.serial_number,
                type: 'inactive_sensors_report_result_details',
                ...sorting.current
            });

            setLoading(false);
            setSensors(sensors);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the inactive sensors activity list for this comm link. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchSensors();
    }, []);
    
    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Inactive Sensors Activity for Comm Link ${commLink.serial_number}`}
        utils={utils}
        options={{
            ...options,
            loading: loading === true,
            sizing: 'extra_large'
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {getContent()}
            </div>
        </Layer>
    )
}

export const OmniShieldNetworkInstallationsReport = ({ index, options, utils }) => {

    const panelID = 'omnishield_network_installations';
    const groupByDateFormat = useRef('month');

    const [datasets, setDatasets] = useState([]);
    const [dates, setDates] = useState({ end_date: moment(), start_date: moment('2016-01-01') });
    const [labels, setLabels] = useState([]);
    const [loading, setLoading] = useState(false);

    const onDateChange = (key, date) => {
        setDates(dates => {
            return update(dates, {
                [key]: {
                    $set: date
                }
            });
        });
    }

    const getButtons = () => {
        return [{
            color: Appearance.colors.primary(),
            key: 'group_by_date_format',
            onClick: () => {
                groupByDateFormat.current = groupByDateFormat.current === 'month' ? 'day' : 'month';
                fetchReport();
            },
            title: `Group By ${groupByDateFormat.current === 'month' ? 'Day' : 'Month'}`
        }];
    }

    const getContent = () => {

        if(datasets.length === 0 || labels.length === 0){
            return null;
        }

        return  (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                padding: 12,
                width: '100%'
            }}>
                <Bar
                height={250}
                width={600}
                data={{
                    datasets: datasets,
                    labels: labels
                }}
                options={{
                    legend: { display: false },
                    maintainAspectRatio: true,
                    responsive: true,
                    title: { display: false },
                    tooltips: {
                        callbacks: {
                            label: (evt, data) => {
                                return `${data.datasets[evt.datasetIndex].data[evt.index]} ${data.datasets[evt.datasetIndex].data[evt.index] === 1 ? 'Network' : 'Networks'}`;
                            },
                            title: (evt, data) => {
                                return data.labels[evt[0].index];
                            }
                        }
                    }
                }} />
            </div>
        )
    }

    const getVisibilityChangeProps = () => {
        if(utils.user.get().level > User.levels.get().admin) {
            return null;
        }
        return {
            onVisibilityChange: val => {
                fetchReport({ restrict_to_dealership: val });
            }
        }
    }

    const fetchReport = async props => {
        try {

            setLoading(true);
            let { data, labels } = await Request.get(utils, '/omnishield/', {
                end_date: dates.end_date.format('YYYY-MM-DD'),
                group_by_date_format: groupByDateFormat.current,
                start_date: dates.start_date.format('YYYY-MM-DD'),
                type: 'network_installations',
                ...props
            });

            setLoading(false);
            setLabels(labels.map(label => {
                return moment(label).format(groupByDateFormat.current === 'month' ? 'MMM YYYY' : 'MM/DD/YYYY');
            }));
            setDatasets([{
                backgroundColor: evt => {
                    return evt.dataset.data[evt.dataIndex] > 0 ? Appearance.colors.primary() : Appearance.colors.grey();
                },
                data: data,
                label: 'Network Installations',
            }]);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the network installations report. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchReport();
    }, [dates]);

    useEffect(() => {
        utils.events.on(panelID, 'dealership_change', fetchReport);
        return () => {
            utils.events.off(panelID, 'dealership_change', fetchReport);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        index={index}
        name={'OmniShield Network Installations'}
        utils={utils}
        options={{
            ...options,
            buttons: getButtons(),
            loading: loading,
            removePadding: true,
            ...getVisibilityChangeProps()
        }}>
            <div style={{
                padding: 12,
                width: '100%'
            }}>
                <DualDatePickerField
                onStartDateChange={onDateChange.bind(this, 'start_date')}
                onEndDateChange={onDateChange.bind(this, 'end_date')} 
                selectedEndDate={dates.end_date}
                selectedStartDate={dates.start_date}
                utils={utils}/>
            </div>
            {getContent()}
        </Panel>
    )
}

export const OmniShieldSensorReplacements = ({ index, options, utils }) => {

    const panelID = 'omnishield_sensor_replacements';
    const limit = 10;
    const offset = useRef(0);
    const sorting = useRef({ sort_key: 'date', sort_type: Content.sorting.type.descending })

    const [date, setDate] = useState(moment());
    const [loading, setLoading] = useState(false);
    const [paging, setPaging] = useState(null);
    const [sensors, setSensors] = useState([]);

    const onDownloadClick = () => {
        setLoading(true);
        fetchReport({ download: true });
    }

    const onSensorClick = async target => {
        utils.sheet.show({
            items: [{
                key: 'original',
                title: 'View Original Sensor',
                style: 'default'
            },{
                key: 'replacement',
                title: 'View Replacement Sensor',
                style: 'default'
            }]
        }, key => {
            if(key !== 'cancel') {
                onShowSensorDetails(key === 'original' ? target.original_serial_number : target.replacement_serial_number, target.sensor_type);
                return;
            }
        });
    }

    const onShowSensorDetails = async (serialNumber, sensorType) => {
        try {
            setLoading(true);
            let sensor = await CommLink.Sensor.get(utils, { sensor_type: sensorType, serial_number: serialNumber });

            setLoading(false);
            utils.layer.open({
                abstract: Abstract.create({
                    object: sensor,
                    type: 'comm_link_sensor'
                }),
                Component: CommLinkSensorDetails,
                id: `comm_link_sensor_details_${sensor.id}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this sensor. ${e.message || 'An unknown error occurred;'}`
            });
        }
    }

    const getButtons = () => {
        return [{
            key: 'download',
            onClick: onDownloadClick,
            style: 'default',
            title: 'Download'
        }];
    }

    const getContent = () => {
        if(sensors.length === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: 'No replacement sensor entries were found',
                    title: 'No Sensors Found'
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {sensors.map(getFields)}
                </tbody>
            </table>
        )
    }

    const getFields = (target, index) => {
        
        let fields = [{
            key: 'date',
            title: 'Date',
            value: target?.date && Utils.formatDate(target.date)
        },{
            key: 'sensor_type_text',
            title: 'Sensor Type',
            value: target?.sensor_type && CommLink.Sensor.types.toText(target.sensor_type)
        },{
            key: 'original_serial_number',
            title: 'Original Serial Number',
            value: target?.original_serial_number
        },{
            key: 'replacement_serial_number',
            title: 'Replacement Serial Number',
            value: target?.replacement_serial_number
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!target) {
            return (
                <TableListHeader
                fields={fields}
                onChange={next => {
                    sorting.current = next;
                    setLoading(true);
                    fetchReport();
                }} 
                value={sorting.current}/>
            )
        }

        return (
            <TableListRow
            key={index}
            values={fields}
            lastItem={index === sensors.length - 1}
            onClick={onSensorClick.bind(this, target)} />
        )
    }

    const fetchReport = async props => {
        try {

            setLoading(true);
            let { paging, sensors, url } = await Request.get(utils, '/omnishield/', {
                limit: limit,
                offset: offset.current,
                type: 'replacement_sensors',
                ...sorting.current,
                ...props
            });

            setLoading(false);
            setPaging(paging);

            // determine if a file download url was provided
            if(url) {
                utils.alert.show({
                    title: 'Ready to Download',
                    message: `Your sensor replacements report is ready to download. Click the button below to start the download process.`,
                    buttons: [{
                        key: 'download',
                        title: 'Download',
                        style: 'default'
                    },{
                        key: 'cancel',
                        title: 'Cancel',
                        style: 'cancel'
                    }],
                    onClick: key => {
                        if(key === 'download') {
                            window.open(url);
                            return;
                        }
                    }
                });
                return;
            }

            // update local state with sensor results
            setSensors(sensors.map(sensor => ({
                ...sensor,
                date: moment.utc(sensor.date).local()
            })));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the omnishield network dead sensors report. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchReport(); 
    }, []);

    return (
        <Panel
        panelID={panelID}
        index={index}
        name={'OmniShield Sensor Replacements'}
        utils={utils}
        options={{
            ...options,
            buttons: getButtons(),
            loading: loading === true,
            paging: {
                data: paging,
                limit: limit,
                offset: offset.current,
                onClick: next => {
                    offset.current = next;
                    setLoading('paging');
                    fetchReport();
                }
            },
            removePadding: true
        }}>
            {getContent()}
        </Panel>
    )
}

export const OmniShieldSensorVerboseFrequencyReport = ({ index, options, utils }) => {

    const panelID = 'omnishield_sensor_verbose_frequency_report';
    const [datasets, setDatasets] = useState([]);
    const [date, setDate] = useState(moment());
    const [labels, setLabels] = useState([]);
    const [loading, setLoading] = useState(false);

    const getContent = () => {
        if(loading === true) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: 100,
                    width: '100%'
                }}>
                    <LottieView
                    loop={true}
                    autoPlay={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 40,
                        width: 40
                    }}/>
                </div>
            )
        }
        if(datasets.length === 0 || labels.length === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: 'No data is available for the selected date.',
                    title: 'No Data Found'
                })
            )
        }
        return  (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                padding: 12,
                width: '100%'
            }}>
                <Line
                data={{
                    datasets: datasets,
                    labels: labels
                }}
                height={150}
                width={600}
                options={{
                    legend: { display: false },
                    maintainAspectRatio: true,
                    responsive: true,
                    title: { display: false },
                    tooltips: {
                        callbacks: {
                            label: (evt, data) => {
                                return `${data.datasets[evt.datasetIndex].data[evt.index]} Verbose ${data.datasets[evt.datasetIndex].data[evt.index] === 1 ? 'Event' : 'Events'}`;
                            },
                            title: (evt, data) => {
                                return data.labels[evt[0].index];
                            }
                        }
                    },
                    scales: {
                        xAxes: [{
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 50
                            }
                        }],
                        yAxes: [{
                            ticks: {
                                beginAtZero: false
                            }
                        }]
                    }
                }} />
            </div>
        )
    }

    const fetchReport = async props => {
        try {

            setLoading(true);
            let { data, labels } = await Request.get(utils, '/omnishield/', {
                date: date.format('YYYYMMDD'),
                type: 'sensor_verbose_frequency_report',
                ...props
            });

            setLoading(false);
            setLabels(labels.map(label => moment(label, 'HH:mm').format('h:mma')));
            setDatasets(data?.length > 0 && [{
                borderColor: Appearance.colors.grey(),
                borderWidth: 1,
                data: data,
                fill: true,
                label: 'Verbose Events Per Hour',
                pointBackgroundColor: Appearance.colors.grey(),
                pointBorderColor: Appearance.colors.grey(),
                pointBorderWidth: 1,
                pointRadius: 1,
                pointStyle: 'circle'
            }]);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the sensor verbose frequency report. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchReport();
    }, [date]);

    return (
        <Panel
        panelID={panelID}
        index={index}
        name={'OmniShield Sensors Verbose Event Frequency'}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            removePadding: true
        }}>
            <div style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`,
                padding: 12,
                width: '100%'
            }}>
                <DatePickerField
                onDateChange={setDate}
                selected={date}
                utils={utils}/>
            </div>
            {getContent()}
        </Panel>
    )
}

export const OmniShieldNetworkSensorAveragesReport = ({ index, options, utils }) => {

    const panelID = 'omnishield_network_sensor_averages';
    const [datasets, setDatasets] = useState([]);
    const [dates, setDates] = useState({ end_date: moment(), start_date: moment('2016-01-01') });
    const [labels, setLabels] = useState([]);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);

    const onDateChange = (key, date) => {
        setDates(dates => {
            return update(dates, {
                [key]: {
                    $set: date
                }
            });
        });
    }

    const getContent = () => {

        if(datasets.length === 0 || labels.length === 0){
            return null;
        }

        return  (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                padding: 12,
                width: '100%'
            }}>
                <div style={{
                    height: 400,
                    padding: 24,
                    width: 400
                }}>
                    <Pie
                    height={400}
                    width={400}
                    data={{
                        datasets: datasets,
                        labels: labels
                    }}
                    options={{
                        legend: { display: false },
                        maintainAspectRatio: true,
                        responsive: true,
                        title: { display: false },
                        tooltips: {
                            callbacks: {
                                label: (evt, data) => {
                                    return `${(data.datasets[evt.datasetIndex].data[evt.index] * 100).toFixed(2)}%`;
                                },
                                title: (evt, data) => {
                                    return data.labels[evt[0].index];
                                }
                            }
                        }
                    }} />
                </div>
                <div style={{
                    flexGrow: 1,
                    padding: 24
                }}>
                    <div style={{
                        ...Appearance.styles.unstyledPanel()
                    }}>
                        {Views.row({
                            label: 'Network Count',
                            prepend: (
                                <div style={{
                                    backgroundColor: Appearance.colors.grey(),
                                    borderRadius: 15,
                                    height: 20,
                                    marginRight: 8,
                                    width: 20
                                }} />
                            ),
                            value: Utils.softNumberFormat(total)
                        })}
                        {labels.map((label, index) => {
                            return (
                                Views.row({
                                    bottomBorder: index !== labels.length - 1,
                                    key: index,
                                    label: label,
                                    prepend: (
                                        <div style={{
                                            backgroundColor: datasets[0].colors[index],
                                            borderRadius: 15,
                                            height: 20,
                                            marginRight: 8,
                                            width: 20
                                        }} />
                                    ),
                                    value: `${(datasets[0].data[index] * 100).toFixed(2)}%`
                                })
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }

    const getVisibilityChangeProps = () => {
        if(utils.user.get().level > User.levels.get().admin) {
            return null;
        }
        return {
            onVisibilityChange: val => {
                fetchReport({ restrict_to_dealership: val });
            }
        }
    }

    const fetchReport = async props => {
        try {

            setLoading(true);
            let { colors, data, labels, total } = await Request.get(utils, '/omnishield/', {
                end_date: dates.end_date.format('YYYY-MM-DD'),
                start_date: dates.start_date.format('YYYY-MM-DD'),
                type: 'network_sensor_averages',
                ...props
            });

            setLoading(false);
            setLabels(labels);
            setDatasets([{
                backgroundColor: evt => {
                    return evt.dataset.colors[evt.dataIndex] || Appearance.colors.grey();
                },
                colors: colors,
                data: data,
                label: 'Network Sensor Averages',
            }]);
            setTotal(total);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the network sensors averages report. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchReport();
    }, [dates]);

    useEffect(() => {
        utils.events.on(panelID, 'dealership_change', fetchReport);
        return () => {
            utils.events.off(panelID, 'dealership_change', fetchReport);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        index={index}
        name={'OmniShield Network Sensor Averages'}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            removePadding: true,
            ...getVisibilityChangeProps()
        }}>
            <div style={{
                padding: 12,
                width: '100%'
            }}>
                <DualDatePickerField
                onStartDateChange={onDateChange.bind(this, 'start_date')}
                onEndDateChange={onDateChange.bind(this, 'end_date')} 
                selectedEndDate={dates.end_date}
                selectedStartDate={dates.start_date}
                utils={utils}/>
            </div>
            {getContent()}
        </Panel>
    )
}

export const OmniShieldVersion2SensorsReport = ({ index, options, utils }) => {

    const panelID = 'omnishield_version2_sensors_report';
    const limit = 9;
    
    const [date, setDate] = useState(moment());
    const [geojson, setGeoJson] = useState(null);
    const [loading, setLoading] = useState('init');
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    
    const onSensorClick = async (properties, loadingVal) => {
        try {
            setLoading(loadingVal === 'sensor_serial_number' ? properties.sn : true);
            let sensor = await CommLink.Sensor.get(utils, { serial_number: properties.sn, sensor_type: properties.tc });

            setLoading(false);
            utils.layer.open({
                abstract: Abstract.create({
                    object: sensor,
                    type: 'comm_link_sensor'
                }),
                Component: CommLinkSensorDetails,
                id: `comm_link_sensor_details_${sensor.id}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this sensor. ${e.message || 'An unknown error occurred;'}`
            });
        }
    }
    
    const getContent = () => {
        if(loading === 'init') {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: 100,
                    width: '100%'
                }}>
                    <LottieView
                    loop={true}
                    autoPlay={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 40,
                        width: 40
                    }}/>
                </div>
            )
        }
        if(!geojson || !geojson.data) {
            return (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: 'No data is available for the selected date.',
                    title: 'No Data Found'
                })
            )
        }
        return (
            <div className={'row m-0'}>
                <div className={'col-12 col-lg-8 col-xl-9 p-3'}>
                    <Map
                    features={getLocationFeatures()}
                    isScrollEnabled={true}
                    isZoomEnabled={true}
                    isRotationEnabled={true}
                    region={geojson.region}
                    style={{
                        height: 600,
                        width: '100%'
                    }}/>
                </div>
                <div 
                className={'col-12 col-lg-4 col-xl-3 p-0'}
                style={{
                    borderLeft: `1px solid ${Appearance.colors.divider()}`
                }}>
                    <div style={{
                        borderBottom: `1px solid ${Appearance.colors.divider()}`
                    }}>
                        <div style={{
                            alignItems: 'center',
                            borderBottom: `1px solid ${Appearance.colors.divider()}`,
                            display: 'flex',
                            flexDirection: 'row',
                            padding: 12
                        }}>
                            <TextField
                            onChange={setSearchText}
                            placeholder={'Search by comm link or sensor serial number...'} />
                        </div>
                        <div style={{
                            flexGrow: 1,
                            height: '100%',
                            width: '100%'
                        }}>
                            {getResults().filter((_, index) => {
                                return paging ? (index >= offset && index < offset + limit) : true;
                            }).map((result, index, results) => {
                                return (
                                    Views.entry({
                                        bottomBorder: index !== results.length - 1,
                                        hideIcon: true,
                                        key: index,
                                        loading: loading === result.properties.sn,
                                        onClick: onSensorClick.bind(this, result.properties, 'sensor_serial_number'),
                                        subTitle: `Comm Link Serial Number ${result.properties.csn}`,
                                        title: `${result.properties.t}: ${result.properties.sn}`
                                    })
                                )
                            })}
                        </div>
                        {paging && (
                            <PageControl
                            data={paging}
                            limit={limit}
                            offset={offset}
                            onClick={setOffset} />
                        )}
                    </div>
                </div>
            </div>
        )
    }

    const getLocationFeatures = () => {

        // prevent moving forward if no geojson was provided or if loading is in progress
        if(!geojson || loading === true) {
            return null;
        }
        return {
            data: geojson.data,
            id: 'sensors',
            icons: [{
                key: 'sensor-icon',
                path: 'images/sensor-map-icon.png',
                raster: true
            }],
            layers: [{
                id: 'sensors_heatmap',
                maxzoom: 8,
                source: 'sensors',
                type: 'heatmap',
                paint: {
                    'heatmap-weight': [
                        'interpolate',
                        ['linear'],
                        ['get', 'dur'],
                        0,
                        0,
                        6,
                        1
                    ],
                    'heatmap-intensity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        0,
                        3,
                        12,
                        5
                    ],
                    'heatmap-color': [
                        'interpolate',
                        ['linear'],
                        ['heatmap-density'],
                        0,
                        'rgba(177,181,175,0)',
                        0.2,
                        'rgb(177,181,175)',
                        0.4,
                        'rgb(219,225,216)',
                        0.6,
                        'rgb(195,224,181)',
                        0.8,
                        'rgb(137,197,107)',
                        1,
                        'rgb(69,170,96)'
                    ],
                    'heatmap-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        0,
                        2,
                        9,
                        20
                    ],
                    'heatmap-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        7,
                        1,
                        8,
                        0
                    ]
                }
            },{
                id: 'sensor_icons',
                minzoom: 7,
                onClick: onSensorClick,
                onHover: properties => {
                    return {
                        title: `${properties.t}: ${properties.sn}`,
                        subTitle: `Comm Link ${properties.csn}`
                    }
                },
                source: 'sensors',
                type: 'symbol',
                layout: {
                    'icon-size': 0.35,
                    'icon-anchor': 'center',
                    'icon-image': 'sensor-icon',
                    'icon-allow-overlap': true
                }
            }]
        }
    }

    const getResults = () => {
        if(!geojson || !geojson.data) {
            return [];
        }
        return geojson.data.features.filter(entry => {
            if(searchText) {
                return entry.properties.sn.toString().includes(searchText) || entry.properties.csn.toString().includes(searchText);
            }
            return true;
        });
    }

    const fetchGeoJson = async () => {
        try {
            setLoading(val => val === 'init' ? val : true);
            let { geojson } = await Request.get(utils, '/omnishield/', {
                date: date.format('YYYY-MM-DD'),
                type: 'version2_sensors_report'
            });

            setLoading(false);
            setGeoJson(geojson);

            // prepare manual paging props for list
            setPaging(geojson.data && {
                total: geojson.data.features.length,
                current_page: parseInt(offset / limit) + 1,
                number_of_pages: geojson.data.features.length > limit ? Math.ceil(geojson.data.features.length / limit) : 1
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the version 2.0 sensors report. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        let targets = getResults();
        setPaging({
            total: targets.length,
            current_page: parseInt(offset / limit) + 1,
            number_of_pages: targets.length > limit ? Math.ceil(targets.length / limit) : 1
        });
    }, [offset, searchText]);

    useEffect(() => {
        setOffset(0);
    }, [searchText]);

    useEffect(() => {
        fetchGeoJson();
    }, [date])

    return (
        <Panel
        panelID={panelID}
        name={'OmniShield Version 2.0 Sensors'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading === true,
            removePadding: true,
            subTitle: `Data prepared for ${Utils.formatDate(date)}`
        }}>
            <div style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`,
                padding: 12,
                width: '100%'
            }}>
                <DatePickerField
                onDateChange={setDate}
                selected={date}
                utils={utils}/>
            </div>
            {getContent()}
        </Panel>
    )
}

export const CommLinkSensorsList = ({ category, sensors, title }, { abstract, index, options, utils }) => {
    
    const layerID = `comm_link_sensors_list_${abstract.getID()}`;
    const [targets, setTargets] = useState(sensors || []);

    const onSensorClick = async sensor => {
        utils.layer.open({
            id: `comm_link_sensor_details_${sensor.id}`,
            abstract: Abstract.create({
                type: 'comm_link_sensor',
                object: sensor
            }),
            Component: CommLinkSensorDetails
        })
    }

    const getDescription = sensor => {
        if(sensor.active === false) {
            return sensor.date ? `Installed ${Utils.formatDuration(sensor.date)}` : 'Installation date not available';
        }
        return sensor.get('date') ? `Updated ${Utils.formatDuration(sensor.get('date'))}` : 'Waiting for update...'
    }

    useEffect(() => {
        utils.content.subscribe(layerID, 'comm_link_sensor', {
            onUpdate: next => {
                setTargets(targets => {

                    // update list of targets with new abstract object if applicable
                    let results = targets.map(target => {
                        return target.id === next.object.id ? next.object : target;
                    });

                    // determine if the sensors list needs to be updated
                    if(category === 'deleted_sensors') {
                        results = results.filter(result => result.active === false);
                    }
                    return results;
                });
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        id={layerID}
        index={index}
        title={`Comm Link ${abstract.object.serial_number} ${title}`}
        utils={utils}
        options={{
            ...options,
            sizing: 'small'
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {targets.map((sensor, index, sensors) => {
                    return (
                        Views.entry({
                            badge: sensor.active === false && {
                                color: Appearance.colors.grey(),
                                text: `ID ${sensor.id}`
                            },
                            bottomBorder: index !== sensors.length - 1,
                            icon: { path: getSensorIcon(sensor) },
                            key: index,
                            onClick: onSensorClick.bind(this, sensor),
                            subTitle: getDescription(sensor),
                            title: sensor.location || 'Unnamed Location'
                        })
                    )
                })}
            </div>
        </Layer>
    )
}

export const CommLinkSensorActivationDetails = ({ activation }, { abstract, index, options, utils }) => {

    const layerID = `comm_link_sensor_activation_${activation.mongo_id}`;

    const [filters, setFilters] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState('init');
    const [packet, setPacket] = useState(null);
    const [selectedKey, setSelectedKey] = useState('temperature');

    const onSensorClick = async () => {
        utils.layer.open({
            id: `comm_link_sensor_details_${abstract.getID()}`,
            abstract: abstract,
            Component: CommLinkSensorDetails
        });
    }

    const getButtons = () => {
        return null;
    }

    const getContent = () => {
        if(loading === 'init') {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: 100,
                    width: '100%'
                }}>
                    <LottieView
                    loop={true}
                    autoPlay={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 40,
                        width: 40
                    }}/>
                </div>
            )
        }
        return (
            <>
            {getDatasets()}
            {getFieldComponents()}
            {getSensor()}
            {getEntries()}
            </>
        )
    }

    const getDatasets = () => {

        // declare currently selected dataset and currently selected dataset filter
        let dataset = packet && packet.datasets.find(set => set.key === selectedKey);
        let filter = selectedKey && filters && filters.find(filter => filter.id === selectedKey);

        return dataset && (
            <LayerItem 
            collapsed={false}
            title={'Activity'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    padding: 12,
                    width: '100%'
                }}>
                    <div style={{
                        padding: 24
                    }}>
                        <Line
                        height={250}
                        width={400}
                        data={{
                            labels: dataset.labels,
                            datasets: [{ 
                                borderColor: Appearance.colors.grey(),
                                borderDash: [5, 5],
                                borderWidth: 1.5,
                                data: dataset.data,
                                fill: false,
                                label: dataset.name,
                                metadata: dataset.metadata,
                                pointBackgroundColor: getPointColor,
                                pointBorderColor: getPointColor,
                                pointRadius: 4,
                                pointStyle: 'circle'
                            }]
                        }}
                        options={{
                            interaction: {
                                mode: 'dataset'
                            },
                            legend: { display: false },
                            maintainAspectRatio: true,
                            responsive: true,
                            title: { display: false },
                            tooltips: {
                                callbacks: {
                                    title: (items, data) => {
                                        if(items[0].index === data.datasets[0].metadata.activation_index) {
                                            return 'Activation';
                                        }
                                        return data.datasets[0].label;
                                    },
                                    label: (evt, data) => {
                                        if(evt.index === data.datasets[0].metadata.activation_index) {
                                            return evt.xLabel;
                                        }
                                        switch(selectedKey) {
                                            case 'battery_level':
                                            case 'chamber_status':
                                            case 'moisture_level':
                                            case 'smoke_status':
                                            return `${parseInt(evt.yLabel)}%`;
                                            
                                            case 'co_level':
                                            return `${parseInt(evt.yLabel)} ppm`;
                                            
                                            case 'temperature':
                                            return `${parseInt(evt.yLabel)}°F`;
                                            
                                            default:
                                            return evt.yLabel || '0';
                                        }
                                    }
                                }
                            },
                            scales: {
                                xAxes: [{
                                    gridLines: {
                                        color: Appearance.colors.transparent,
                                        display: false
                                    },
                                    ticks: {
                                        autoSkip: true,
                                        maxTicksLimit: 5
                                    }
                                }],
                                yAxes: [{
                                    gridLines: {
                                        color: Appearance.colors.transparent,
                                        display: false
                                    },
                                    ticks: {
                                        beginAtZero: true,
                                        maxTicksLimit: 5,
                                        callback: value => {
                                            switch(selectedKey) {
                                                case 'battery_level':
                                                case 'chamber_status':
                                                case 'moisture_level':
                                                case 'smoke_status':
                                                return `${parseInt(value)}%`;
                                                
                                                case 'co_level':
                                                return `${parseInt(value)} ppm`;
                                                
                                                case 'temperature':
                                                return `${parseInt(value)}°F`;
                                                
                                                default:
                                                return value;
                                            }
                                        }
                                    }
                                }]
                            }
                        }} />
                    </div>
                    {filters.length > 0 && (
                        <ListField
                        items={filters}
                        onChange={item => setSelectedKey(item && item.id)}
                        placeholderItem={false}
                        value={filter && filter.title} />
                    )}
                </div>
            </LayerItem>
        )
    }

    const getEntries = () => {
        return packet && packet.entries.length > 0 && (
            <LayerItem 
            title={'Sensor History'}
            style={{
                marginTop: 20
            }}>
                {packet.entries.map((entry, index) => {
                    return (
                        <SensorEventComponent 
                        collapsible={false}
                        evt={entry}
                        key={index}
                        sensor={abstract.object} />
                    )
                })}
            </LayerItem>
        )
    }

    const getFieldComponents = () => {

        // prepare field items
        let items = [{
            key: 'details',
            lastItem: false,
            title: 'Activation',
            items: [{
                id: 'date',
                title: 'Date',
                value: activation.date && Utils.formatDate(activation.date)
            },{
                id: 'id',
                title: 'ID',
                value: activation.mongo_id
            }]
        }];

        return (
            <FieldMapper
            fields={items}
            utils={utils} />
        )
    }

    const getPointColor = evt => {
        if(evt.dataIndex !== evt.dataset.metadata.activation_index) {
            return Appearance.colors.grey();
        }
        return evt.dataset.metadata.sensor_type === CommLink.Sensor.types.water ? Appearance.colors.blue : Appearance.colors.red;
    }

    const getSensor = () => {
        return (
            <LayerItem title={'Sensor'}>
                <div style={Appearance.styles.unstyledPanel()}>
                    {Views.entry({
                        bottomBorder: false,
                        icon: { path: getSensorIcon(abstract.object) },
                        onClick: onSensorClick,
                        subTitle: abstract.object.type.text,
                        title: abstract.object.location
                    })}
                </div>
            </LayerItem>
        )
    }

    const fetchActivationPacket = async () => {
        try {

            // send request to fetch activation packet details
            let { activation_packet } = await Request.get(utils, '/omnishield/', {
                id: activation.mongo_id,
                type: 'sensor_activation_packet_details'
            });

            // prevent moving forward if no activation packet details are available
            if(!activation_packet) {
                utils.alert.show({
                    title: 'Just a Second',
                    message: 'We were unable to find any additional information about this activation. Would you like to view the details for this sensor instead?',
                    buttons: [{
                        key: 'confirm',
                        title: 'Yes',
                        style: 'default'
                    },{
                        key: 'cancel',
                        title: 'No',
                        style: 'cancel'
                    }],
                    onClick: key => {
                        setLayerState('close');
                        if(key === 'confirm') {
                            setTimeout(onSensorClick, 500);
                            return;
                        }
                    }
                });
                return;
            }

            // update state with formatted packet details
            setLoading(false);
            setPacket(activation_packet && {
                ...activation_packet,
                datasets: activation_packet.datasets.map(dataset => ({
                    ...dataset,
                    labels: dataset.labels.map(date => moment.utc(date).local().format('h:m:sa'))
                })),
                entries: activation_packet.entries.map(entry => ({
                    ...entry,
                    date: entry.date && moment.utc(entry.date).local()
                }))
            })
            
            // set filters and default filter key
            let defaultFilter = activation_packet && activation_packet.filters.find(filter => filter.default);
            setFilters(activation_packet && activation_packet.filters);
            setSelectedKey(defaultFilter ? defaultFilter.id : 'temperature');

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the packet of events. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        setTimeout(fetchActivationPacket, 250);
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Activation Details for ${abstract.object.location}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
           {getContent()} 
        </Layer>
    )
}

export const CommLinkDetails = ({ abstract, index, options, utils }) => {

    const layerID = `comm_link_details_${abstract.getID()}`;
    const activationsLimit = 5;
    const devicesLimit = 5;

    const [activations, setActivations] = useState([]);
    const [activationsOffset, setActivationsOffset] = useState(0);
    const [activationsPaging, setActivationsPaging] = useState(null);
    const [commLink, setCommLink] = useState(null);
    const [contacts, setContacts] = useState([]);
    const [devices, setDevices] = useState([]);
    const [devicesOffset, setDevicesOffset] = useState(0);
    const [devicesPaging, setDevicesPaging] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState('init');
    const [online, setOnline] = useState(abstract.object.online);
    const [onlineStatusMessage, setOnlineStatusMessage] = useState(null);
    const [sensors, setSensors] = useState([]);

    const onActivationClick = activation => {
        utils.layer.open({
            id: `comm_link_sensor_activation_${activation.mongo_id}`,
            abstract: Abstract.create({
                object: activation.sensor,
                type: 'comm_link_sensor'
            }),
            Component: CommLinkSensorActivationDetails.bind(this, { activation })
        });
    }

    const onContactClick = contact => {
        utils.layer.open({
            id: `comm_link_contact_details_${contact.id}`,
            abstract: Abstract.create({
                type: 'comm_link_contact',
                object: contact
            }),
            Component: CommLinkContactDetails.bind(this, {
                commLink: abstract.object
            })
        })
    }

    const onDeletedSensorsClick = async () => {
        try {

            setLoading('options');
            let { sensors } = await Request.get(utils, '/omnishield/', {
                id: abstract.getID(),
                security_key: abstract.object.security_key,
                serial_number: abstract.object.serial_number,
                show_only_inactive: true,
                type: 'sensors_from_network'
            });

            setLoading(false);
            utils.layer.open({
                abstract: abstract,
                Component: CommLinkSensorsList.bind(this, {
                    category: 'deleted_sensors',
                    title: 'Deleted Sensors',
                    sensors: sensors.map(sensor => CommLink.Sensor.create(sensor))
                }),
                id: `comm_link_sensors_list_${abstract.getID()}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the list of deleted sensors for this network. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onDeviceClick = device => {
        utils.layer.open({
            abstract: Abstract.create({
                object: device,
                type: 'comm_link_device'
            }),
            Component: CommLinkDeviceDetails,
            id: `comm_link_device_details_${device.id}`
        });
    }

    const onEditClick = () => {
        utils.layer.open({
            abstract: abstract,
            Component: AddEditCommLink.bind(this, {
                isNewTarget: false
            }),
            id: `edit_comm_link_${abstract.getID()}`
        })
    }

    const onEmergencyAuditClick = () => {
        utils.alert.show({
            title: 'Emergency Audit',
            message: `An emergency audit can be used to review all the data for a network in the event that a real world emergency occurs. This report shows all activations, customer devices, self tests, and text messages for the network. A list of verbose events is included and can contain up to 15 days worth of events.`,
            buttons: [{
                key: 'confirm',
                title: 'Continue',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onEmergencyAuditDownload();
                    return;
                }
            }
        });
    }

    const onEmergencyAuditDownload = async () => {
        try {
            setLoading('options');
            let { url } = await Request.get(utils, '/omnishield/', {
                comm_link_id: abstract.object.id,
                type: 'emergency_audit'
            });

            setLoading(false);
            utils.alert.show({
                title: 'Ready to Download',
                message: `Your emergency audit for the ${abstract.object.name} network is ready to download. Click the button below to start the download process.`,
                buttons: [{
                    key: 'download',
                    title: 'Download',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Cancel',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(key === 'download') {
                        window.open(url);
                        return;
                    }
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing your emergency audit. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onNewContactClick = () => {

        // prepare new contact target
        let contact = CommLink.Contact.new();
        contact.comm_link_id = abstract.object.id;

        // open editing layer for new contact
        utils.layer.open({
            abstract: Abstract.create({
                object: contact,
                type: 'comm_link_contact'
            }),
            Component: AddEditCommLinkContact.bind(this, {
                isNewTarget: true
            }),
            id: 'new_comm_link_contact'
        });
    }

    const onNewVerboseEvent = evt => {
        try {

            // decode data for json payload
            let { comm_link_guid, serial_number, verbose } = JSON.parse(evt);

            // determine if the serial number matches the current target, very likely
            if(comm_link_guid === abstract.object.guid) {

                // update comm link online object
                abstract.object.online = {
                    ...abstract.object.online,
                    date: moment().utc(),
                    online: true
                }

                // update comm link state with online status changes
                setCommLink(abstract.object);
                setOnline({ ...abstract.object.online });

                // update sensors state if a match is found
                setSensors(sensors => {
                    return update(sensors, {
                        $apply: sensors => sensors.map(sensor => {
                            if(sensor.serial_number === serial_number) {
                                sensor.verbose = { ...sensor.verbose, ...verbose };
                            }
                            return sensor;
                        })
                    });
                });
            }

        } catch(e) {
            console.error(e.message);
        }
    }

    const onOnlineStatusClick = () => {
        let { message } = CommLink.formatStatusProps(online);
        let { status } = online || {};
        utils.alert.show({
            title: `Comm Link ${status ? 'Online' : 'Offline'}`,
            message: message,
            icon: {
                path: status ? 'images/online-icon.png' : 'images/offline-icon.png',
                style: {
                    backgroundColor: null,
                    border: 'none',
                    borderRadius: 32.5
                }
            }
        });
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'new_contact',
                title: 'Add New Contact',
                style: 'default'
            },{
                key: 'active_status',
                title: commLink.active ? 'Deactivate' : 'Activate',
                style: commLink.active ? 'destructive' : 'default',
                visible: utils.user.get().level <= User.levels.get().exigent_admin
            },{
                key: 'emergency_audit',
                title: 'Emergency Audit',
                style: 'default',
                visible: utils.user.get().level <= User.levels.get().exigent_admin
            },{
                key: 'push_notifications',
                title: 'Push Notification Activity',
                style: 'default'
            },{
                key: 'update_status',
                title: 'Update Status',
                style: 'default',
                visible: utils.user.get().level <= User.levels.get().dealer
            },{
                key: 'deleted_sensors',
                title: 'View Deleted Sensors',
                style: 'default'
            },{
                key: 'aft_protection',
                title: 'View Protection',
                style: 'default'
            }],
            target: evt.target
        }, key => {
            switch(key) {
                case 'active_status':
                onSetActiveStatus();
                break;

                case 'aft_protection':
                onViewProtection();
                break;

                case 'deleted_sensors':
                onDeletedSensorsClick();
                break;

                case 'emergency_audit':
                onEmergencyAuditClick();
                break;

                case 'new_contact':
                onNewContactClick();
                break;

                case 'push_notifications':
                onViewPushNotificationsActivity();
                break;

                case 'update_status':
                onUpdateStatus();
                break;
            }
        });
    }

    const onSensorClick = async sensor => {
        utils.layer.open({
            id: `comm_link_sensor_details_${sensor.id}`,
            abstract: Abstract.create({
                type: 'comm_link_sensor',
                object: CommLink.Sensor.create(sensor)
            }),
            Component: CommLinkSensorDetails
        });
    }

    const onSensorsListClick = (title, sensors) => {
        utils.layer.open({
            id: `comm_link_sensors_list_${abstract.getID()}`,
            abstract: abstract,
            Component: CommLinkSensorsList.bind(this, {
                title: title,
                sensors: sensors.map(sensor => CommLink.Sensor.create(sensor))
            })
        });
    }

    const onSensorStatusChange = evt => {
        try {
            let { id, status } = JSON.parse(evt);
            if(abstract.object.sensor?.id === id) {
                abstract.object.sensor.status = status;
                utils.content.update(abstract);
                setCommLink(abstract.object);
            }
            setSensors(sensors => {
                return sensors.map(sensor => {
                    if(sensor.id === id) {
                        sensor.status = status;
                    }
                    return sensor;
                });
            });
        } catch(e) {
            console.error(e.message);
        }
    }

    const onSetActiveStatus = () => {
        utils.alert.show({
            title: commLink.active ? 'Deactivate Comm Link' : 'Activate Comm Link',
            message: `Are you sure that you want to ${commLink.active ? 'deactivate' : 'activate'} this comm link? ${commLink.active ? 'Deactivating a comm link will prevent it from communicating with the server.' : 'Activating a comm link will allow it to start communicating with the server.'}`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: commLink.active ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: commLink.active ? 'default' : 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetActiveStatusConfirm();
                    return;
                }
            }
        });
    }

    const onSetActiveStatusConfirm = async () => {
        try {

            // start loading and sleep briefly before submitting the request
            setLoading(true);
            await Utils.sleep(0.25);

            // prepare status and send request to server
            let status = !commLink.active;
            await Request.post(utils, '/omnishield/', {
                id: commLink.id,
                security_key: commLink.security_key,
                serial_number: commLink.serial_number,
                status: status,
                type: 'set_comm_link_active_status'
            });

            // update active status for abstract and notify subscribers
            abstract.object.active = status;
            utils.content.update(abstract);

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `This comm link has been ${status ? 'activated' : 'deactivated'}`,
                onClick: () => {
                    if(status === false) {
                        setLayerState('close');
                    }
                    setTimeout(utils.content.fetch.bind(this, 'comm_link'), status ? 0 : 500);
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${commLink.active ? 'deactivating' : 'activating'} this comm link. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateStatus = () => {

        // prepare list of status options
        let codes = CommLink.Sensor.status.get();
        let items = [
            codes.in_service,
            codes.out_of_service,
            codes.replacement_requested,
            codes.replacement_in_progress,
            codes.replacement_completed,
            codes.serial_number_collision
        ].map(code => ({
            id: code,
            title: CommLink.Sensor.status.toText(code)
        })).sort((a,b) => {
            return a.title.localeCompare(b.title);
        });

        // show alert with option to select a status
        let status = null;
        utils.alert.show({
            title: 'Update Status',
            message: 'Please choose a status from the list below to continue. This status will be visible by all who have access to this Comm Link.',
            content: (
                <div style={{
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    width: '100%'
                }}>
                    <ListField 
                    items={items}
                    onChange={item => status = item && item.id} 
                    placeholder={'Choose a status from the list below'} />
                </div>
            ),
            buttons: [{
                key: 'confirm',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm' && status) {
                    onUpdateStatusConfirm(status);
                    return;
                }
            }
        });
    }

    const onUpdateStatusConfirm = async code => {
        try {

            // start loading and sleep briefly before submitting request
            setLoading('options');
            await Utils.sleep(0.25);

            // submit request to server
            let { status } = await Request.post(utils, '/omnishield/', {
                id: abstract.object.sensor.id,
                status: code,
                type: 'set_sensor_status'
            });

            // update status for abstract target
            abstract.object.status = status;

            // notify subscribers that a status change has occurred
            utils.content.update(abstract);

            // end loading and show confirmation
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The status for this Comm Link has been updated to "${status.text}"`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the status for this sensor. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onViewProtection = async () => {
        try {

            // send request to server
            setLoading('options');
            let { card } = await Request.get(utils, '/omnishield/', {
                serial_number: abstract.object.serial_number,
                type: 'comm_link_protection_details'
            });

            // prevent moving forward if no protection was found
            if(!card) {
                throw new Error('We were unable to locate any protections where this Comm Link was listed.');
            }

            // end loading and show card details layer
            setLoading(false);
            utils.layer.open({
                id: `card_details_${card.id}`,
                abstract: Abstract.create({
                    type: 'card',
                    object: Card.create(card)
                }),
                Component: CardDetails
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the protection details for this comm link. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onViewPushNotificationsActivity = () => {
        utils.layer.open({
            abstract: abstract,
            Component: PushNotificationEvents,
            id: 'push_notification_events'
        });
    }

    const getButtons = () => {
        return loading !== 'init' && [{
            color: 'secondary',
            key: 'options',
            loading: loading === 'options',
            onClick: onOptionsClick,
            text: 'Options'
        },{
            color: 'primary',
            key: 'edit',
            onClick: onEditClick,
            text: 'Edit'
        }];
    }

    const getActivations = () => {
        return activations.length > 0 && (
            <LayerItem title={'Activations'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {activations.filter((_, index) => {
                        return activationsPaging ? (index >= activationsOffset && index < activationsOffset + activationsLimit) : true;
                    }).map((activation, index, activations) => {
                        return (
                            Views.entry({
                                bottomBorder: index !== activations.length - 1,
                                icon: { path: getSensorIcon(activation.sensor, { force_emergency_alert: true }) },
                                key: index,
                                onClick: onActivationClick.bind(this, activation),
                                subTitle: Utils.formatDate(activation.date),
                                title: activation.sensor && activation.sensor.location || 'Unnamed Location'
                            })
                        )
                    })}
                    {activationsPaging && (
                        <PageControl
                        data={activationsPaging}
                        limit={activationsLimit}
                        offset={activationsOffset}
                        onClick={setActivationsOffset} />
                    )}
                </div>
            </LayerItem>
        )
    }

    const getContacts = () => {
        return contacts.length > 0 && (
            <LayerItem title={'Contacts'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {contacts.map((contact, index) => {
                        return (
                            Views.entry({
                                badge: contact.owner && {
                                    color: Appearance.colors.secondary(),
                                    text: 'Owner'
                                },
                                bottomBorder: index !== contacts.length - 1,
                                icon: {
                                    path: contact.avatar || 'images/user-placeholder.png'
                                },
                                key: index,
                                onClick: onContactClick.bind(this, contact),
                                subTitle: contact.phone_number,
                                title: contact.full_name
                            })
                        )
                    })}
                </div>
            </LayerItem>
        )
    }

    const getContent = () => {
        if(loading === 'init' || !commLink) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: 100,
                    width: '100%'
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 40,
                        width: 40
                    }}/>
                </div>
            )
        }
        return commLink && (
            <>
            {getOnlineStatus()}
            {getFlags()}
            <FieldMapper
            utils={utils}
            fields={getFields()} />

            {getActivations()}
            {getContacts()}
            {getDevices()}
            {getSensors()}
            </>
        )
    }

    const getDevices = () => {
        return devices.length > 0 && (
            <LayerItem title={'Devices'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {devices.filter((_, index) => {
                        return devicesPaging ? (index >= devicesOffset && index < devicesOffset + devicesLimit) : true;
                    }).map((device, index, devices) => {
                        return (
                            Views.entry({
                                bottomBorder: index !== devices.length - 1,
                                icon: {
                                    path: getDeviceIcon(device)
                                },
                                key: index,
                                onClick: onDeviceClick.bind(this, device),
                                subTitle: Utils.formatDate(device.date),
                                title: getDeviceName(device)
                            })
                        )
                    })}
                    {devicesPaging && (
                        <PageControl
                        data={devicesPaging}
                        limit={devicesLimit}
                        offset={devicesOffset}
                        onClick={setDevicesOffset} />
                    )}
                </div>
            </LayerItem>
        )
    }

    const getDeviceName = device => {
        if(device.name) {
            return device.name;
        }
        if(device.os === 1) {
            return 'iOS Device';
        }
        if(device.os === 2) {
            return 'Android Device';
        }
        return 'OmniShield Classic Device';
    }

    const getDeviceIcon = device => {
        if(device.os === 1) {
            return 'images/apple-icon-grey.png';
        }
        if(device.os === 2) {
            return 'images/google-icon-grey.png';
        }
        if(device.os === 3) {
            return 'images/alexa-device-icon.jpg';
        }
        return 'images/omnishield-classic-icon.jpg';
    }

    const getFields = () => {
        if(!commLink) {
            return [];
        }
        return [{
            key: 'about',
            title: 'About this Comm Link',
            items: [{
                key: 'id',
                title: 'ID',
                value: commLink.id
            },{
                key: 'firmware_version',
                title: 'Firmware Version',
                value: commLink.firmware_version
            },{
                key: 'guid',
                title: 'GUID',
                value: commLink.guid
            },{
                key: 'name',
                title: 'System Name',
                value: commLink.name
            },{
                key: 'serial_number',
                title: 'Serial Number',
                value: commLink.serial_number
            },{
                key: 'security_key',
                title: 'Security Key',
                value: commLink.security_key
            },{
                color: commLink.sensor?.status.color,
                key: 'status',
                title: 'Status',
                value: commLink.sensor?.status.text || 'Unknown'
            }]
        },{
            key: 'connection',
            title: 'Connection',
            items: [{
                key: 'connection_status',
                title: 'Connection Type',
                value: commLink.connection_status ? commLink.connection_status.text : 'Unknown'
            },{
                key: 'online.isp',
                title: 'Internet Service Provider',
                value: commLink.online && commLink.online.isp || 'Unknown'
            },{
                key: 'online.ip_address',
                title: 'IP Address',
                value: commLink.online && commLink.online.ip_address || 'Unknown'
            },{
                key: 'online.origin',
                title: 'Server',
                value: commLink.online && commLink.online.origin ? (commLink.online.origin === 'api.aftplatform.com' ? 'Azure Forwarding' : commLink.online.origin) : 'Unknown'
            },{
                key: 'wifi_network_name',
                visible: commLink.connection_status && commLink.connection_status.code === 2,
                title: 'Wifi Network',
                value: commLink.wifi_network_id || commLink.wifi_network_name || 'Unknown'
            }]
        },{
            key: 'location',
            title: 'Location',
            visible: commLink.address && commLink.location ? true : false,
            items: [{
                key: 'location',
                title: 'Location',
                component: 'map',
                value: commLink.location && {
                    latitude: commLink.location.lat,
                    longitude: commLink.location.long
                }
            },{
                key: 'address',
                title: 'Address',
                value: Utils.formatAddress(commLink.address)
            },{
                key: 'maps',
                onClick: () => {
                    let address = Utils.formatAddress(commLink.address);
                    window.open(`https://www.google.com/maps/place/${encodeURIComponent(address)}`)
                },
                title: 'Directions',
                value: 'Click to View'
            }]
        },{
            key: 'dealer_and_seller',
            title: 'Dealer and Seller',
            items: [{
                key: 'dealership',
                title: 'Authorized Dealer',
                value: commLink.dealership ? commLink.dealership.name : null
            },{
                key: 'dealership',
                title: 'Dealership Phone Number',
                value: commLink.dealership_phone_number
            },{
                key: 'sold_by',
                title: 'Sold By',
                value: commLink.sold_by_user ? commLink.sold_by_user.full_name : null
            },{
                key: 'install_date',
                title: 'Installation Date',
                value: commLink.install_date ? moment(commLink.install_date).format('MMMM Do, YYYY [at] h:mma') : 'Not available'
            }]
        },{
            key: 'stats',
            lastItem: false,
            title: 'Sensor Statistics',
            items: [{
                key: 'percent_reporting',
                title: 'Percent Reporting',
                value: commLink.stats && commLink.stats.percent_reporting !== null ? `${parseInt(commLink.stats.percent_reporting * 100)}%` : null
            },{
                key: 'last_activated_sensor',
                title: 'Last Activation',
                value: 'None',
                ...commLink.stats && commLink.stats.last_activated_sensor && {
                    value: commLink.stats.last_activated_sensor.location,
                    onClick: onSensorClick.bind(this, CommLink.Sensor.create(commLink.stats.last_activated_sensor))
                }
            },{
                key: 'last_self_test_sensor',
                title: 'Last Self Test',
                value: 'None',
                ...commLink.stats && commLink.stats.last_self_test_sensor && {
                    value: commLink.stats.last_self_test_sensor.location,
                    onClick: onSensorClick.bind(this, CommLink.Sensor.create(commLink.stats.last_self_test_sensor))
                }
            },{
                key: 'most_active_sensor',
                title: 'Most Active',
                value: 'None',
                ...commLink.stats && commLink.stats.most_active_sensor && {
                    value: commLink.stats.most_active_sensor.location,
                    onClick: onSensorClick.bind(this, CommLink.Sensor.create(commLink.stats.most_active_sensor))
                }
            },{
                key: 'least_active_sensor',
                title: 'Least Active',
                value: 'None',
                ...commLink.stats && commLink.stats.least_active_sensor && {
                    value: commLink.stats.least_active_sensor.location,
                    onClick: onSensorClick.bind(this, CommLink.Sensor.create(commLink.stats.least_active_sensor))
                }
            },{
                key: 'problem_sensors',
                title: 'Not Active in 30 Days',
                value: 'None',
                ...commLink.stats && commLink.stats.problem_sensors && {
                    value: commLink.stats.problem_sensors.length === 1 ? commLink.stats.problem_sensors[0].location : `${commLink.stats.problem_sensors.length} ${commLink.stats.problem_sensors.length === 1 ? 'sensor' : 'sensors'}`,
                    onClick: commLink.stats.problem_sensors.length === 1 ? onSensorClick.bind(this, commLink.stats.problem_sensors[0]) : onSensorsListClick.bind(this, '30 Day', commLink.stats.problem_sensors)
                }
            }]
        }];
    }

    const getFlags = () => {

        // no additional logic is required for non-administrators
        if(utils.user.get().level > User.levels.get().exigent_admin) {
            return null;
        }

        // prepare empty list of flag entries
        let flags = [];

        // add mobile app review badge flag if applicable
        if(commLink.flags?.includes('mobile.new_install_google_review_link')) {
            flags.push({
                icon: { 
                    path: 'images/google-icon-grey.png',
                    imageStyle: {
                        boxShadow: 'none'
                    }  
                },
                key: 'mobile.new_install_google_review_link',
                subTitle: `This comm link is showing a Google review link in the OmniShield mobile app until ${moment(abstract.object.install_date).add(7, 'days').format('MMMM Do, YYYY')}.`,
                style: {
                    subTitle: {
                        whiteSpace: 'normal'
                    }
                },
                title: 'Mobile App Google Review Link'
            });
        }

        // add firmware self test issue flag if applicable
        if(commLink.flags?.includes('firmware.comm_link_self_test_serial_number_overflow')) {
            flags.push({
                icon: { 
                    path: 'images/warning-icon-yellow.png',
                    imageStyle: {
                        backgroundColor: Appearance.colors.transparent,
                        borderRadius: 0,
                        boxShadow: 'none',
                        objectFit: 'contain'
                    } 
                },
                key: 'firmware.comm_link_self_test_serial_number_overflow',
                subTitle: 'This comm link is running a firmware version that is unable to send comm link self tests properly.',
                style: {
                    subTitle: {
                        whiteSpace: 'normal'
                    }
                },
                title: 'Comm Link Self Test Firmware Issue'
            });
        }

        // add sms not supported flag if applicable
        if(commLink.flags?.includes('sms_not_supported')) {
            flags.push({
                icon: { 
                    path: 'images/warning-icon-yellow.png',
                    imageStyle: {
                        backgroundColor: Appearance.colors.transparent,
                        borderRadius: 0,
                        boxShadow: 'none',
                        objectFit: 'contain'
                    } 
                },
                key: 'sms_not_supported',
                subTitle: 'This comm link is unable to send text messages due to the region where it is installed.',
                style: {
                    subTitle: {
                        whiteSpace: 'normal'
                    }
                },
                title: 'Text Messages Not Supported'
            });
        }

        // return list of flag components if applicable
        return flags.length > 0 && (
            <LayerItem title={'Important Messages'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    width: '100%'
                }}>
                    {flags.map((entry, index) => {
                        return (
                            Views.entry({
                                bottomBorder: index !== flags.length - 1,
                                key: index,
                                ...entry
                            })
                        )
                    })}
                </div>
            </LayerItem>
        )
    }

    const getOnlineStatus = () => {
        return (
            <div
            className={'text-button'}
            onClick={onOnlineStatusClick}
            style={{
                ...Appearance.styles.unstyledPanel(),
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 24,
                padding: '12px 20px 12px 20px',
                width: '100%'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexShrink: 1,
                    justifyContent: 'center'
                }}>
                    <span style={{
                        ...Appearance.textStyles.layerItemTitle(),
                        marginBottom: 4
                    }}>{`Comm Link ${online && online.status ? 'Online' : 'Offline'}`}</span>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        whiteSpace: 'normal'
                    }}>{onlineStatusMessage}</span>
                </div>
                <img
                src={online && online.status ? 'images/online-icon.png' : 'images/offline-icon.png'}
                style={{
                    height: 65,
                    marginLeft: 12,
                    objectFit: 'contain',
                    width: 65,
                }} />
            </div>
        )
    }

    const getSensors = () => {
        
        // declare list of sensor types
        let types = [
            CommLink.Sensor.types.get().smoke_co,
            CommLink.Sensor.types.get().smoke,
            CommLink.Sensor.types.get().heat,
            CommLink.Sensor.types.get().co,
            CommLink.Sensor.types.get().bed_shaker,
            CommLink.Sensor.types.get().water
        ].map(code => {
            return {
                code,
                text: CommLink.Sensor.types.toText(code)
            };
        });

        return (
            <div style={{
                marginTop: 24
            }}>
                {getSensorTiles(types)}
            </div>
        )
    }

    const getSensorTiles = types => {
        return types.map((type, index) => {
            let targets = getSensorsForType(sensors, type);
            return targets.length > 0 && (
                <div
                key={index}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 24,
                    width: '100%'
                }}>
                    <span style={{
                        ...Appearance.textStyles.layerItemTitle(),
                        maxWidth: '100%',
                        marginBottom: 8
                    }}>{`${type.text}s`}</span>
                    <div style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        width: '100%'
                    }}>
                        {targets.map((sensor, index) => getSensorTile(sensor, index, { utils }))}
                    </div>
                </div>
            )
        });
    }

    const connectToSockets = async () => {
        try {
            await utils.sockets.emit('omnishield', 'comm_links', 'join', { comm_link_guid: abstract.object.guid });
            await utils.sockets.on('omnishield', 'comm_links', 'new_verbose_event', onNewVerboseEvent);
            await utils.sockets.emit('omnishield', 'sensors', 'join', { comm_link_guid: abstract.object.guid });
            await utils.sockets.on('omnishield', 'sensors', 'status_change', onSensorStatusChange);
        } catch(e) {
            console.error(e.message);
        }
    };

    const disconnectFromSockets = async () => {
        try {
            await utils.sockets.emit('omnishield', 'comm_links', 'leave', { comm_link_guid: abstract.object.guid });
            await utils.sockets.emit('omnishield', 'sensors', 'leave', { comm_link_guid: abstract.object.guid });
            await utils.sockets.off('omnishield', 'comm_links', 'new_verbose_event', onNewVerboseEvent);
            await utils.sockets.off('omnishield', 'sensors', 'status_change', onSensorStatusChange);
        } catch(e) {
            console.error(e.message);
        }
    };

    const fetchCommLinkDetails = async () => {
        try {
            setLoading(current => current === 'init' ? current : true);
            let { activations, comm_link, contacts, devices, sensors } = await Request.get(utils, '/omnishield/', {
                id: abstract.getID(),
                type: 'comm_link_details',
            });

            // update abstract target with updated comm link object
            let target = CommLink.create(comm_link);
            abstract.object = target;

            // update state values for comm link, contacts, and sensors
            setActivations(activations.map(activation => ({
                ...activation,
                date: moment.utc(activation.date).local(),
                sensor: CommLink.Sensor.create(activation.sensor)
            })));
            setCommLink(target);
            setContacts(contacts.map(contact => CommLink.Contact.create(contact)));
            setDevices(devices.map(device => ({
                ...device,
                date: moment.utc(device.date).local(),
                last_date: device.last_date && moment.utc(device.last_date).local()
            })));
            setOnline(target.online);
            setSensors(sensors.map(sensor => CommLink.Sensor.create(sensor)));

            // end loading and update state
            setLoading(false);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this Comm Link. ${e.message || 'An unknown error occurred'}`,
                onClick: setLayerState.bind(this, 'close')
            });
        }
    }

    useEffect(() => {
        let { message } = CommLink.formatStatusProps(online);
        setOnlineStatusMessage(message);
    }, [online]);

    useEffect(() => {
        setActivationsPaging({
            total: activations.length,
            current_page: parseInt(activationsOffset / activationsLimit) + 1,
            number_of_pages: activations.length > activationsLimit ? Math.ceil(activations.length / activationsLimit) : 1
        });
    }, [activations, activationsOffset]);

    useEffect(() => {
        setDevicesPaging({
            total: devices.length,
            current_page: parseInt(devicesOffset / devicesLimit) + 1,
            number_of_pages: devices.length > devicesLimit ? Math.ceil(devices.length / devicesLimit) : 1
        });
    }, [devices, devicesOffset]);

    useEffect(() => {

        connectToSockets();
        setTimeout(fetchCommLinkDetails, 500);
        
        utils.content.subscribe(layerID, ['comm_link', 'comm_link_contact', 'comm_link_sensor'], {
            onFetch: fetchCommLinkDetails,
            onUpdate: next => {
                switch(next.type) {
                    case 'comm_link':
                    if(next.getID() === abstract.getID()) {
                        fetchCommLinkDetails();
                    }
                    break;

                    case 'comm_link_contact':
                    setContacts(contacts => {
                        return contacts.map(contact => {
                            return contact.id === next.getID() ? next.object : contact;
                        }).sort((a,b) => {
                            if(a.last_name === b.last_name) {
                                return a.first_name.localeCompare(b.first_name);
                            }
                            return a.last_name.localeCompare(b.last_name);
                        });
                    });
                    break;

                    case 'comm_link_sensor':
                    setSensors(sensors => {
                        return sensors.map(sensor => {
                            return sensor.id === next.getID() ? next.object : sensor;
                        }).sort((a,b) => {
                            return a.location.localeCompare(b.location);
                        });
                    });
                    break;
                }
            }
        });

        return () => {
            disconnectFromSockets();
            utils.content.unsubscribe(layerID);
        }
    }, []);
    
    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        title={`${abstract.getTitle()} Details`}
        index={index}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            {getContent()}
        </Layer>
    )
}

export const CommLinkContactDetails = ({ commLink }, { abstract, index, options, utils }) => {

    const layerID = `comm_link_contact_details_${abstract.getID()}`;
    const [contact, setContact] = useState(abstract.object);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onAddressClick = () => {
        let address = Utils.formatAddress(contact.address);
        window.open(`https://www.google.com/maps/place/${encodeURIComponent(address)}`)
    }

    const onDeleteContact = () => {
        utils.alert.show({
            title: 'Delete Contact',
            message: `Are you sure that you want to remove ${contact.full_name} from your Emergency Contacts list?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteContactConfirm();
                    return;
                }
            }
        })
    }

    const onDeleteContactConfirm = async () => {
        try {
            setLoading('options');
            await Utils.sleep(1);

            await Request.delete(utils, '/omnishield/', {
                comm_link_id: contact.comm_link_id,
                id: contact.id,
                type: 'delete_contact'
            });

            setLoading(false);
            utils.content.fetch('comm_link_contact');
            utils.alert.show({
                title: 'All Done!',
                message: `${contact.full_name} has been removed from this network's emergency contacts`,
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this contact. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onEditClick = () => {
        utils.layer.open({
            id: `edit_comm_link_contact_${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditCommLinkContact.bind(this, {
                commLink: commLink,
                isNewTarget: false
            })
        });
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'delete',
                title: 'Delete',
                style: 'destructive'
            }],
            target: evt.target
        }, key => {
            if(key === 'delete') {
                onDeleteContact();
                return;
            }
        })
    }

    const getButtons = () => {
        return [{
            key: 'options',
            text: 'Options',
            color: 'secondary',
            loading: loading === 'options',
            onClick: onOptionsClick
        },{
            key: 'edit',
            text: 'Edit',
            color: 'primary',
            loading: loading === 'edit',
            onClick: onEditClick
        }];
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'first_name',
                title: 'First Name',
                value: contact.first_name
            },{
                key: 'last_name',
                title: 'Last Name',
                value: contact.last_name
            },{
                key: 'email_address',
                title: 'Email Address',
                value: contact.email_address
            },{
                key: 'phone_number',
                title: 'Phone Number',
                value: contact.phone_number ? Utils.formatPhoneNumber(contact.phone_number) : null
            }]
        },{
            key: 'location',
            title: 'Location',
            visible: contact.location || contact.address ? true : false,
            items: [{
                key: 'location',
                title: 'Location',
                component: 'map',
                value: contact.location && {
                    latitude: contact.location.lat,
                    longitude: contact.location.long
                },
                visible: contact.location ? true : false
            },{
                key: 'address',
                title: 'Address',
                value: contact.address ? Utils.formatAddress(contact.address) : 'Not Added',
                onClick: onAddressClick,
                visible: contact.address ? true : false
            }]
        },{
            key: 'preferences',
            title: 'Preferences',
            items: [{
                key: 'owner',
                title: 'Owner of Home Safe Network',
                value: contact.owner ? 'Yes' : 'No'
            },{
                key: 'resident',
                title: 'Lives with Home Safe Network',
                value: contact.resident ? 'Yes' : 'No'
            },{
                key: 'include_with_travel',
                title: 'Include on Travel Time Map',
                value: contact.include_with_travel ? 'Yes' : 'No'
            }]
        },{
            key: 'notifications',
            title: 'Notifications',
            items: [{
                key: 'emergencies',
                title: 'Emergencies (Always On)',
                value: contact.getPreference('emergency_alerts') ? 'Enabled' : 'Disabled',
                color: contact.getPreference('emergency_alerts') ? Appearance.colors.green : Appearance.colors.red
            },{
                key: 'maintenance',
                title: 'Maintenance',
                value: contact.getPreference('maintenance') ? 'Enabled' : 'Disabled',
                color: contact.getPreference('maintenance') ? Appearance.colors.green : Appearance.colors.red
            },{
                key: 'news_and_events',
                title: 'OmniShield News and Events',
                value: contact.getPreference('news_and_events') ? 'Enabled' : 'Disabled',
                color: contact.getPreference('news_and_events') ? Appearance.colors.green : Appearance.colors.red
            },{
                key: 'self_test',
                title: 'Self Tests',
                value: contact.getPreference('self_test') ? 'Enabled' : 'Disabled',
                color: contact.getPreference('self_test') ? Appearance.colors.green : Appearance.colors.red
            }]
        }]
    }

    return (
        <Layer
        id={layerID}
        index={index}
        utils={utils}
        title={'Contact Details'}
        buttons={getButtons()}
        options={{
            ...options,
            loading: loading,
            layerState: layerState,
            sizing: 'medium'
        }}>
            <FieldMapper
            utils={utils}
            fields={getFields()}/>
        </Layer>
    )
}

export const CommLinkCommunicationDetails = ({ abstract, index, options, utils }) => {

    const layerID = `comm_link_communication_details_${abstract.getID()}`;
    const [communication, setCommunication] = useState(abstract.object);
    const [loading, setLoading] = useState(false);
    const [task, setTask] = useState(abstract.object.task);
    
    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'resend',
                style: 'default',
                title: 'Resend Messages'
            },{
                key: 'stop',
                style: 'destructive',
                title: 'Stop Sending Messages',
                visible: communication.task && communication.task.status.code === 5 ? true : false
            }],
            target: evt.target
        }, key => {
            if(key === 'resend') {
                onResendMessages();
                return;
            }
            if(key === 'stop') {
                onStopSendingMessages();
                return;
            }
        });
    }

    const onResendMessages = () => {
        utils.alert.show({
            title: 'Resend Messages',
            message: 'Are you sure that you want to resend these messages? This will resend the text message content to all users who match the criteria for this communication.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onResendMessagesConfirm();
                    return;
                }
            }
        })
    }

    const onResendMessagesConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(0.5);
            let { task, total_count } = await Request.post(utils, '/omnishield/', {
                type: 'resend_communication',
                id: abstract.getID()
            });

            // notify subscribers that a new task has been submitted
            utils.events.emit('new_task', { task });

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `Your comm link customer communication has been resubmitted and will begin sending shortly. We will be notifiying ${Utils.softNumberFormat(total_count)} customers`,
                onClick: utils.content.fetch.bind(this, 'comm_link_communication')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue resubmitting your customer communication. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onStopSendingMessages = () => {
        utils.alert.show({
            title: 'Stop Sending Messages',
            message: 'Are you sure that you want to stop sending these messages? This will prevent the rest of the users who are targeted by this communication from receiving their text message.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onStopSendingMessagesConfirm();
                    return;
                }
            }
        })
    }

    const onStopSendingMessagesConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(0.5);
            let { status } = await Request.post(utils, '/omnishield/', {
                type: 'cancel_communicatation',
                id: abstract.getID()
            });

            // update target with new status
            abstract.object.status = status;
            setCommunication(abstract.object);

            // notify subscribers of status change
            utils.content.update(abstract);

            // end loading and show confirmation alert
            setLoading(true);
            utils.alert.show({
                title: 'All Done!',
                message: 'The text messages for this communication have been stopped. It may take a moment for the text messages to end.'
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue stopping these messages. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUserClick = async () => {
        try {

            setLoading(true);
            let user = await User.get(utils, abstract.object.user.user_id);

            setLoading(false);
            utils.layer.open({
                id: `user_details_${user.user_id}`,
                abstract: Abstract.create({
                    object: user,
                    type: 'user'
                }),
                Component: UserDetails
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this user account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUserTaskProgressChange = data => {
        try {
            setTask(task => task && task.id === data.id ? data : task);
        } catch(e) {
            console.error(e.message);
        }
    }

    const getButtons = () => {
        return [{
            key: 'options',
            onClick: onOptionsClick,
            color: 'primary',
            text: 'Options',
        }];
    }

    const getFields = () => {

        let items =  [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'date',
                title: 'Date',
                value: Utils.formatDate(communication.date)
            },{
                key: 'id',
                title: 'ID',
                value: abstract.getID()
            },{
                key: 'message',
                title: 'Message',
                value: communication.message
            },{
                key: 'title',
                title: 'Title',
                value: communication.title
            },{
                key: 'user_count',
                title: 'Users Targeted',
                value: Utils.softNumberFormat(communication.user_count || 0)
            }]
        },{
            key: 'preferences',
            title: 'Restrictions',
            items: [{
                key: 'maintenance',
                title: 'Maintenance',
                value: communication.preferences && communication.preferences.maintenance === true ? 'Yes' : 'No'
            },{
                key: 'news_and_events',
                title: 'News and Events',
                value: communication.preferences && communication.preferences.news_and_events === true ? 'Yes' : 'No'
            }]
        }];

        // add task details to fields list if applicable
        if(task) {
            items.push({
                key: 'task',
                title: 'Task',
                items: [{
                    key: 'id',
                    title: 'ID',
                    value: task.id
                },{
                    key: 'progress',
                    title: 'Progress',
                    value: task.total && `${((task.progress / task.total) * 100).toFixed(0)}%` || 'Not Available'
                },{
                    key: 'status',
                    title: 'Status',
                    value: task.status && task.status.text || 'Unknown'
                },{
                    key: 'progress',
                    title: 'Sent',
                    value: Utils.softNumberFormat(task.progress) || 'Not Available'
                },{
                    key: 'total',
                    title: 'Total',
                    value: Utils.softNumberFormat(task.total) || 'Not Available'
                }]
            });
        }
        return items;
    }

    const connectToSockets = async () => {
        try {
            let user = utils.user.get();
            await utils.sockets.on('aft', 'tasks', `on_user_task_progress_change_${user.user_id}`, onUserTaskProgressChange);
        } catch(e) {
            console.error(e.message);
        }
    }

    const disconnectFromSockets = async () => {
        try {
            let user = utils.user.get();
            await utils.sockets.off('aft', 'tasks', `on_user_task_progress_change_${user.user_id}`, onUserTaskProgressChange);
        } catch(e) {
            console.error(e.message);
        }
    }

    useEffect(() => {
        setTask(communication && communication.task);
    }, [communication]);

    useEffect(() => {

        connectToSockets();
        utils.content.subscribe(layerID, 'comm_link_communication', {
            onUpdate: abstract => {
                setCommunication(target => target.id === abstract.getID() ? abstract.object : target);
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
            disconnectFromSockets();
        }
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'Communication Details'}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            sizing: 'medium'
        }}>
            <LayerItem title={'Submitted By'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {Views.entry({
                        bottomBorder: false,
                        icon: { path: communication.user.avatar },
                        onClick: onUserClick,
                        subTitle: communication.user.email_address,
                        title: communication.user.full_name
                    })}
                </div>
            </LayerItem>
            <FieldMapper
            fields={getFields()}
            utils={utils} />
        </Layer>
    )
}

export const CommLinkDeviceDetails = ({ abstract, index, options, utils }) => {

    const layerID = `comm_link_device_details_${abstract.getID()}`;
    const device = abstract.object;

    const getDeviceName = () => {
        if(device.name) {
            return device.name;
        }
        if(device.os === 1) {
            return 'iOS Device';
        }
        if(device.os === 2) {
            return 'Android Device';
        }
        return 'OmniShield Classic Device';
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'date',
                title: 'Created',
                value: device.date && Utils.formatDate(device.date)
            },{
                key: 'last_date',
                title: 'Last Used',
                value: device.last_date && Utils.formatDate(device.last_date)
            },{
                key: 'id',
                title: 'ID',
                value: device.id
            },{
                key: 'name',
                title: 'Name',
                value: getDeviceName()
            },{
                key: 'os',
                title: 'Operating System',
                value: device.os ? device.os === 1 ? 'iOS' : 'Android' : 'Unknown'
            },{
                key: 'push_token',
                title: 'Push Notifications',
                value: device.push_token ? 'Enabled' : 'Disabled'
            }]
        },{
            key: 'credentials',
            title: 'Credentials',
            items: [{
                key: 'access_token',
                title: 'Access Token',
                value: device.access_token,
                style: {
                    value: {
                        overflow: 'hidden',
                        wordWrap: 'break-word'
                    }
                }
            },{
                key: 'token',
                title: 'Device Token',
                value: device.token
            }]
        }]
    }

    return (
        <Layer
        id={layerID}
        index={index}
        utils={utils}
        title={'Device Details'}
        options={{
            ...options,
            sizing: 'medium'
        }}>
            <FieldMapper
            utils={utils}
            fields={getFields()}/>
        </Layer>
    )
}

export const CommLinkSensorDetails = ({ abstract, index, options, utils }) => {

    const layerID = `comm_link_sensor_details_${abstract.getID()}`;
    const commLinkRef = useRef(null);

    const isDarkMode = window.theme === 'dark';
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(true);
    const [replacements, setReplacements] = useState([]);
    const [sensor, setSensor] = useState(abstract.object);
    const [temperature, setTemperature] = useState('F');
    const [verboseData, setVerboseData] = useState(abstract.object.verbose || false);

    const onAddBackToNetwork = () => {
        utils.alert.show({
            title: 'Add Back to Network',
            message: 'Are you sure that you want to add this sensor back to the network? This will add the sensor back to the mobile app but you may need to re-bind it in order for it to start communicating with the comm link.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Do Not Add',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onAddBackToNetworkConfirm();
                    return;
                }
            }
        })
    }

    const onAddBackToNetworkConfirm = async () => {
        try {
            setLoading('options');
            await Request.post(utils, '/omnishield/', {
                comm_link_id: abstract.object.comm_link_id,
                id: abstract.object.id,
                type: 'undelete_sensor'
            });

            setLoading(false);
            abstract.object.active = true;
            utils.content.update(abstract);
            utils.content.fetch('comm_link_sensor');
            
            utils.alert.show({
                title: 'All Done!',
                message: 'This sensor has been added back to this network',
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue adding this sensor back to this network. ${e.message || 'An unknown error occurred'}`
            });
        }
    }
    
    const onBindModeStateChange = active => {
        //setBindMode(active);
    }

    const onChangeNameClick = async evt => {
        let location = await requestSensorLocation(utils, abstract.object.type.code, evt);
        if(location) {
            onUpdateSensorLocation({ location });
        }
    }

    const onIdentifySensor = () => {
        utils.alert.show({
            title: 'Identify Sensor',
            message: 'While a comm link is in bind mode, we can ask your sensor to make a loud noise so it is easily identifiable. Would you like to identify this sensor?',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Do Not Identify',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onIdentifySensorConfirm();
                    return;
                }
            }
        });
    }

    const onIdentifySensorConfirm = async () => {
        try {
            setLoading('options');
            await Utils.sleep(0.5);
            await Request.post(utils, '/omnishield/', {
                serial_number: abstract.object.serial_number,
                type: 'identify_sensor'
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: 'We have asked your sensor to identify itself as soon as possible'
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue sending your identify request. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onNewVerboseEvent = evt => {
        try {

            // decode data for json payload
            let { serial_number, verbose } = JSON.parse(evt);

            // determine if the serial number matches the current target, very likely
            if(serial_number === abstract.object.serial_number) {

                // update verbose data object for sensor 
                abstract.object.verbose = { ...abstract.object.verbose, ...verbose };
                setSensor(abstract.object);
                setVerboseData(abstract.object.verbose); 
            }

        } catch(e) {
            console.error(e.message);
        }
    }

    const onNotificationPreferenceClick = (channel, evt) => {

        // determine if notification preference is currently disabled
        let { enabled, end_date, regulated } = sensor.notifications[channel.key];

        // determine if the notification channel is regulated
        if(regulated && moment.utc(regulated).unix() > moment.utc().unix()) {
            utils.alert.show({
                title: `${channel.title}s`,
                message: `${channel.title} activation alerts have been regulated until ${Utils.formatDate(moment.utc(regulated).local())} or until the sensor notifies us that the emergency condition has been cleared. Until then, activations will be reported using push notifications instead of text messages. \n\nA sensor may be regulated if it sends an abnormal amount of activations in a short period of time.`,
            });
            return;
        }

        // present sheet list of preference options for preferences are that currently disabled
        if(enabled === false) {

            // prepare remaining duration for disabled alerts
            let duration = moment.utc(end_date).unix() - moment.utc().unix();

            // present sheet list of preference options
            utils.sheet.show({
                title: `${channel.title} Activation Alerts`,
                message: `This sensor currently has ${channel.title.toLowerCase()} activation alerts disabled for the next ${Utils.parseDuration(duration)}. You can immediately re-enable these alerts by selecting "Enable" from the list below.`,
                items: [{
                    key: 'enable',
                    title: 'Enable',
                    style: 'default'
                }],
                target: evt.target
            }, key => {
                if(key === 'enable') {
                    onUpdateNotificationPreferences(channel, 'enable');
                    return;
                }
            });
            return;
        }

        // present sheet list of preference options
        utils.sheet.show({
            title: `${channel.title} Activation Alerts`,
            message: `If needed, you can temporarily disable the ${channel.title.toLowerCase()} alerts that are sent when this sensor detects an emergency condition. ${channel.title} alerts can be disabled for up to 24 hours. This applies for all emergency contacts registered with this network.`,
            items: [{
                key: 1800,
                title: 'Disable for 30 Minutes',
                style: 'default'
            },{
                key: 3600,
                title: 'Disable for 1 Hour',
                style: 'default'
            },{
                key: 86400,
                title: 'Disable for 24 Hours',
                style: 'default'
            }],
            target: evt.target
        }, key => {
            if(key !== 'cancel') {
                onUpdateNotificationPreferences(channel, key);
                return;
            }
        });
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'add',
                title: 'Add Back to Network',
                style: 'default',
                visible: sensor.active === false
            },{
                key: 'comm_link',
                title: 'Comm Link Details',
                style: 'default'
            },{
                key: 'verbose_activity',
                title: 'Recent Activity',
                style: 'default',
                visible: abstract.object.supportsVerboseData()
            },{
                key: 'replace',
                title: 'Replace',
                style: 'destructive',
                visible: false //sensor.canReplace()
            },{
                key: 'troubleshooting',
                title: 'Troubleshooting',
                style: 'default',
                visible: utils.user.get().level <= User.levels.get().exigent_admin && sensor.type.code !== CommLink.Sensor.types.get().bed_shaker
            },{
                key: 'sms_activity',
                title: 'Text Messaging Activity',
                style: 'default'
            },{
                key: 'update_status',
                title: 'Update Status',
                style: 'default',
                visible: utils.user.get().level <= User.levels.get().dealer
            },{
                key: 'remove',
                title: 'Remove from Network',
                style: 'destructive',
                visible: sensor.active === true
            }],
            target: evt.target,
            sort: false
        }, key => {
            switch(key) {
                
                case 'add':
                onAddBackToNetwork();
                break;

                case 'troubleshooting':
                setTimeout(onStartApiTesting.bind(this, evt), 250);
                break;

                case 'comm_link':
                onViewCommLinkDetails();
                break;

                case 'replace':
                onReplaceSensor();
                break;

                case 'remove':
                onRemoveSensor();
                break;

                case 'sms_activity':
                onViewSmsActivity();
                break;

                case 'update_status':
                onUpdateStatus();
                break;

                case 'verbose_activity':
                onViewVerboseActivity();
                break;
            }
        });
    }

    const onReplaceSensor = () => {
        utils.alert.show({
            title: 'Replace Sensor',
            message: 'Do you need to send a replacement sensor to the customer or dealer?',
            buttons: [{
                key: 'shipping',
                title: 'Yes',
                style: 'default'
            },{
                key: 'no_shipping',
                title: 'No Shipping Required',
                style: 'cancel'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                switch(key) {
                    case 'no_shipping':
                    onReplaceSensorConfirm();
                    break;

                    case 'shipping':
                    utils.layer.open({
                        abstract: abstract,
                        Component: ReplaceCommLinkSensor,
                        id: `replace_comm_link_sensor_${abstract.getID()}`
                    });
                    break;
                }
            }
        });
    }

    const onReplaceSensorConfirm = async () => {
        try {
            setLoading('options');
            let { status } = await Request.delete(utils, '/omnishield/', {
                comm_link_id: abstract.object.comm_link_id,
                id: abstract.object.id,
                type: 'replace_sensor',
            });

            // end loading and update abstract target status
            setLoading(false);
            abstract.object.status = status;

            // notify subscribers of data change
            utils.content.update(abstract);
            utils.content.fetch('comm_link_sensor');

            // show confirmation alert, bind alert in app will have option to replace existing sensor with newly bound sensor
            utils.alert.show({
                title: 'All Done!',
                message: `This sensor has been marked as "${status.text}" and is ready to be replaced with a newly bound unit.`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue replacing this sensor. ${e.message || 'An unknown error occurred'}`
            });
        }
    } 

    const onRemoveSensor = () => {
        utils.alert.show({
            title: 'Remove Sensor',
            message: `Are you sure that you want to remove this sensor from this network? This cannot be undone.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Remove',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onRemoveSensorConfirm();
                    return;
                }
            }
        });
    }

    const onRemoveSensorConfirm = async () => {
        try {
            setLoading('options');
            await Utils.sleep(0.25);
            await Request.delete(utils, '/omnishield/', {
                comm_link_id: abstract.object.comm_link_id,
                id: abstract.object.id,
                type: 'delete_sensor',
            });

            setLoading(false);
            abstract.object.active = false;
            utils.content.update(abstract);
            utils.content.fetch('comm_link_sensor');

            utils.alert.show({
                title: 'All Done!',
                message: 'This sensor has been removed from this network',
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue removing this sensor from this network. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onReportedByCommLinkSerialNumberClick = async serialNumber => {
        try {
            setLoading(true);
            let commLink = await CommLink.get(utils, null, { serial_number: serialNumber });

            setLoading(false);
            utils.layer.open({
                id: `comm_link_details_${commLink.id}`,
                abstract: Abstract.create({
                    type: 'comm_link',
                    object: commLink
                }),
                Component: CommLinkDetails
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this comm link. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSensorReplacementClick = async replacement => {
        try {

            // determine which serial number to use as the target serial number
            let serial_number = replacement.original_serial_number === abstract.object.serial_number ? replacement.replacement_serial_number : replacement.original_serial_number;

            // fetch details for sensor from server
            setLoading(true);
            let sensor = await CommLink.Sensor.get(utils, { 
                serial_number,
                sensor_type: replacement.sensor_type
            });

            // end loading and open sensor details layer
            setLoading(false);
            utils.layer.open({
                abstract: Abstract.create({
                    object: sensor,
                    type: 'comm_link_sensor'
                }),
                Component: CommLinkSensorDetails,
                id: `comm_link_sensor_details_${sensor.id}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this sensor. ${e.message || 'An unknown error occurred;'}`
            });
        }
    }

    const onSensorStatusChange = evt => {
        try {
            console.log(evt);
            let { id, status } = JSON.parse(evt);
            if(abstract.object.id === id) {
                abstract.object.status = status;
                utils.content.update(abstract);
                setSensor(abstract.object);
            }
        } catch(e) {
            console.error(e.message);
        }
    }

    const onStartApiTesting = evt => {
        utils.sheet.show({
            title: 'Troubleshooting',
            message: `During administrative troubleshooting, you can send an event to the server as this sensor would do under normal circumstances. This helps determine if a communication issue is caused by the sensor hardware, the server processing logic, or the customer's local area network.`,
            items: [{
                key: 'clear',
                title: 'Clear Event',
                style: 'default',
                visible: abstract.object.type.code !== CommLink.Sensor.types.get().comm_link
            },{
                key: 'co_activation',
                title: 'Carbon Monoxide Activation',
                style: 'destructive',
                visible: [CommLink.Sensor.types.get().ac_smoke_co, CommLink.Sensor.types.get().co, CommLink.Sensor.types.get().smoke_co].includes(abstract.object.type.code)
            },{
                key: 'heat_activation',
                title: 'Heat Activation',
                style: 'destructive',
                visible: abstract.object.type.code === CommLink.Sensor.types.get().heat
            },{
                key: 'moisture_activation',
                title: 'Moisture Activation',
                style: 'destructive',
                visible: abstract.object.type.code === CommLink.Sensor.types.get().water
            },{
                key: 'smoke_activation',
                title: 'Smoke Activation',
                style: 'destructive',
                visible: [CommLink.Sensor.types.get().ac_smoke, CommLink.Sensor.types.get().ac_smoke_co, CommLink.Sensor.types.get().smoke, CommLink.Sensor.types.get().smoke_co].includes(abstract.object.type.code)
            },{
                key: 'self_test',
                title: 'Self Test',
                style: 'default'
            },{
                key: 'verbose',
                title: 'Verbose Event',
                style: 'default'
            }],
            target: evt.target
        }, key => {
            if(key !== 'cancel') {
                onSubmitApiTest(key);
                return;
            }
        });
    }

    const onStatusClick = () => {
        utils.sheet.show({
            items: [{
                key: 'details',
                title: 'About this Status',
                style: 'default'
            },{
                key: 'update',
                title: 'Update Status',
                style: 'default'
            },{
                key: 'events',
                title: 'View Status History',
                style: 'default'
            }]
        }, key => {
            if(key === 'details') {
                utils.alert.show({
                    title: sensor.status.text,
                    message: sensor.status.description
                });
                return;
            }
            if(key === 'events') {
                utils.layer.open({
                    abstract: abstract,
                    Component: CommLinkSensorStatusHistoryEvents,
                    id: `status_history_events_${abstract.getID()}`
                });
                return;
            }
            if(key === 'update') {
                onUpdateStatus();
                return;
            }
        });
    }

    const onSubmitApiTest = async key => {
        try {
            setLoading('options');
            await Request.post(utils, '/omnishield/', {
                id: abstract.getID(),
                test: key,
                type: 'api_test'
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: 'Your api test for this sensor has been submitted'
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue submitting your api test. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateNotificationPreferences = async (channel, value) => {
        try {

            // start loading and send request to the server
            setLoading(channel.key);
            let{ notifications } = await Request.post(utils, '/omnishield/', {
                [channel.key]: value,
                comm_link_id: abstract.object.comm_link_id,
                id: abstract.getID(),
                type: 'update_sensor_notification_preferences'
            });

            // update abstract target with notifications changes
            abstract.object.notifications = notifications;

            // end loading and update sensors state
            setLoading(false);
            setSensor(abstract.object);

            // notify subscribers of data change
            utils.content.update(abstract);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating your ${channel.title.toLowerCase()} alerts preference. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateSensorLocation = async ({ location }) => {
        try {
            setLoading('change_name');
            await Request.put(utils, '/omnishield/', {
                id: abstract.getID(),
                location: location,
                type: 'update_sensor'
            });

            abstract.object.location = location;
            utils.content.update(abstract);
            setSensor(abstract.object);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The name for this sensor has been changed to "${location}"`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the name for this sensor. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateStatus = () => {

        // prepare list of status options
        let items = Object.values(CommLink.Sensor.status.get()).map(code => ({
            id: code,
            title: CommLink.Sensor.status.toText(code)
        })).sort((a,b) => {
            return a.title.localeCompare(b.title);
        });

        // show alert with option to select a status
        let status = null;
        utils.alert.show({
            title: 'Update Status',
            message: 'Please choose a status from the list below to continue. This status will be visible by all who have access to this sensor.',
            content: (
                <div style={{
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    width: '100%'
                }}>
                    <ListField 
                    items={items}
                    onChange={item => status = item && item.id} 
                    placeholder={'Choose a status from the list below'} />
                </div>
            ),
            buttons: [{
                key: 'confirm',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm' && status) {
                    onUpdateStatusConfirm(status);
                    return;
                }
            }
        });
    }

    const onUpdateStatusConfirm = async sts => {
        try {

            // start loading and sleep briefly before submitting request
            setLoading('options');
            await Utils.sleep(0.25);

            // submit request to server
            let { status } = await Request.post(utils, '/omnishield/', {
                id: abstract.getID(),
                status: sts,
                type: 'set_sensor_status'
            });

            // update status for abstract target
            abstract.object.status = status;

            // notify subscribers that a status change has occurred
            utils.content.update(abstract);

            // end loading and show confirmation
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The status for this sensor has been updated to "${status.text}"`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the status for this sensor. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onViewCommLinkDetails = async () => {
        try {
            setLoading('options');
            let commLink = await CommLink.get(utils, null, { id: abstract.object.comm_link_id });

            setLoading(false);
            utils.layer.open({
                abstract: Abstract.create({
                    object: commLink,
                    type: 'comm_link'
                }),
                Component: CommLinkDetails,
                id: `comm_link_details_${commLink.id}`
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this sensor's comm link. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onViewSmsActivity = () => {
        utils.layer.open({
            abstract: abstract,
            Component: SmsEvents,
            id: 'sms_events'
        });
    }

    const onViewVerboseActivity = () => {
        utils.layer.open({
            abstract: abstract,
            Component: VerboseEvents,
            id: 'verbose_events'
        });
    }

    const getBatteryLevel = () => {

        // prevent moving forward if sensor does not support verbose data
        if(sensor.supportsVerboseData() === false) {
            return null;
        }

        // show loading components if verbose data has not been fetched
        if(verboseData === false) {
            return getLoadingComponent('Battery Level');
        }

        // declare battery level and return no components if data if not available
        let battery = sensor.get('battery_level');
        if(!battery) {
            return null;
        }

        // determine image source based on battery level
        let imageSource = isDarkMode ? 'images/battery-full-dark.png' : 'images/battery-full-light.png';
        if(!battery) {
            imageSource = isDarkMode ? 'images/battery-unavailable-dark.png' : 'images/battery-unavailable-light.png';
        } else if(battery <= 33) {
            imageSource = isDarkMode ? 'images/battery-low-dark.png' : 'images/battery-low-light.png';
        } else if(battery <= 66) {
            imageSource = isDarkMode ? 'images/battery-mid-dark.png' : 'images/battery-mid-light.png';
        }

        return (
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 12,
                padding: '8px 12px 8px 12px',
                width: '100%',
                ...Appearance.styles.unstyledPanel()
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    marginRight: 8
                }}>
                    <span style={{
                        ...Appearance.textStyles.subHeader(),
                        marginBottom: 2
                    }}>{'Battery Level'}</span>
                    <span style={{
                        ...Appearance.textStyles.subTitle()
                    }}>{battery ? `${battery}%` : 'Not Available'}</span>
                </div>
                <img 
                src={imageSource}
                style={{
                    height: 40,
                    objectFit: 'contain',
                    width: 40
                }} />
            </div>
        )
    }

    const getButtons = () => {
        return [{
            key: 'options',
            text: 'Options',
            color: 'secondary',
            loading: loading === 'options',
            onClick: onOptionsClick
        },{
            key: 'change_name',
            text: 'Change Name',
            color: 'dark',
            loading: loading === 'change_name',
            onClick: onChangeNameClick
        }]
    }

    const getChamberStatusImage = () => {

        let value = verboseData && verboseData.chamber_status > 0 ? verboseData.chamber_status : 0;
        if(value < 25) {
            return 'images/chamber-status-icon-dirty.png';
        }
        if(value < 50) {
            return 'images/chamber-status-icon-moderate.png';
        }
        if(value <= 100) {
            return 'images/chamber-status-icon-clean.png';
        }
    }

    const getCOSensorComponents = () => {
        let { image, text } = getCOValues() || {};
        return (
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 12,
                padding: '8px 12px 8px 12px',
                width: '100%',
                ...Appearance.styles.unstyledPanel()
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    marginRight: 8
                }}>
                    <span style={{
                        ...Appearance.textStyles.subHeader(),
                        marginBottom: 2
                    }}>{'Carbon Monoxide Gas Level'}</span>
                    <span style={{
                        ...Appearance.textStyles.subTitle()
                    }}>{text}</span>
                </div>
                <img 
                src={image}
                style={{
                    height: 30,
                    objectFit: 'contain',
                    width: 30
                }} />
            </div>
        )
    }

    const getCOValues = () => {

        // declare co level and prevent moving forward if no value is available
        let coLevel = sensor.get('co_level');
        if(coLevel === null || coLevel === undefined) {
            return {
                image: 'images/co-icon-none.png',
                text: '0 ppm'
            }
        }

        // return color and width values based on co level
        // only three possible values are returned
        if(coLevel >= 0 && coLevel <= 30) {
            return {
                image: 'images/co-icon-low.png',
                text: `${coLevel} ppm`
            }
        }
        if(coLevel >= 31 && coLevel <= 150) {
            return {
                image: 'images/co-icon-moderate.png',
                text: `${coLevel} ppm`
            }
        }
        if(coLevel > 150) {
            return {
                image: 'images/co-icon-heavy.png',
                text: `${coLevel} ppm`
            }
        }
    }

    const getCountableLabel = key => {

        // declare date value and prevent moving forward if nothing is found
        let date = sensor.get(key);
        if(!date) {
            return 'Not available';
        }

        // return countable label component
        return (
            <CountableLabel 
            date={date}
            ellipsizeMode={'tail'}
            numberOfLines={1}
            placeholder={'Waiting...'}
            style={{
                ...Appearance.textStyles.value(),
                maxWidth: '100%'
            }} />
        )
    }

    const getFields = () => {

        // prevent moving forward if sensor state has not been set
        if(!sensor) {
            return [];
        }

        // prepare verbose values
        let reportedByCommLinkSerialNumber = sensor.get('reported_by_comm_link_serial_number');
        let supportsVerboseData = sensor.supportsVerboseData();

        // prepare list of line items
        let items = [{
            key: 'details',
            title: `About this ${sensor.type.text}`,
            style: {
                marginTop: 24
            },
            items: [{
                key: 'ac_power',
                title: 'AC Power',
                value: sensor.get('ac_power') === true ? 'Connected' : 'Disconnected',
                visible: supportsVerboseData === true && sensor.type.code === CommLink.Sensor.types.get().ac_smoke
            },{
                key: 'serial_number',
                selectable: true,
                title: 'Identifier',
                value: sensor.serial_number
            },{
                key: 'location',
                title: 'Location',
                value: sensor.location
            },{
                color: sensor.status.color,
                key: 'status',
                onClick: onStatusClick,
                title: 'Status',
                value: sensor.status.text
            },{
                key: 'type',
                title: 'Type',
                value: sensor.type.text
            },{
                key: 'version',
                title: 'Version',
                value: sensor.version.toFixed('1')
            }]
        },{
            key: 'activity',
            title: 'Recent Activity',
            items: [{
                key: 'last_self_test_date',
                title: 'Last Self Test',
                value: getCountableLabel('last_self_test_date')
            },{
                key: 'last_activation_date',
                title: 'Last Alarm Activation',
                value: getCountableLabel('last_activation_date'),
                visible: supportsVerboseData === true
            },{
                key: 'updated',
                title: 'Updated',
                value: getCountableLabel('date'),
                visible: supportsVerboseData === true
            },{
                key: 'reported_by_comm_link_serial_number',
                onClick: onReportedByCommLinkSerialNumberClick.bind(this, reportedByCommLinkSerialNumber),
                title: 'Reported By Comm Link Serial Number',
                value: reportedByCommLinkSerialNumber,
                visible: reportedByCommLinkSerialNumber ? true : false
            }]
        }];

        // add notification preferences if applicable
        if(sensor.notifications && supportsVerboseData === true) {
            items.push({
                key: 'notifications',
                title: 'Activation Alerts',
                items: getNotificationPreferences()
            });
        }
        return items;
    }

    const getFixedTemperature = () => {
        let temp = sensor.get('heat_settings');
        if(!temp) {
            return 'Unavailable';
        }
        let value = temperature === 'F' ? temp : parseInt((temp - 32) * 5/9);
        return `${value}°${temperature}`;
    }

    const getLoadingComponent = title => {
        return (
            <LayerItem
            title={title}
            useStyle={false}>
                <div style={{
                    alignItems: 'center',
                    backgroundColor: Appearance.colors.divider(),
                    borderRadius: 16.5,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: 33,
                    overflow: 'hidden',
                    width: '100%'
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={isDarkMode ? 'files/lottie/dots-white.json' : 'files/lottie/dots-grey.json'}
                    duration={2500}
                    style={{
                        width: 35
                    }} />
                </div>
            </LayerItem>
        )
    }

    const getMoistureLevelText = () => {

        let value = verboseData && verboseData.moisture_level > 0 ? verboseData.moisture_level : 0;
        if(value < 25) {
            return 'Dry';
        }
        if(value < 50) {
            return 'Wet';
        }
        if(value <= 100) {
            return 'Reposition';
        }
    }

    const getMoistureLevelImage = () => {

        let value = verboseData && verboseData.chamber_status > 0 ? verboseData.chamber_status : 0;
        if(value < 25) {
            return 'images/moisture-icon-dry.png';
        }
        if(value < 50) {
            return 'images/moisture-icon-wet.png';
        }
        if(value <= 100) {
            return 'images/moisture-icon-reposition.png';
        }
    }

    const getNotificationPreferences = () => {

        // prepare list of notification preference channels
        let channels = [{
            key: 'email',
            title: 'Email'
        },{
            key: 'push_notification',
            title: 'Push Notification'
        },{
            key: 'sms',
            title: 'Text Message'
        }];

        // loop through channels and prepare line item entry
        return channels.map(channel => {

            // prepare default props based off of enabled status
            let { enabled, regulated } = sensor.notifications[channel.key];
            let props = {
                color: enabled ? Appearance.colors.green : Appearance.colors.red, 
                value: enabled ? 'Enabled' : 'Disabled'
            };

            // determine if the notification channel is regulated
            if(regulated && moment.utc(regulated).unix() > moment.utc().unix()) {
                props = {
                    color: Appearance.colors.orange,
                    value: 'Regulated'
                }
            }

            return {
                ...props,
                key: channel.key,
                loading: loading === channel.key,
                onClick: onNotificationPreferenceClick.bind(this, channel),
                title: `${channel.title}s`
            }
        });
    }

    const getRoomTemperature = () => {

        // prevent moving forward if sensor does not support verbose data
        if(sensor.supportsVerboseData() === false || sensor.type.code === CommLink.Sensor.types.get().bed_shaker) {
            return null;
        }

        // show loading components if verbose data has not been fetched
        if(verboseData === false) {
            return getLoadingComponent('Room Temperature');
        }
        
        // declare temperature and return no components if data if not available
        let temp = sensor.get('temperature');
        if(!temp) {
            return null;
        }

        // convert to celcius is applicable
        if(temperature === 'C') {
            temp = parseInt((temp - 32) * 5/9);
        }

        // determine which image source should be shown
        let imageSource = 'images/temperature-icon-hot.png';
        if(!temp) {
            imageSource = 'images/temperature-icon-none.png';
        } else if(temp <= 80) {
            imageSource = 'images/temperature-icon-cold.png';
        } else if(temp <= 90) {
            imageSource = 'images/temperature-icon-warm.png';
        }

        return (
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 12,
                padding: '8px 12px 8px 12px',
                width: '100%',
                ...Appearance.styles.unstyledPanel()
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    marginRight: 8
                }}>
                    <span style={{
                        ...Appearance.textStyles.subHeader(),
                        marginBottom: 2
                    }}>{'Room Temperature'}</span>
                    <span style={{
                        ...Appearance.textStyles.subTitle()
                    }}>{temp ? `${temp}°${temperature}` : 'Not Available'}</span>
                </div>
                <img 
                src={imageSource}
                style={{
                    height: 30,
                    objectFit: 'contain',
                    width: 30
                }} />
            </div>
        )
    }

    const getSensorAnimation = () => {

        // declare source variable
        let props = {
            source: 'files/lottie/smoke-sensor-default.json'
        };

        // loop through sensor type and set verbose reading value
        let { emergency_alert = false} = verboseData || {};
        let { ac_smoke, ac_smoke_co, bed_shaker, co, comm_link, heat, smoke, smoke_co, water } = CommLink.Sensor.types.get();
        switch(sensor.type.code) {
            case ac_smoke:
            case smoke:
            props = {
                source: emergency_alert ? require('files/lottie/smoke-sensor-activated.json') : require('files/lottie/smoke-sensor-default.json')
            };
            break;

            case ac_smoke_co:
            case smoke_co:
            props = {
                source: emergency_alert ? require('files/lottie/smoke-co-sensor-activated.json') : require('files/lottie/smoke-co-sensor-default.json')
            };
            break;

            case bed_shaker:
            props = {
                source: require('files/lottie/bed-shaker-default.json')
            };
            break;

            case co:
            props = {
                source: emergency_alert ? require('files/lottie/co-sensor-activated.json') : require('files/lottie/co-sensor-default.json')
            };
            break;

            case comm_link:
            props = {
                source: require('files/lottie/comm-link-default.json')
            };
            break;

            case heat:
            props = {
                source: emergency_alert ? require('files/lottie/heat-sensor-activated.json') : require('files/lottie/heat-sensor-default.json')
            };
            break;

            case water:
            props = {
                source: emergency_alert ? require('files/lottie/water-sensor-activated.json') : require('files/lottie/water-sensor-default.json')
            };
            break;
        }

        return (
            <div style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                width: '100%'
            }}>
                <LottieView
                {...props}
                autoPlay={true}
                loop={true}
                style={{
                    height: 300,
                    width: 300
                }} />
            </div>
        )
    }

    const getSensorReading = () => {

        // prevent moving forward if sensor does not support verbose data
        if(sensor.supportsVerboseData() === false) {
            return null;
        }

        // loop through sensor types and determine which graphics to show
        switch(sensor.type.code) {
        
            case CommLink.Sensor.types.get().ac_smoke:
            case CommLink.Sensor.types.get().smoke:
            return (
                <div style={{
                    flexDirection: 'column',
                    width: '100%'
                }}>
                    {getSmokeSensorComponents()}
                </div>
            )

            case CommLink.Sensor.types.get().ac_smoke_co:
            case CommLink.Sensor.types.get().smoke_co:
            return (
                <div style={{
                    flexDirection: 'column',
                    width: '100%'
                }}>
                    {getCOSensorComponents()}
                    {getSmokeSensorComponents()}
                </div>
            )

            case CommLink.Sensor.types.get().comm_link: 
            return null;

            case CommLink.Sensor.types.get().co:
            return getCOSensorComponents();

            case CommLink.Sensor.types.get().heat:
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: 12,
                    padding: '8px 12px 8px 12px',
                    width: '100%',
                    ...Appearance.styles.unstyledPanel()
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        marginRight: 8
                    }}>
                        <span style={{
                            ...Appearance.textStyles.subHeader(),
                            marginBottom: 2
                        }}>{'Fixed Temperature'}</span>
                        <span style={{
                            ...Appearance.textStyles.subTitle()
                        }}>{getFixedTemperature()}</span>
                    </div>
                    <img 
                    src={'images/heat-icon-default.png'}
                    style={{
                        height: 30,
                        objectFit: 'contain',
                        width: 30
                    }} />
                </div>
            )

            case CommLink.Sensor.types.get().water:
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: 12,
                    padding: '8px 12px 8px 12px',
                    width: '100%',
                    ...Appearance.styles.unstyledPanel()
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        marginRight: 8
                    }}>
                        <span style={{
                            ...Appearance.textStyles.subHeader(),
                            marginBottom: 2
                        }}>{'Moisture Level'}</span>
                        <span style={{
                            ...Appearance.textStyles.subTitle()
                        }}>{getMoistureLevelText()}</span>
                    </div>
                    <img 
                    src={getMoistureLevelImage()}
                    style={{
                        height: 30,
                        objectFit: 'contain',
                        width: 30
                    }} />
                </div>
            )

            default:
            return null;
        }
    }

    const getSmokeLevelImage = () => {

        let value = verboseData && verboseData.smoke_status > 0 ? verboseData.smoke_status : 0;
        if(value < 25) {
            return 'images/smoke-level-icon-low.png';
        }
        if(value < 50) {
            return 'images/smoke-level-icon-moderate.png';
        }
        if(value <= 100) {
            return 'images/smoke-level-icon-heavy.png';
        }
    }

    const getSmokeSensorComponents = () => {
        return (
            <>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 12,
                padding: '8px 12px 8px 12px',
                width: '100%',
                ...Appearance.styles.unstyledPanel()
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    marginRight: 8
                }}>
                    <span style={{
                        ...Appearance.textStyles.subHeader(),
                        marginBottom: 2
                    }}>{'Chamber Status'}</span>
                    <span style={{
                        ...Appearance.textStyles.subTitle()
                    }}>{verboseData && verboseData.chamber_level || 'Unavailable'}</span>
                </div>
                <img 
                src={getChamberStatusImage()}
                style={{
                    height: 30,
                    objectFit: 'contain',
                    width: 30
                }} />
            </div>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 12,
                padding: '8px 12px 8px 12px',
                width: '100%',
                ...Appearance.styles.unstyledPanel()
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    marginRight: 8
                }}>
                    <span style={{
                        ...Appearance.textStyles.subHeader(),
                        marginBottom: 2
                    }}>{'Smoke Status'}</span>
                    <span style={{
                        ...Appearance.textStyles.subTitle()
                    }}>{verboseData && verboseData.smoke_level || 'Unavailable'}</span>
                </div>
                <img 
                src={getSmokeLevelImage()}
                style={{
                    height: 30,
                    objectFit: 'contain',
                    width: 30
                }} />
            </div>
            </>
        )
    }

    const getWarningMessages = () => {

        // prepare empty list of flag entries
        let messages = [];

        // add mobile app review badge flag if applicable
        if(replacements.length > 0) {
            replacements.forEach((replacement, index) => {
                messages.push({
                    icon: { 
                        path: 'images/warning-icon-yellow.png',
                        imageStyle: {
                            backgroundColor: Appearance.colors.transparent,
                            borderRadius: 0,
                            boxShadow: 'none',
                            objectFit: 'contain'
                        }  
                    },
                    key: `replacement.${index}`,
                    onClick: onSensorReplacementClick.bind(this, replacement),
                    subTitle: Utils.formatDate(replacement.date),
                    style: {
                        subTitle: {
                            whiteSpace: 'normal'
                        }
                    },
                    title: replacement.original_serial_number === abstract.object.serial_number ? `Sensor Replaced By Serial Number ${replacement.replacement_serial_number}` : `Sensor Replaced Serial Number ${replacement.original_serial_number}`
                });
            })
        }

        // return list of flag components if applicable
        return messages.length > 0 && (
            <LayerItem title={'Important Messages'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    width: '100%'
                }}>
                    {messages.map((entry, index) => {
                        return (
                            Views.entry({
                                bottomBorder: index !== messages.length - 1,
                                key: index,
                                ...entry
                            })
                        )
                    })}
                </div>
            </LayerItem>
        )
    }

    const connectToSockets = async () => {
        try {
            await utils.sockets.emit('omnishield', 'comm_links', 'join', { comm_link_guid: commLinkRef.current.guid });
            await utils.sockets.on('omnishield', 'comm_links', 'new_verbose_event', onNewVerboseEvent);
            await utils.sockets.emit('omnishield', 'sensors', 'join', { comm_link_guid: commLinkRef.current.guid });
            await utils.sockets.on('omnishield', 'sensors', 'status_change', onSensorStatusChange);
        } catch(e) {
            console.error(e.message);
        }
    }

    const disconnectFromSockets = async () => {
        try {
            if(commLinkRef.current) {
                await utils.sockets.emit('omnishield', 'comm_links', 'leave', { comm_link_guid: commLinkRef.current.guid });
                await utils.sockets.emit('omnishield', 'sensors', 'leave', { comm_link_guid: commLinkRef.current.guid });
            }
            await utils.sockets.off('omnishield', 'comm_links', 'new_verbose_event', onNewVerboseEvent); 
            await utils.sockets.off('omnishield', 'sensors', 'status_change', onSensorStatusChange);
        } catch(e) {
            console.error(e.message);
        }
    }

    const fetchDetails = async () => {
        try {
            let { comm_link, replacements, sensor } = await Request.get(utils, '/omnishield/', {
                id: abstract.getID(),
                type: 'sensor_details'
            });
      
            // update abstract target with sensor result
            abstract.object = CommLink.Sensor.create(sensor);

            // set a reference to the comm link guid where this sensor is bound for websocket connect and disconnect
            // request a connection to the websockets server after ref is set
            commLinkRef.current = comm_link;
            connectToSockets();

            // end loading and update local states
            setLoading(false);
            setSensor(abstract.object);
            setVerboseData(sensor.verbose);
            setReplacements(replacements.map(replacement => ({
                ...replacement,
                date: moment.utc(replacement.date).local()
            })));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this sensor. ${e.message || 'An unknown error occurred'}`,
                onClick: setLayerState.bind(this, 'close')
            });
        }
    }

    useEffect(() => {
        setTimeout(fetchDetails, 250);
        return disconnectFromSockets;
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Sensor Details for ${abstract.object.location}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>

            {getSensorAnimation()}
            <div style={{
                marginBottom: 24,
                width: '100%'
            }}>
                {getBatteryLevel()}
                {getRoomTemperature()}
                {getSensorReading()}
            </div>
            {getWarningMessages()}
            <FieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const CommLinkSensorStatusHistoryEvents = ({ abstract, index, options, utils }) => {

    const layerID = `status_history_events_${abstract.getID()}`;
    const limit = 10;

    const offset = useRef(0);
    const sorting = useRef({ sort_key: 'date', sort_type: Content.sorting.type.descending });

    const [events, setEvents] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(true);
    const [paging, setPaging] = useState(null);

    const getContent = () => {
        if(loading === true) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: 100,
                    width: '100%'
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 40,
                        width: 40
                    }}/>
                </div>
            )
        }
        return (
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                width: '100%'
            }}>
                <table
                className={'px-3 py-2 m-0'}
                style={{
                    width: '100%'
                }}>
                    <thead style={{
                        width: '100%'
                    }}>
                        {getFields()}
                    </thead>
                    <tbody style={{
                        width: '100%'
                    }}>
                        {events.map(getFields)}
                    </tbody>
                </table>
                {paging && (
                    <PageControl
                    data={paging}
                    limit={limit}
                    loading={loading === 'paging'}
                    offset={offset.current}
                    onClick={next => {
                        offset.current = next;
                        setLoading('paging');
                        fetchEvents();
                    }} />
                )}
            </div>
        )
    }

    const getFields = (evt, index) => {
        
        let target = evt || {};
        let fields = [{
            key: 'user',
            sortable: false,
            title: 'User',
            value: target.user ? target.user.full_name : 'Name Not Available'
        },{
            key: 'date',
            title: 'Date',
            value: target.date && Utils.formatDate(target.date)
        },{
            key: 'id',
            title: 'Event ID',
            value: target.id
        },{
            key: 'status',
            title: 'Status',
            value: (
                <AltBadge content={target.status} />
            )
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!evt) {
            return (
                <TableListHeader
                fields={fields}
                onChange={next => {
                    sorting.current = next;
                    setLoading('paging');
                    fetchEvents();
                }}
                value={sorting.current && {
                    direction: sorting.current.sort_type,
                    key: sorting.current.sort_key
                }}/>
            )
        }

        return (
            <TableListRow
            key={index}
            lastItem={index === events.length - 1}
            values={fields}/>
        )
    }

    const fetchEvents = async () => {
        try {
            let { events, paging } = await Request.get(utils, '/omnishield/', {
                limit: limit,
                id: abstract.getID(),
                offset: offset.current,
                sorting: sorting.current,
                type: 'sensor_status_history'
            });
      
            // prevent moving forward if no events are found
            if(events.length === 0) {
                utils.alert.show({
                    title: 'Just a Second',
                    message: 'No status history events were found for this sensor',
                    onClick: setLayerState.bind(this, 'close')
                });
                return;
            }

            // update local state for events and paging
            setLoading(false);
            setPaging(paging);
            setEvents(events.map(evt => ({
                ...evt,
                date: moment.utc(evt.date).local()
            })));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the status history events for this sensor. ${e.message || 'An unknown error occurred'}`,
                onClick: setLayerState.bind(this, 'close')
            });
        }
    }

    useEffect(() => {
        fetchEvents();
    }, []); 

    return (
        <Layer
        id={layerID}
        index={index}
        title={`Status History Events for ${abstract.object.location} (${abstract.object.serial_number})`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState
        }}>
            {getContent()}
        </Layer>
    )
}

export const ExpiringCOSensors = ({ index, options, utils }) => {

    const panelID = 'expiring_co_sensors';
    const limit = 10;
    const offset = useRef(0);
    const sorting = useRef(0);

    const [loading, setLoading] = useState(false);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [sensors, setSensors] = useState([]);

    const onSensorClick = async target => {
        try {
            setLoading(true);
            let sensor = await CommLink.Sensor.get(utils, { id: target.id });

            setLoading(false);
            utils.layer.open({
                id: `comm_link_sensor_details_${sensor.id}`,
                abstract: Abstract.create({
                    type: 'comm_link_sensor',
                    object: sensor
                }),
                Component: CommLinkSensorDetails
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this sensor. ${e.message || 'An unknown error occurred;'}`
            });
        }
    }

    const getContent = () => {
        if(sensors.length === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: 'No sensors were found for your dealership',
                    title: 'Nothing to see here'
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {sensors.map(getFields)}
                </tbody>
            </table>
        )
    }

    const getFields = (sensor, index) => {
    
        let target = sensor || {};
        let fields = [{
            key: 'location',
            title: 'Location',
            value: target.location || 'Unnamed Location'
        },{
            key: 'serial_number',
            title: 'Serial Number',
            value: target.serial_number
        },{
            key: 'comm_link_serial_number',
            title: 'Comm Link Serial Number',
            value: target.comm_link_serial_number
        },{
            key: 'date',
            title: 'Expiration Date',
            value: target.date && Utils.formatDate(target.date, true)
        },{
            key: 'duration',
            title: 'Days Until Expiration',
            value: target.duration && Utils.parseDuration(target.duration)
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!sensor) {
            return (
                <TableListHeader
                fields={fields}
                onChange={next => {
                    sorting.current = next;
                    setLoading(true);
                    fetchSensors();
                }} 
                value={sorting.current}/>
            )
        }

        return (
            <TableListRow
            key={index}
            values={fields}
            lastItem={index === sensors.length - 1}
            onClick={onSensorClick.bind(this, target)} />
        )
    }

    const fetchSensors = async () => {
        try {
            setLoading(true);
            let { paging, sensors } = await Request.get(utils, '/omnishield/', {
                limit: limit,
                offset: offset.current,
                search_text: searchText,
                type: 'expiring_carbon_monoxide_sensors',
                ...sorting.current
            });

            setLoading(false);
            setPaging(paging);
            setSensors(sensors.map(sensor => ({
                ...sensor,
                date: moment.utc(sensor.date).local(),
                expiration_date: moment.utc(sensor.expiration_date).local()
            })));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the expiring carbon monoxide sensors list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchSensors();
    }, [searchText]);

    useEffect(() => {

        utils.events.on(panelID, 'dealership_change', fetchSensors);
        utils.content.subscribe(panelID, 'comm_link_sensor', {
            onFetch: fetchSensors,
            onUpdate: fetchSensors
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchSensors);
        }
    }, [])

    return (
        <Panel
        panelID={panelID}
        name={'Expiring Carbon Monoxide Sensors'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            paging: {
                data: paging,
                limit: limit,
                offset: offset.current,
                onClick: next => {
                    offset.current = next;
                    setLoading('paging');
                    fetchSensors();
                }
            },
            search: {
                placeholder: 'Search by sensor location, serial number, or comm link serial number...',
                onChange: text => {
                    offset.current = 0;
                    setSearchText(text);
                }
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                width: '100%'
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const getGaugeWithValue = (val, explicit_width = 0) => {

    // generate size using image dimensions of 1131 x 745
    let width = explicit_width || window.innerWidth - 30;
    let height = width / 1131 * 745;

    const getNeedleComponent = () => {

        // generate rotation and transform offsets
        let { transform } = withAnchorPoint({ transform: [{ rotate: val * 180 }] }, { x: 0.50132625994695, y: 0.76510067114094 }, { width: width, height: height });

        // collapse array of transforms into a string on rotations and translates
        let values = transform.reduce((array, entry) => {
            let items = Object.keys(entry).map(key => {
                return `${key}(${entry[key]}${key === 'rotate' ? 'deg' : 'px'})`
            });
            return array.concat(items);
        }, []).join(' ');

        return (
            <img
            className={'omnishield-guage-needle'}
            src={window.theme === 'dark' ? 'images/sensor-guage-needle-dark.png' : 'images/sensor-guage-needle-light.png'}
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: width,
                height: height,
                objectFit: 'contain',
                transform: values
            }} />
        )
    }

    return (
        <div style={{
            position: 'relative'
        }}>
            <img
            src={window.theme === 'dark' ? 'images/sensor-guage-frame-dark.png' : 'images/sensor-guage-frame-light.png'}
            style={{
                width: width,
                height: height,
                objectFit: 'contain'
            }} />
            {getNeedleComponent()}
        </div>
    )
}

export const getSensorIcon = (sensor, options) => {

    // prevent moving forward if a sensor was not provided or if the code is invalid
    if(!sensor || !sensor.type) {
        return 'images/missing-sensor-icon.png';
    }

    // prepare verbose values and option flags
    let { ac_power, emergency_alert } = sensor.verbose || {};
    let { ac_smoke, ac_smoke_co, bed_shaker, co, heat, smoke, smoke_co, water } = CommLink.Sensor.types.get();
    let { force_emergency_alert } = options || {};
    let showEmergencyAlertIcon = force_emergency_alert || emergency_alert;

    // determine which image to show based on the sensor type
    switch(sensor.type.code) {
        case ac_smoke:
        case ac_smoke_co:
        if(ac_power === false) {
            return 'images/ac-smoke-power-issue-icon.png';
        }
        if(typeof(sensor.supportsVerboseData) === 'fucntion' && sensor.supportsVerboseData() === false) {
            return showEmergencyAlertIcon ? 'images/legacy-smoke-alert-icon.png' : 'images/legacy-smoke-icon.png'; 
        }
        return showEmergencyAlertIcon ? 'images/smoke-alert-icon.png' : 'images/smoke-icon.png'; 

        case bed_shaker: 
        return showEmergencyAlertIcon ? 'images/bed-shaker-alert-icon.png' : 'images/bed-shaker-icon.png';

        case co: 
        return showEmergencyAlertIcon ? 'images/co-alert-icon.png' : 'images/co-icon.png';

        case heat: 
        return showEmergencyAlertIcon ? 'images/heat-alert-icon.png' : 'images/heat-icon.png';

        case smoke: 
        case smoke_co:
        return showEmergencyAlertIcon ? 'images/smoke-alert-icon.png' : 'images/smoke-icon.png';

        case water: 
        return showEmergencyAlertIcon ? 'images/water-alert-icon.png' : 'images/water-icon.png';

        default:
        return showEmergencyAlertIcon ? 'images/bed-shaker-alert-icon.png' : 'images/bed-shaker-icon.png';
    }
}

export const getSensorTile = (sensor, index, options = {}) => {

    let { temperaturePref = 'F', utils } = options;

    const onSensorClick = () => {
        utils.layer.open({
            abstract: Abstract.create({
                object: sensor,
                type: 'comm_link_sensor'
            }),
            Component: CommLinkSensorDetails,
            id: `comm_link_sensor_details_${sensor.id}`
        })
    }

    const getAccessoryComponents = () => {

        // declare verbose values
        let battery = sensor.get('battery_level');
        let temperature = sensor.get('temperature');

        // determine if temperature needs to be converted to celcius
        if(temperature && temperaturePref === 'C') {
            temperature = parseInt((temperature - 32) * 5/9);
        }
        
        return (
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%'
            }}>
                <SensorVerboseIcon
                temperaturePref={temperaturePref}
                type={'temperature'}
                value={temperature}
                style={{
                    fontSize: 10,
                    opacity: temperature > 0 && sensor.type.code !== CommLink.Sensor.types.get().bed_shaker ? 1 : 0
                }} />
                <SensorVerboseIcon
                type={'battery'}
                value={battery}
                style={{
                    height: 18,
                    opacity: sensor.supportsVerboseData() === true ? 1 : 0,
                    width: 40
                }} />
            </div>
        )
    }

    const getLabel = () => {

        // determine if a status based text label should be used in place of the verbose timestamp
        if(sensor.status && sensor.status.should_replace_verbose_timestamp.web) {
            return (
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    color: sensor.status.color,
                    maxWidth: '100%'
                }}>{sensor.status.text}</span>
            )
        }

        // fallback to showing the verbose timestamp
        return (
            <CountableLabel 
            date={sensor.get('date')}
            placeholder={'Waiting for update...'}
            style={{
                ...Appearance.textStyles.subTitle(),
                maxWidth: '100%',
                opacity: sensor.supports_verbose_data === false ? 0 : 1
            }} />
        )
    }

    return (
        <div
        key={index}
        style={{
            paddingBottom: 8,
            paddingLeft: 4,
            paddingRight: (index + 1) % 3 === 0 ? 0 : 4,
            width: '33%'
        }}>
            <div 
            className={'highlight-button'}
            onClick={onSensorClick}
            style={{
                ...Appearance.styles.unstyledPanel(),
                alignItems: 'center',
                backgroundColor: Appearance.colors.panelBackground(),
                borderRadius: 12,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                minWidth: 0,
                padding: 12,
                width: '100%'
            }}>
                {getAccessoryComponents(sensor)}
                <img 
                src={getSensorIcon(sensor)}
                style={{
                    height: 60,
                    marginBottom: 8,
                    objectFit: 'contain',
                    width: 60
                }} />
                <span style={{
                    ...Appearance.textStyles.title(),
                    maxWidth: '100%'
                }}>{sensor.location}</span>
                {getLabel()}
            </div>
        </div>
    )
}

export const getSensorsForType = (sensors, type) => {
    return sensors.filter(sensor => {
        if(type.code === CommLink.Sensor.types.get().smoke) {
            return [ CommLink.Sensor.types.get().ac_smoke, CommLink.Sensor.types.get().smoke ].includes(sensor.type.code);
        }
        if(type.code === CommLink.Sensor.types.get().smoke_co) {
            return [ CommLink.Sensor.types.get().ac_smoke_co, CommLink.Sensor.types.get().smoke_co ].includes(sensor.type.code);
        }
        return sensor.type.code === type.code;
    });
}

export const getTemperatureColor = val => {
    if(!val) {
        return Appearance.colors.grey();
    }
    if(val <= 80) {
        return Appearance.colors.blue;
    }
    if(val <= 90) {
        return Appearance.colors.orange;
    }
    return Appearance.colors.red;
}

export const NewCommLinkCommunication = ({ index, options, utils }) => {

    const layerID = 'new_comm_link_communication';
    const [edits, setEdits] = useState({});
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onSubmit = () => {
        utils.alert.show({
            title: 'Subit Communication',
            message: 'Are you sure that you want to submit this customer communication? This will automatically send a text message to all comm link customers who match your criteria.',
            buttons: [{
                key: 'confirm',
                title: 'Confirm',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSubmitConfirm();
                    return;
                }
            }
        });
    }

    const onSubmitConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);
            let { task, total_count } = await Request.post(utils, '/omnishield/', {
                ...edits,
                type: 'new_communication'
            });

            // notify subscribers that a new task has been submitted
            utils.events.emit('new_task', { task });

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `Your comm link customer communication has been submitted and will begin sending shortly. We will be notifiying ${Utils.softNumberFormat(total_count)} customers`,
                onClick: () => {
                    setLayerState('close');
                    utils.content.fetch('comm_link_communication');
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue submitting your customer communication. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        setEdits(prev => ({
            ...prev, 
            ...props
        }));
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'submit',
            onClick: onSubmit,
            text: 'Submit'
        }];
    }

    const getFields = () => {
        return [{
            key: 'content',
            title: 'Content',
            items: [{
                component: 'textfield',
                key: 'title',
                onChange: text => onUpdateTarget({ title: text }),
                title: 'Title',
                value: edits.title
            },{
                component: 'textfield',
                key: 'message',
                onChange: text => onUpdateTarget({ message: text }),
                title: 'Message',
                value: edits.message
            }]
        },{
            key: 'preferences',
            title: 'Restrictions',
            items: [{
                component: 'bool_list',
                key: 'maintenance',
                onChange: val => {
                    onUpdateTarget({
                        preferences: update(edits.preferences, {
                            maintenance: {
                                $set: val
                            }
                        })
                    });
                },
                title: 'Maintenance',
                value: edits.preferences && edits.preferences.maintenance
            },{
                component: 'bool_list',
                key: 'news_and_events',
                onChange: val => {
                    onUpdateTarget({
                        preferences: update(edits.preferences, {
                            news_and_events: {
                                $set: val
                            }
                        })
                    });
                },
                title: 'News and Events',
                value: edits.preferences && edits.preferences.news_and_events
            }]
        }]
    }

    useEffect(() => {
        setEdits({ 
            preferences: {
                maintenance: false,
                news_and_events: false
            }
        });
    }, [])

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'New Comm Link Customer Communication'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            fields={getFields()}
            utils={utils} />
        </Layer>
    )
}

export const OmniShieldWhiteLabelApps = ({ index, options, utils }) => {

    const panelID = 'omnishield_white_label_apps';
    
    const limit = 10;
    const offset = useRef(0);
    const sorting = useRef({ 
        sort_key: 'dealership_name', 
        sort_type: Content.sorting.type.ascending 
    });

    const [apps, setApps] = useState([]);
    const [loading, setLoading] = useState(false);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onAppClick = async id => {
        try {

            // set loading flag and fetch dealership details from server
            setLoading(true);
            let dealership = await Dealership.get(utils, id);

            // end loading and show details layer
            setLoading(false);
            utils.layer.open({
                abstract: Abstract.create({
                    object: dealership,
                    type: 'dealership'
                }),
                Component: DealershipDetails,
                id: `dealership_details_${dealership.id}`
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this dealership. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSortingChange = val => {
        sorting.current = val;
        setLoading(true);
        fetchApps();
    }

    const getContent = () => {
        if(apps.length === 0) {
            return (
                Views.entry({
                    title: 'Nothing to see here',
                    subTitle: 'No white label apps were found',
                    bottomBorder: false,
                    hideIcon: true
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {apps.map(getFields)}
                </tbody>
            </table>
        )
    }

    const getFields = (app, index) => {
    
        // prepare default row items
        let target = app || {};
        let fields = [{
            key: 'dealership_name',
            title: 'Dealership',
            value: (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    <img 
                    src={target.mobile_app_icon}
                    style={{
                        border: `0.5px solid ${Appearance.colors.divider()}`,
                        borderRadius: 8,
                        height: 25,
                        marginRight: 8,
                        width: 25
                    }} />
                    <span>{target.dealership_name}</span>
                </div>
            )
        },{
            key: 'android_current_version',
            title: 'Android Version',
            value: target.android_current_version || 'Not published'
        },{
            key: 'android_status',
            title: 'Android Status',
            value: target.android_status && (
                <AltBadge content={target.android_status} />
            )
        },{
            key: 'ios_current_version',
            title: 'iOS Version',
            value: target.ios_current_version || 'Not published'
        },{
            key: 'ios_status',
            title: 'iOS Status',
            value: target.ios_status && (
                <AltBadge content={target.ios_status} />
            )
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!app) {
            return (
                <TableListHeader
                fields={fields}
                onChange={onSortingChange}
                value={sorting.current} />
            )
        }

        return (
            <TableListRow
            key={index}
            values={fields}
            lastItem={index === apps.length - 1}
            onClick={onAppClick.bind(this, target && target.id)} />
        )
    }

    const fetchApps = async () => {
        try {
            setLoading(true);
            let { apps, paging } = await Request.get(utils, '/omnishield/', {
                limit: limit,
                offset: offset.current,
                search_text: searchText,
                type: 'white_label_apps',
                ...sorting.current
            });

            setLoading(false);
            setPaging(paging);
            setApps(apps);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the omnishield white label apps list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchApps();
    }, [searchText]);

    useEffect(() => {
        utils.content.subscribe(panelID, ['omnishield_white_label_change_request'], {
            onFetch: fetchApps,
            onUpdate: fetchApps
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, [])

    return (
        <Panel
        index={index}
        name={'OmniShield White Label Apps'}
        panelID={panelID}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            paging: {
                data: paging,
                limit: limit,
                offset: offset.current,
                onClick: next => {
                    setLoading(true);
                    offset.current = next;
                    fetchApps();
                }
            },
            search: {
                placeholder: 'Search by dealership id or dealership name...',
                onChange: text => {
                    offset.current = 0;
                    setSearchText(text);
                }
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                width: '100%'
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const OmniShieldWhiteLabelChangeRequests = ({ index, options, utils }) => {

    const panelID = 'omnishield_white_label_change_requests';
    
    const limit = 10;
    const offset = useRef(0);
    const sorting = useRef({ 
        sort_key: 'date', 
        sort_type: Content.sorting.type.descending 
    });
    const statusFilterRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const [requests, setRequests] = useState([]);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [statusFilter, setStatusFilter] = useState(null);

    const onRequestClick = async id => {
        try {

            // set loading flag and fetch request details from server
            setLoading(id);
            let request = await OmniShieldWhiteLabel.ChangeRequest.get(utils, id);

            // end loading and show request details layer
            setLoading(false);
            utils.layer.open({
                id: `omnishield_white_label_change_request_details_${request.id}`,
                abstract: Abstract.create({
                    object: request,
                    type: 'omnishield_white_label_change_request'
                }),
                Component: OmniShieldWhiteLabelChangeRequestDetails
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this omnishield white label change request. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSortingChange = val => {
        sorting.current = val;
        setLoading(true);
        fetchRequests();
    }

    const getContent = () => {
        if(requests.length === 0) {
            return (
                Views.entry({
                    title: 'Nothing to see here',
                    subTitle: 'No change requests were found',
                    bottomBorder: false,
                    hideIcon: true
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {requests.map(getFields)}
                </tbody>
            </table>
        )
    }

    const getFields = (request, index) => {
    
        // prepare default row items
        let target = request || {};
        let fields = [{
            key: 'id',
            title: 'Request ID',
            value: `OMNI-WL-CR-${target.id}`
        },{
            key: 'date',
            title: 'Date',
            value: Utils.formatDate(target.date)
        },{
            key: 'dealership_name',
            title: 'Dealership',
            value: target.dealership && target.dealership.name
        },{
            key: 'summary',
            sortable: false,
            title: 'Summary',
            value: target.data && target.data.summary
        },{
            key: 'status',
            title: 'Status',
            value: target.status && (
                <AltBadge content={target.status} />
            )
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!request) {
            return (
                <TableListHeader
                fields={fields}
                onChange={onSortingChange}
                value={sorting.current} />
            )
        }

        return (
            <TableListRow
            key={index}
            values={fields}
            lastItem={index === requests.length - 1}
            onClick={onRequestClick.bind(this, target && target.id)} />
        )
    }

    const getStatusFilter = () => {

        // prepare list of status options
        let items = Object.values(OmniShieldWhiteLabel.ChangeRequest.status.get()).filter(code => {
            return code !== OmniShieldWhiteLabel.ChangeRequest.status.get().in_service;
        }).map(code => ({
            id: code,
            title: OmniShieldWhiteLabel.ChangeRequest.status.toText(code)
        })).sort((a,b) => {
            return a.title.localeCompare(b.title);
        });

        return (
            <ListField 
            items={items}
            onChange={item => setStatusFilter(item && item.id)} 
            placeholder={'Choose a status from the list below'} 
            style={{
                marginLeft: 8,
                width: 300
            }}/>
        )
    }

    const fetchRequests = async () => {
        try {
            setLoading(true);
            let { paging, requests } = await Request.get(utils, '/omnishield/', {
                limit: limit,
                offset: offset.current,
                search_text: searchText,
                status: statusFilterRef.current,
                type: 'white_label_change_requests',
                ...sorting.current
            });

            setLoading(false);
            setPaging(paging);
            setRequests(requests.map(request => OmniShieldWhiteLabel.ChangeRequest.create(request)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the omnishield white label change requests list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        statusFilterRef.current = statusFilter;
        if(statusFilter) {
            fetchRequests();
        }
    },[statusFilter]);

    useEffect(() => {
        fetchRequests();
    }, [searchText]);

    useEffect(() => {

        utils.content.subscribe(panelID, ['omnishield_white_label_change_request'], {
            onFetch: fetchRequests,
            onUpdate: fetchRequests
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, [])

    return (
        <Panel
        index={index}
        name={'OmniShield White Label Change Requests'}
        panelID={panelID}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            paging: {
                data: paging,
                limit: limit,
                offset: offset.current,
                onClick: next => {
                    setLoading(true);
                    offset.current = next;
                    fetchRequests();
                }
            },
            search: {
                placeholder: 'Search by request id, dealership id, or dealership name...',
                onChange: text => {
                    offset.current = 0;
                    setSearchText(text);
                },
                rightContent: getStatusFilter()
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                width: '100%'
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const OmniShieldWhiteLabelChangeRequestDetails = ({ abstract, index, options, utils }) => {

    const layerID = `omnishield_white_label_change_request_details_${abstract.getID()}`;
    const [dealership, setDealership] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState(abstract.object);
    
    const onApproveStatusClick = () => {
        utils.alert.show({
            title: 'Approve Change Request',
            message: 'Are you sure that you want to approve this change request? We will send an email to the dealer with your decision and submit the app changes to Apple and Google for review.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onStatusClickConfirm(OmniShieldWhiteLabel.ChangeRequest.status.get().approved);
                    return;
                }
            }
        });
    }

    const onDealershipClick = async () => {
        try {

            // set loading flag and fetch dealership details from server
            setLoading(true);
            let dealership = await Dealership.get(utils, request.dealership.id);

            // end loading and show details layer
            setLoading(false);
            utils.layer.open({
                id: `dealership_details_${dealership.id}`,
                abstract: Abstract.create({
                    type: 'dealership',
                    object: dealership
                }),
                Component: DealershipDetails
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this dealership. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'remove',
                title: 'Remove from Review',
                style: 'destructive'
            }],
            target: evt.target
        }, key => {
            if(key === 'remove') {
                onRemoveFromReview();
                return;
            }
        });
    }

    const onRejectStatusClick = () => {
        let message = null;
        utils.alert.show({
            title: 'Reject Change Request',
            message: 'Are you sure that you want to reject this change request? Please provide information describing your rejection so we can notify the dealer.',
            content: (
                <div style={{
                    padding: 12,
                    width: '100%'
                }}>
                    <TextView 
                    onChange={text => message = text}
                    placeholder={'There was an issue with your submission...'} />
                </div>
            ),
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(message && key === 'confirm') {
                    onStatusClickConfirm(OmniShieldWhiteLabel.ChangeRequest.status.get().rejected, { message });
                    return;
                }
            }
        });
    }

    const onRemoveFromReview = () => {

        // prevent moving forward if the status is not pending, only pending changes can be removed from review
        if(canRemoveFromReview() === false) {
            utils.alert.show({
                title: 'Just a Second',
                message: `This item has already been reviewed and can not be removed. Please contact support if you have additional questions.`
            });
            return;
        }

        // show alert requesting confirmation for action
        utils.alert.show({
            title: 'Remove from Review',
            message: `Are you sure that you want to remove these changes from review? You'll need to resubmit these changes if you decide that you want to proceed with these changes in the future.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onRemoveFromReviewConfirm();
                    return;
                }
            }
        });
    }

    const onRemoveFromReviewConfirm = async () => {
        try {

            // set loading flag and submit request to server
            setLoading('options');
            await Request.post(utils, '/omnishield/', {
                id: abstract.getID(),
                type: 'cancel_white_label_change_request'
            });

            // end loading and notify subscribers that new change request data is available
            setLoading(false);
            utils.content.fetch('omnishield_white_label_change_request');

            // update preferences for dealership if dealership is the active dealership 
            utils.content.update({
                object: {
                    category: 'cancel_change_request',
                    dealership_id: abstract.object.dealership.id,
                },
                type: 'omnishield_white_label_change_request'
            });

            // show confirmation alert
            utils.alert.show({
                title: 'All Done!',
                message: 'Your changes has been removed from review.',
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue removing these changes from review. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onStatusClickConfirm = async (code, props) => {
        try {

            // prepare list of available status codes
            let codes = OmniShieldWhiteLabel.ChangeRequest.status.get();

            // set loading flag and send request to server
            setLoading(code === codes.approved ? 'approve' : 'reject');
            let { app_review_status, status } = await Request.post(utils, '/omnishield/', {
                id: abstract.getID(),
                status: code,
                type: 'set_white_label_change_request_status',
                ...props
            });

            // update target with new status result and notify subscribers of data change
            abstract.object.status = status;
            utils.content.update(abstract);

            // notify dealership subscribers that new data is available for omnishield preferences if status is "approved"
            if(app_review_status) {
                utils.content.update({
                    object: {
                        category: 'app_review_status',
                        data: app_review_status,
                        dealership_id: abstract.object.dealership.id
                    },
                    type: 'omnishield_white_label_preferences'
                });
            }

            // end loading and show confirmatio alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `This change request has been ${code === codes.approved ? 'approved' : 'rejected'}.`,
                onClick: () => {
                    if(code === codes.rejected) {
                        setLayerState('close');
                    }
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the status for this change request. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {

        // prepare status codes and level flag for buttons list
        let codes = OmniShieldWhiteLabel.ChangeRequest.status.get();
        let isAdmin = utils.user.get().level <= User.levels.get().admin;

        return [{
            color: 'danger',
            key: 'reject',
            loading: loading === 'reject',
            onClick: onRejectStatusClick,
            text: 'Reject',
            visible: isAdmin && request.status.code === codes.pending
        },{
            color: 'dark',
            key: 'options',
            loading: loading === 'options',
            onClick: onOptionsClick,
            text: 'Options',
            visible: canRemoveFromReview()
        },{
            color: 'primary',
            key: 'approve',
            loading: loading === 'approve',
            onClick: onApproveStatusClick,
            text: 'Approve',
            visible: isAdmin && request.status.code === codes.pending
        }];
    }

    const getFields = () => {
        return [{
            key: 'details',
            lastItem: false,
            title: 'Details',
            items: [{
                key: 'date',
                title: 'Date',
                value: Utils.formatDate(request.date)
            },{
                key: 'id',
                title: 'ID',
                value: `OMNI-WL-CR-${request.id}`
            },{
                key: 'dealership_name',
                onClick: onDealershipClick,
                title: 'Dealership',
                value: request.dealership && request.dealership.name
            },{
                color: request.status.color,
                key: 'status',
                title: 'Status',
                value: request.status.text
            }]
        }];
    }

    const getPendingDataChanges = () => {
        return (
            <LayerItem 
            collapsed={false}
            lastItem={false}
            title={'Pending Changes'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {request.data.props.map((entry, index, entries) => {
                    
                        // prepare an asset row if applicable
                        if(entry.url) {
                            return (
                                Views.row({
                                    bottomBorder: index !== entries.length - 1,
                                    key: index,
                                    label: entry.title,
                                    value: (
                                        <img 
                                        className={'text-button'}
                                        onClick={window.open.bind(this, entry.url)}
                                        src={entry.url}
                                        style={{
                                            borderRadius: 8,
                                            height: 25,
                                            objectFit: 'contain',
                                            width: 25,
                                        }} />
                                    )
                                })
                            )
                        }
                        return (
                            Views.row({
                                bottomBorder: index !== entries.length - 1,
                                key: index,
                                label: entry.title,
                                value: entry.value
                            })
                        )
                    })}
                </div>
            </LayerItem>
        )
    }

    const getStatusHistoryList = () => {
        return (
            <LayerItem 
            collapsed={false}
            title={'Activity'}>
                {request.events.map((entry, index, entries) => {
                    return (
                        <div 
                        key={index}
                        style={{
                            ...Appearance.styles.unstyledPanel(),
                            marginBottom: index !== entries.length - 1 ? 8 : 0
                        }}>
                            {Views.entry({
                                bottomBorder: false,
                                icon: { path: entry.user.avatar },
                                subTitle: Utils.formatDate(entry.date),
                                title: entry.user.full_name,
                                rightContent: (
                                    <AltBadge content={entry.status} />
                                )
                            })}
                            {typeof(entry.message) === 'string' && (
                                <div style={{
                                    borderTop: `1px solid ${Appearance.colors.divider()}`,
                                    padding: '8px 12px 8px 12px',
                                    width: '100%'
                                }}>
                                    <span style={{
                                        ...Appearance.textStyles.subTitle(),
                                        display: 'block',
                                        lineHeight: 1.5,
                                        whiteSpace: 'normal'
                                    }}>{entry.message}</span>
                                </div>
                            )}
                        </div>
                    )
                })}
            </LayerItem>
        )
    }

    const canRemoveFromReview = () => {
        return abstract.object.status.code === OmniShieldWhiteLabel.ChangeRequest.status.get().pending;
    }

    useEffect(() => {
        utils.content.subscribe(layerID, ['omnishield_white_label_change_request'], {
            onUpdate: abstract => {
                setRequest(target => target.id === abstract.getID() ? abstract.object : target);
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'OmniShield White Label Change Request Details'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            <FieldMapper
            fields={getFields()}
            utils={utils} />
            {getPendingDataChanges()}
            {getStatusHistoryList()}
        </Layer>
    )
}

export const PushNotificationEvents = ({ abstract, index, options, utils }) => {

    const layerID = 'push_notification_events';
    const container = useRef(null);
    const limit = 10;
    const offset = useRef(0);

    const [events, setEvents] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(true);
    const [paging, setPaging] = useState(null);

    const getDeviceIcon = os => {
        if(os === 1) {
            return 'images/apple-icon-grey.png';
        }
        if(os === 2) {
            return 'images/google-icon-grey.png';
        }
        if(os === 3) {
            return 'images/alexa-device-icon.jpg';
        }
        return 'images/omnishield-classic-icon.jpg';
    }

    const getDeviceType = os => {
        if(os === 1) {
            return 'iOS Device';
        }
        if(os === 2) {
            return 'Android Device';
        }
        if(os === 3) {
            return 'Alexa Smart Speaker';
        }
        return 'OmniShield Classic Device';
    }

    const fetchPushNotificationEntries = async () => {
        try {
            let { notifications, paging } = await Request.get(utils, '/omnishield/', {
                comm_link_guid: abstract.object.guid,
                limit: limit,
                offset: offset.current,
                type: 'comm_link_push_notification_events'
            });

            // close layer if no events are found
            if(notifications.length === 0) {
                utils.alert.show({
                    title: 'No Activity Found',
                    message: 'We were unable to locate any recent push notification activity for this comm link. Please check back later.',
                    onClick: setLayerState.bind(this, 'close')
                });
                return;
            }

            // end loading, reset active open drop-down content, and format events
            setLoading(false);
            setEvents(notifications.map(notification => ({ ...notification, date: moment.utc(notification.date).local() })));
            setPaging(paging);

            // scroll container to top of content
            container.current.scrollTo(0, 0);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the recent push notifications activity list for this comm link. ${e.message || 'An unknown error occurred'}`,
                onClick: setLayerState.bind(this, 'close')
            });
        }
    }

    useEffect(() => {
        fetchPushNotificationEntries();
    }, []);

    return (
        <Layer
        id={layerID}
        index={index}
        title={'Push Notification Activity'}
        utils={utils}
        options={{
            ...options,
            bottomCard: true,
            layerState: layerState,
            loading: loading === true,
            sticky: {
                bottom: paging && (
                    <PageControl
                    data={paging}
                    limit={limit}
                    loading={loading === 'paging'}
                    offset={offset.current}
                    onClick={next => {
                        offset.current = next;
                        setLoading('paging');
                        fetchPushNotificationEntries();
                    }} />
                )
            }
        }}>
            <div ref={container}>
                {events.map((evt, index) => {
                    return (
                        <div 
                        key={index}
                        style={{
                            ...Appearance.styles.unstyledPanel(),
                            marginBottom: 12,
                            overflow: 'hidden'
                        }}>
                            <div style={{
                                alignItems: 'center',
                                borderBottom: `1px solid ${Appearance.colors.divider()}`,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                padding: '8px 12px 8px 12px',
                                width: '100%'
                            }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    minWidth: 0,
                                    width: '100%'
                                }}>
                                    <span style={{
                                        ...Appearance.textStyles.layerItemTitle()
                                    }}>{evt.title}</span>
                                    <span style={{
                                        ...Appearance.textStyles.subTitle(),
                                        whiteSpace: 'normal'
                                    }}>{evt.message}</span>
                                </div>
                                <div style={{
                                    width: 'auto'
                                }}>
                                    <AltBadge content={{
                                        color: Appearance.colors.grey(),
                                        text: Utils.formatDate(evt.date)
                                    }} />
                                </div>
                            </div>
                            {evt.devices.map((device, index, devices) => {
                                return (
                                    Views.entry({
                                        bottomBorder: index !== devices.length - 1,
                                        icon: { path: getDeviceIcon(device.os) },
                                        key: index,
                                        subTitle: getDeviceType(device.os),
                                        title: device.name
                                    })
                                )
                            })}
                        </div>
                    )
                })}
            </div>
        </Layer>
    )
}

export const RapidlyUpdatingSensors = ({ index, options, utils }) => {

    const panelID = 'rapidly_sensors';
    const limit = 10;

    const canAutoFetch = useRef(false);
    const offset = useRef(null);
    const sorting = useRef({sort_key: 'percent', sort_type: 3});

    const [loading, setLoading] = useState(false);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [sensors, setSensors] = useState([]);

    const onDownloadClick = () => {

    }
    
    const onHistoryClick = (target, evt) => {
        evt.stopPropagation();
        utils.layer.open({
            id: `rapidly_updating_sensor_history_report_${target.serial_number}`,
            Component: RapidlyUpdatingSensorHistoryReport.bind(this, { 
                comm_link_serial_number: target.comm_link_serial_number,
                sensor_type: target.sensor_type,
                serial_number: target.serial_number
            })
        });
    }

    const onSensorClick = async target => {
        try {
            setLoading(true);
            let sensor = await CommLink.Sensor.get(utils, {
                sensor_type: target.sensor_type,
                serial_number: target.serial_number
            });

            setLoading(false);
            utils.layer.open({
                id: `comm_link_sensor_details_${sensor.id}`,
                abstract: Abstract.create({
                    type: 'comm_link_sensor',
                    object: sensor
                }),
                Component: CommLinkSensorDetails
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this sensor. ${e.message || 'An unknown error occurred;'}`
            });
        }
    }

    const getButtons = () => {
        return [{
            key: 'download',
            title: 'Download',
            style: 'default',
            onClick: onDownloadClick
        }];
    }

    const getContent = () => {

        // determine if results have been auto fetched
        if(canAutoFetch.current === false) {
            return (
                <div 
                className={'text-button'}
                onClick={() => {
                    canAutoFetch.current = true;
                    fetchSensors();
                }}
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: 12,
                    width: '100%'
                }}>
                    <img 
                    src={window.theme === 'dark' ? 'images/panel-click-white.png' : 'images/panel-click-grey.png'}
                    style={{
                        height: 50,
                        marginBottom: 8,
                        objectFit: 'contain',
                        width: 50
                    }} />
                    <span style={{
                        ...Appearance.textStyles.title(),
                        marginBottom: 2,
                        textAlign: 'center'
                    }}>{'Click to Load Sensors'}</span>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        maxWidth: 250,
                        textAlign: 'center',
                        whiteSpace: 'wrap'
                    }}>{'We automatically hide certain lists to improve the overall performance of Applied Fire Technologies.'}</span>
                </div>
            )
        }
        if(sensors.length === 0) {
            return (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    width: '100%'
                }}>
                    {Views.entry({
                        bottomBorder: false,
                        hideIcon: true,
                        subTitle: 'No rapidly updating sensors were found',
                        title: 'Nothing to see here'
                    })}
                </div>
            )
        }
        return (
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                width: '100%'
            }}>
                <table
                className={'px-3 py-2 m-0'}
                style={{
                    width: '100%'
                }}>
                    <thead style={{
                        width: '100%'
                    }}>
                        {getFields()}
                    </thead>
                    <tbody style={{
                        width: '100%'
                    }}>
                        {sensors.map(getFields)}
                    </tbody>
                </table>
            </div>
        )
    }

    const getFields = (sensor, index) => {
    
        let target = sensor || {};
        let fields = [{
            key: 'comm_link_serial_number',
            title: 'Comm Link Serial Number',
            value: target.comm_link_serial_number
        },{
            key: 'serial_number',
            title: 'Serial Number',
            value: target.serial_number
        },{
            key: 'sensor_type',
            title: 'Sensor Type',
            value: target.sensor_type ? `${CommLink.Sensor.types.toText(target.sensor_type)} (${target.sensor_type})` : 'Unavailable'
        },{
            key: 'date',
            title: 'Time Since Last Event',
            value: target.date && Utils.formatDateDuration(target.date)
        },{
            key: 'count',
            title: 'Event Count',
            value: target.count
        },{
            key: 'percent',
            title: 'Percent Over Threshold',
            value: target.percent && `${target.percent.toFixed(2)}%`
        },{
            key: 'history_count',
            title: 'Occurences',
            value: target.history_count
        },{
            key: 'history',
            title: 'History',
            value: (
                <AltBadge 
                onClick={target.history_count > 1 ? onHistoryClick.bind(this, target) : null}
                content={{
                    color: target.history_count > 1 ? Appearance.colors.primary() : Appearance.colors.grey(),
                    text: target.history_count > 1 ? 'View Report' : 'Not Applicable'
                }} />
            )
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!sensor) {
            return (
                <TableListHeader
                fields={fields}
                onChange={next => {
                    sorting.current = next;
                    fetchSensors();
                }}
                value={sorting.current && {
                    direction: sorting.current.sort_type,
                    key: sorting.current.sort_key
                }}/>
            )
        }

        return (
            <TableListRow
            key={index}
            values={fields}
            lastItem={index === sensors.length - 1}
            onClick={onSensorClick.bind(this, target)} />
        )
    }

    const fetchSensors = async () => {
        try {
            setLoading(true);
            let { paging, sensors } = await Request.get(utils, '/omnishield/', {
                limit: limit,
                offset: offset.current,
                search_text: searchText,
                type: 'rapidly_updating_sensors_admin',
                ...sorting.current
            });
    
            setLoading(false);
            setPaging(paging);
            setSensors(sensors);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue rapidly updating the sensors list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(canAutoFetch.current === true) {
            fetchSensors();
        }
    }, [searchText]);

    useEffect(() => {

        utils.events.on(panelID, 'dealership_change', fetchSensors);
        utils.content.subscribe(panelID, 'comm_link_sensor', {
            onFetch: fetchSensors,
            onUpdate: fetchSensors
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchSensors);
        }
    }, [])

    return (
        <Panel
        panelID={panelID}
        name={'Rapidly Updating Sensors'}
        index={index}
        utils={utils}
        options={{
            ...options,
            buttons: getButtons(),
            loading: loading,
            paging: {
                data: paging,
                limit: limit,
                offset: offset.current,
                onClick: next => {
                    offset.current = next;
                    fetchSensors();
                }
            },
            search: canAutoFetch.current === true && {
                placeholder: 'Search by sensor serial number or comm link serial number...',
                onChange: text => {
                    offset.current = 0;
                    setSearchText(text);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const RapidlyUpdatingSensorHistoryReport = ({ comm_link_serial_number, sensor_type, serial_number }, { index, options, utils }) => {

    const layerID = `rapidly_updating_sensor_history_report_${serial_number}`;
    const sorting = useRef(null);

    const [data, setData] = useState(null);
    const [entries, setEntries] = useState([]);
    const [labels, setLabels] = useState(null);
    const [loading, setLoading] = useState('init');
    const [sensor, setSensor] = useState(null);

    const getEntries = () => {
        return (
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                <table
                className={'px-3 py-2 m-0'}
                style={{
                    width: '100%'
                }}>
                    <thead style={{
                        width: '100%'
                    }}>
                        {getFields()}
                    </thead>
                    <tbody style={{
                        width: '100%'
                    }}>
                        {entries.map(getFields)}
                    </tbody>
                </table>
            </div>
        )
    }

    const getFields = (entry, index) => {
    
        // create mobile entries for mobile devices if applicable
        let target = entry || {};
        if(Utils.isMobile() === true) {
            return (
                Views.entry({
                    bottomBorder: index !== entries.length - 1,
                    hideIcon: true,
                    key: index,
                    subTitle: target.percent && `${target.percent.toFixed(2)}% Over Threshold`,
                    title: target.date && target.date.format('MMMM Do, YYYY [at] h:mma')
                })
            )
        }

        // create desktop and tablet entries if applicable
        let fields = [{
            key: 'date',
            title: 'Date',
            value: target.date && target.date.format('MMMM Do, YYYY [at] h:mma')
        },{
            key: 'max_events_count',
            title: 'Event Threshold',
            value: target.max_events_count
        },{
            key: 'count',
            title: 'Event Count',
            value: target.count
        },{
            key: 'percent',
            title: 'Percent Over Threshold',
            value: target.percent && `${parseInt(target.percent)}%`
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!entry) {
            return (
                <TableListHeader
                fields={fields}
                onChange={next => {
                    sorting.current = next;
                    setLoading(true);
                    fetchHistoryEntries();
                }} />
            )
        }

        return (
            <TableListRow
            key={index}
            values={fields}
            lastItem={index === entries.length - 1} />
        )
    }

    const getGraph = () => {
        if(loading === 'init' || !data) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: 100,
                    width: '100%'
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 40,
                        width: 40
                    }}/>
                </div>
            )
        }
        return (
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                marginBottom: 8,
                padding: 12
            }}>
                <Line
                height={350}
                width={400}
                data={{
                    labels: labels,
                    datasets: [{ 
                        borderColor: Appearance.colors.red,
                        borderWidth: 1.5,
                        data: data,
                        fill: false,
                        pointRadius: 0,
                        pointStyle: 'circle'
                    }]
                }}
                options={{
                    interaction: {
                        mode: 'dataset'
                    },
                    legend: { display: false },
                    maintainAspectRatio: false,
                    responsive: true,
                    title: { display: false },
                    tooltips: {
                        callbacks: {
                            label: evt => {
                                return `${evt.yLabel} verbose ${parseInt(evt.yLabel) === 1 ? 'event' : 'events'}`
                            }
                        }
                    },
                    scales: {
                        xAxes: [{
                            gridLines: {
                                color: Appearance.colors.transparent,
                                display: false
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 10
                            }
                        }],
                        yAxes: [{
                            gridLines: {
                                color: Appearance.colors.transparent,
                                display: false
                            },
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    }
                }} />
            </div>
        )
    }

    const getTitle = () => {
        if(sensor) {
            return `Rapid Update History for "${sensor.location}" ${CommLink.Sensor.types.toText(sensor.type.code)}`;
        }
        return `Rapid Update History for ${serial_number}`;
    }

    const fetchHistoryEntries = async () => {
        try {
            let { entries, sensor } = await Request.get(utils, '/omnishield/', {
                comm_link_serial_number: comm_link_serial_number,
                sensor_type: sensor_type,
                serial_number: serial_number,
                type: 'rapidly_updating_sensor_history',
                ...sorting.current
            });

            // end loading and set sensor object
            setLoading(false);
            setEntries(entries.map(entry => ({
                ...entry,
                date: moment.utc(entry.date).local()
            })));
            setSensor(CommLink.Sensor.create(sensor));

            // prepare dataset and labels for graph
            // data should be sorted by ascending date in case a manual sort order was provided in the query
            let results = entries.map(entry => {
                entry.date = moment.utc(entry.date).local();
                return entry;
            }).sort((a,b) => {
                return a.date.unix() > b.date.unix();
            });
            setData(results.map(entry => entry.count));
            setLabels(results.map(entry => entry.date.format('MM/DD/YYYY h:mma')));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the history for this sensor. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        setTimeout(fetchHistoryEntries, 250);
    }, []);

    return (
        <Layer
        id={layerID}
        index={index}
        title={getTitle()}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            sizing: 'extra_large'
        }}>
            {getGraph()}
            {getEntries()}
        </Layer>
    )
}

export const RegisterCommLink = ({ index, options, utils }) => {

    const layerID = 'register_comm_link';
    const [edits, setEdits] = useState({});
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(null);

    const onRegisterCommLink = async () => {
        try {

            setLoading(true);
            await validateRequiredFields(getFields);
            await Request.post(utils, '/omnishield/', {
                dealership_id: edits.dealership && edits.dealership.id,
                security_key: edits.security_key,
                serial_number: edits.serial_number,
                sold_by_user_id: edits.sold_by_user && edits.sold_by_user.user_id,
                type: 'register_comm_link'
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `This Comm Link has been registered with the "${edits.dealership.name}" dealership`,
                onClick: () => {
                    setLayerState('close');
                    utils.content.fetch('comm_link');
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue registering this comm link. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        setEdits(prev => ({
            ...prev,
            ...props
        }));
    }

    const getButtons = () => {
        return [{
            color: 'default',
            key: 'confirm',
            onClick: onRegisterCommLink,
            text: 'Register'
        }];
    }
    
    const getFields = () => {
        return [{
            key: 'credentials',
            title: 'Credentials',
            items: [{
                component: 'textfield',
                key: 'serial_number',
                onChange: text => onUpdateTarget({ 'serial_number': text }),
                title: 'Serial Number',
                value: edits.serial_number
            },{
                component: 'textfield',
                key: 'security_key',
                onChange: text => onUpdateTarget({ 'security_key': text && text.toUpperCase() }),
                title: 'Security Key',
                value: edits.security_key
            }]
        },{
            key: 'details',
            title: 'Details',
            items: [{
                component: 'dealership_lookup',
                key: 'dealership',
                onChange: dealership => onUpdateTarget({ 'dealership': dealership }),
                required: false,
                title: 'Dealership',
                value: edits.dealership,
                visible: utils.user.get().level < User.levels.get().dealer
            },{
                component: 'user_lookup',
                key: 'sold_by_user',
                onChange: user => onUpdateTarget({ 'sold_by_user': user }),
                props: {
                    dealership: edits.dealership,
                    restrictToDealership: true
                },
                required: false,
                title: 'Sold By',
                value: edits.sold_by_user,
                visible: edits.dealership ? true : false
            }]
        }];
    }

    useEffect(() => {
        setEdits({ dealership: utils.dealership.get() });
    }, []);
    
    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'Register Comm Link'}
        utils={utils}
        options={{
            ...options,
            bottomCard: true,
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            fields={getFields()}
            utils={utils} />
        </Layer>
    )
}

export const RegulatedSensors = ({ index, options, utils }) => {

    const panelID = 'regulated_sensors';
    const limit = 10;

    const canAutoFetch = useRef(false);
    const offset = useRef(null);
    const sorting = useRef(null);

    const [loading, setLoading] = useState(false);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [sensors, setSensors] = useState([]);

    const onSensorClick = async target => {
        try {
            setLoading(true);
            let sensor = await CommLink.Sensor.get(utils, {
                sensor_type: target.sensor_type,
                serial_number: target.serial_number
            });

            setLoading(false);
            utils.layer.open({
                id: `comm_link_sensor_details_${sensor.id}`,
                abstract: Abstract.create({
                    type: 'comm_link_sensor',
                    object: sensor
                }),
                Component: CommLinkSensorDetails
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this sensor. ${e.message || 'An unknown error occurred;'}`
            })
        }
    }

    const getContent = () => {

        // determine if results have been auto fetched
        if(canAutoFetch.current === false) {
            return (
                <div 
                className={'text-button'}
                onClick={() => {
                    canAutoFetch.current = true;
                    fetchSensors();
                }}
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: 12,
                    width: '100%'
                }}>
                    <img 
                    src={window.theme === 'dark' ? 'images/panel-click-white.png' : 'images/panel-click-grey.png'}
                    style={{
                        height: 50,
                        marginBottom: 8,
                        objectFit: 'contain',
                        width: 50
                    }} />
                    <span style={{
                        ...Appearance.textStyles.title(),
                        marginBottom: 2,
                        textAlign: 'center'
                    }}>{'Click to Load Sensors'}</span>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        maxWidth: 250,
                        textAlign: 'center',
                        whiteSpace: 'wrap'
                    }}>{'We automatically hide certain lists to improve the overall performance of Applied Fire Technologies.'}</span>
                </div>
            )
        }
        if(sensors.length === 0) {
            return (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    width: '100%'
                }}>
                    {Views.entry({
                        bottomBorder: false,
                        hideIcon: true,
                        subTitle: 'No regulated sensors were found',
                        title: 'Nothing to see here',
                    })}
                </div>
            )
        }
        return (
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                width: '100%'
            }}>
                <table
                className={'px-3 py-2 m-0'}
                style={{
                    width: '100%'
                }}>
                    <thead style={{
                        width: '100%'
                    }}>
                        {getFields()}
                    </thead>
                    <tbody style={{
                        width: '100%'
                    }}>
                        {sensors.map(getFields)}
                    </tbody>
                </table>
            </div>
        )
    }

    const getFields = (sensor, index) => {
    
        let target = sensor || {};
        let fields = [{
            key: 'comm_link_serial_number',
            title: 'Comm Link Serial Number',
            value: target.comm_link_serial_number
        },{
            key: 'serial_number',
            title: 'Serial Number',
            value: target.serial_number
        },{
            key: 'start_date',
            title: 'Start Date',
            value: target.start_date && Utils.formatDate(target.start_date)
        },{
            key: 'last_date',
            title: 'Last Activation',
            value: target.last_date && Utils.formatDate(target.last_date)
        },{
            key: 'expiration_duration',
            title: 'Time Remaining',
            value: target.expiration_duration && Utils.parseDuration(target.expiration_duration)
        },{
            key: 'sms',
            title: 'SMS',
            value: target.sms
        },{
            key: 'cost',
            title: 'SMS Cost',
            value: Utils.toCurrency(target.cost)
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!sensor) {
            let sorts = {
                [Content.sorting.type.alphabetically]: 'serial_number',
                [Content.sorting.type.ascending]: 'date',
                [Content.sorting.type.descending]: 'date'
            };
            return (
                <TableListHeader
                fields={fields}
                onChange={next => {
                    sorting.current = next;
                    fetchSensors();
                }}
                {...sorting.current && sorting.current.general === true && {
                    value: {
                        direction: sorting.current.sort_type,
                        key: sorts[sorting.current.sort_type]
                    }
                }} />
            )
        }

        return (
            <TableListRow
            lastItem={index === sensors.length - 1}
            key={index}
            onClick={onSensorClick.bind(this, target)} 
            values={fields}/>
        )
    }

    const fetchSensors = async () => {
        try {
            setLoading(true);
            let { paging, sensors } = await Request.get(utils, '/omnishield/', {
                limit: limit,
                offset: offset.current,
                search_text: searchText,
                type: 'regulated_sensors_admin',
                ...sorting.current
            });
    
            setLoading(false);
            setPaging(paging);
            setSensors(sensors);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the sensors list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(canAutoFetch.current === true) {
            fetchSensors();
        }
    }, [searchText]);

    useEffect(() => {

        utils.events.on(panelID, 'dealership_change', fetchSensors);
        utils.content.subscribe(panelID, 'comm_link_sensor', {
            onFetch: fetchSensors,
            onUpdate: fetchSensors
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchSensors);
        }
    }, [])

    return (
        <Panel
        panelID={panelID}
        name={'Regulated Sensors'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            paging: {
                data: paging,
                limit: limit,
                offset: offset.current,
                onClick: next => {
                    offset.current = next;
                    fetchSensors();
                }
            },
            search: canAutoFetch.current === true && {
                placeholder: 'Search by sensor serial number, comm link serial number, or identifier...',
                onChange: text => {
                    offset.current = 0;
                    setSearchText(text);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const ReplaceCommLinkSensor = ({ abstract, index, options, utils }) => {

    const layerID = `replace_comm_link_sensor_${abstract.getID()}`;

    const [edits, setEdits] = useState({});
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(true);
    
    const onSubmit = async () => {
        try {

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue submitting your replacement request. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        setEdits(prev => ({ ...prev, ...props }));
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'submit',
            onClick: onSubmit,
            text: 'Submit'
        }];
    }

    const getFields = () => {

        // prepare deafult list of fields
        let fields = [{
            key: 'details',
            title: 'Details',
            items: [{
                component: 'bool_list',
                key: 'shipping_required',
                onChange: val => onUpdateTarget({ shipping_required: val }),
                title: 'Shipping Required',
                value: edits.shipping_required
            }]
        }];

        // add option to specify replacement comm link if applicable
        if(abstract.object.type.code === CommLink.Sensor.types.get().comm_link) {
            fields = fields.concat([{
                key: 'comm_link',
                title: 'Replacement Comm Link',
                items: [{
                    component: 'textfield',
                    key: 'comm_link_serial_number',
                    onChange: text => onUpdateTarget({ comm_link_serial_number: text }),
                    title: 'Serial Number',
                    value: edits.comm_link_serial_number
                },{
                    component: 'textfield',
                    key: 'comm_link_security_key',
                    onChange: text => onUpdateTarget({ comm_link_security_key: text }),
                    title: 'Security Key',
                    value: edits.comm_link_security_key
                }]
            }])
        }
    }

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Replace Sensor: ${abstract.object.location}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const requestSensorLocation = async (utils, type, evt) => {
    return new Promise(resolve => {

        // declare support for inputting a custom location name
        const onChooseCustomName = () => {
            let location = null;
            utils.alert.show({
                title: 'Custom Location',
                message: 'What name do you want to use for your custom location?',
                content: (
                    <div style={{
                        width: '100%',
                        padding: 12
                    }}>
                        <TextField
                        icon={'name'}
                        placeholder={'Location'}
                        onChange={text => location = text} 
                        fieldStyle={{
                            textAlign: 'center'
                        }} />
                    </div>
                ),
                buttons: [{
                    key: 'confirm',
                    title: 'Done',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Cancel',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(key === 'confirm' && location) {
                        resolve(location);
                        return;
                    }
                    resolve();
                }
            });
        }

        // set flags to determine which pre-defined location names are available
        let { ac_smoke, ac_smoke_co, bed_shaker, co, heat, smoke, smoke_co, water } = CommLink.Sensor.types.get();
        let isBedShaker = type === bed_shaker;
        let isCOSensor = type === co;
        let isHeatSensor = type === heat;
        let isSmokeSensor = [ ac_smoke, ac_smoke_co, smoke, smoke_co ].includes(type);
        let isWaterSensor = type === water;

        // prepare list of pre-defined location names
        let items = [{
            key: 'guest-bedroom',
            title: 'Guest Bedroom',
            visible: isBedShaker
        },{
            key: 'master-bedroom',
            title: 'Master Bedroom',
            visible: isBedShaker
        },{
            key: 'basement',
            title: 'Basement',
            visible: isCOSensor
        },{
            key: 'downstairs-hallway',
            title: 'Downstairs Hallway',
            visible: isCOSensor
        },{
            key: 'kitchen',
            title: 'Kitchen',
            visible: isCOSensor
        },{
            key: 'living-room',
            title: 'Living Room',
            visible: isCOSensor
        },{
            key: 'upstairs-hallway',
            title: 'Upstairs Hallway',
            visible: isCOSensor
        },{
            key: 'attic',
            title: 'Attic',
            visible: isHeatSensor
        },{
            key: 'basement',
            title: 'Basement',
            visible: isHeatSensor
        },{
            key: 'crawl-space',
            title: 'Crawl Space',
            visible: isHeatSensor
        },{
            key: 'garage',
            title: 'Garage',
            visible: isHeatSensor
        },{
            key: 'kitchen',
            title: 'Kitchen',
            visible: isHeatSensor
        },{
            key: 'living-room',
            title: 'Living Room',
            visible: isHeatSensor
        },{
            key: 'basement',
            title: 'Basement',
            visible: isSmokeSensor
        },{
            key: 'hallway',
            title: 'Hallway',
            visible: isSmokeSensor
        },{
            key: 'family-room',
            title: 'Family Room',
            visible: isSmokeSensor
        },{
            key: 'guest-bedroom',
            title: 'Guest Bedroom',
            visible: isSmokeSensor
        },{
            key: 'living-room',
            title: 'Living Room',
            visible: isSmokeSensor
        },{
            key: 'master-bedroom',
            title: 'Master Bedroom',
            visible: isSmokeSensor
        },{
            key: 'office',
            title: 'Office',
            visible: isSmokeSensor
        },{
            key: 'ac-drain-pan',
            title: 'A/C Drain Pan',
            visible: isWaterSensor
        },{
            key: 'basement',
            title: 'Basement',
            visible: isWaterSensor
        },{
            key: 'guest-bath',
            title: 'Guest Bath',
            visible: isWaterSensor
        },{
            key: 'kitchen',
            title: 'Kitchen',
            visible: isWaterSensor
        },{
            key: 'laundry-room',
            title: 'Laundry Room',
            visible: isWaterSensor
        },{
            key: 'master-bath',
            title: 'Master Bath',
            visible: isWaterSensor
        },{
            key: 'water-heater',
            title: 'Water Heater',
            visible: isWaterSensor
        }].filter(entry => {
            return entry.visible === true;
        }).sort((a,b) => {
            return a.title.localeCompare(b.title)
        }).concat([{
            key: 'custom',
            title: 'Custom Location'
        }]).map(item => ({
            ...item,
            style: 'default'
        }));

        // present pre-defined location options
        utils.sheet.show({
            title: 'Change Sensor Name',
            message: `What would you like to call this ${CommLink.Sensor.types.toText(type)}?`,
            items: items,
            target: evt && evt.target
        }, key => {
            if(key === 'custom') {
                onChooseCustomName();
                return;
            }
            if(key !== 'cancel') {
                let match = items.find(item => item.key === key);
                resolve(match && match.title);
                return;
            }
            resolve();
        });
    })
}

export const SensorEventComponent = ({ collapsible = true, evt, sensor, temperaturePref }) => {

    const [active, setActive] = useState(collapsible === false);

    const onCollapseChange = () => {
        setActive(val => !val);
    }

    return (
        <div style={{
            ...Appearance.styles.unstyledPanel(),
            marginBottom: 8,
            overflow: 'hidden'
        }}>
            {Views.entry({
                bottomBorder: false,
                hideOnClickIcon: true,
                icon: { path: getSensorIcon(sensor, evt) },
                onClick: collapsible ? onCollapseChange : null,
                subTitle: Utils.formatDate(evt.date),
                title: evt.text,
                rightContent: (
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        paddingRight: 12
                    }}>
                        {evt.temperature > 0 && (
                            <SensorVerboseIcon
                            temperaturePref={temperaturePref}
                            type={'temperature'}
                            value={evt.temperature} 
                            style={{
                                marginLeft: 8 
                            }} />
                        )}
                        {evt.battery_level > 0 && (
                            <SensorVerboseIcon
                            type={'battery'}
                            value={evt.battery_level} 
                            style={{
                                marginLeft: 8 
                            }} />
                        )}
                        
                        {collapsible && evt.items && evt.items.length > 0 && (
                            <CollapseArrow 
                            collapsed={active ? false : true}
                            style={{
                                marginLeft: 8
                            }}/>
                        )}
                    </div>
                )
            })}
            {(active || collapsible === false) && evt.items && evt.items.map((item, index) => {
                return (
                    <div 
                    key={index}
                    style={{
                        borderTop: `1px solid ${Appearance.colors.divider()}`,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        padding: '8px 12px 8px 12px'
                    }}>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            color: Appearance.colors.text()
                        }}>{item.key}</span>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            color: Appearance.colors.text()
                        }}>{item.value}</span>
                    </div>
                )
            })}
        </div>
    )
}

export const SensorVerboseIcon = ({ style, temperaturePref = 'F', type, value }) => {

    const isDarkMode = window.theme === 'dark';

    const getBatteryImageSource = () => {
        if(value === null || value === undefined) {
            return isDarkMode ? 'images/battery-unavailable-dark.png' : 'images/battery-unavailable-light.png';
        }
        if(value < 33) {
            return isDarkMode ? 'images/battery-low-dark.png' : 'images/battery-low-light.png';
        }
        if(value < 66) {
            return isDarkMode ? 'images/battery-mid-dark.png' : 'images/battery-mid-light.png';
        }
        return isDarkMode ? 'images/battery-full-dark.png' : 'images/battery-full-light.png';
    }

    const getContent = () => {

        // loop through types and determine which components to render
        switch(type) {
            case 'battery':
            return (
                <img
                src={getBatteryImageSource()}
                style={{
                    height: 20,
                    marginLeft: 8,
                    objectFit: 'contain',
                    width: 28,
                    ...style
                }} />
            )

            case 'temperature':
            return (
                <div style={{
                    alignItems: 'center',
                    backgroundColor: getTemperatureColor(value),
                    borderRadius: 5,
                    display: 'flex',
                    justifyContent: 'center',
                    minWidth: 50,
                    padding: '1px 4px 1px 4px',
                    ...style
                }}>
                    <span style={{
                        ...Appearance.textStyles.title(),
                        color: 'white'
                    }}>{`${value}°${temperaturePref}`}</span>
                </div>
            )

            default:
            return null;
        }
    }
    return getContent();
}

export const Sensors = ({ index, options, utils }) => {

    const panelID = 'sensors';
    const limit = 10;
    const offset = useRef(0);
    const sorting = useRef(0);

    const [loading, setLoading] = useState(false);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [sensors, setSensors] = useState([]);

    const onPrintCommLinks = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let { comm_links } = await Request.get(utils, '/omnishield/', {
                    type: 'comm_links_admin',
                    search_text: searchText,
                    ...sorting.current,
                    ...props
                });

                setLoading(false);
                resolve(comm_links.map(comm_link => CommLink.create(comm_link)));

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        })
    }

    const onSensorClick = sensor => {
        utils.layer.open({
            id: `comm_link_sensor_details_${sensor.id}`,
            abstract: Abstract.create({
                type: 'comm_link_sensor',
                object: sensor
            }),
            Component: CommLinkSensorDetails
        })
    }

    const getContent = () => {
        if(sensors.length === 0) {
            return (
                Views.entry({
                    title: 'Nothing to see here',
                    subTitle: 'No sensors were found for your dealership',
                    bottomBorder: false,
                    hideIcon: true
                })
            )
        }
        if(Utils.isMobile()) {
            return sensors.map(getFields);
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {sensors.map(getFields)}
                </tbody>
            </table>
        )
    }

    const getFields = (sensor, index) => {
    
        // create mobile entries for mobile devices if applicable
        let target = sensor || {};
        if(Utils.isMobile() === true) {
            return (
                Views.entry({
                    key: index,
                    title: target.location || 'Unnamed Location',
                    subTitle: sensor && sensor.get('date') ? `Updated ${Utils.formatDuration(sensor.get('date'))}` : 'Waiting for update...',
                    hideIcon: true,
                    firstItem: index === 0,
                    singleItem: sensors.length === 1,
                    lastItem: index === sensors.length - 1,
                    bottomBorder: index !== sensors.length - 1,
                    onClick: onSensorClick.bind(this, target)
                })
            )
        }

        // create desktop and tablet entries if applicable
        let fields = [{
            key: 'location',
            title: 'Location',
            value: target.location || 'Unnamed Location'
        },{
            key: 'serial_number',
            title: 'Serial Number',
            value: target.serial_number
        },{
            key: 'comm_link_serial_number',
            title: 'Comm Link Serial Number',
            value: target.comm_link_serial_number
        },{
            key: 'date',
            title: 'Added to Network',
            value: target.date && Utils.formatDuration(target.date)
        },{
            key: 'verbose.date',
            sortable: false,
            title: 'Updated',
            value: sensor && sensor.get('date') ? Utils.formatDuration(sensor.get('date')) : 'Waiting for update...'
        },{
            key: 'verbose.last_activation_date',
            sortable: false,
            title: 'Last Activation',
            value: sensor && sensor.get('last_activation_date') ? Utils.formatDuration(sensor.get('last_activation_date')) : 'No Recent Activations'
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!sensor) {
            let sorts = {
                [Content.sorting.type.alphabetically]: 'location',
                [Content.sorting.type.ascending]: 'date',
                [Content.sorting.type.descending]: 'date'
            };
            return (
                <TableListHeader
                fields={fields}
                onChange={next => {
                    sorting.current = next;
                    setLoading(true);
                    fetchSensors();
                }}
                {...sorting.current && sorting.current.general === true && {
                    value: {
                        direction: sorting.current.sort_type,
                        key: sorts[sorting.current.sort_type]
                    }
                }} />
            )
        }

        return (
            <TableListRow
            key={index}
            values={fields}
            lastItem={index === sensors.length - 1}
            onClick={onSensorClick.bind(this, target)} />
        )
    }

    const fetchSensors = async () => {
        try {
            setLoading(true);
            let { paging, sensors } = await Request.get(utils, '/omnishield/', {
                limit: limit,
                offset: offset.current,
                search_text: searchText,
                type: 'sensors_admin',
                ...sorting.current
            });

            setLoading(false);
            setPaging(paging);
            setSensors(sensors.map(sensor => CommLink.Sensor.create(sensor)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the sensors list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchSensors();
    }, [searchText]);

    useEffect(() => {

        utils.events.on(panelID, 'dealership_change', fetchSensors);
        utils.content.subscribe(panelID, 'comm_link_sensor', {
            onFetch: fetchSensors,
            onUpdate: fetchSensors
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchSensors);
        }
    }, [])

    return (
        <Panel
        panelID={panelID}
        name={'Sensors'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            paging: {
                data: paging,
                limit: limit,
                offset: offset.current,
                onClick: next => {
                    offset.current = next;
                    setLoading('paging');
                    fetchSensors();
                }
            },
            search: {
                placeholder: 'Search by sensor location, serial number, or comm link serial number...',
                onChange: text => {
                    offset.current = 0;
                    setSearchText(text);
                }
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                width: '100%'
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const SensorsOverview = ({ index, options, utils }) => {

    const panelID = 'sensors_overview';
    const limit = 10;
    const offset = useRef(0);
    const sorting = useRef({ sort_key: 'event_date', sort_type: Content.sorting.type.descending });

    const [loading, setLoading] = useState(false);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [sensors, setSensors] = useState([]);
    const [statusFilter, setStatusFilter] = useState(null);
    
    const onSensorClick = sensor => {
        utils.layer.open({
            abstract: Abstract.create({
                type: 'comm_link_sensor',
                object: sensor
            }),
            Component: CommLinkSensorDetails,
            id: `comm_link_sensor_details_${sensor.id}`
        })
    }

    const onSensorStatusChange = evt => {
        console.log(evt);
    }

    const getContent = () => {
        if(sensors.length === 0) {
            return (
                Views.entry({
                    title: 'Nothing to see here',
                    subTitle: 'No rapidly updating sensors were found',
                    bottomBorder: false,
                    hideIcon: true
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {sensors.map(getFields)}
                </tbody>
            </table>
        )
    }

    const getFields = (result, index) => {
        
        let target = result || {};
        let fields = [{
            key: 'date',
            title: 'Bind Date',
            value: target.sensor && target.sensor.date && Utils.formatDate(target.sensor.date)
        },{
            key: 'event_date',
            title: 'Status Change Date',
            value: target.event && target.event.date && Utils.formatDate(target.event.date)
        },{
            key: 'id',
            title: 'Sensor ID',
            value: target.sensor && target.sensor.id
        },{
            key: 'location',
            title: 'Location',
            value: target.sensor && target.sensor.location
        },{
            key: 'serial_number',
            title: 'Serial Number',
            value: target.sensor && target.sensor.serial_number
        },{
            key: 'sensor_type',
            title: 'Sensor Type',
            value: target.sensor && target.sensor.type ? `${CommLink.Sensor.types.toText(target.sensor.type.code)} (${target.sensor.type.code})` : 'Unavailable'
        },{
            key: 'status',
            title: 'status',
            value: target.sensor && (
                <AltBadge content={target.sensor.status} />
            )
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!result) {
            return (
                <TableListHeader
                fields={fields}
                onChange={next => {
                    sorting.current = next;
                    fetchSensors();
                }}
                value={sorting.current && {
                    direction: sorting.current.sort_type,
                    key: sorting.current.sort_key
                }}/>
            )
        }

        return (
            <TableListRow
            key={index}
            lastItem={index === sensors.length - 1}
            onClick={onSensorClick.bind(this, target && target.sensor)} 
            values={fields}/>
        )
    }

    const getStatusFilter = () => {

        // prepare list of status options
        let items = Object.values(CommLink.Sensor.status.get()).filter(code => {
            return code !== CommLink.Sensor.status.get().in_service;
        }).map(code => ({
            id: code,
            title: CommLink.Sensor.status.toText(code)
        })).sort((a,b) => {
            return a.title.localeCompare(b.title);
        });

        return (
            <ListField 
            items={items}
            onChange={item => setStatusFilter(item && item.id)} 
            placeholder={'Choose a status from the list below'} 
            style={{
                marginLeft: 8,
                width: 300
            }}/>
        )
    }

    const connectToSockets = async () => {
        try {

            // join status change room for each sensor
            sensors.forEach(async sensor => {
                try {
                    await utils.sockets.emit('omnishield', 'sensors', 'join', { id: sensor.id });
                } catch(e) {
                    console.error(e.message);
                }
            });

            // add event listener for status changes
            await utils.sockets.on('omnishield', 'sensors', 'status_change', onSensorStatusChange);

        } catch(e) {
            console.error(e.message);
        }
    }

    const disconnectFromSockets = async () => {
        try {
            
            // leave status change room for each sensor
            sensors.forEach(async sensor => {
                try {
                    await utils.sockets.emit('omnishield', 'sensors', 'leave', { id: sensor.id });
                } catch(e) {
                    console.error(e.message);
                }
            });

            // remove event listener for status changes
            await utils.sockets.off('omnishield', 'sensors', 'status_change', onSensorStatusChange);

        } catch(e) {
            console.error(e.message);
        }
    }

    const fetchSensors = async () => {
        try {
            setLoading(true);
            let { paging, sensors } = await Request.get(utils, '/omnishield/', {
                limit: limit,
                offset: offset.current,
                search_text: searchText,
                sorting: sorting.current,
                status: statusFilter,
                type: 'sensors_overview'
            });

            setLoading(false);
            setPaging(paging);
            setSensors(sensors.map(result => ({
                sensor: CommLink.Sensor.create(result.sensor),
                event: result.event && {
                    date: result.event.date && moment.utc(result.event.date).local()
                }
            })));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the sensors overview list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        connectToSockets();
        return disconnectFromSockets;
    }, [sensors]);

    useEffect(() => {
        fetchSensors();
    }, [searchText, statusFilter]);

    useEffect(() => {
        utils.content.subscribe(panelID, ['comm_link_sensor'], {
            onFetch: fetchSensors,
            onUpdate: abstract => {
                setSensors(sensors => {
                    return sensors.map(sensor => {
                        return sensor.id === abstract.getID() ? abstract.object : sensor;
                    });
                });
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        index={index}
        name={'Sensors Overview'}
        panelID={panelID}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            paging: {
                data: paging,
                limit: limit,
                offset: offset.current,
                onClick: next => {
                    setLoading(true);
                    offset.current = next;
                    fetchSensors();
                }
            },
            search: {
                onChange: text => {
                    offset.current = 0;
                    setSearchText(text);
                },
                placeholder: 'Search by sensor serial number...',
                rightContent: getStatusFilter()
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                width: '100%'
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const SmsEvents = ({ abstract, index, options, utils }) => {

    const layerID = 'sms_events';
    const limit = 10;

    const [active, setActive] = useState(null);
    const [events, setEvents] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(true);
    const offset = useRef(0);
    const [paging, setPaging] = useState(null);
    const [temperaturePref, setTemperaturePref] = useState('F');

    const onCollapseChange = index => {
        setActive(val => val === index ? null : index);
    }

    const fetchSmsEntries = async () => {
        try {
            setLoading(true);
            let { events, paging } = await Request.get(utils, '/omnishield/', {
                id: abstract.getID(),
                limit: limit,
                offset: offset.current,
                temperature_units: temperaturePref,
                type: 'sensor_sms_events'
            });

            // close layer if no events are found
            if(events.length === 0) {
                utils.alert.show({
                    title: 'No Activity Found',
                    message: 'We were unable to locate any recent sms activity for this sensor. Please check back later.',
                    onClick: setLayerState.bind(this, 'close')
                });
                return;
            }

            // end loading, reset active open drop-down content, and format events
            setLoading(false);
            setActive(null);
            setEvents(events.map(evt => ({ ...evt, date: moment.utc(evt.date).local() })));
            setPaging(paging);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the recent sms activity list for this sensor. ${e.message || 'An unknown error occurred'}`,
                onClick: setLayerState.bind(this, 'close')
            });
        }
    }

    useEffect(() => {
        if(temperaturePref) {
            fetchSmsEntries();
        }
    }, [temperaturePref]);

    return (
        <Layer
        id={layerID}
        index={index}
        title={'Text Messaging Activity'}
        utils={utils}
        options={{
            ...options,
            bottomCard: true,
            layerState: layerState,
            loading: loading,
            sizing: 'small'
        }}>
            {events.map((evt, index) => {
                return (
                    <div 
                    key={index}
                    style={{
                        ...Appearance.styles.unstyledPanel(),
                        marginBottom: 8,
                        overflow: 'hidden'
                    }}>
                        {Views.entry({
                            bottomBorder: false,
                            hideOnClickIcon: true,
                            icon: { path: evt.icon },
                            onClick: evt.items.length > 0 ? onCollapseChange.bind(this, index) : null,
                            subTitle: Utils.formatDate(evt.date),
                            title: evt.title,
                            rightContent: evt.items.length > 0 && (
                                <div style={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    paddingRight: 12
                                }}>
                                    <CollapseArrow 
                                    collapsed={active === index ? false : true}
                                    style={{
                                        marginLeft: 8
                                    }}/>
                                </div>
                            )
                        })}
                        {active === index && evt.items.map((item, index) => {
                            return (
                                <div 
                                key={index}
                                style={{
                                    borderTop: `1px solid ${Appearance.colors.divider()}`,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    padding: '8px 12px 8px 12px'
                                }}>
                                    <span style={{
                                        ...Appearance.textStyles.subTitle(),
                                        color: Appearance.colors.text()
                                    }}>{item.key}</span>
                                    <span style={{
                                        ...Appearance.textStyles.subTitle(),
                                        color: Appearance.colors.text()
                                    }}>{item.value}</span>
                                </div>
                            )
                        })}
                    </div>
                )
            })}
            {paging && (
                <PageControl
                data={paging}
                limit={limit}
                loading={loading}
                offset={offset.current}
                onClick={next => {
                    offset.current = next;
                    fetchSmsEntries();
                }} />
            )}
        </Layer>
    )
}

export const VerboseEvents = ({ abstract, index, options, utils }) => {

    const layerID = 'verbose_events';
    const limit = 25;

    const download = useRef(false);
    const offset = useRef(0);
    const sorting = useRef({ sort_key: 'date', sort_type: Content.sorting.type.descending });

    const [events, setEvents] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(true);
    const [paging, setPaging] = useState(null);
    const [temperaturePref, setTemperaturePref] = useState('F');

    const onDownloadClick = () => {
        download.current = true;
        fetchVerboseEntries();
    }

    const onSortingChange = val => {
        sorting.current = val;
        setLoading(true);
        fetchVerboseEntries();
    }

    const getEvents = () => {
        return events.length > 0 && (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    <TableListHeader
                    fields={getHeaders()}
                    onChange={onSortingChange} 
                    value={{
                        direction: sorting.current.sort_type,
                        key: sorting.current.sort_key
                    }} />
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {events.map(getFields)}
                </tbody>
            </table>
        )
    }

    const getFields = (evt, index) => {
    
        // prepare target and default fields
        let fields = [{
            key: 'date',
            title: 'Date',
            value: evt.date && Utils.formatDate(evt.date)
        },{
            key: 'text',
            title: 'Event Type',
            value: evt.text
        },{
            key: 'battery_level',
            title: 'Battery Level',
            value: evt.battery_level >= 0 ? `${evt.battery_level}%` : 'Unknown'
        }];

        // add temperature field for all sensors except for bed shakers
        if(abstract.object.type.code !== CommLink.Sensor.types.get().bed_shaker) {
            fields.push({
                key: 'temperature',
                title: 'Temperature',
                value: evt.temperature >= 0 ? `${evt.temperature}°${temperaturePref}` : 'Unknown'
            });
        }

        // add items to list of field entries if applicable
        if(evt.items && evt.items.length > 0) {
            evt.items.forEach((item, index) => {
                fields.push({
                    key: index,
                    title: item.key,
                    value: item.value || 'Unknown'
                })
            });
        }
        return (
            <TableListRow
            key={index}
            lastItem={index === events.length - 1}
            values={fields}/>
        )
    }

    const getHeaders = () => {

        // prepare default array of columns
        let columns = [{
            key: 'date',
            title: 'Date'
        },{
            key: 'text',
            title: 'Event Type'
        },{
            key: 'battery_level',
            title: 'Battery Level'
        }];

        // add temperature column for all sensors except for bed shakers
        if(abstract.object.type.code !== CommLink.Sensor.types.get().bed_shaker) {
            columns.push({
                key: 'temperature',
                title: 'Temperature'
            });
        }

        // prepare list of sensor specific headers
        let target = events.find(evt => evt.items && evt.items.length > 0);
        (target?.items || []).forEach(entry => {
            columns.push({
                key: entry.key.toLowerCase().replace(' ', '_'),
                title: entry.key
            });
        });
        
        return columns;
    }
    
    const fetchVerboseEntries = async () => {
        try {
            let { events, paging, url } = await Request.get(utils, '/omnishield/', {
                download: download.current,
                id: abstract.getID(),
                limit: limit,
                offset: offset.current,
                sorting: sorting.current,
                temperature_units: temperaturePref,
                type: 'sensor_verbose_events'
            });

            // determine if a file download was requested
            setLoading(false);
            if(download.current === true) {
                download.current = false;
                window.open(url);
                return;
            }

            // close layer if no events are found
            if(events.length === 0) {
                utils.alert.show({
                    title: 'No Activity Found',
                    message: 'We were unable to locate any recent activity for this sensor. Please check back later.',
                    onClick: setLayerState.bind(this, 'close')
                });
                return;
            }

            // end loading, reset active open drop-down content, and format events
            setEvents(events.map(evt => ({ ...evt, date: moment.utc(evt.date).local() })));
            setPaging(paging);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the recent activity list for this sensor. ${e.message || 'An unknown error occurred'}`,
                onClick: setLayerState.bind(this, 'close')
            });
        }
    }

    useEffect(() => {
        if(temperaturePref) {
            fetchVerboseEntries();
        }
    }, [temperaturePref]);

    return (
        <Layer
        id={layerID}
        index={index}
        title={'Recent Activity'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            rightButton: {
                color: Appearance.colors.primary(),
                onClick: onDownloadClick,
                style: { width: 95 },
                text: 'Download'
            },
            sizing: 'extra_large'
        }}>
            {getEvents()}
            {paging && (
                <PageControl
                data={paging}
                limit={limit}
                loading={loading === 'paging'}
                offset={offset.current}
                onClick={next => {
                    offset.current = next;
                    setLoading('paging');
                    fetchVerboseEntries();
                }} />
            )}
        </Layer>
    )
}