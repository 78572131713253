import React, { useRef, useState, useEffect } from 'react';

import { animated, useSpring } from '@react-spring/web';
import moment from 'moment';

import { AltBadge } from 'views/Main.js';
import Appearance from 'styles/Appearance.js';
import Content from 'managers/Content.js';
import { CalloutIndex } from 'structure/Layer.js';
import PageControl from 'views/PageControl.js';
import PrintContent from 'views/PrintContent.js';
import ProgressBar from 'views/ProgressBar.js';
import TextField from 'views/TextField.js';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';

const Panel = ({ children, className, column, headerStyle, index, id, name, options = {}, style, utils }) => {

    const { buttons, callout, download, embeded = false, filters, loading, maxWidth, onSizeChange, paging, print, removeOverflow, removePadding, rightContent, search, subTitle, onVisibilityChange } = options || {};

    const [animations, setAnimations] = useSpring(() => ({
        top: embeded ? 0 : -50,
        opacity: embeded ? 1 : 0,
        config: { mass: 1, tension: 180, friction: 12 }
    }));
    const contentContainer = useRef(null);
    const [filtersExpanded, setFiltersExpanded] = useState(false);
    const [restrictToDealership, setRestrictToDealership] = useState(utils.dealership.restricted.get());
    const [showCallout, setShowCallout] = useState(false);
    const [sortExpanded, setSortExpanded] = useState(false);
    const [sortItem, setSortItem] = useState(Content.sorting.type.descending);
    const [updated, setUpdated] = useState(moment());

    const onBodyClick = evt => {
        if(evt.target.className.toString().includes('persist-callout')) {
            evt.preventDefault();
            return;
        }
        setShowCallout(false);
    }

    const onChangeSortOrder = key => {
        setSortItem(key);
        setSortExpanded(false);
        let { onChange } = search.sorting;
        if(typeof(onChange) === 'function') {
            onChange({
                sort_type: key,
                general: true
            });
        }
    }

    const onDownloadClick = async () => {
        try {
            let url = await download();
            window.open(url);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing your downloaded content. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onFiltersClick = callback => {
        setFiltersExpanded(expanded => !expanded);
        if(typeof(callback) === 'function') {
            callback();
        }
    }

    const onFiltersExpandChange = () => {
        document.body.style.overflowY = filtersExpanded ? 'hidden' : 'scroll';
    }

    const onGraciSupportContentClick = (items, evt) => {

        // automatically open the first entry if only one entry was provided
        if(items.length === 1) {
            window.open(`https://homesafealerts.com/dealers/resources/content/${items[0]}`);
            return;
        }

        // show list items with options for each support entry
        utils.sheet.show({
            items: items.map(item => ({
                key: item.id,
                style: 'default',
                title: item.title
            })),
            position: 'top',
            target: evt.target
        }, key => {
            if(key !== 'cancel') {
                window.open(`https://homesafealerts.com/dealers/resources/content/${key}`);
                return;
            }
        });
    }

    const onPanelSizeChange = () => {
        onSizeChange({
            height: contentContainer.current.clientHeight,
            width: contentContainer.current.clientWidth
        })
    }

    const onPrintClick = () => {
        utils.layer.open({
            id: 'print_content',
            Component: PrintContent.bind(this, {
                ...print,
                title: name
            })
        })
    }

    const onSortClick = () => {
        setSortExpanded(expanded => !expanded);
    }

    const onSortExpandChange = () => {
        document.body.style.overflowY = sortExpanded ? 'hidden' : 'scroll';
    }

    const onVisibilityValueChange = ({ detail }) => {
        setRestrictToDealership(detail);
        if(typeof(onVisibilityChange) === 'function') {
            onVisibilityChange(detail);
        }
    }

    const onVisibilityValueChangeSilent = ({ detail }) => {
        // this is needed to prevent double state changes
        // we use this function when the active dealership is also changing
        // the active dealership change triggers the state update for listeners
        // we update the state in the panel class to keep the button text in-sync
        // visibility value is pulled by the request class from a root ref
        setRestrictToDealership(detail);
    }

    const getButtons = () => {

        // prepare array for requested buttons
        let targets = buttons ? [...buttons] : [];

        // provide option for admin or director to view data outside of their dealership if applicable
        if(typeof(onVisibilityChange) === 'function' && utils.user.get().level < User.levels.get().dealer) {
            targets.push({
                key: 'restrict_to_dealership',
                title: restrictToDealership ? 'Show All Dealerships' : 'Show My Dealership',
                style: 'secondary',
                onClick: () => utils.dealership.restricted.set(!restrictToDealership)
            });
        }

        // determine if a download button was requested
        if(download) {
            targets.push({
                key: 'download',
                title: 'Download',
                style: 'default',
                onClick: onDownloadClick
            });
        }

        // determine if a print button was requested
        if(print) {
            targets.push({
                key: 'print',
                title: 'Print',
                style: 'default',
                onClick: onPrintClick
            });
        }

        // filter out buttons that have been marked as non-visible
        targets = targets.filter(target => target.visible !== false);
        
        // no additional logic is needed if no buttons were found
        if(targets.length === 0) {
            return null;
        }

        // fallback to formatting buttons for desktop and tablet
        return (
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row'
            }}>
                {targets.map((button, index, buttons) => {

                    // prepare values for button component
                    let target = { ...button };
                    if(button.key === 'graci_support_content') {
                        target.color = 'grey';
                        target.onClick = onGraciSupportContentClick.bind(this, button.items);
                        target.title = 'Get Support';
                    }

                    // prepare button color using requested style
                    let color = Appearance.colors.grey();
                    switch(target.style) {
                        case 'default':
                        color = Appearance.colors.primary();
                        break;

                        case 'secondary':
                        color = Appearance.colors.secondary();
                        break;

                        case 'destructive':
                        color = Appearance.colors.red;
                        break;
                    }

                    return (
                        <AltBadge
                        content={{
                            text: target.title,
                            color: color
                        }}
                        key={index}
                        onClick={target.onClick}
                        style={{
                            marginRight: index !== buttons.length - 1 || target.lastItem === false ? 8 : 0,
                            padding: '5px 16px 5px 16px'
                        }} />
                    )
                })}
                {rightContent}
            </div>
        )
    }

    const getFilterComponents = filters => {
        return (
            <div style={{
                position: 'relative'
            }}>
                <div
                className={'text-button'}
                onClick={onFiltersClick.bind(this, filters.onVisibilityChange)}
                style={{
                    alignItems: 'center',
                    backgroundColor: Appearance.colors.primary(),
                    borderRadius: 15,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 30,
                    justifyContent: 'center',
                    marginLeft: 8,
                    minHeight: 30,
                    minWidth: 30,
                    overflow: 'hidden',
                    padding: 7.5,
                    width: 30
                }}>
                    <img
                    src={'images/filter-icon-white.png'}
                    style={{
                        height: '100%',
                        objectFit: 'contain',
                        width: '100%'
                    }} />
                </div>
                {filtersExpanded && (
                    <div
                    onMouseEnter={() => document.body.style.overflowY = 'hidden'}
                    onMouseLeave={() => document.body.style.overflowY = 'scroll'}
                    style={{
                        ...Appearance.styles.panel(),
                        backgroundColor: window.theme === 'dark' ? 'rgba(100,100,100,1)' : 'white',
                        borderColor: Appearance.colors.softBorder(),
                        borderWidth: 2,
                        boxShadow: window.theme === 'dark' ? '5px 5px 15px rgba(0,0,0,0.25)' : '5px 5px 15px rgba(175,175,174,0.25)',
                        maxHeight: window.innerHeight / 2,
                        overflowY: 'scroll',
                        position: 'absolute',
                        right: -5,
                        top: 32,
                        width: 350,
                        zIndex: CalloutIndex
                    }}>
                        {filters.content}
                    </div>
                )}
            </div>
        )
    }

    const getUpdatedDate = () => {
        let date = moment(updated);
        if(date.isSame(moment(), 'day')) {
            return date.format('[Today at] h:mma');
        }
        if(date.isSame(moment().subtract(1, 'days'), 'day')) {
            return date.format('[Yesterday at] h:mma');
        }
        return date.format('MMMM Do [at] h:mma');
    }

    const getSearchFields = () => {

        if(!search || !utils) {
            return null;
        }
        
        let { leftContent, onChange, placeholder, rightContent } = search;
        return (
            <div style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`,
                display: 'flex',
                flexDirection: 'column',
                padding: 15,
                width: '100%'
            }}>
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%'
                }}>
                    {leftContent}
                    {typeof(onChange) === 'function' && (
                        <>
                        <TextField
                        icon={'search'}
                        loading={search.loading}
                        useDelay={true}
                        placeholder={placeholder || 'Search for something...'}
                        onChange={onChange}
                        containerStyle={{
                            flexGrow: 1
                        }} />
                        {getSearchAppendContent()}
                        </>
                    )}
                    {rightContent}
                </div>
            </div>
        )
    }

    const getSearchAppendContent = () => {

        let { filters, sorting } = search;
        if(!filters && !sorting){
            return null;
        }
        let items = [{
            key: Content.sorting.type.alphabetically,
            title: 'Sort Alphabetically',
            icon: 'sort-alphabetical'
        },{
            key: Content.sorting.type.descending,
            title: 'Order from Newest to Oldest',
            icon: 'sort-descending'
        },{
            key: Content.sorting.type.ascending,
            title: 'Order from Oldest to Newest',
            icon: 'sort-ascending'
        }];

        let sortMatch = items.find(item => item.key === sortItem);
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: 8
            }}>
                {filters && getFilterComponents(filters)}
                {sorting && (
                    <div style={{
                        position: 'relative'
                    }}>
                        <div
                        className={'text-button'}
                        onClick={onSortClick}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 30,
                            height: 30,
                            minWidth: 30,
                            minHeight: 30,
                            padding: 7.5,
                            borderRadius: 15,
                            overflow: 'hidden',
                            marginLeft: 8,
                            backgroundColor: Appearance.colors.primary()
                        }}>
                            <img
                            src={sortMatch ? `images/${sortMatch.icon}-white.png` : null}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain'
                            }} />
                        </div>
                        {sortExpanded && (
                            <div
                            onMouseEnter={() => document.body.style.overflowY = 'hidden'}
                            onMouseLeave={() => document.body.style.overflowY = 'scroll'}
                            style={{
                                ...Appearance.styles.panel(),
                                position: 'absolute',
                                right: -5,
                                top: 32,
                                width: 350,
                                overflowY: 'scroll',
                                borderWidth: 2,
                                borderColor: Appearance.colors.softBorder(),
                                backgroundColor: window.theme === 'dark' ? 'rgba(100,100,100,1)' : 'white',
                                boxShadow: window.theme === 'dark' ? '5px 5px 15px rgba(0,0,0,0.25)' : '5px 5px 15px rgba(175,175,174,0.25)',
                                maxHeight: window.innerHeight / 2,
                                zIndex: CalloutIndex
                            }}>
                                {items.filter(item => {
                                    return item.visible !== false;
                                }).map((item, index) => {
                                    return (
                                        <div
                                        key={index}
                                        className={`view-entry ${window.theme}`}
                                        onClick={onChangeSortOrder.bind(this, item.key)}
                                        style={{
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            width: '100%',
                                            padding: 12,
                                            borderBottom: `${index !== items.length - 1 ? 1 : 0}px solid ${Appearance.colors.divider()}`
                                        }}>
                                            <img
                                            src={`images/${item.icon}-${item.key === sortItem ? 'blue' : 'grey'}.png`}
                                            style={{
                                                minWidth: 18,
                                                minHeight: 18,
                                                width: 18,
                                                height: 18,
                                                objectFit: 'contain',
                                                marginRight: 12
                                            }} />
                                            <span style={{
                                                ...Appearance.textStyles.title(),
                                                color: item.key === sortItem ? Appearance.colors.primary() : Appearance.colors.text()
                                            }}>{item.title}</span>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                )}
            </div>
        )
    }

    const runAnimations = async () => {
        try {
            await Utils.sleep(0.2 * index);
            setAnimations({
                top: 0,
                opacity: 1
            })
        } catch(e) {
            console.log(e.message);
        }
    }

    useEffect(() => {
        let elements = document.getElementsByClassName('panel');
        Array.from(elements).forEach(el => {
            if(showCallout) {
                el.addEventListener('click', onBodyClick);
                return;
            }
            el.removeEventListener('click', onBodyClick)
        })

    }, [showCallout]);

    useEffect(() => {
        if(contentContainer.current && typeof(onSizeChange) === 'function') {
            onPanelSizeChange();
            window.addEventListener('resize', onPanelSizeChange);
            return () => {
                window.removeEventListener('resize', onPanelSizeChange);
            }
        }
    }, [contentContainer.current]);

    useEffect(() => {
        setUpdated(moment());
    }, [children]);

    useEffect(() => {
        onFiltersExpandChange();
    }, [filtersExpanded]);

    useEffect(() => {
        onSortExpandChange();
    }, [sortExpanded]);

    useEffect(() => {
        runAnimations();
        utils.events.on(`${id}_internal`, 'visibility_change', onVisibilityValueChange);
        utils.events.on(`${id}_internal`, 'visibility_change_silent', onVisibilityValueChangeSilent);
        return () => {
            utils.events.off(`${id}_internal`, 'visibility_change', onVisibilityValueChange);
            utils.events.off(`${id}_internal`, 'visibility_change_silent', onVisibilityValueChangeSilent);
        }
    }, []);

    return (
        <animated.div
        className={`panel window ${className || `${column || 'col-12'} ${embeded ? 'px-3 pt-3 pb-0' : 'p-3'}`}`}
        style={{
            position: 'relative',
            ...animations
        }}>
            <div style={{
                maxWidth: maxWidth,
                paddingTop: name && Utils.isMobile() ? 40 : 0,
                position: 'relative',
                width: '100%',
                ...style
            }}>
                {/* mobiles panel title */}
                {name && Utils.isMobile() && (
                    <div style={{
                        alignItems: 'center',
                        backgroundColor: Appearance.colors.panelMobileTabBackground(),
                        border: `4px solid ${window.theme === 'dark' ? 'rgba(25,25,25,1)' : 'white'}`,
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        display: 'flex',
                        flexDirection: 'row',
                        height: 43,
                        justifyContent: 'center',
                        left: 15,
                        paddingLeft: 12,
                        paddingRight: 12,
                        position: 'absolute',
                        right: 15,
                        textAlign: 'center',
                        top: 0
                    }}>
                        <span style={{
                            color: Appearance.colors.text(),
                            flexGrow: 1,
                            fontSize: 14,
                            fontWeight: '700',
                            textAlign: 'center',
                            maxWidth: '100%',
                            overflow: 'hidden',
                            paddingRight: 16,
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}>{name}</span>
                    </div>
                )}

                {/* panel container */}
                <div style={{
                    height: '100%',
                    ...embeded === true && {
                        ...Appearance.styles.unstyledPanel(),
                        borderColor: Appearance.colors.softBorder(),
                    },
                    ...embeded === false && {
                        ...Appearance.styles.panel(),
                        overflow: 'visible'
                    }
                }}>
                    {/* hide top bar if mobile */}
                    <div style={{
                        ...Appearance.styles.header(),
                        display: Utils.isMobile() || !name ? 'none' : 'block',
                        ...headerStyle
                    }}>
                        <div style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            position: 'relative'
                        }}>
                            <div style={{
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'row',
                                flexGrow: 1
                            }}>
                                {embeded !== true && (
                                    <img
                                    src={'images/aft_system_user.png'}
                                    style={{
                                        height: 30,
                                        marginRight: 8,
                                        objectFit: 'contain',
                                        width: 30
                                    }} />
                                )}
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}>
                                    <span style={{
                                        color: Appearance.colors.text(),
                                        fontSize: 16,
                                        fontWeight: 700,
                                        lineHeight: 1.1,
                                        marginBottom: 2,
                                        maxWidth: '100%',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                    }}>{name}</span>
                                    <span style={{
                                        color: Appearance.colors.subText(),
                                        fontSize: 11,
                                        fontWeight: 600,
                                        lineHeight: 1.1,
                                        maxWidth: '100%',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                    }}>{subTitle || `Last Updated: ${getUpdatedDate()}`}</span>
                                </div>
                            </div>
                            {getButtons()}
                            {filters && getFilterComponents(filters)}
                        </div>
                    </div>
                    <div
                    className={'card-body p-0'}
                    style={{
                        position: 'relative'
                    }}>
                        {loading && (
                            <div style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                height: 2,
                                borderRadius: 1,
                                overflow: 'hidden'
                            }}>
                                <ProgressBar/>
                            </div>
                        )}
                        {getSearchFields()}
                        <div
                        ref={contentContainer}
                        className={`card-body-content`}
                        style={{
                            padding: removePadding ? 0 : 15,
                            overflowY: removeOverflow === true ? 'visible' : 'scroll'
                        }}>
                            {children}
                        </div>
                    </div>
                    <PageControl {...paging} />
                </div>
            </div>
        </animated.div>
    )
}
export default Panel;
